<template>
  <div class="onboarding-container grid w-full grid-cols-1 md:grid-cols-12">
    <div class="col-span-1 p-4 md:col-span-8 md:p-8">
      <div
        class="container relative mx-auto mb-8 flex flex-col justify-between space-y-5 md:mb-24 md:max-w-4xl md:flex-row md:items-center md:space-y-0"
      >
        <div
          class="absolute top-0 left-0 right-0 flex w-full flex-row items-center justify-between text-sm text-gray-400"
        >
          <small
            @click="goBack"
            class="flex w-full max-w-[10rem] cursor-pointer flex-row items-center gap-2 underline hover:text-emerald-500"
          >
            <ArrowLeftIcon class="h-4 w-4" />
            Back
          </small>
          <div class="h-1.5 rounded-full bg-gray-100 dark:bg-gray-700 md:mx-5 md:w-[60%]">
            <div
              class="h-1.5 rounded-full bg-emerald-600"
              style="width: 45%"
            ></div>
          </div>
          <span class="w-full max-w-[10rem] text-right">
            <small v-if="projectsStore.getLastSavedTime">
              Last saved: {{ moment(projectsStore.getLastSavedTime).utc(true).from() }}
            </small>
            <small v-else> Last saved: 0 mins ago </small>
          </span>
        </div>
      </div>
      <div class="container mx-auto md:max-w-xl">
        <div
          class="mb-12 flex w-full flex-col items-start justify-items-stretch gap-4 md:justify-items-start"
        >
          <h1 class="text-3xl font-semibold dark:text-gray-300 md:text-4xl">Create a project</h1>
          <p class="inline-block w-full text-lg text-gray-600">
            Your project will be crucial on scaling up carbon removal globally. Progress is saved
            automatically every minute.
          </p>
        </div>
        <Input
          placeholder="Name your project"
          label="Name your project"
          autocomplete="off"
          v-model="projectsStore.project.name"
          type="text"
          name="projectName"
          id="projectName"
          chunky
          full
        />
        <div class="w-full">
          <label class="mb-2 block text-base font-medium text-gray-600 dark:text-gray-500">
            Select project type
          </label>
          <v-select
            placeholder="Select project type"
            class="sq-v-s mb-4"
            v-model="projectsStore.project.type"
            :options="categories"
          />
        </div>
        <div class="w-full">
          <label class="mb-2 block text-base font-medium text-gray-600 dark:text-gray-500">
            Location for your project
          </label>
          <GMapAutocomplete
            placeholder="Location of your project"
            v-model="projectsStore.project.location"
            @place_changed="onPlace"
            name="projectLocation"
            class="w-full rounded-md border border-gray-100 bg-gray-50 py-4 px-4 placeholder-gray-400 invalid:border-pink-500 invalid:text-pink-600 focus:border-emerald-500 focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 disabled:shadow-none dark:border-gray-900 dark:bg-gray-900 dark:text-gray-300"
            required
          />
        </div>

        <div class="my-8">
          <Button
            text="Continue to project details"
            type="submit"
            @click="onCreateProjectSubmit"
            :loading="isCreateProjectLoading"
            :disabled="isCreateProjectLoading"
            chunky
            full
          />

          <span
            v-if="isCreateProjectError"
            class="mt-10 block text-sm text-red-400"
            >{{ createProjectError }}</span
          >
        </div>
      </div>
    </div>

    <div
      class="relative top-0 col-span-1 h-auto bg-gray-50 py-8 px-8 dark:bg-gray-900 md:sticky md:col-span-4 md:h-screen md:py-32"
    >
      <div class="flex flex-col items-stretch gap-4 md:max-w-lg">
        <div class="flex flex-col items-stretch gap-1">
          <span class="block text-2xl font-semibold dark:text-gray-300 md:text-xl">
            Location and activity example from a similar project near you in Tanzania
          </span>
          <span class="block text-sm text-gray-400">
            As you progress through the project creation flow we'll show you information on
            comparable projects so you can better understand the type and quality of information
            we're looking for when adding a project to Sequestr.
          </span>
        </div>
        <img
          src="/img/project-creation-illustration.png"
          class="mt-10 w-full"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import { useMutation } from "vue-query";
  import moment from "moment";

  import { ArrowLeftIcon } from "@heroicons/vue/24/outline";

  import { useProjectStore } from "@/store/projectStore";

  import { logEvent, logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import Input from "@/components/Input.vue";

  const router = useRouter();
  const route = useRoute();
  const projectsStore = useProjectStore();

  const categories = ref([
    { label: "Silvopasture agroforestry", code: "Silvopasture agroforestry" },
    { label: "Tree-crop agroforestry", code: "Tree-crop agroforestry" },
    { label: "Afforestation & reforestation", code: "Afforestation & reforestation" },
    { label: "Improved forest management", code: "Improved forest management" },
    { label: "Costal ecosystem restoration", code: "Costal ecosystem restoration" },
    { label: "Sustainable soil management", code: "Sustainable soil management" },
    { label: "Biomass permanent removal", code: "Biomass permanent removal" },
    { label: "Direct air capture", code: "Direct air capture" },
    { label: "Enhanced mineralization", code: "Enhanced mineralization" },
    { label: "Renewable energy", code: "Renewable energy" },
    { label: "Sustainable grassland management", code: "Sustainable grassland management" },
  ]);

  onMounted(() => {
    projectsStore.clearProject();
    logPageView(route.name);
  });

  const {
    isLoading: isCreateProjectLoading,
    isError: isCreateProjectError,
    error: createProjectError,
    mutate: createProjectMutate,
  } = useMutation(() => projectsStore.createProject(), {
    onSuccess: (data) => {
      router.push(`/project/${data.slug}/edit`);
    },
  });

  const onCreateProjectSubmit = () => {
    logEvent("Submit project");
    createProjectMutate();
  };

  const goBack = () => {
    if (!projectsStore.previousStep()) {
      router.push("/projects");
      return;
    }

    return projectsStore.previousStep();
  };

  const onPlace = (data) => {
    projectsStore.project.location = data;
  };
</script>
