<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <div
        v-if="isGetUserLoading"
        class="flex h-screen w-full animate-pulse flex-col items-center justify-center text-center text-5xl font-bold text-gray-300"
      >
        <small class="mx-auto w-full">Loading profile</small>
      </div>
      <div
        v-if="noProfile"
        class="flex h-screen w-full flex-col items-center justify-center gap-8 text-center text-5xl font-bold text-gray-300"
      >
        <small class="mx-auto w-full">This user does not exist</small>
        <div class="mx-auto w-auto">
          <Button
            @click="claimProfile"
            classes="bg-transparent border-2 border-emerald-100 text-emerald-500 shadow-none text-sm w-full py-1 px-2"
            text="Claim this profile"
            type="button"
          />
        </div>
      </div>
      <div
        v-else
        class="w-full"
      >
        <!-- Header -->
        <div
          class="items-middle flex w-full flex-col items-center justify-start gap-4 md:flex-row md:justify-between"
        >
          <div class="flex w-full flex-col gap-4">
            <h1 class="mb-4 text-4xl font-semibold dark:text-gray-300">{{ profile.name }}</h1>
            <p
              v-if="profile.bio"
              class="mb-4 whitespace-pre-line text-base leading-6 text-gray-500"
            >
              {{ profile.bio }}
            </p>
            <VisitWebsite
              v-if="profile.external_link"
              :link="profile.external_link"
            />
            <div class="flex flex-col gap-4 md:flex-row">
              <Button
                classes="text-gray-800 dark:text-gray-500 bg-transparent dark:bg-gray-800 hover:bg-gray-100 border-gray-200 dark:border-gray-700 shadow-none text-sm gap-2"
                text="Logout"
                type="button"
                :disabled="isLogOutLoading"
                :loading="isLogOutLoading"
                @click="logOutMutate"
              />
              <Button
                text="Edit profile"
                type="button"
                iconRight="PencilIcon"
                :to="`/edit/profile`"
              />
            </div>
          </div>
          <div class="order-first col-auto mb-4 md:order-last md:mb-0">
            <div
              class="flex flex-col items-center justify-center md:items-end md:justify-items-end"
            >
              <ProfilePicture :src="profile.avatar_url" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from "vue";
  import { useRouter } from "vue-router";
  import { useMutation } from "vue-query";
  import { storeToRefs } from "pinia";
  import { createToast } from "mosha-vue-toastify";

  import { ClipboardDocumentIcon, PencilIcon } from "@heroicons/vue/24/outline";
  import { TabGroup, TabList, TabPanels, TabPanel, Tab } from "@headlessui/vue";

  // import { logEvent } from "@/services/analytics";

  import { useAuthStore } from "@/store/authStore";

  import Button from "@/components/Button.vue";
  import ProfilePicture from "@/components/ProfilePicture.vue";
  import VisitWebsite from "@/components/VisitWebsite.vue";

  const router = useRouter();

  let selectedTab = ref(0);
  //
  const authStore = useAuthStore();
  const { user: profile } = storeToRefs(authStore);

  const {
    isLoading: isLogOutLoading,
    isError: isLogOutError,
    error: logOutError,
    mutate: logOutMutate,
  } = useMutation(authStore.logout, {
    onSuccess: () => {
      router.push("/");
    },
    onError: () => {
      createToast("Something went wrong, please try again", {
        type: "danger",
        position: "bottom-right",
      });
    },
  });

  const noProfile = ref(false);

  const {
    isLoading: isGetUserLoading,
    isError: isGetUserError,
    error: getUserError,
    mutate: getUserMutate,
  } = useMutation(authStore.getUser, {
    onSuccess: () => {
      console.info("user:", authStore.user);
    },
    onError: (error) => {
      // TODO: add profile 404s
      console.error("getUser mutate error: ", error);
    },
  });

  const claimProfile = () => {
    console.log("TODO: Add Claim profile");
  };

  let isMyProfile = authStore.user.isMyProfile || false;
</script>
