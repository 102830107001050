<template>
  <div
    class="sticky right-0 left-0 top-0 w-full bg-gray-50/50 text-sm text-gray-600 backdrop-blur-sm dark:bg-gray-900 dark:text-gray-500"
  >
    <div class="container mx-auto">
      <div class="flex items-center justify-between py-2 px-4">
        <span class="flex-row justify-start md:block">
          The Sequestr team is reviewing your profile
        </span>
        <router-link
          class="rounded bg-white px-2 py-1 font-semibold shadow-md dark:bg-gray-600 dark:text-white"
          to="/onboarding"
          >View</router-link
        >
      </div>
    </div>
  </div>
</template>

<script setup></script>
