<template>
  <div class="relative block w-full">
    <div
      :class="[
        'w-fit max-w-md text-ellipsis rounded-full border border-gray-100 p-2 text-gray-900 dark:border-gray-800 dark:text-gray-300 ',
        size === 'small' ? 'text-xs md:text-sm' : 'text-sm md:text-base',
      ]"
    >
      <div class="flex w-auto flex-row items-center justify-start gap-2">
        <Avatar
          :imageUrl="company.imageUrl"
          :title="company.title"
          :size="size"
        />
        <div
          :class="[
            'flex flex-row items-center justify-start font-semibold',
            size === 'small' ? 'mr-1' : 'mr-2',
          ]"
        >
          <p class="w-full overflow-hidden text-ellipsis">{{ company.title }} • &nbsp;</p>
          <a
            v-if="company?.websiteUrl"
            class="overflow-ellipsis whitespace-nowrap font-medium underline opacity-75"
            :href="companyURL"
            target="_blank"
          >
            Visit
          </a>
          <span
            v-else
            class="font-medium opacity-75"
            >Project owner</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from "vue";

  import Avatar from "./Avatar.vue";

  const props = defineProps({
    company: {
      id: String,
      title: String,
      slug: String,
      imageUrl: String,
      websiteUrl: String,
    },
    size: {
      type: String,
      default: "normal",
    },
  });

  const { company, size } = props;

  const companyURL = computed(() => {
    if (!company.websiteUrl) return null;
    console.info({ company });

    const testURL = /(http(s?)):\/\//i.test(company.websiteUrl);
    // If testURL fails, likely no http/https
    if (!testURL) return `//${company.websiteUrl}`;
    return company.websiteUrl;
  });
</script>
