<script setup>
  import moment from "moment";

  const props = defineProps({
    title: String || Number,
    helper: String || Number,
    amountDollars: Number,
    amountTonnes: Number,
    endDate: String,
    user: {
      company: Object,
      avatarUrl: String,
      id: String || Number,
      name: String,
      email: String,
    },
  });

  const { title, helper, amountDollars, amountTonnes, endDate, user } = props;
</script>

<template>
  <div
    class="flex flex-col justify-between gap-4 border-b border-gray-100 py-4 align-middle text-base font-normal last-of-type:border-b-0 dark:border-gray-800 md:flex-row"
  >
    <div class="flex flex-col gap-0">
      <strong class="font-semibold text-gray-900 dark:text-gray-400">{{ title }}</strong>
      <small class="text-gray-500">
        Auction {{ moment(endDate).isAfter() ? `will end on` : `ended` }}
        {{ moment(endDate).format("LL") }} {{ helper }}
      </small>
    </div>
    <div
      class="flex flex-row justify-start gap-4 text-right text-sm md:justify-end md:text-inherit"
    >
      <p
        v-if="amountDollars"
        class="text-gray-500"
      >
        {{
          amountDollars.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}
        USD
      </p>
      <p
        v-if="amountTonnes"
        class="text-gray-500"
      >
        {{ amountTonnes.toLocaleString() }} tonnes
      </p>
    </div>
  </div>
</template>
