<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <Loader v-if="isLoading" />

      <span
        v-if="isError"
        class="mt-10 block text-sm text-red-400"
        >Something went wrong: {{ error }}</span
      >

      <div v-if="data">
        <div class="flex flex-col gap-6">
          <PageHeader
            :title="`${data.title} projects`"
            :body="
              !data.projects.length ? `No active projects` : `${data.projects.length} projects`
            "
          />
          <ProjectsGrid
            v-if="!isLoading && data.projects.length"
            :projects="data.projects"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted } from "vue";
  import { useMutation } from "vue-query";
  import { useRoute } from "vue-router";

  import { useCompanyStore } from "@/store/companyStore";
  import { logPageView } from "@/services/analytics";

  import Loader from "@/components/Loader.vue";
  import PageHeader from "@/components/PageHeader.vue";
  import ProjectsGrid from "@/components/ProjectsGrid.vue";

  const route = useRoute();
  const companyStore = useCompanyStore();

  const { isLoading, isError, error, mutate, data } = useMutation((slug) =>
    companyStore.getCompany(slug),
  );

  onMounted(() => {
    const slug = route.params.slug;
    mutate(slug);
    logPageView(route.name);
  });
</script>
