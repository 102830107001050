<template>
  <div
    class="flex flex-row justify-between gap-4 border-b border-gray-100 py-2 align-middle text-base font-normal last-of-type:border-b-0 dark:border-gray-800"
  >
    <div class="flex flex-1 flex-row items-center justify-start gap-3">
      <!-- Text -->
      <div class="flex flex-1 flex-col gap-0">
        <strong class="text-base font-normal text-gray-900 dark:text-gray-500">
          {{ title }}
        </strong>
      </div>
    </div>
    <div class="flex flex-row justify-end gap-2 align-middle">
      <div class="flex flex-row items-center align-middle">
        <small
          v-if="year"
          class="w-24 text-right text-base text-gray-900 dark:text-gray-400 md:max-w-none md:text-inherit"
        >
          {{ year }}
        </small>
        <small
          v-if="amount"
          class="w-24 text-right text-base text-gray-900 dark:text-gray-400 md:max-w-none md:text-inherit"
        >
          {{ amount.toLocaleString("en", { maximumFractionDigits: 2 }) }}
        </small>
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    title: {
      required: true,
      type: String || Number,
    },
    helper: String | Number,
    year: String | Number,
    amount: String | Number,
    opacity: {
      default: null,
      type: String,
    },
  });

  const { title, year, amount } = props;
</script>
