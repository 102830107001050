import { defineStore } from "pinia";
import { api } from "@/utils/axios";
import { useCookies } from "vue3-cookies";
import { parseJwt } from "@/utils/jwtDecoder";
import { identify } from "@/services/analytics";

const { cookies } = useCookies();

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    verifyEmailOpenModal: false,
    magicLinkSetModal: false,
    user: null,
    onboardingAnswers: {
      name: "",
      role: "common", // common,project
      companyName: "",
      companyUrl: "",
    },
  }),
  getters: {
    hasUserNotStartedOnboarding(state) {
      return state?.user?.verificationStatus === "none";
    },
    isUserStatusVerified(state) {
      return state?.user?.verificationStatus === "verified";
    },
    isUserStatusComplete(state) {
      return state?.user?.verificationStatus === "complete";
    },
    isUserPendingVerification(state) {
      return state?.user?.verificationStatus === "pending";
    },
    userHasCompanyRegistered(state) {
      return state?.user?.company;
    },
  },
  actions: {
    toggleVerifyEmailModal() {
      this.verifyEmailOpenModal = !this.verifyEmailOpenModal;
    },
    toggleMagicLinkSentModal() {
      this.magicLinkSetModal = !this.magicLinkSetModal;
    },
    async signIn(body, isMagicLinkSet = false) {
      console.info({ body });
      const { data } = await api.post("auth/login", body);
      if (!isMagicLinkSet) {
        cookies.set("session", data.access_token);
        await this.getUser();
      }
    },
    oAuth: async () => {
      const { data } = await api.post("auth/oauth/google");
      window.open(data, "_self");
    },
    signUp: (body) => {
      return api.post("auth/register", {
        email: body.email,
        password: body.password,
        role: body.role.code,
      });
    },
    forgotPassword: (body) => {
      return api.post("auth/forgot-password", body);
    },
    confirmForgotPassword: (token, newPassword) => {
      return api.post("auth/forgot-password-confirm", {
        accessToken: token,
        newPassword,
      });
    },
    getBidsAndPurchases(page) {
      return api.get("user/purchases", {
        params: {
          page,
        },
      });
    },
    async logout() {
      await api.post("auth/logout");
      this.clearAuth();
    },
    setUser(sessionToken) {
      this.user = parseJwt(sessionToken);
    },
    async getUserWithToken(token) {
      cookies.set("session", token);

      try {
        const user = await this.getUser();
        return user;
      } catch (error) {
        cookies.remove("session");
        console.error(error);
        this.user = null;
        throw error;
      }
    },
    async getUser() {
      if (!cookies.get("session")) {
        return;
      }

      const { data } = await api.get("user");

      this.user = data;

      if (this.hasUserNotStartedOnboarding) {
        if (data.username) {
          this.onboardingAnswers.username = data.username;
        }

        if (data.name) {
          this.onboardingAnswers.name = data.name;
        }

        if (data.userRole) {
          this.onboardingAnswers.role = data.userRole;
        }

        if (data?.company?.title) {
          this.onboardingAnswers.companyName = data.company.title;
        }

        if (data?.company?.websiteUrl) {
          this.onboardingAnswers.companyUrl = data.company.websiteUrl;
        }
      }

      // Analytics
      identify(this.user.id);

      return data;
    },
    async updateUser(body) {
      const { data } = await api.patch("user", body);
      this.user = data;
      return data;
    },
    async submitOnboardingAnswers(body) {
      const { data } = await api.post("user/onboarding", body);
      this.user = data;
    },
    async inviteEmails(emails) {
      return await api.post("auth/invite-emails", emails);
    },
    async updateOnboardingStatus(newStatus) {
      const { data } = await api.patch("user/onboarding", newStatus);
      this.user = data;
    },
    clearAuth() {
      cookies.remove("session");
      this.user = null;
    },
  },
});
