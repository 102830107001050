import { defineStore } from "pinia";
// import { useStorage } from "@vueuse/core";

import clientsGlobal from "@/utils/corporate_clients.json";
import clientsJSON from "@/utils/corporate_clients_small.json";
import countries from "@/utils/country-by-continent.json";

let clients = clientsJSON;

const assetManager = {
  name: "BentallGreenOak",
};

const calcNetZeroClients = () => {
  return clients.filter((client) => client.net_zero_committed).length;
};

const totalCarbonEmissionsCurrentYear = () => {
  return clients.reduce((sum, client) => {
    return (
      sum ||
      0 + client.emissions.current_year?.scope_1 ||
      0 + client.emissions.current_year?.scope_2 ||
      0 + client.emissions.current_year?.scope_3 ||
      0
    );
  }, 0);
};

const calcAverageYearlyPurchases = () => {
  let totalCarbonCreditsPurchased = 0;
  let count = 0;

  for (let i = 0; i < clients.length; i++) {
    if (clients[i].carbon_credits && clients[i].carbon_credits.purchased) {
      totalCarbonCreditsPurchased += clients[i].carbon_credits.purchased;
      count++;
    }
  }

  return count > 0 ? totalCarbonCreditsPurchased / count : 0;
};

const calcCommittedPurchases = () => {
  let totalCarbonCreditsCommitted = 0;
  let count = 0;

  for (let i = 0; i < clients.length; i++) {
    if (clients[i].carbon_credits && clients[i].carbon_credits.committed_future_offsets) {
      totalCarbonCreditsCommitted += clients[i].carbon_credits.committed_future_offsets;
      count++;
    }
  }

  return count > 0 ? totalCarbonCreditsCommitted / count : 0;
};

const ratingValues = {
  AAA: 22,
  "AA+": 21,
  AA: 20,
  "AA-": 19,
  "A+": 18,
  A: 17,
  "A-": 16,
  "BBB+": 15,
  BBB: 14,
  "BBB-": 13,
  "BB+": 12,
  BB: 11,
  "BB-": 10,
  "B+": 9,
  B: 8,
  "B-": 7,
  "CCC+": 6,
  CCC: 5,
  "CCC-": 4,
  CC: 3,
  C: 2,
  D: 1,
  undefined: 11,
};

const convertRatingsToValues = (ratings) => {
  return ratings.map((rating) => ratingValues[rating]);
};

const averageRating = convertRatingsToValues(
  clients.map((client) => client.financial_data?.esg_rating),
);

const averageESGRating = () => {
  const averageRatingPercent = averageRating.reduce((a, b) => a + b, 0) / averageRating.length;

  // Find the closest rating inside ratingValues from averageRatingPercent and return it as an object
  const closestRating = Object.keys(ratingValues).reduce((prev, curr) => {
    return Math.abs(ratingValues[curr] - averageRatingPercent) <
      Math.abs(ratingValues[prev] - averageRatingPercent)
      ? curr
      : prev;
  });

  // Find the lowest and highest keys and values inside ratingsValues
  const lowestRatingValue = Object.keys(ratingValues).reduce((prev, curr) => {
    return ratingValues[curr] < ratingValues[prev] ? curr : prev;
  });
  const highestRatingValue = Object.keys(ratingValues).reduce((prev, curr) => {
    return ratingValues[curr] > ratingValues[prev] ? curr : prev;
  });
  const lowestRating = {
    rating: lowestRatingValue,
    value: ratingValues[lowestRatingValue],
  };
  const highestRating = {
    rating: highestRatingValue,
    value: ratingValues[highestRatingValue],
  };

  const finalRating = {
    rating: closestRating,
    value: ratingValues[closestRating],
  };

  return {
    finalRating,
    lowestRating,
    highestRating,
  };
};

const getContinentByLocation = (location) => {
  // Make sure location is lowerCase and does not have spaces, same with country
  location = location.toLowerCase().replace(/\s/g, "");

  for (const item of countries) {
    // Make sure item.country is lowerCase and does not have spaces
    item.country = item.country.toLowerCase().replace(/\s/g, "");
    if (item.country === location) {
      return item.continent;
    }
  }
};

const calculateCarbonProjectStats = () => {
  const stats = {
    totalCarbonProjectsPurchased: 0,
    totalInvestmentAmount: 0,
    investmentAmountByType: {},
    totalNumberOfLocations: 0,
    locations: [],
    carbonCredits: {
      purchased: 0,
      retired: 0,
      committedFutureOffsets: 0,
    },
    carbonProjectsPurchased: {
      totalCredits: 0,
    },
  };

  const companiesWithCarbonCredits = clients.filter((company) => {
    return "carbon_credits" in company && Object.keys(company.carbon_credits).length !== 0;
  });

  companiesWithCarbonCredits.forEach((company) => {
    const carbonCredits = company.carbon_credits || {};
    stats.carbonCredits.purchased += carbonCredits.purchased || 0;
    stats.carbonCredits.retired += carbonCredits.retired || 0;
    stats.carbonCredits.committedFutureOffsets += carbonCredits.committed_future_offsets || 0;

    if ("carbon_projects_purchased" in company) {
      const carbonProjectsPurchased = company.carbon_projects_purchased;
      stats.totalCarbonProjectsPurchased += carbonProjectsPurchased.length || 0;

      carbonProjectsPurchased.forEach((project) => {
        stats.totalInvestmentAmount += project.investment_amount || 0;
        stats.carbonProjectsPurchased.totalCredits += project.carbon_credits_generated || 0;

        if (project.type in stats.investmentAmountByType) {
          stats.investmentAmountByType[project.type] += project.investment_amount;
        } else {
          stats.investmentAmountByType[project.type] = project.investment_amount;
        }

        const location = project.location;
        const continent = getContinentByLocation(location);

        // Group investmentAmountByType[project.type] by continent into the "locations" key
        if (continent in stats.locations) {
          stats.locations[continent].investmentAmount += project.investment_amount;
          stats.locations[continent].carbonCreditsGenerated += project.carbon_credits_generated;
        }
        // If continent is not in stats.locations, add it
        else {
          stats.locations[continent] = {
            investmentAmount: project.investment_amount,
            carbonCreditsGenerated: project.carbon_credits_generated,
          };
        }
      });

      stats.totalNumberOfLocations = stats.locations.length;
    }
  });

  return stats;
};

const calculateTransactionsByVintageYear = () => {
  const result = {};

  clients.forEach((client) => {
    if (!client.carbon_projects_purchased) {
      return null;
    }

    client.carbon_projects_purchased.forEach((project) => {
      const { annual_data } = project;

      for (const vintageYear in annual_data) {
        const { credits, investment_amount } = annual_data[vintageYear];

        if (result[vintageYear]) {
          result[vintageYear].credits += credits;
          result[vintageYear].investmentAmount += investment_amount;
        } else {
          result[vintageYear] = {
            credits: credits,
            investmentAmount: investment_amount,
          };
        }
      }
    });
  });

  return Object.keys(result).length === 0 ? 0 : result;
};

const projectStats = calculateCarbonProjectStats() || {};
const transactionsByVintageYear = calculateTransactionsByVintageYear() || {};

// Temp eventsByClientJSON
const eventsByClientJSON = {
  IE00B4BNMY34: {
    company_events: [
      {
        name: "New Commercial RE",
        years: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
        projected_emissions: 200000,
        location: "United States",
        event_type: "Building & Energy",
        future_inventory: [
          {
            type: "Nature-based Avoidance",
            credits: 50000,
          },
          {
            type: "Nature-based Removals",
            credits: 50000,
          },
          {
            type: "Permanent CDR",
            credits: 100000,
          },
        ],
        lender_opportunity: [
          {
            amount: 1000000,
            type: "Required Loan Amount",
          },
          {
            amount: 80000,
            type: "Loan Revenue",
          },
          {
            amount: 12.5,
            type: "Annual ROI",
          },
        ],
      },
    ],
  },
  US5801351017: {
    company_events: [
      {
        name: "Cold Storage Facility",
        years: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
        projected_emissions: 200000,
        location: "United States",
        event_type: "Building & Energy",
        future_inventory: [
          {
            type: "Nature-based Avoidance",
            credits: 50000,
          },
          {
            type: "Nature-based Removals",
            credits: 50000,
          },
          {
            type: "Permanent CDR",
            credits: 100000,
          },
        ],
        lender_opportunity: [
          {
            amount: 1000000,
            type: "Required Loan Amount",
          },
          {
            amount: 80000,
            type: "Loan Revenue",
          },
          {
            amount: 12.5,
            type: "Annual ROI",
          },
        ],
      },
      {
        name: "New Restaurants",
        years: [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
        projected_emissions: 400000,
        location: "United States",
        event_type: "Building & Energy",
        future_inventory: [
          {
            type: "Nature-based Avoidance",
            credits: 100000,
          },
          {
            type: "Nature-based Removals",
            credits: 90000,
          },
          {
            type: "Permanent CDR",
            credits: 210000,
          },
        ],
        lender_opportunity: [
          {
            amount: 4000000,
            type: "Required Loan Amount",
          },
          {
            amount: 400000,
            type: "Loan Revenue",
          },
          {
            amount: 10,
            type: "Annual ROI",
          },
        ],
      },
    ],
  },
};

const summaryStats = clients?.reduce(
  (acc, client) => {
    if (!client.carbon_projects_purchased) {
      return acc;
    }

    const projects = client.carbon_projects_purchased;

    projects.forEach((project) => {
      Object.keys(project.annual_data).forEach((year) => {
        const annualData = project.annual_data[year];

        // Initialize the accumulator for the given year if it doesn't exist
        if (!acc.yearlyStats[year]) {
          acc.yearlyStats[year] = {
            totalRevenue: 0,
            totalCarbonCredits: 0,
            revenueByProjectType: {},
            revenueByMechanism: {},
            revenueByClient: {},
            revenueByGeography: {},
          };
        }

        const yearlyStats = acc.yearlyStats[year];

        // Total Revenue
        yearlyStats.totalRevenue += annualData.investment_amount;

        // Total Carbon Credits
        yearlyStats.totalCarbonCredits += annualData.credits;

        // Revenue Projections by Project Type
        if (!yearlyStats.revenueByProjectType[project.type]) {
          yearlyStats.revenueByProjectType[project.type] = 0;
        }
        yearlyStats.revenueByProjectType[project.type] += annualData.investment_amount;

        // Group by permanentCdr, nbsRemovals, nbsAvoided using the functions above using project.type
        if (["Direct Air Capture", "Enhanced Weathering", "Biochar"].includes(project.type)) {
          if (!yearlyStats.revenueByMechanism["Permanent CDR"]) {
            yearlyStats.revenueByMechanism["Permanent CDR"] = 0;
          }
          yearlyStats.revenueByMechanism["Permanent CDR"] += annualData.investment_amount;
        } else if (["Reforestation", "Mangrove Restoration"].includes(project.type)) {
          if (!yearlyStats.revenueByMechanism["Nature-based Removals"]) {
            yearlyStats.revenueByMechanism["Nature-based Removals"] = 0;
          }
          yearlyStats.revenueByMechanism["Nature-based Removals"] += annualData.investment_amount;
        } else {
          if (!yearlyStats.revenueByMechanism["Nature-based Avoidance"]) {
            yearlyStats.revenueByMechanism["Nature-based Avoidance"] = 0;
          }
          yearlyStats.revenueByMechanism["Nature-based Avoidance"] += annualData.investment_amount;
        }

        // Revenue Projections by Client
        if (!yearlyStats.revenueByClient[client.id]) {
          yearlyStats.revenueByClient[client.id] = 0;
        }
        yearlyStats.revenueByClient[client.id] += annualData.investment_amount;

        // Revenue Projections by Project Geography using getContinentByLocation(project.location)
        const continent = getContinentByLocation(project.location);
        if (!yearlyStats.revenueByGeography[continent]) {
          yearlyStats.revenueByGeography[continent] = 0;
        }
        yearlyStats.revenueByGeography[continent] += annualData.investment_amount;
      });
    });

    return acc;
  },
  {
    yearlyStats: {},
  },
);

console.log(summaryStats);

export const useDemoStore = defineStore("demoStore", {
  state: () => ({
    assetManager: assetManager,
    totalNumberOfClients: clients.length,
    clientsWithNetZeroCommitments: calcNetZeroClients() || 0,
    projectStats: projectStats,
    averageESGRating: averageESGRating() || "AAA",
    totalCarbonEmissionsCurrentYear: totalCarbonEmissionsCurrentYear() || 0,
    totalCarbonCreditsCommitted: calcCommittedPurchases() || 0,
    averageYearlyPurchases: calcAverageYearlyPurchases() || 0,
    portfolioHealth: averageESGRating() || 0,
    transactionsByVintageYear: transactionsByVintageYear,
    globalClients: clientsGlobal,
    clients: clients,
    events: eventsByClientJSON,
    summaryStats: summaryStats,
    showAddClientModal: false,
    showProcurementModal: false,
  }),
  getters: {
    getAssetManager: (state) => state.assetManager,
    getTotalNumberOfClients: (state) => state.totalNumberOfClients,
    getClientsWithNetZeroCommitments: (state) => state.clientsWithNetZeroCommitments,
    getProjectStats: (state) => state.projectStats,
    getAverageESGRating: (state) => state.averageESGRating,
    getTotalCarbonEmissionsCurrentYear: (state) => state.totalCarbonEmissionsCurrentYear,
    getTotalCarbonCreditsCommitted: (state) => state.totalCarbonCreditsCommitted,
    getAverageYearlyPurchases: (state) => state.averageYearlyPurchases,
    getPortfolioHealth: (state) => state.portfolioHealth,
    getTransactionsByVintageYear: (state) => state.transactionsByVintageYear,
    getGlobalClients: (state) => state.globalClients,
    getClients: (state) => state.clients,
    getEvents: (state) => state.events,
    getSummaryStats: (state) => state.summaryStats,
  },
  actions: {
    addClient(client) {
      console.info({ client });
      this.clients.push(client);
      // sort
      this.clients.sort((a, b) => (a.name > b.name ? 1 : -1));
      // update totalNumberOfClients and clientsWithNetZeroCommitments
      this.totalNumberOfClients = this.clients.length;
      this.clientsWithNetZeroCommitments = calcNetZeroClients();
    },
    getSingleClient(id) {
      const client = this.clients.find((client) => client.id === id);
      console.info("getSingleClient():", client);
      return client;
    },
    getEventsByCompany(id) {
      // Find the events for the company by id inside this.events array
      const events = this.events[id]?.company_events || [];
      return events;
    },
    async setPurchasesByClient(id, purchases) {
      console.info({ id, purchases });
      // Find the client by id and update the purchases
      const client = this.clients.find((client) => client.id === id);

      if (!client) {
        throw new Error(`Client with id ${id} not found`);
      }

      // Add all the purchases to the client on top of the existing carbon_projects_purchased array if the key exists, if not add it and add the purchases to the carbon_projects_purchased key
      if (client.carbon_projects_purchased) {
        client.carbon_projects_purchased = [...client.carbon_projects_purchased, ...purchases];
      } else {
        client.carbon_projects_purchased = purchases;
      }

      const currentYear = new Date().getFullYear();

      // Update carbon_credits.purchased (if client.carbon_projects.purchased === current year), carbon_credits.retired (0), carbon_credits.committed_future_offsets.
      // Anything outside of current year inside client.carbon_projects_purchased[].annual_data[year]) should be added to carbon_credits.committed_future_offsets
      client.carbon_credits.purchased = 0;
      client.carbon_credits.retired = 0;
      client.carbon_credits.committed_future_offsets = 0;

      // Loop through the purchases and update the carbon_credits
      purchases.forEach((purchase) => {
        // Get the annual data for the purchase
        const annualData = purchase.annual_data;
        // Loop through the annual data and update the carbon_credits
        Object.keys(annualData).forEach((year) => {
          // If the year is the current year, update the carbon_credits.purchased
          if (parseInt(year) === currentYear) {
            client.carbon_credits.purchased += annualData[year].credits;
          } else {
            // If the year is not the current year, update the carbon_credits.committed_future_offsets
            client.carbon_credits.committed_future_offsets += annualData[year].credits;
          }
        });
      });

      // Update the summary stats
      this.summaryStats = summaryStats;
      // Update the project stats
      this.projectStats = calculateCarbonProjectStats();
      // Update the totalCarbonCreditsCommitted
      this.totalCarbonCreditsCommitted = calcCommittedPurchases();
      // Update the averageYearlyPurchases
      this.averageYearlyPurchases = calcAverageYearlyPurchases();

      // Return a promise that resolves with the updated client object
      return Promise.resolve(client);
    },
    toggleAddNewClientModal() {
      this.showAddClientModal = !this.showAddClientModal;
    },
    toggleProcurementModal() {
      this.showProcurementModal = !this.showProcurementModal;
    },
  },
});
