<script setup>
  import ProgressBar from "./ProgressBar.vue";

  const props = defineProps({
    title: [String, Number],
    body: [String, Number],
    large: Boolean,
    progress: Boolean,
    progressData: {
      finalRating: { rating: String, value: Number },
      highestRating: { rating: String, value: Number },
      lowestRating: { rating: String, value: Number },
    },
  });

  let { title, body, large, progress, progressData } = props;
</script>

<template>
  <div
    :class="[
      'flex flex-col gap-4 rounded-xl',
      'border-2 border-gray-100 bg-white/80 dark:border-gray-800 dark:bg-white/10',
      'p-4 md:p-6',
      large ? 'justify-start md:justify-end' : 'justify-start md:justify-between',
    ]"
  >
    <p
      v-if="title"
      :class="['text-sm font-normal text-gray-500', large ? 'margin-b-large-fix' : '']"
    >
      {{ title }}
    </p>
    <strong
      v-if="body && !progress"
      :class="`inline-block w-full ${
        large ? 'text-3xl text-emerald-500' : 'text-lg text-gray-900 dark:text-gray-300'
      }`"
    >
      {{ body }}
    </strong>
    <ProgressBar
      v-if="progress && progressData"
      :leftText="progressData.lowestRating.rating"
      :rightText="progressData.highestRating.rating"
      :lowestProgressValue="progressData.lowestRating.value"
      :highestProgressValue="progressData.highestRating.value"
      :currentProgress="progressData.finalRating.value"
      :currentProgressText="progressData.finalRating.rating"
    />
  </div>
</template>

<style lang="scss" scoped>
  .margin-b-large-fix {
    @media screen and (min-width: 48rem) {
      margin-bottom: -0.5rem;
    }
  }
</style>
