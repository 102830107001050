<template>
  <a
    :href="link"
    target="_blank"
    rel="noopener noreferrer"
    class="mb-8 flex w-full cursor-pointer flex-row items-center justify-center gap-4 rounded-md border border-blue-100 py-1 px-2 text-center font-medium text-blue-800 hover:bg-blue-50 dark:border-blue-800 dark:text-blue-500 md:max-w-[10rem]"
    @click="trackEvent"
  >
    Visit website
    <ArrowLongRightIcon class="h-4 w-4" />
  </a>
</template>
<script>
  import { ArrowLongRightIcon } from "@heroicons/vue/24/solid";
  import { logEvent } from "@/services/analytics";

  export default {
    components: {
      ArrowLongRightIcon,
    },
    props: {
      link: String,
    },
    setup(props) {
      const trackEvent = () => {
        logEvent("Visit website", {
          destination: props.link,
        });
      };
      return {
        trackEvent,
      };
    },
  };
</script>
