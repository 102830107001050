<script setup>
  import { onMounted, isProxy, nextTick, ref, toRaw, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";

  import { TabGroup, TabList, TabPanels, TabPanel, Tab } from "@headlessui/vue";
  import { StarIcon } from "@heroicons/vue/24/outline";

  import { useDemoStore } from "@/store/demoStore";

  import Button from "@/components/Button.vue";
  import ClientHeader from "@/components/ClientHeader.vue";
  import ListItem from "@/components/ListItem.vue";
  import StackedListItem from "@/components/StackedListItem.vue";

  import CompanyInventory from "@/components/Companies/CompanyInventory.vue";
  import CompanyEmissions from "@/components/Companies/CompanyEmissions.vue";
  import CompanyEvents from "@/components/Companies/CompanyEvents.vue";
  import CompanySustainability from "@/components/Companies/CompanySustainability.vue";
  import ProcureInventory from "@/components/Modals/ProcureInventory.vue";

  const demoStore = useDemoStore();
  const route = useRoute();
  const router = useRouter();

  let loaded = ref(false);
  let client = ref({
    name: "",
    id: "",
    brand_icon: "/favicons/apple-touch-icon.png",
    carbon_credits: {},
    carbon_projects_purchased: [],
    organization_type: "",
    progress: {},
    region: "",
    sector: "",
    target: "No target set",
    target_classification: "No target set",
    events: [],
  });
  let growthScenarios = ref("High Growth");
  let inventoryByYear = ref("All Years");
  let inventoryByYearOptions = ref({
    label: "All Years",
    value: "All Years",
  });
  let selectedYear = ref("All Years");
  let projectsPurchased = ref(null);
  let emissions = ref(null);

  const currentTab = ref(0);
  const tabs = [
    {
      name: "Corporate Emissions",
    },
    {
      name: "Inventory",
    },
    {
      name: "Events",
    },
    {
      name: "Sustainability Report",
    },
  ];

  const setSelectedTabIndex = (index) => {
    currentTab.value = index;
  };

  const updateEmissionsData = async (scenario) => {
    console.info({ scenario });
    emissions.value = false;
    let scope1Multiple = 1;
    let scope2scope1Multiple = 1;
    let scope3scope1Multiple = 1;
    switch (scenario) {
      case "High Growth":
        scope1Multiple = 1;
        break;
      case "Medium Growth":
        scope1Multiple = 0.75;
        scope2scope1Multiple = 0.5;
        scope3scope1Multiple = 0.25;
        break;
      case "Low Growth":
        scope1Multiple = 0.35;
        scope2scope1Multiple = 0.2;
        scope3scope1Multiple = 0.1;
        break;
      default:
        scope1Multiple = 1;
    }

    let newEmissions = {};
    let newProjectsPurchased = [];

    await nextTick();

    // Update the emissions.value and projectsPurchased.value with the new growth scenario ie: If Medium Growth multiply emissions and carbon_projects_purchased values by 0.75, if Low Growth scope1Multiple by 0.35, High Growth is the default
    // If scope1Multiple is 1, use the original values from client
    if (scope1Multiple === 1) {
      newEmissions = client.emissions;
      newProjectsPurchased = client.carbon_projects_purchased;
    } else {
      newEmissions = {
        current_year: {
          scope_1: client.emissions.current_year.scope_1 * scope1Multiple,
          scope_2: client.emissions.current_year.scope_2 * scope2scope1Multiple,
          scope_3: client.emissions.current_year.scope_3 * scope3scope1Multiple,
          total: client.emissions.current_year.total * scope1Multiple,
        },
        // For all objects inside projections (looks like: projections: [ { year: 2024, scope_1: Number, scope_2: Number, scope_3: Number }... ]) scope1Multiple all the values by scope1Multiple
        projections: client.emissions.projections.map((projection) => {
          return {
            ...projection,
            scope_1: projection.scope_1 * scope1Multiple,
            scope_2: projection.scope_2 * scope2scope1Multiple,
            scope_3: projection.scope_3 * scope3scope1Multiple,
          };
        }),
      };
      client.carbon_projects_purchased?.forEach((project) => {
        newProjectsPurchased.push({
          ...project,
          carbon_offset: project.carbon_offset * scope1Multiple,
        });
      });
    }

    emissions.value = newEmissions;
    projectsPurchased.value = newProjectsPurchased;

    console.info({
      newEmissions,
      newProjectsPurchased,
    });
  };

  const onGrowthScenarioChange = (e) => {
    growthScenarios.value = e.label;
    updateEmissionsData(e.label);
  };

  const onInventoryByYearChange = (e) => {
    inventoryByYear.value = toRaw({
      label: e.label,
      inventory: e.inventory,
    });

    selectedYear.value = toRaw(e.label);
  };

  const updateProjectData = () => {
    const { id } = route.params;
    if (!id) {
      router.push({ name: "AssetManagers" });
      return;
    }

    const singleClientData = demoStore.getSingleClient(id);
    const events = demoStore.getEventsByCompany(id);

    let rawClient = toRaw(singleClientData);
    if (isProxy(singleClientData)) {
      rawClient = toRaw(singleClientData);
      rawClient.events = toRaw(events);
    }
    client = rawClient;
    console.info({ client: rawClient });

    projectsPurchased.value = client.carbon_projects_purchased;
    emissions.value = client.emissions;

    // Set inventoryByYearOptions using client.carbon_projects_purchased[Array].annual_data[Object] where the key is the year, and key.credits is the value, add up all the credits and set totalInventory into a new variable
    let inventoryByYearOptionsTemp = [];
    let totalInventory = 0;
    rawClient.carbon_projects_purchased?.forEach((project) => {
      console.info({ project });
      if (!project) return;

      // project.annual_data is an object, with each key being the year, add the credits if the year is already in the array, otherwise push it
      Object.keys(project.annual_data).forEach((year) => {
        const credits = project.annual_data[year].credits;
        totalInventory += credits;

        const existingYear = inventoryByYearOptionsTemp.find(
          (vintageYear) => vintageYear.year === year,
        );
        if (existingYear) {
          existingYear.credits += credits;
        } else {
          inventoryByYearOptionsTemp.push({
            year,
            credits,
          });
        }
      });
    });
    console.info({
      inventoryByYearOptionsTemp,
      totalInventory,
    });
    inventoryByYearOptionsTemp.sort((a, b) => a - b);
    inventoryByYearOptionsTemp = inventoryByYearOptionsTemp.map((vintageYear) => {
      return {
        label: parseInt(vintageYear.year),
        inventory: vintageYear.credits || 0,
      };
    });

    inventoryByYearOptionsTemp.unshift({
      label: "All Years",
      inventory: totalInventory,
    });

    inventoryByYearOptions.value = inventoryByYearOptionsTemp;

    loaded.value = true;
  };

  const toggleProcureInventoryModal = () => {
    demoStore.toggleProcurementModal();
  };

  const reloadTabs = () => {
    console.info("reloadTabs");
    setSelectedTabIndex(0);
    updateProjectData();
  };

  onMounted(() => {
    // Scroll to top of page
    window.scrollTo(0, 0);
    updateProjectData();
  });

  // Watch growthScenarios changes and update the emissions and projectsPurchased values
  watch(
    growthScenarios,
    (newGrowthScenario) => {
      updateEmissionsData(newGrowthScenario);
    },
    { immediate: true, deep: true },
  );
</script>
<template>
  <ProcureInventory @reload-tabs="reloadTabs" />
  <div class="container mx-auto max-w-4xl py-8 px-4 md:py-24">
    <div
      v-if="loaded"
      class="flex flex-col gap-4"
    >
      <!-- Header -->
      <ClientHeader
        :logo="client.brand_icon"
        :name="client.name"
        size="large"
      />
      <div class="w-full">
        <TabGroup
          :key="`tab-${currentTab}`"
          :defaultIndex="currentTab"
          @change="(index) => setSelectedTabIndex(index)"
        >
          <TabList class="flex items-center justify-start gap-4 md:justify-between">
            <div
              class="flex space-x-4 overflow-x-auto whitespace-nowrap bg-transparent md:whitespace-normal"
            >
              <Tab
                v-for="(tab, i) in tabs"
                as="template"
                :key="`tab-${i}`"
                v-slot="{ selected }"
              >
                <button
                  :class="[
                    'w-auto border-b-2 py-2 font-semibold',
                    'ring-transparent focus:outline-none',
                    'hover:text-emerald-500',
                    selected
                      ? 'border-gray-900 text-gray-900 dark:border-gray-300 dark:text-gray-300'
                      : 'border-transparent text-gray-400 dark:text-gray-500',
                  ]"
                >
                  {{ tab.name }}
                </button>
              </Tab>
            </div>
            <Tab v-slot="{ selected }">
              <button
                :class="[
                  'w-auto border-b-2 py-2 font-semibold',
                  'ring-transparent focus:outline-none',
                  'whitespace-nowrap hover:text-emerald-500',
                  selected
                    ? 'border-gray-900 text-gray-900 dark:border-gray-300 dark:text-gray-300'
                    : 'border-transparent text-gray-400 dark:text-gray-500',
                ]"
              >
                Company Info
              </button>
            </Tab>
          </TabList>

          <TabPanels class="mt-2">
            <!-- Emissions -->
            <TabPanel class="flex flex-col gap-2">
              <div
                class="sticky top-20 flex h-16 flex-col items-start justify-between gap-4 bg-white py-2 dark:bg-black md:flex-row md:items-center"
              >
                <h2 class="text-2xl font-semibold dark:text-gray-300">Corporate Emissions</h2>
                <v-select
                  placeholder="Select Page"
                  class="sq-v-s w-48 py-0"
                  v-model="growthScenarios"
                  :options="[
                    {
                      label: 'High Growth',
                      value: 'highGrowth',
                    },
                    {
                      label: 'Medium Growth',
                      value: 'mediumGrowth',
                    },
                    {
                      label: 'Low Growth',
                      value: 'lowGrowth',
                    },
                  ]"
                  :clearable="false"
                  @option:selected="onGrowthScenarioChange"
                />
              </div>
              <div class="flex flex-col gap-8">
                <div
                  v-if="client.progress"
                  class="grid grid-cols-1 text-gray-500 md:grid-cols-2 md:gap-x-3"
                >
                  <StackedListItem
                    title="Progress"
                    :body="`Reporting climate progress since ${client.progress.base_year}`"
                  />
                  <StackedListItem
                    title="Current status"
                    :body="client.progress.company_status_text || 'No status'"
                  />
                  <StackedListItem
                    title="Target Scope"
                    :body="client.progress.target_scope || 'No scopes set'"
                  />
                  <StackedListItem
                    title="Target Timeframe"
                    :body="client.progress.target_timeframe || 'No timeframe set'"
                  />
                  <StackedListItem
                    title="Target Year"
                    :body="client.progress.target_year || 'No target year set'"
                  />
                  <StackedListItem
                    title="Year Target Approved"
                    :body="client.progress.year_target_approved || 'N/A'"
                  />
                </div>
                <CompanyEmissions
                  v-if="emissions"
                  :emissions="emissions"
                  :credits="projectsPurchased"
                />
              </div>
            </TabPanel>
            <!-- Inventory -->
            <TabPanel class="flex flex-col gap-8">
              <div
                class="sticky top-20 flex h-16 flex-col items-start justify-between gap-4 bg-white py-2 dark:bg-black md:flex-row md:items-center"
              >
                <h2 class="text-2xl font-semibold dark:text-gray-300">Inventory Requirements</h2>
                <v-select
                  placeholder="Select Page"
                  class="sq-v-s w-40 py-0"
                  v-model="inventoryByYear"
                  :options="inventoryByYearOptions"
                  :clearable="false"
                  @option:selected="onInventoryByYearChange"
                  required
                />
              </div>
              <CompanyInventory
                :stats="client.carbon_credits"
                :inventory="client.carbon_projects_purchased"
                :selectedYear="selectedYear"
                :totalInventory="inventoryByYearOptions"
              />
              <div class="flex flex-col gap-4">
                <h2 class="text-2xl font-semibold dark:text-gray-300">
                  Procure Additional Inventory
                </h2>
                <div
                  class="flex w-full flex-col items-stretch gap-4 rounded-xl border border-gray-100 bg-gray-50 p-4 dark:border-gray-700 dark:bg-gray-800 md:flex-row md:items-center md:justify-start md:rounded-2xl md:p-8"
                >
                  <div
                    class="flex h-10 w-10 items-center justify-center rounded-full border border-gray-100 bg-white p-2 text-amber-500 dark:border-gray-700"
                  >
                    <StarIcon class="aspect-1 h-full w-full" />
                  </div>
                  <div class="flex flex-1 flex-col gap-0">
                    <strong class="text-lg font-semibold text-gray-900 dark:text-gray-300">
                      Procure additional inventory for your client
                    </strong>
                    <p class="text-gray-500">
                      Select between SDGs, prices, project types, &amp; more.
                    </p>
                  </div>
                  <Button
                    text="Get Started"
                    @click="toggleProcureInventoryModal"
                  />
                </div>
              </div>
            </TabPanel>
            <!-- Events -->
            <TabPanel class="flex flex-col gap-8">
              <div
                class="sticky top-20 flex h-16 flex-col items-start justify-between gap-4 bg-white py-2 dark:bg-black md:flex-row md:items-center"
              >
                <h2 class="text-2xl font-semibold dark:text-gray-300">Events</h2>
                <Button
                  classes="bg-transparent text-gray-900 dark:text-gray-300 border border-gray-200 dark:border-gray-700 drop-shadow-none hover:text-white hover:bg-emerald-500 hover:border-emerald-500"
                  text="Simulate Event"
                />
              </div>
              <CompanyEvents :events="client.events" />
            </TabPanel>
            <!-- Sustainability Report -->
            <TabPanel class="flex flex-col gap-8">
              <div
                class="sticky top-20 flex h-16 flex-col items-start justify-between gap-4 bg-white py-2 dark:bg-black md:flex-row md:items-center"
              >
                <h2 class="text-2xl font-semibold dark:text-gray-300">Sustainability Report</h2>
              </div>
              <CompanySustainability />
            </TabPanel>
            <!-- Company Info -->
            <TabPanel class="flex flex-col gap-2">
              <div
                class="sticky top-20 flex h-16 flex-col items-start justify-between gap-4 bg-white py-2 dark:bg-black md:flex-row md:items-center"
              >
                <h2 class="text-2xl font-semibold dark:text-gray-300">Company Info</h2>
              </div>
              <div class="flex flex-col gap-0">
                <ListItem
                  title="Organization Type"
                  :body="client.organization_type || 'N/A'"
                />
                <ListItem
                  title="Region"
                  :body="client.region || 'N/A'"
                />
                <ListItem
                  title="Location"
                  :body="client.location || 'N/A'"
                />
                <ListItem
                  title="Sector"
                  :body="client.sector || 'N/A'"
                />
                <ListItem
                  title="Net-zero Commitment"
                  :body="(client.net_zero_committed && 'Yes') || 'No'"
                />
                <ListItem
                  title="Carbon Credits Retired"
                  :body="`${
                    client.carbon_credits && client.carbon_credits?.retired
                      ? client.carbon_credits?.retired?.toLocaleString('en-us')
                      : 'N/A'
                  } t`"
                />
                <ListItem
                  title="Target"
                  :helper="client.target"
                  body="Target Set"
                />
                <ListItem
                  title="Target Classification"
                  :helper="client.target_classification"
                  body="Target Set"
                />
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  </div>
</template>
