<template>
  <div
    :class="`relative ${margin} ${full ? 'w-full' : 'w-auto'} ${
      containerClass ? containerClass : ''
    }`"
  >
    <label
      :class="[
        'mb-2 block text-base font-medium text-gray-600 dark:text-gray-500',
        hideLabel ? 'hidden' : 'visible',
      ]"
      :for="name"
      v-show="!hideLabel"
    >
      {{ label }}
    </label>
    <textarea
      :class="`read-only:focus:ring-none rounded-md border border-gray-100 bg-gray-50 px-4 py-2 leading-6
      placeholder-gray-400
      invalid:border-pink-500 invalid:text-pink-600 read-only:text-gray-500 focus:border-emerald-500
      focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:invalid:border-pink-500
      focus:invalid:ring-pink-500 read-only:focus:outline-none
      disabled:border-gray-200 disabled:bg-gray-50
      disabled:text-gray-500/50
      disabled:shadow-none
      dark:border-gray-800
      dark:bg-gray-800
      dark:text-gray-300
      ${full ? 'w-full' : 'w-auto'}
      ${chunky ? 'px-5 py-4' : 'px-4 py-2'}
      ${classes ? classes : ''}`"
      :placeholder="placeholder"
      :required="required || false"
      :name="name"
      v-model="value"
      :minlength="min"
      :readonly="readonly"
      :ref="name"
      v-if="textarea"
    />
    <input
      :class="`read-only:focus:ring-none rounded-md border border-gray-100 bg-gray-50 px-4 py-2 leading-6
      placeholder-gray-400
      invalid:border-pink-500 invalid:text-pink-600 read-only:text-gray-500 focus:border-emerald-500
      focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:invalid:border-pink-500
      focus:invalid:ring-pink-500 read-only:focus:outline-none
      disabled:border-gray-200 disabled:bg-gray-50
      disabled:text-gray-500/50
      disabled:shadow-none
      dark:border-gray-800
      dark:bg-gray-800
      dark:text-gray-300
      ${full ? 'w-full' : 'w-auto'}
      ${chunky ? 'px-5 py-4' : 'px-4 py-2'}
      ${classes ? classes : ''}`"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :required="required || false"
      :name="name"
      :type="type"
      v-model="value"
      :maxlength="max"
      :minlength="min"
      :max="type === 'number' && max"
      :min="type === 'number' && min"
      :ref="name"
      :readonly="readonly"
      :step="type === 'number' && step"
      :id="id"
      v-else
    />
    <small
      class="mt-2 inline-block w-full text-xs leading-6 text-gray-400"
      v-if="helper && helper.length > 0"
    >
      {{ helper }}
    </small>
  </div>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    // Booleans
    textarea: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    chunky: Boolean,
    full: Boolean,
    // Rest
    containerClass: String,
    classes: String,
    id: {
      default: "email",
      type: String,
    },
    autocomplete: {
      default: "username",
      type: String,
    },
    modelValue: String | Number,
    placeholder: String,
    label: String,
    margin: {
      default: "mb-4",
      type: String,
    },
    helper: String,
    name: String,
    type: String,
    max: {
      default: 524288,
      type: Number,
    },
    min: {
      default: 0,
      type: Number,
    },
    step: {
      default: 0.01,
      type: Number,
    },
  });
  const {
    textarea,
    hideLabel,
    required,
    readonly,
    chunky,
    full,
    containerClass,
    classes,
    id,
    autocomplete,
    modelValue,
    placeholder,
    label,
    margin,
    helper,
    name,
    type,
    max,
    min,
    step,
  } = props;

  const emit = defineEmits(["update:modelValue"]);

  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit("update:modelValue", value);
    },
  });
</script>
