export const projectSize = [
  {
    label: "0 to 100 hectares",
    score: 0.0,
  },
  {
    label: "100 to 500 hectares",
    score: 0.0125,
  },
  {
    label: "500 to 1,000 hectares",
    score: 0.0125,
  },
  {
    label: "1,000 to 10,000 hectares",
    score: 0.025,
  },
  {
    label: "Over 10,000 hectares",
    score: 0.05,
  },
];

export const sdgList = [
  {
    id: 0,
    label: "Goal 1",
    description: "No Poverty To eradicate extreme poverty for all people everywhere.",
    checked: false,
  },
  {
    id: 1,
    label: "Goal 2",
    description:
      "Zero Hunger To end hunger, achieve food security, and improved nutrition by 2030.",
    checked: false,
  },
  {
    id: 2,
    label: "Goal 3",
    description:
      "Good Health and Well-being To ensure healthy lives and promote well-being for all at all ages by 2030.",
    checked: false,
  },
  {
    id: 3,
    label: "Goal 4",
    description:
      "Quality Education To ensure that all girls and boys complete free, equitable, and quality primary and secondary education by 2030.",
    checked: false,
  },
  {
    id: 4,
    label: "Goal 5",
    description: "Gender Equality To achieve gender equality and empower all women and girls.",
    checked: false,
  },
  {
    id: 5,
    label: "Goal 6",
    description:
      "Clean Water and Sanitation To ensure availability and sustainable management of water and sanitation for all by 2030.",
    checked: false,
  },
  {
    id: 6,
    label: "Goal 7",
    description:
      "Affordable and Clean Energy To ensure access to affordable, reliable, sustainable, and modern energy for all by 2030.",
    checked: false,
  },
  {
    id: 7,
    label: "Goal 8",
    description:
      "Decent Work and Economic Growth To promote sustained, inclusive, and sustainable economic growth.",
    checked: false,
  },
  {
    id: 8,
    label: "Goal 9",
    description:
      "Industry, Innovation, and  Infrastructure To build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation by 2030",
    checked: false,
  },
  {
    id: 9,
    label: "Goal 10",
    description: "Reduced Inequality To reduce inequality within and among countries by 2030.",
    checked: false,
  },
  {
    id: 10,
    label: "Goal 11",
    description:
      "Sustainable Cities and Communities To make cities and human settlements inclusive, safe, resilient, and sustainable.",
    checked: false,
  },
  {
    id: 11,
    label: "Goal 12",
    description:
      "Responsible Consumption and Production To ensure sustainable consumption and production patterns.",
    checked: false,
  },
  {
    id: 12,
    label: "Goal 13",
    description: "Climate Action To take urgent action to combat climate change and its impacts.",
    checked: false,
  },
  {
    id: 13,
    label: "Goal 14",
    description:
      "Life Below Water To conserve and sustainably use the oceans, seas, and marine resources for sustainable development.",
    checked: false,
  },
  {
    id: 14,
    label: "Goal 15",
    description:
      "Life on Land To protect, restore and promote sustainable use of terrestrial ecosystems, combat desertification and halt biodiversity loss.",
    checked: false,
  },
  {
    id: 15,
    label: "Goal 16",
    description:
      "Peace and Justice Strong Institutions To promote peaceful and inclusive societies for sustainable development; provide access to justice for all.",
    checked: false,
  },
  {
    id: 16,
    label: "Goal 17",
    description:
      "Partnerships to Achieve the Goal To strengthen the means of implementation and revitalize the global partnership for sustainable development.",
    checked: false,
  },
];
