<script setup>
  import { ref, computed } from "vue";

  const props = defineProps({
    leftText: { type: String, required: false },
    rightText: { type: String, required: false },
    lowestProgressValue: { type: Number, required: true, default: 0 },
    highestProgressValue: { type: Number, required: true, default: 100 },
    currentProgress: { type: Number, required: true, default: 50 },
    currentProgressText: { type: [String, Number], required: false },
  });

  const currentProgressWidth = computed(() => {
    const range = props.highestProgressValue - props.lowestProgressValue;
    const progressInRange = props.currentProgress - props.lowestProgressValue;
    const percentage = (progressInRange / range) * 100;
    return percentage + "%";
  });

  const fillColorClass = computed(() => {
    if (props.currentProgress < props.lowestProgressValue) {
      return "bg-amber-500";
    } else if (props.currentProgress > props.highestProgressValue) {
      return "bg-emerald-500";
    } else {
      return "bg-indigo-500";
    }
  });
</script>

<template>
  <div class="flex items-center justify-between text-sm font-medium">
    <div class="text-gray-500">{{ leftText }}</div>
    <div class="relative mx-3 w-full">
      <div class="h-6 rounded-full bg-gray-200">
        <div
          :class="`h-full rounded-full ${fillColorClass}`"
          :style="{ width: currentProgressWidth }"
        ></div>
      </div>
      <div
        v-if="currentProgressText"
        class="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center px-2 font-semibold text-white"
      >
        <div>{{ currentProgressText }}</div>
      </div>
    </div>
    <div class="text-gray-500">{{ rightText }}</div>
  </div>
</template>
