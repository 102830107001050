<script setup>
  import { ref, computed } from "vue";
  import { storeToRefs } from "pinia";

  import { useDemoStore } from "@/store/demoStore";

  import DoughnutChart from "@/components/Charts/DoughnutChart.vue";

  const demoStore = useDemoStore();
  const { getProjectStats } = storeToRefs(demoStore);
  const totalInvestmentAmount = getProjectStats.value.totalInvestmentAmount;
  const locations = getProjectStats.value.locations;

  // Calculate the investmentAmountByCountry and add a "outstandingLoan" key and a key called "continent" which is the key from locations
  const investmentAmountByCountry = computed(() => {
    return Object.entries(locations)
      .map(([key, value]) => {
        const outstandingLoan = value.investmentAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
        return {
          ...value,
          outstandingLoan,
          continent: key,
        };
      })
      .sort((a, b) => (a.continent > b.continent ? 1 : -1));
  });

  // Map the locations by key and value to the doughnut chart ie: Africa: { investmentAmount: 1000 } to chartData
  const colors = ["#14b8a6", "#06b6d4", "#6366f1", "#a855f7", "#ec4899", "#71717a", "#64748b"];
  const chartData = ref({
    labels: Object.keys(locations),
    datasets: [
      {
        // Generate background colors for each location depending on the number of locations from the colors array
        backgroundColor: Object.keys(locations).map((_, index) => colors[index]),
        data: Object.values(locations).map((location) => location.investmentAmount),
        cutout: "80%",
        spacing: 2,
      },
    ],
  });
</script>

<template>
  <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
    <!-- Doughnut Chart -->
    <DoughnutChart
      :chartData="chartData"
      :floatingLabel="totalInvestmentAmount"
      title="Current Loans"
      format="currency"
    />
    <!-- Table Data -->
    <table class="w-full table-auto text-left">
      <thead>
        <tr class="h-8 text-sm text-gray-500">
          <th class="font-medium">Continent</th>
          <th class="text-right font-medium">Outstanding Loan</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in investmentAmountByCountry">
          <tr
            class="h-12 border-t border-gray-100 py-3 text-sm text-gray-900 dark:border-gray-700 dark:text-gray-400"
          >
            <td class="font-semibold">{{ row.continent }}</td>
            <td class="text-right">
              {{ row.outstandingLoan }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
