<template>
  <div class="container relative mx-auto">
    <div class="mx-auto w-full p-4 md:max-w-xl">
      <div class="absolute left-0 top-0 m-4 md:m-5">
        <Button
          v-if="
            ![
              onboardingSteps.name,
              onboardingSteps.pending,
              onboardingSteps.done,
              onboardingSteps.invite,
            ].includes(currentStep)
          "
          classes="bg-gray-50 dark:bg-gray-800 border-0 text-black dark:text-white rounded-full dark:hover:bg-gray-500 hover:bg-emerald-100 dark:hover:bg-emerald-900"
          iconLeft="ArrowLeftIcon"
          type="submit"
          @click="onBackClick"
          square
        />
      </div>

      <div
        v-if="isAuthCheckLoading"
        class="mt-0 flex w-full items-center justify-center md:mt-96"
      >
        <Loader size="12" />
      </div>

      <div v-else-if="isAuthCheckError">
        <span class="flex w-full items-center justify-center text-sm text-red-400">
          Something went wrong trying to authenticate. Please contact support.
        </span>
      </div>

      <div
        class="my-16 md:my-16"
        v-else
      >
        <div
          v-if="currentStep === onboardingSteps.name"
          class="flex flex-col gap-8"
        >
          <PageHeader
            title="Welcome to Sequestr"
            body="Let's get to know you a little bit!"
          />
          <Input
            placeholder="Enter your full name"
            v-model="authStore.onboardingAnswers.name"
            label="Enter your full name"
            autocomplete="name"
            type="text"
            name="name"
            id="name"
            margin="mb-0"
            chunky
            full
          />
          <Button
            text="Continue to Sequestr"
            type="submit"
            @click="onNameSubmit"
            :loading="isUpdateUserLoading"
            :disabled="isUpdateUserLoading || !authStore.onboardingAnswers.name"
            chunky
          />
          <span
            v-if="isUpdateUserError"
            class="flex w-full items-center justify-center pt-10 text-sm text-red-400"
          >
            {{ updateUserError }}
          </span>
        </div>

        <div
          v-if="currentStep === onboardingSteps.role"
          class="flex flex-col gap-8"
        >
          <PageHeader
            :title="`Tell us about yourself ${authStore.onboardingAnswers.name}`"
            body="What's your main goal with Sequestr?"
          />
          <RadioGroup
            v-model="role"
            @click.native="onRoleClick(role)"
          >
            <RadioGroupLabel class="sr-only">Select a role</RadioGroupLabel>
            <div class="mb-4">
              <RadioGroupOption
                as="template"
                v-for="role in roles"
                :key="role.title"
                :value="role.type"
                v-slot="{ active, checked }"
              >
                <div
                  :class="[
                    active
                      ? 'ring-0 ring-opacity-30 focus:ring-0  dark:ring-gray-800'
                      : 'border-gray-50 dark:border-gray-900',
                    checked
                      ? 'bg-gray-900 text-white dark:bg-gray-700'
                      : 'bg-white dark:bg-gray-900',
                  ]"
                  class="relative mb-4 flex cursor-pointer rounded-xl border border-gray-100 p-0 focus:outline-none md:p-6"
                >
                  <div
                    class="flex w-full flex-col items-start justify-between gap-0 md:flex-row md:items-center md:gap-4"
                  >
                    <div
                      :class="[
                        'relative ml-6 mt-6 h-12 w-12 overflow-hidden rounded-full border border-black/[.01] p-2 text-center md:ml-0 md:mt-0',
                        role.type === 'project'
                          ? 'bg-emerald-50 text-emerald-500 dark:bg-emerald-800'
                          : 'bg-indigo-50 text-indigo-500 dark:bg-indigo-800',
                      ]"
                    >
                      <GlobeAmericasIcon v-if="role.type === 'project'" />
                      <SparklesIcon v-else />
                    </div>
                    <div class="flex flex-1 items-center gap-4 p-4 md:p-0">
                      <div class="text-sm">
                        <RadioGroupLabel
                          as="p"
                          :class="checked ? 'text-white' : 'text-gray-900 dark:text-gray-400'"
                          class="text-lg font-semibold"
                        >
                          {{ role.title }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                          as="span"
                          :class="checked ? 'text-gray-200' : 'text-gray-500'"
                          class="inline"
                        >
                          <p class="block whitespace-pre-line text-sm">
                            {{ role.description }}
                          </p>
                        </RadioGroupDescription>
                      </div>
                    </div>
                    <div
                      v-show="checked"
                      class="absolute right-0 top-4 flex-shrink-0 p-4 text-white md:relative md:top-0 md:right-auto md:p-0"
                    >
                      <CheckCircleIcon class="h-6 w-6" />
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
          <Button
            text="Continue to Sequestr"
            type="submit"
            @click="onRoleSubmit"
            chunky
          />
        </div>

        <div
          v-if="currentStep === onboardingSteps.team"
          class="flex flex-col gap-8"
        >
          <PageHeader
            title="Let's create your team"
            body="Name your team, and add a website."
          />
          <div class="flex flex-col gap-4">
            <Input
              placeholder="Enter your company name"
              label="Enter your company name"
              v-model="authStore.onboardingAnswers.companyName"
              autocomplete="organization"
              margin="mb-0"
              type="text"
              name="name"
              id="companyName"
              chunky
              full
            />
            <Input
              placeholder="Enter your company website"
              label="Enter your company website"
              v-model="authStore.onboardingAnswers.companyUrl"
              autocomplete="none"
              margin="mb-0"
              type="text"
              name="website"
              id="companyUrl"
              chunky
              full
            />
          </div>
          <Button
            text="Continue to Sequestr"
            type="submit"
            @click="onCompanySubmit"
            :loading="isOnboardingSubmitLoading"
            :disabled="
              isOnboardingSubmitLoading || !authStore.onboardingAnswers.companyName || disableButton
            "
            chunky
            full
          />
          <span
            v-if="isOnboardingSubmitError"
            class="flex w-full items-center justify-center text-sm text-red-400"
          >
            {{ errorMessage }}
          </span>
        </div>

        <div
          v-if="currentStep === onboardingSteps.pending"
          class="flex flex-col gap-8"
        >
          <PageHeader
            title="We'll be in touch!"
            body="Sequestr is a managed marketplace and as such we're reviewing your application, we'll
            add you to Sequestr within 24-48 hours."
          />
          <Button
            text="Sounds good"
            @click="onPendingVerificationSubmit"
            chunky
            full
          />
        </div>

        <div
          v-if="currentStep === onboardingSteps.invite"
          class="flex flex-col gap-8"
        >
          <PageHeader
            title="Invite your team members"
            body="Enter email addresses for team members; in the future they will join your workspace."
          />
          <div class="mb-10">
            <span v-for="(emailInvite, index) in emailInvites">
              <Input
                :placeholder="invitePlaceholderDomain"
                v-model="emailInvites[index]"
                autocomplete="email"
                type="email"
                :name="`emailInvite-${index}`"
                :id="`emailInvite-${index}`"
                :key="`emailInvite-${index}`"
                chunky
                full
              />
            </span>
            <Button
              classes="bg-gray-50 dark:bg-gray-900 dark:text-gray-400 border border-gray-100 dark:border-gray-800 pl-0.5 pr-3 py-0 text-xs font-semibold rounded-full shadow-none hover:bg-emerald-500 hover:text-white dark:hover:bg-emerald-600"
              :iconOutline="false"
              iconLeft="PlusCircleIcon"
              text="Add more"
              @click="onAddMoreInvitesClick"
            />
          </div>

          <div class="flex flex-col items-center justify-between gap-4 md:flex-row md:space-y-0">
            <Button
              text="Skip for now"
              classes="w-full bg-transparent border-gray-200 dark:border-gray-800 text-black dark:text-gray-400 hover:bg-emerald-500 hover:text-white dark:hover:text-white shadow-none font-medium"
              @click="onInviteEmailSkip"
              :loading="onboardingStatusLoading"
              :disabled="inviteEmailLoading || onboardingStatusLoading"
              chunky
            />
            <Button
              text="Continue with Sequestr"
              @click="onInviteEmailSubmit"
              :disabled="inviteEmailLoading || onboardingStatusLoading"
              :loading="inviteEmailLoading"
              chunky
            />
          </div>

          <span
            v-if="isInviteEmailError || isSetOnboardingStatusError"
            class="mt-10 flex w-full items-center justify-center text-sm text-red-400"
          >
            Something went wrong trying to invite emails. Please contact support.
            {{ inviteEmailError }}
          </span>
        </div>

        <div
          v-if="currentStep === onboardingSteps.done"
          class="flex flex-col gap-8"
        >
          <PageHeader
            title="You're all set"
            body=" You can start viewing projects or fill out your personal or company's profile."
          />
          <router-link
            v-for="link in allSetLinks"
            class="text-gray-900 hover:text-emerald-500 dark:text-gray-400 dark:hover:text-emerald-500"
            :key="link.id"
            :to="link.to"
          >
            <div
              class="flex w-full flex-col items-center justify-between gap-0 md:flex-row md:gap-4"
            >
              <div
                :class="[
                  'relative h-12 w-12 overflow-hidden rounded-full border border-black/[.01] p-2 text-center',
                  link.type === 'project'
                    ? 'bg-emerald-50 text-emerald-500 dark:bg-emerald-800'
                    : 'bg-indigo-50 text-indigo-500 dark:bg-indigo-800',
                ]"
              >
                <UserCircleIcon v-if="link.type === 'profile'" />
                <SparklesIcon v-else-if="link.type === 'company'" />
                <CogIcon v-else-if="link.type === 'settings'" />
                <GlobeAmericasIcon v-else />
              </div>
              <div class="flex flex-1 items-center gap-4 p-4 md:p-0">
                <div class="text-lg font-semibold">
                  {{ link.title }}
                </div>
              </div>
              <ArrowRightIcon
                size="24"
                class="h-4"
              />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from "vue";
  import { useMutation } from "vue-query";
  import { useRoute, useRouter } from "vue-router";

  import {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    RadioGroupDescription,
  } from "@headlessui/vue";
  import { CheckCircleIcon } from "@heroicons/vue/24/solid";
  import {
    ArrowRightIcon,
    CogIcon,
    GlobeAmericasIcon,
    SparklesIcon,
    UserCircleIcon,
  } from "@heroicons/vue/24/outline";

  import { useAuthStore } from "@/store/authStore";

  import { logEvent, logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import Input from "@/components/Input.vue";
  import Loader from "@/components/Loader.vue";
  import PageHeader from "@/components/PageHeader.vue";

  const authStore = useAuthStore();
  const route = useRoute();
  const router = useRouter();

  const role = ref("common");
  const roles = ref([
    {
      title: "I'm looking to buy or pre-purchase offsets",
      description: "Stream offsets and plan futures",
      type: "common",
    },
    {
      title: "I'm a carbon removal project owner",
      description: "Create & auction carbon removal while getting project development support",
      type: "project",
    },
  ]);

  let errorMessage = ref(
    "Something went wrong trying to submit onboarding. Please contact support.",
  );

  let invitePlaceholderDomain = ref("@sequestr.io");

  const companySlug = computed(() => authStore.user?.company?.slug || "");

  let allSetLinks = ref([
    {
      title: "View carbon removal projects",
      to: "/projects",
      type: "project",
      id: 0,
    },
  ]);
  let isPD = ref(false);
  let disableButton = ref(false);

  onMounted(async () => {
    if (route.hash === "") {
      // user has clicked onboarding
      if (authStore.isUserStatusComplete && authStore.user.name !== "") {
        await router.push("/settings");
      }
    } else {
      // get hash (starts with #, so remove #)
      const [, hash] = route?.hash.split("#");
      const authParams = new URLSearchParams(hash);
      const token = authParams.get("access_token");

      // try getting user from token to see if its valid only if router.beforeEach didnt call
      if (!authStore.user) {
        await authCheckMutate(token);
      }
    }

    const companyUrl = authStore.userHasCompanyRegistered?.websiteUrl;

    if (companyUrl) {
      const hasHTTP = /(http(s?)):\/\//i.test(companyUrl);
      const { hostname } = new URL(hasHTTP ? companyUrl : `https://${companyUrl}`);
      invitePlaceholderDomain.value = `@${hostname}`;
      return;
    }
  });

  const {
    isLoading: isAuthCheckLoading,
    isError: isAuthCheckError,
    error: authCheckError,
    mutate: authCheckMutate,
  } = useMutation((token) => authStore.getUserWithToken(token), {
    onSuccess: async (data) => {
      if (authStore.isUserPendingVerification) {
        currentStep.value = onboardingSteps.pending;
      }
      if (authStore.isUserStatusVerified) {
        await router.push("/");
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const {
    isLoading: isOnboardingSubmitLoading,
    isError: isOnboardingSubmitError,
    error: onboardingAnswersError,
    mutate: onboardingAnswersMutate,
  } = useMutation(
    () =>
      authStore.submitOnboardingAnswers({
        role: authStore.onboardingAnswers.role,
        companyName: authStore.onboardingAnswers.companyName,
        companyUrl: authStore.onboardingAnswers.companyUrl,
      }),
    {
      onSuccess: () => {
        currentStep.value = onboardingSteps.pending;
      },
      onError: (error) => {
        console.log(error);
        errorMessage.value = error;
      },
    },
  );

  const {
    isLoading: isUpdateUserLoading,
    isError: isUpdateUserError,
    error: updateUserError,
    mutate: updateUserMutate,
  } = useMutation(
    () =>
      authStore.updateUser({
        username: authStore.onboardingAnswers.username,
        name: authStore.onboardingAnswers.name,
      }),
    {
      onSuccess: (data) => {
        hasToFillOutName.value = false;

        // was invited, skip
        if (data.userRole && data.company?.id) {
          onboardingStatusMutate("complete");
        } else {
          if (data.userRole) {
            currentStep.value = onboardingSteps.team;
          } else {
            currentStep.value = onboardingSteps.role;
          }
        }
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );

  const onboardingSteps = {
    // in order
    name: "name",
    role: "role",
    team: "team",
    pending: "pending",
    // after successful verification
    invite: "invite",
    done: "done",
  };

  let currentStep = ref();

  const onNameSubmit = () => {
    updateUserMutate();
  };

  const onRoleSubmit = () => {
    currentStep.value = onboardingSteps.team;
  };

  const onCompanySubmit = () => {
    onboardingAnswersMutate();
  };

  const onPendingVerificationSubmit = () => {
    router.push("/");
  };

  const onBackClick = () => {
    switch (true) {
      case currentStep.value === onboardingSteps.name:
        break;
      case currentStep.value === onboardingSteps.role:
        currentStep.value = onboardingSteps.name;
        break;
      case currentStep.value === onboardingSteps.team:
        currentStep.value = onboardingSteps.role;
        break;
      default:
        break;
    }
  };

  const onRoleClick = (role) => {
    authStore.onboardingAnswers.role = role;
  };

  const skipNameStep = () => {
    if (authStore?.user?.name) {
      currentStep.value = onboardingSteps.role;
      return;
    }
    currentStep.value = onboardingSteps.name;
    return;
  };

  const hasToFillOutName = ref(false);

  authStore.$subscribe((mutation, state) => {
    if (
      ["verified", "complete", "pending"].indexOf(state.user?.verificationStatus) !== -1 &&
      !hasToFillOutName.value &&
      currentStep.value !== onboardingSteps.team
    ) {
      router.replace("/projects");
    }
  });

  const usersName = ref();

  onMounted(() => {
    const authUser = authStore.user;
    const userRole = authUser?.userRole;
    usersName.value = authUser?.name;
    isPD.value = userRole === "project" ? true : false;

    const pdVal = isPD.value;
    skipNameStep();

    if (isPD.value) {
      allSetLinks.value.push(
        {
          title: "Create a carbon removal project",
          to: "/create",
          type: "project",
          id: 1,
        },
        {
          title: "Update company profile",
          to: `/company/${companySlug.value}`,
          type: "company",
          id: 2,
        },
      );
    }

    allSetLinks.value.push({
      title: "Update settings",
      to: "/settings",
      type: "settings",
      id: 3,
    });

    if (authStore.isUserPendingVerification) {
      currentStep.value = onboardingSteps.pending;
    } else if (authStore.isUserStatusVerified) {
      currentStep.value = onboardingSteps.invite;
    } else if (
      (authStore.isUserStatusVerified || authStore.isUserStatusComplete) &&
      usersName.value === ""
    ) {
      hasToFillOutName.value = true;
      currentStep.value = onboardingSteps.name;
    } else if (authStore.isUserStatusComplete) {
      router.replace("/");
    }

    logPageView("Onboarding", {
      currentStep: currentStep.value,
    });
  });

  const emailInvites = ref([""]);

  const onAddMoreInvitesClick = () => {
    emailInvites.value.push("");
  };

  const {
    isError: isInviteEmailError,
    error: inviteEmailError,
    isLoading: inviteEmailLoading,
    mutate: inviteEmailMutate,
  } = useMutation(authStore.inviteEmails, {
    onSuccess: () => {
      onboardingStatusMutate("complete");
    },
  });

  const onInviteEmailSubmit = async () => {
    const emails = emailInvites.value;
    console.info("emailInvites.value:", emails);
    // remove empty values from the array before sending in case the user does not fill out all "add more" email rows
    const finalEmailsArray = emails.filter((a) => a);
    console.info("final emails:", finalEmailsArray);
    logEvent("Onboarding invite emails", {
      amount: finalEmailsArray?.length,
    });
    await inviteEmailMutate({ emails: finalEmailsArray });
  };

  const onInviteEmailSkip = () => {
    logEvent("Onboarding skip invite emails");
    onboardingStatusMutate("complete");
  };

  const {
    isError: isSetOnboardingStatusError,
    error: onboardingStatusError,
    isLoading: onboardingStatusLoading,
    mutate: onboardingStatusMutate,
  } = useMutation((status) => authStore.updateOnboardingStatus({ status }), {
    onSuccess: (data, variables, context) => {
      logEvent("Onboarding complete");
      currentStep.value = onboardingSteps.done;
    },
  });
</script>
