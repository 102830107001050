<template>
  <div
    class="flex flex-1 flex-row items-center justify-start gap-4 rounded-lg bg-gray-50 p-4 dark:bg-gray-800"
  >
    <!-- Icon -->
    <div
      v-if="iconLeft"
      class="flex h-10 w-10 items-center justify-center rounded-full border border-gray-100 bg-white p-2 font-bold dark:border-gray-800 dark:bg-gray-700"
    >
      <component
        class="h-full w-full"
        :is="setIconLeft"
      />
    </div>
    <!-- Text -->
    <div class="flex flex-1 flex-col gap-0">
      <strong class="font-semibold text-gray-900 dark:text-gray-400">{{ title }}</strong>
      <small v-if="helper">
        {{ helper }}
      </small>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from "vue";
  import * as solid from "@heroicons/vue/24/solid";
  import * as outline from "@heroicons/vue/24/outline";

  const props = defineProps({
    title: {
      required: true,
      type: String,
    },
    helper: String,
    iconLeft: String,
    iconOutline: {
      default: true,
      type: Boolean,
    },
  });

  const { title, helper, iconLeft, iconOutline } = props;

  const icons = { solid, outline };
  const setIconLeft = computed(() => {
    return icons[iconOutline ? "outline" : "solid"][`${iconLeft}`] || null;
  });
</script>
