<template>
  <div :class="[color(), 'rounded-lg border px-2 py-1 text-sm font-semibold']">
    <slot />
  </div>
</template>

<script setup>
  const props = defineProps({
    type: String,
  });
  const { type } = props;

  const color = () => {
    switch (type) {
      case "informational":
        return "bg-blue-50 border-blue-100 text-blue-600";
        break;
      case "error":
        return "";
        break;
      case "pending approval":
      case "pending":
        return "dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 bg-gray-50 text-gray-800";
        break;
      case "success":
        return "bg-emerald-500 text-white";
        break;
      default:
        return "bg-white dark:bg-transparent dark:border-gray-500 border-gray-100 text-gray-800 dark:text-gray-400";
        break;
    }
  };
</script>
