<script setup>
  import { computed, onMounted, reactive, ref, watch } from "vue";
  import { useMutation } from "vue-query";
  import { useRoute, useRouter } from "vue-router";
  import { storeToRefs } from "pinia";

  import { TabGroup, TabList, TabPanels, TabPanel, Tab } from "@headlessui/vue";
  import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
  import { useDebounce } from "@vueuse/core";

  import { useAuthStore } from "@/store/authStore";
  import { useDemoStore } from "@/store/demoStore";
  import { useProjectStore } from "@/store/projectStore";

  import { logPageView } from "@/services/analytics";

  import AssetManagerClient from "@/components/AssetManagerClient.vue";
  import Button from "@/components/Button.vue";
  import Input from "@/components/Input.vue";
  import StatBox from "@/components/StatBox.vue";
  import ProjectTypeLoanPortfolioTab from "@/components/LoanPortfolio/ProjectTypeTab.vue";
  import GeographyLoanPortfolioTab from "@/components/LoanPortfolio/GeographyTab.vue";
  import VintageYearTab from "@/components/LoanPortfolio/VintageYearTab.vue";
  import ProjectTypeRevenueTab from "@/components/RevenueProjections/ProjectTypeRevenueTab.vue";
  import GeographyRevenueTab from "@/components/RevenueProjections/GeographyRevenueTab.vue";
  import VintageYearRevenueTab from "@/components/RevenueProjections/VintageYearRevenueTab.vue";

  import AddClient from "@/components/Modals/AddClient.vue";

  const authStore = useAuthStore();
  const demoStore = useDemoStore();
  const projectStore = useProjectStore();
  const router = useRouter();
  const route = useRoute();

  let authenticated = ref(false);
  let clients = ref([]);
  let clientsSearchTerm = ref("");
  let clientsClonedArray = ref([]);
  let projects = ref([]);
  let projectsSearchTerm = ref("");
  let projectsClonedArray = ref([]);
  let user = ref(null);
  let isPD = ref(false);

  let financialOverviewPage = ref("Loan Portfolio");

  const currentTab = ref(0);
  const tabs = [
    {
      name: "Project Type",
    },
    {
      name: "Geography",
    },
    {
      name: "Vintage Year",
    },
  ];

  const setSelectedTabIndex = (index) => {
    currentTab.value = index;
  };

  const onToggleAddClientModal = () => {
    demoStore.toggleAddNewClientModal();
  };

  const {
    getAssetManager,
    getTotalNumberOfClients,
    getClientsWithNetZeroCommitments,
    getProjectStats,
    getAverageESGRating,
    getTotalCarbonEmissionsCurrentYear,
    getTotalCarbonCreditsCommitted,
    getAverageYearlyPurchases,
    getPortfolioHealth,
    getClients,
  } = storeToRefs(demoStore);

  let dashboardStats = reactive({
    assetManager: getAssetManager.value,
    totalNumberOfClients: getTotalNumberOfClients.value,
    clientsWithNetZeroCommitments: getClientsWithNetZeroCommitments.value,
    projectStats: getProjectStats.value,
    averageESGRating: getAverageESGRating.value,
    totalCarbonEmissionsCurrentYear: getTotalCarbonEmissionsCurrentYear.value,
    totalCarbonCreditsCommitted: getTotalCarbonCreditsCommitted.value,
    averageYearlyPurchases: getAverageYearlyPurchases.value,
    portfolioHealth: getPortfolioHealth.value,
  });

  clients.value = getClients.value;
  clientsClonedArray.value = clients.value;

  console.info({
    dashboardStats: {
      assetManager: getAssetManager.value,
      totalNumberOfClients: getTotalNumberOfClients.value,
      clientsWithNetZeroCommitments: getClientsWithNetZeroCommitments.value,
      projectStats: getProjectStats.value,
      averageESGRating: getAverageESGRating.value,
      totalCarbonEmissionsCurrentYear: getTotalCarbonEmissionsCurrentYear.value,
      totalCarbonCreditsCommitted: getTotalCarbonCreditsCommitted.value,
      averageYearlyPurchases: getAverageYearlyPurchases.value,
      portfolioHealth: getPortfolioHealth.value,
    },
    clients: getClients.value,
  });

  const {
    isLoading: isUserLoading,
    isError: isUserError,
    error: userError,
    mutate: userMutate,
  } = useMutation(
    () => {
      return authStore.getUser();
    },
    {
      onSuccess: () => {
        const authUser = authStore.user;
        user.value = authUser;
        console.info({ authUser });

        if (!authUser) {
          authenticated.value = false;
          router.push("/login");
          return;
        }

        const userRole = authUser?.userRole;
        isPD.value = userRole === "project" ? true : false;
        authenticated.value = true;
        projectsMutate();
      },
    },
  );

  const {
    isLoading: isProjectsLoading,
    isError: isProjectsError,
    error: projectsError,
    mutate: projectsMutate,
  } = useMutation(
    (projectType) => {
      return projectStore.getAllProjects(projectType);
    },
    {
      onSuccess: (data) => {
        console.info({ data });
        data.push(
          {
            soon: true,
            auction: {
              startingInventory: 100000,
            },
            id: Math.random(),
            name: "Canada Biochar",
            mechanism: "removal",
            type: "Biochar",
            slug: null,
            location: {
              address_components: [
                {
                  long_name: "Saskatchewan",
                  short_name: "Saskatchewan",
                  types: ["locality", "political"],
                },
                {
                  long_name: "Canada",
                  short_name: "CA",
                  types: ["country", "political"],
                },
              ],
            },
            images: ["/img/methodologies/supported/biochar.jpg"],
            projectData: {
              story: {
                context:
                  "Project owner is committed to the exclusive provision, maintenance and upgrades of patented technologies in the bio-carbon processes.",
              },
            },
            projectStatus: "soon",
            createdAt: "2022-09-07T20:28:22.000Z",
            company: {
              id: Math.random(),
              title: "Launching soon",
              slug: null,
              imageUrl: null,
              websiteUrl: "https://sequestr.io",
            },
          },
          {
            soon: true,
            auction: {
              startingInventory: 100000,
            },
            id: Math.random(),
            name: "Cameroon Cookstoves Project",
            mechanism: "avoidance",
            type: "Cookstoves",
            slug: null,
            location: {
              address_components: [
                {
                  long_name: "Bangang Bamboutos",
                  short_name: "Bangang Bamboutos",
                  types: ["locality", "political"],
                },
                {
                  long_name: "Cameroon",
                  short_name: "CM",
                  types: ["country", "political"],
                },
              ],
            },
            images: ["/img/methodologies/supported/cookstoves.jpg"],
            projectData: {
              story: {
                context:
                  "This phase of the project will be to design, manufacture, register and distribute 150,000 (one hundred fifty thousand) Cookstoves with a fuel efficiency of at least 60%. The distribution of the energy-efficient Cookstoves shall be divided into three (03) tranches of fifty thousand (50,000) Cookstoves each annually, for three consecutive years.",
              },
            },
            projectStatus: "soon",
            createdAt: "2022-09-07T20:28:22.000Z",
            company: {
              id: Math.random(),
              title: "Launching soon",
              slug: null,
              imageUrl: null,
              websiteUrl: "https://sequestr.io",
            },
          },
          {
            soon: true,
            auction: {
              startingInventory: 100000,
            },
            id: Math.random(),
            name: "Bhutan BCC Project",
            mechanism: "removal",
            type: "Cookstoves",
            slug: null,
            location: {
              address_components: [
                {
                  long_name: "Thimphu",
                  short_name: "Thimphu",
                  types: ["locality", "political"],
                },
                {
                  long_name: "Bhutan",
                  short_name: "BT",
                  types: ["country", "political"],
                },
              ],
            },
            images: ["/img/bhutan.jpg"],
            projectData: {
              story: {
                context: "Clean cookstoves project in Bhutan",
              },
            },
            projectStatus: "soon",
            createdAt: "2022-09-07T20:28:22.000Z",
            company: {
              id: Math.random(),
              title: "Launching soon",
              slug: null,
              imageUrl: null,
              websiteUrl: "https://sequestr.io",
            },
          },
        );
        projects.value = data;
        projectsClonedArray.value = data;
      },
    },
  );

  const onFinancialOverviewChange = (e) => {
    financialOverviewPage.value = e.label;
  };

  const debouncedClientSearch = useDebounce(clientsSearchTerm, 250);
  const debouncedProjectSearch = useDebounce(projectsSearchTerm, 250);

  const filteredClients = computed({
    get() {
      const searchTerm = debouncedClientSearch.value.trim();
      if (!searchTerm) {
        return clients.value;
      }
      const regex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i");
      return clientsClonedArray.value.filter((client) => regex.test(client.name));
    },
    set(newValue) {
      clientsClonedArray.value = newValue;
    },
  });

  const filteredProjects = computed({
    get() {
      const searchTerm = debouncedProjectSearch.value.trim();
      if (!searchTerm) {
        return projects.value;
      }
      const regex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i");
      return projectsClonedArray.value.filter((project) => regex.test(project.name));
    },
    set(newValue) {
      projectsClonedArray.value = newValue;
    },
  });

  watch(
    [debouncedClientSearch, debouncedProjectSearch],
    ([debouncedClientSearch, debouncedProjectSearch]) => {
      filteredClients.value = filteredClients.value;
      filteredProjects.value = filteredProjects.value;
    },
  );

  onMounted(() => {
    userMutate();
    logPageView(route.name);
  });
</script>

<template>
  <AddClient />
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <div class="flex flex-col gap-8 md:gap-12">
        <div class="flex flex-col justify-between gap-4 md:flex-row">
          <h1 class="text-3xl font-semibold dark:text-gray-300 md:text-4xl">
            {{ dashboardStats.assetManager.name }}
          </h1>
          <Button
            text="View Action Items"
            classes="text-emerald-500 bg-transparent border-emerald-300 hover:border-emerald-500 hover:text-white hover:bg-emerald-500"
            iconRight="ArrowRightIcon"
            to="/asset-managers/action-items"
            outline
          />
        </div>

        <!-- Grid -->
        <div
          class="grid auto-rows-auto grid-cols-none gap-0 rounded-xl border border-gray-100 bg-gray-100 dark:border-gray-800 dark:bg-gray-900 md:grid-cols-2"
        >
          <!-- Left side -->
          <div class="grid grid-cols-none grid-rows-none gap-0 md:grid-cols-1 md:grid-rows-2">
            <div
              class="grid auto-rows-auto grid-cols-none grid-rows-2 gap-0 md:grid-cols-3 md:grid-rows-1"
            >
              <StatBox
                title="Clients with Net-Zero Commitments"
                :body="`${dashboardStats.clientsWithNetZeroCommitments} / ${dashboardStats.totalNumberOfClients}`"
              />
              <StatBox
                title="# Projects Financed"
                :body="dashboardStats.projectStats.totalCarbonProjectsPurchased"
              />
              <StatBox
                title="Average Carbon Purchases / Year"
                :body="dashboardStats.averageYearlyPurchases.toLocaleString('en-US')"
              />
            </div>
            <div
              class="grid auto-rows-auto grid-cols-none grid-rows-2 gap-0 md:grid-cols-2 md:grid-rows-1"
            >
              <StatBox
                title="Current Loan Exposure"
                :body="
                  dashboardStats.projectStats.totalInvestmentAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                "
              />
              <StatBox
                title="Average Client ESG Rating"
                :progressData="dashboardStats.portfolioHealth"
                progress
              />
            </div>
          </div>
          <!-- Right Side -->
          <StatBox
            title="Total Carbon Removals Committed"
            :body="`${dashboardStats.projectStats.carbonCredits.committedFutureOffsets.toLocaleString(
              'en-US',
            )} t`"
            large
          />
        </div>

        <!-- Financial Overview -->
        <div class="flex flex-col gap-4">
          <div
            class="flex w-full flex-col items-stretch justify-between md:flex-row md:items-center"
          >
            <h2 class="text-2xl font-semibold dark:text-gray-300">Financial Overview</h2>
            <v-select
              placeholder="Select Page"
              class="sq-v-s w-full py-0 md:w-56"
              v-model="financialOverviewPage"
              :options="[
                {
                  label: 'Loan Portfolio',
                  value: 'loanPortfolio',
                },
                {
                  label: 'Revenue Projection',
                  value: 'revenueProjection',
                },
              ]"
              :clearable="false"
              @option:selected="onFinancialOverviewChange"
              required
            />
          </div>
          <div class="w-full">
            <TabGroup
              :key="`tab-${currentTab}`"
              :defaultIndex="currentTab"
              @change="(index) => setSelectedTabIndex(index)"
            >
              <TabList
                class="mb-4 flex space-x-4 overflow-x-auto whitespace-nowrap bg-transparent md:mb-8 md:whitespace-normal"
              >
                <Tab
                  v-for="(tab, i) in tabs"
                  as="template"
                  :key="`tab-${i}`"
                  v-slot="{ selected }"
                >
                  <button
                    :class="[
                      'w-auto border-b-2 py-2 font-semibold',
                      'ring-transparent focus:outline-none',
                      'hover:text-emerald-500',
                      selected
                        ? 'border-gray-900 text-gray-900 dark:border-gray-300 dark:text-gray-300'
                        : 'border-transparent text-gray-400 dark:text-gray-500',
                    ]"
                  >
                    {{ tab.name }}
                  </button>
                </Tab>
              </TabList>

              <TabPanels class="mt-2">
                <!-- Project Type -->
                <TabPanel>
                  <div v-if="financialOverviewPage === 'Loan Portfolio'">
                    <ProjectTypeLoanPortfolioTab />
                  </div>
                  <div v-else><ProjectTypeRevenueTab /></div>
                </TabPanel>
                <!-- Geography -->
                <TabPanel>
                  <div v-if="financialOverviewPage === 'Loan Portfolio'">
                    <GeographyLoanPortfolioTab />
                  </div>
                  <div v-else><GeographyRevenueTab /></div>
                </TabPanel>
                <!-- Vintage Year Tab -->
                <TabPanel>
                  <div v-if="financialOverviewPage === 'Loan Portfolio'">
                    <VintageYearTab />
                  </div>
                  <div v-else><VintageYearRevenueTab /></div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>

        <!-- Projects -->
        <div class="flex flex-col gap-4">
          <div class="flex flex-row items-center justify-between">
            <h2 class="text-2xl font-semibold dark:text-gray-300">
              Carbon Project Investment Data
            </h2>
            <div class="flex flex-row gap-2">
              <div
                class="input-container hidden flex-row items-center justify-start gap-2 rounded-lg border border-gray-100 bg-gray-50 py-2 px-2 text-gray-800 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 md:flex"
              >
                <MagnifyingGlassIcon class="h-4 w-4" />
                <input
                  v-model="projectsSearchTerm"
                  class="bg-transparent outline-none hover:bg-transparent disabled:cursor-not-allowed disabled:opacity-30"
                  placeholder="Find a project"
                  :disabled="!projects || !projects.length"
                />
              </div>
            </div>
          </div>
          <transition-group
            name="projectsList"
            tag="div"
            class="flex w-full snap-x flex-row gap-4 overflow-x-auto whitespace-nowrap"
          >
            <div
              v-if="projects.length"
              v-for="project in projectsClonedArray"
              :key="project.id"
              class="relative h-auto w-64 flex-shrink-0 snap-start overflow-hidden rounded-2xl"
            >
              <div
                class="absolute z-[1] flex w-full flex-col gap-0 overflow-hidden whitespace-normal rounded-xl bg-gradient-to-b from-black/60 to-transparent px-4 pt-4 pb-8 text-white"
              >
                <strong class="w-full text-lg font-semibold leading-6">
                  {{ project.name }}
                </strong>
                <p class="o-50">
                  {{ project.auction.startingInventory.toLocaleString("en") }} units
                </p>
              </div>
              <div class="absolute bottom-0 z-[1] w-full p-2">
                <div
                  class="flex w-full flex-col rounded-xl bg-white/90 px-3 py-2 text-gray-900 shadow-xl backdrop-blur"
                >
                  <strong>
                    Inventory:
                    {{
                      `${project.auction?.startingInventory.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })} t` || "In development"
                    }}
                  </strong>
                  <strong>
                    Price:
                    {{
                      project.auction?.weightedAveragePrice?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }) || "Price TBD"
                    }}
                  </strong>
                  <small>Corporate Offtakers: 2</small>
                </div>
              </div>
              <img
                v-if="project.images?.length"
                :src="project.images[0]"
                :alt="project.name"
                class="h-[24rem] w-full object-cover"
              />
            </div>
            <div
              v-else
              class="flex w-full items-center justify-center rounded-lg bg-gray-50 px-4 py-12 text-center text-gray-500 dark:bg-gray-800"
            >
              No projects
            </div>
          </transition-group>
        </div>

        <!-- Brands -->
        <div class="flex flex-col gap-4">
          <div class="flex flex-row items-center justify-between">
            <h2 class="text-2xl font-semibold">Clients</h2>
            <div class="flex flex-row gap-4">
              <button
                @click="onToggleAddClientModal()"
                class="rounded-lg border border-gray-200 px-4 py-2 font-semibold text-gray-900 hover:border-emerald-500 hover:bg-emerald-500 hover:text-white dark:border-gray-700 dark:text-gray-300"
              >
                + New Client
              </button>
              <div
                class="input-container hidden flex-row items-center justify-start gap-2 rounded-lg border border-gray-100 bg-gray-50 py-2 px-2 text-gray-800 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 md:flex"
              >
                <MagnifyingGlassIcon class="h-4 w-4" />
                <input
                  v-model="clientsSearchTerm"
                  class="bg-transparent outline-none hover:bg-transparent"
                  placeholder="Find a client"
                />
              </div>
            </div>
          </div>
          <transition-group
            name="clientList"
            tag="div"
            class="flex min-h-[64rem] flex-col gap-4"
          >
            <AssetManagerClient
              v-for="client in clientsClonedArray"
              :key="client.id"
              :client="client"
            />
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .clientList-enter-active,
  .projectsList-enter-active,
  .clientList-leave-active,
  .projectsList-leave-active {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .clientList-enter,
  .clientList-leave-to {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  .projectsList-enter,
  .projectsList-leave-to {
    opacity: 0;
    transform: translateX(-0.5rem);
  }
</style>
