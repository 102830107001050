<script setup>
  import { toRefs } from "vue";
  import { PencilSquareIcon } from "@heroicons/vue/24/outline";

  import Button from "@/components/Button.vue";

  const props = defineProps({
    event: {
      name: String,
      years: Array,
      projected_emissions: Number,
      location: String,
      event_type: String,
      future_inventory: Array,
      lender_opportunity: Array,
    },
  });

  let { event } = toRefs(props);

  // Get the first and last year of the event
  const eventYears = (years) => {
    return `${years.at(0)} - ${years.at(-1)}`;
  };
</script>
<template>
  <div
    class="rounded-xl border border-gray-100 bg-gray-50 text-gray-900 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 md:rounded-2xl"
  >
    <!-- Top meta context -->
    <div
      class="flex flex-col items-start justify-start gap-0 p-4 md:flex-row md:items-center md:justify-between md:p-6"
    >
      <strong class="text-lg font-semibold">
        {{ event.name }}
      </strong>
      <div
        class="flex w-full flex-row items-stretch justify-start gap-1 text-left text-gray-500 md:w-auto md:items-center md:justify-end md:text-right"
      >
        <div class="grow">
          <small class="text-sm">
            {{ event.event_type }}
          </small>
          <small class="text-sm"> • </small>
          <small class="text-sm">
            {{ eventYears(event.years) }}
          </small>
        </div>
        <button
          class="flex-none rounded-lg border border-gray-100 bg-white p-1 text-indigo-500 hover:border-indigo-500 hover:bg-indigo-500 hover:text-white dark:border-gray-700"
        >
          <PencilSquareIcon class="aspect-1 w-5" />
        </button>
      </div>
    </div>

    <!-- Emissions and future inventory -->
    <div
      class="grid w-full grid-cols-1 border-t border-gray-100 dark:border-gray-700 md:grid-cols-2"
    >
      <div class="flex flex-col gap-2 p-4 md:p-6">
        <small class="text-gray-500"> Projected Total Emissions </small>
        <strong class="text-lg font-semibold">
          {{
            `${event.projected_emissions.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })} t` || `&mdash;`
          }}
        </strong>
        <small class="text-gray-500"> Location </small>
        <strong class="text-lg font-semibold">
          {{ event.location || `&mdash;` }}
        </strong>
      </div>
      <div
        class="flex flex-col gap-2 border-t border-gray-100 p-4 dark:border-gray-700 md:border-l md:p-6"
      >
        <small class="text-gray-500">Future Inventory Requirements</small>
        <div
          v-if="event.future_inventory"
          v-for="requirement in event.future_inventory"
          class="flex flex-row items-center justify-between"
        >
          <strong class="flex flex-row items-center gap-2 text-base font-semibold">
            <div
              :class="`
              aspect-1 h-3 w-3 rounded-full bg-gradient-to-b
              ${
                requirement.type === 'Nature-based Avoidance'
                  ? ' from-emerald-100 to-emerald-700'
                  : requirement.type === 'Nature-based Removals'
                  ? 'from-blue-100 to-blue-700'
                  : 'from-indigo-100 to-indigo-700'
              }
            `"
            ></div>
            {{ requirement.type }}
          </strong>
          <strong class="text-right text-base font-normal">
            {{
              `${requirement.credits.toLocaleString("en-US", {
                maximumFractionDigits: 0,
              })} t` || `&mdash;`
            }}
          </strong>
        </div>
        <div
          v-else
          class="text-gray-500"
        >
          No requirements suggested yet
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2 border-t border-gray-100 p-4 dark:border-gray-700 md:p-6">
      <small class="text-gray-500">Lender Opportunity</small>
      <div
        v-if="event.lender_opportunity"
        class="flex flex-row flex-wrap gap-2 md:gap-4"
      >
        <div
          v-for="opportunity in event.lender_opportunity"
          class="flex flex-row flex-wrap items-center justify-start gap-2"
        >
          <strong class="text-base font-semibold">{{ opportunity.type }}:</strong>
          <strong class="text-base font-normal"
            >{{
              opportunity.type.includes("ROI")
                ? `${opportunity.amount}%`
                : `$${opportunity.amount.toLocaleString("en-US", {
                    format: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  })}`
            }}
          </strong>
        </div>
      </div>
      <div
        v-else
        class="text-gray-500"
      >
        No lender opportunity suggested yet
      </div>
    </div>
    <div class="flex flex-row gap-2 border-t border-gray-100 p-4 dark:border-gray-700 md:p-6">
      <Button
        classes="bg-transparent border-emerald-500 text-emerald-500 hover:text-white hover:bg-emerald-500"
        text="Generate Client Report"
      />
    </div>
  </div>
</template>
