<script setup>
  const props = defineProps({
    title: String,
    helper: String,
    body: String,
  });

  let { title, helper, body } = props;
</script>

<template>
  <div
    class="flex flex-row items-center justify-between gap-2 border-b border-gray-200 py-3 last-of-type:border-b-0 dark:border-gray-700"
  >
    <div class="flex flex-1 flex-col gap-1">
      <span class="font-semibold text-gray-900 dark:text-gray-300">{{ title }}</span>
      <small
        v-if="helper"
        class="text-sm text-gray-500"
      >
        {{ helper }}
      </small>
    </div>
    <span class="min-w-[12rem] text-right text-gray-700 dark:text-gray-400">{{ body }}</span>
  </div>
</template>
