<template>
  <div class="container mx-auto">
    <CompanyInvitation :onCloseModalClick="onCloseTeamInviteModal" :open="isTeamInviteModalOpen" />
    <EmailChange
      :emailChangeModalOpen="emailChangeModalOpen"
      @on-close="emailChangeModalOpen = false"
    />
    <div class="mx-auto max-w-6xl px-4 py-12">
      <!-- Header -->
      <div
        class="md:items-middle flex w-full flex-col items-start justify-start md:flex-row md:justify-between mb-4 md:mb-12"
      >
        <h1 class="text-4xl font-semibold dark:text-gray-300">Settings</h1>
      </div>

      <div class="grid w-full grid-cols-1 items-start justify-start gap-8 md:grid-cols-3 md:gap-16">
        <!-- Tabs -->
        <TabGroup
          :key="`tab-${selectedTab}`"
          :defaultIndex="selectedTab"
          :selectedIndex="selectedTab" @change="changeTab"
        >
          <TabList
            class="flex w-auto flex-row space-x-4 overflow-x-auto whitespace-nowrap rounded-full md:rounded-2xl bg-gray-100/50 px-4 py-0 backdrop-blur-lg dark:bg-gray-600/50 md:flex-col md:space-x-0 md:whitespace-normal md:px-4 md:py-2"
          >
            <Tab
              v-for="(tab, i) in tabs"
              as="template"
              :key="`tab-${i}`"
              v-slot="{ selected }"
            >
              <button
                :class="[
                  'w-full min-w-min py-2 text-base font-semibold leading-5 md:w-auto',
                  'flex flex-row items-center justify-start gap-2 md:gap-4 text-center align-middle ring-white ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-0 md:text-left',
                  selected
                    ? ' text-gray-900 dark:border-b-gray-300 dark:text-gray-300'
                    : ' text-gray-400 hover:text-gray-900 dark:hover:text-gray-300',
                ]"
              >
              <IdentificationIcon
                  v-if="i === 0"
                  class="h-6 w-6 stroke-2"
                />
                <ShoppingBagIcon
                  v-if="i === 1"
                  class="h-6 w-6 stroke-2"
                />
                <ComputerDesktopIcon
                  v-if="i === 2"
                  class="h-6 w-6 stroke-2"
                />
                <UserGroupIcon
                  v-if="i === 3"
                  class="h-6 w-6 stroke-2"
                />
                {{ tab }}
              </button>
            </Tab>
          </TabList>

          <!-- Tab panels -->
          <TabPanels class="col-auto md:col-span-2">
            <!-- Personal settings -->
            <TabPanel class="text-gray-900 dark:text-gray-300">
              <strong class="mb-4 block text-2xl font-semibold "
                >Personal settings</strong
              >
              <Input
                autocomplete="name"
                :placeholder="profile?.name || `Enter your name`"
                :readonly="isEditing ? false : true"
                name="name"
                v-model="updatedProfile.name"
                label="Your name"
                id="name"
                chunky
                full
              />
              <Input
                autocomplete="email"
                :placeholder="profile.email || `Enter your email address`"
                :readonly="isEditing ? false : true"
                name="email"
                @change="onkeydownEmailInput"
                v-model="updatedProfile.email"
                label="Your email address"
                id="email"
                chunky
                full
              />
              <!-- Button handling -->
              <div
                v-if="isEditing"
                class="flex w-full flex-col justify-start gap-4 align-middle md:flex-row md:justify-between"
              >
                <Button
                  text="Save profile"
                  type="button"
                  @click="saveProfile"
                  :loading="isUpdateUserLoading"
                  :disabled="isUpdateUserLoading"
                />
                <Button
                  classes="text-gray-800 dark:text-gray-200 dark:hover:bg-gray-800 bg-transparent dark:bg-gray-700 hover:bg-gray-100 border-gray-200 dark:border-gray-700 shadow-none text-sm gap-2"
                  text="Cancel"
                  type="button"
                  @click="cancelEditProfile"
                />
              </div>
              <div
                v-else
                class="flex w-full flex-col justify-start gap-4 align-middle md:flex-row md:justify-between"
              >
                <Button
                  text="Edit profile"
                  type="button"
                  @click="editProfile"
                />
                <Button
                  classes="text-gray-800 dark:text-gray-200 dark:hover:bg-gray-800 bg-transparent dark:bg-gray-700 hover:bg-gray-100 border-gray-200 dark:border-gray-700 shadow-none text-sm gap-2"
                  text="Logout"
                  type="button"
                  :disabled="isLogOutLoading"
                  :loading="isLogOutLoading"
                  @click="logOutMutate"
                />
              </div>
            </TabPanel>
            <!-- Purchases -->
            <TabPanel class="flex flex-col gap-8 md:gap-12">
              <BidsPurchases />
            </TabPanel>
            <!-- Company -->
            <TabPanel class="text-gray-900 dark:text-gray-300">
              <div v-if="!profile.company" >
                <strong class="mb-4 block text-2xl font-semibold">
                  You currently do not have a company
                </strong>
                <p class="mb-4 w-full text-sm md:mb-6">
                  To set up a company please contact us via
                  <a
                    class="underline"
                    href="support@sequestr.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    >support@sequestr.io</a
                  >
                </p>
              </div>
              <div v-else>
                <div
                  class="flex w-full flex-col items-center justify-center md:flex-row md:justify-between "
                >
                  <div class="flex-grow">
                    <strong
                      class="mb-4 block text-2xl font-semibold "
                    >
                      Company info
                    </strong>
                    <p class="mb-4 w-full text-sm md:mb-6">
                      To edit company info please contact us via
                      <a
                        class="underline"
                        href="support@sequestr.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        >support@sequestr.io</a
                      >
                    </p>
                  </div>
                  <div
                    v-if="!profile.company.imageUrl"
                    class="order-first flex w-full items-center justify-center py-2 md:order-last md:w-auto"
                  >
                    <Avatar
                      :imageUrl="profile.company.imageUrl"
                      :title="profile.company.title"
                      size="large"
                    />
                  </div>
                </div>
                <div
                  v-if="profile.company.title"
                  class="flex w-full flex-col items-center justify-start border-b border-gray-100 py-4 dark:border-gray-800 md:flex-row md:justify-between"
                >
                  <strong class="w-full">Company name</strong>
                  <p class="w-full text-left md:text-right">
                    {{ profile.company.title }}
                  </p>
                </div>
                <div
                  v-if="profile.company.websiteUrl"
                  class="flex w-full flex-col items-center justify-start border-b border-gray-100 py-4 dark:border-gray-800 md:flex-row md:justify-between"
                >
                  <strong class="w-full">Company website</strong>
                  <p class="w-full text-left md:text-right">
                    {{ profile.company.websiteUrl }}
                  </p>
                </div>
                <div
                  v-if="profile.company.slug"
                  class="flex w-full flex-col items-center justify-start border-b border-gray-100 py-4 dark:border-gray-800 md:flex-row md:justify-between"
                >
                  <strong class="w-full">Company Sequestr URL</strong>
                  <p class="w-full text-left md:text-right">
                    https://sequestr.io/{{ profile.company.slug }}
                  </p>
                </div>
              </div>
            </TabPanel>
            <!-- Team -->
            <TabPanel class="flex flex-col gap-8 md:gap-12 text-gray-900 dark:text-gray-300">
              <!-- Invite members -->
                <div
                  class="relative w-full"
                >
                  <strong class="mb-4 block text-2xl font-semibold "
                    >Invite team members</strong
                  >
                  <div class="flex w-full flex-col gap-0">
                    <div class="flex flex-col gap-6 items-stretch md:items-start">
                      <Input
                        placeholder="Enter emails of team members to invite"
                        autocomplete="off"
                        type="email"
                        label="Enter emails to invite"
                        @keydown.enter.prevent="addTag(newTag)"
                        @keydown.prevent.,="addTag(newTag)"
                        @keydown.delete="newTag.length || removeTag(tags.length - 1)"
                        v-model="newTag"
                        helper="Press enter or comma to add multiple"
                        margin="mb-0"
                        full
                      />
                      <ul
                        v-show="tags && tags.length > 0"
                        class="tags flex w-full max-w-max list-none flex-row flex-wrap items-start gap-2 overflow-x-auto whitespace-pre-line p-0"
                        ref="tagsUl"
                      >
                        <li
                          v-for="(tag, index) in tags"
                          :key="tag"
                          class="tag mr-1 flex flex-row items-center justify-start gap-1 rounded-full bg-gray-50 py-1 pl-3 pr-2 text-base font-semibold text-gray-900 dark:bg-gray-800 dark:text-gray-200"
                          :class="{ duplicate: tag === duplicate }"
                        >
                          {{ tag }}
                          <button
                            class="delete opacity-100 hover:opacity-50"
                            @click="removeTag(index)"
                          >
                            <XMarkIcon
                              size="24"
                              class="h-4 w-4"
                            />
                          </button>
                        </li>
                      </ul>
                      <Button
                        :loading="invitesLoading"
                        @click="onInviteEmailSubmit()"
                        :disabled="!tags || !tags.length"
                        :text="`Send invites to ${tags.length} members`"
                      />
                      <span
                        v-if="invitesSent"
                        class="w-full text-center text-emerald-500"
                      >
                        Invites sent</span
                      >
                      <span
                        v-if="invitesError"
                        class="w-full text-center text-amber-500"
                      >
                        {{ inviteEmailError || `Please complete onboarding before inviting other team members. For support contact support@sequestr.io` }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- Current members -->
                <div
                  v-if="companyMembers && companyMembers.length"
                  class="relative w-full"
                >
                  <strong class="mb-4 block text-2xl font-semibold text-gray-900 dark:text-gray-300"
                    >Manage team members</strong
                  >
                  <Loader
                    v-if="isGetMembersLoading"
                    class="text-white"
                  />
                  <small
                    class="mt-12 inline-block w-full text-sm text-red-400"
                    v-if="isGetMembersError"
                  >
                    Error: {{ getMembersError }}
                  </small>
                  <div
                    class="flex flex-col gap-0"
                    v-else
                  >
                    <div
                      v-for="(member, index) in companyMembers"
                      class="flex flex-col md:flex-row justify-between gap-2 md:gap-4 border-b border-gray-100 py-2 align-middle text-base font-normal last-of-type:border-b-0 dark:border-gray-800 relative"
                    >
                      <div class="flex flex-1 flex-row items-start justify-start gap-4">
                        <!-- Text -->
                        <div class="flex flex-1 flex-col gap-0">
                          <strong v-if="member.invited" :class="'text-md font-semibold text-black dark:text-gray-300', member.disabled ? 'opacity-50' : ''">
                            Invitation sent
                          </strong>
                          <strong v-if="member.name" :class="'text-md font-semibold text-black dark:text-gray-300', member.disabled ? 'opacity-50' : ''">
                            {{ member.name }} <span v-if="member.id === authStore?.user.id">(you)</span>
                          </strong>
                          <small class="text-gray-900 dark:text-gray-400">
                            {{ member.email }}
                          </small>
                        </div>
                      </div>
                      <!-- Actions -->
                      <SettingsCompanyMembersActions :disabled="member.invited" :delete-members-api-call="companyStore.deleteMember" :current-user-id="authStore.user.id" :member-id="member.id" :index="index" :company-members="companyMembers"/>
                    </div>
                  </div>
                </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { nextTick, onMounted, ref, watchEffect } from "vue";
  import { storeToRefs } from "pinia";
  import { useMutation } from "vue-query";
  import { useRoute, useRouter } from "vue-router";
  import { createToast } from "mosha-vue-toastify";

  import { TabGroup, TabList, TabPanels, TabPanel, Tab } from "@headlessui/vue";

  import {
    IdentificationIcon,
    ComputerDesktopIcon,
    UserGroupIcon,
    ShoppingBagIcon,
    XMarkIcon,
  } from "@heroicons/vue/24/outline";

  import { useAuthStore } from "@/store/authStore";
  import { useCompanyStore } from "@/store/companyStore";

  import { logPageView, logEvent } from "@/services/analytics";

  import Avatar from "@/components/Avatar.vue";
  import Button from "@/components/Button.vue";
  import EmailChange from "@/components/Modals/EmailChange.vue";
  import Input from "@/components/Input.vue";
  import Loader from "@/components/Loader.vue";
  import SettingsCompanyMembersActions from "@/components/SettingsCompanyMembersActions.vue";
  import BidsPurchases from "@/views/Settings/BidsPurchases.vue";
  import CompanyInvitation from "@/components/Modals/CompanyInvitation.vue";

  const tabs = ["Personal settings", "Bids & purchases", "Company info", "Invite & manage team"];

  const emit = defineEmits(["update:modelValue"]);

  const authStore = useAuthStore();
  const { user: profile } = storeToRefs(authStore);
  const router = useRouter();
  const route = useRoute();

  const isEmailInputDirty = ref(false);
  let emailChangeModalOpen = ref(false);
  const duplicate = ref(null);
  const newTag = ref("");
  const tags = ref([]);

  let isEditing = ref(false);
  let updatedProfile = ref({
    name: null,
    email: null,
  });
  let invitesSent = ref(false);
  let invitesError = ref(false);
  let invitesLoading = ref(false);
  let selectedTab = ref(0);

  const onkeydownEmailInput = (e) => {
    isEmailInputDirty.value = true;
    const email = e.target.value;

    // reset
    if (email === authStore.user.email) {
      isEmailInputDirty.value = false;
    }
  };

  const {
    isLoading: isGetUserLoading,
    isError: isGetUserError,
    error: getUserError,
    mutate: getUserMutate,
  } = useMutation(authStore.getUser, {
    onSuccess: () => {
      updatedProfile.value = {
        name: profile.name,
        email: profile.email,
      };
    },
    onError: (error) => {
      // TODO: add profile 404s
      console.error("getUser mutate error: ", error);
    },
  });

  const {
    isLoading: isUpdateUserLoading,
    isError: isUpdateUserError,
    error: updateUserError,
    mutate: updateUser,
  } = useMutation((body) => authStore.updateUser(body), {
    onSuccess: () => {
      if (isEmailInputDirty.value) {
        emailChangeModalOpen.value = true;
      }
      createToast("Your profile has been updated", {
        position: "bottom-right",
        type: "success",
      });
      isEditing.value = false;
      logEvent("Edit: Update success");
      setReadOnly();
    },
    onError: (error) => {
      // TODO: add profile 404s
      console.error("getUser mutate error: ", error);
    },
  });

  const companyMembers = ref([]);
  const companyStore = useCompanyStore();

  const {
    isLoading: isGetMembersLoading,
    isError: isGetMembersError,
    error: getMembersError,
    mutate: getMembersMutate,
  } = useMutation(companyStore.getCompanyMembers, {
    onSuccess: (data) => {
      console.info({ data });
      // Put current user at the top and sort by active users first
      const sorted = data.sort((a, b) => {
        if (!a?.invited) return -1;
        if (!b?.invited) return 1;
        return a.invited > b.invited ? -1 : 1 || a.id === authStore?.user.id ? -1 : 1;
      });
      companyMembers.value = sorted;
    },
    onError: (error) => {
      // TODO: add profile 404s
      console.error("getUser mutate error: ", error);
    },
  });

  const changeTab = (index) => {
    console.info("changed tab:", index);

    let tabName;
    switch(index) {
      case 0:
        tabName = "me";
        break;
      case 1:
        tabName = "bids";
        break;
      case 2:
        tabName = "company";
        break;
      case 3:
        tabName = "team";
        break;
      default:
        tabName = "me";
        break;
    }

    nextTick(() => {
      router.push({
        route: route.currentPath,
        query: {
          tab: tabName,
          ...inviteCode.value.length > 0 && { inviteCode: inviteCode.value }
        },
      });

      selectedTab.value = index;
    });
  }

  watchEffect(route, (route) => {
    console.info("Watch route:", route)
  })

  const inviteCode = ref("");
  const isTeamInviteModalOpen = ref(false);

  const onCloseTeamInviteModal = () => {
    isTeamInviteModalOpen.value = false;
  }

  onMounted(() => {
    getMembersMutate();
    onTagsChange();
    updatedProfile.value = {
      email: authStore.user.email,
      name: authStore.user.name,
    };

    const { query } = route;

    // handle invitations to companies
    const inviteCodeFromQuery = query?.inviteCode;

    if (inviteCodeFromQuery) {
      inviteCode.value = inviteCodeFromQuery;
      isTeamInviteModalOpen.value = true;
    }

    const tab = query?.tab
    switch (tab) {
      case "bids":
        changeTab(1);
        break;
      case "company":
        changeTab(2);
        break;
      case "team":
        changeTab(3);
        break;
      default:
        changeTab(0);
        break;
    }

    logPageView(route.name);
  });

  const {
    isLoading: isLogOutLoading,
    isError: isLogOutError,
    error: logOutError,
    mutate: logOutMutate,
  } = useMutation(authStore.logout, {
    onSuccess: () => {
      logEvent("Logout");
      router.push("/");
      router.go();
    },
    onError: () => {
      createToast("Something went wrong, please try again", {
        type: "danger",
        position: "bottom-right",
      });
    },
  });

  const setReadOnly = () => {
    // Add readonly attr on name and email inputs
    document.getElementById("name").setAttribute("readonly", true);
    document.getElementById("email").setAttribute("readonly", true);
  };

  const editProfile = () => {
    isEditing.value = true;
    // Remove readonly on name and email inputs;
    document.getElementById("name").removeAttribute("readonly");
    document.getElementById("email").removeAttribute("readonly");
    logEvent("Edit: Profile");
  };

  const cancelEditProfile = () => {
    isEditing.value = false;
    setReadOnly();
  };

  const saveProfile = () => {
    const payload = {
      name: updatedProfile.value.name,
      email: undefined,
    };

    if (isEmailInputDirty.value) {
      payload.email = updatedProfile.value.email;
    }

    updateUser(payload);
    logEvent("Edit: Save profile");
  };

  const {
    isError: isInviteEmailError,
    error: inviteEmailError,
    isLoading: inviteEmailLoading,
    mutate: inviteEmailMutate,
  } = useMutation(authStore.inviteEmails, {
    onSuccess: () => {
      invitesLoading.value = false;
      invitesSent.value = true;
      tags.value = [];
      getMembersMutate();
    },
    onError: () => {
      invitesLoading.value = false;
      invitesSent.value = false;
      invitesError.value = true;
    },
  });

  const onInviteEmailSubmit = async () => {
    invitesLoading.value = true;
    const emails = tags.value;
    console.info("tags.value:", emails);
    // remove empty values from the array before sending in case the user does not fill out all "add more" email rows
    const finalEmailsArray = emails.filter((a) => a);
    console.info("final emails:", finalEmailsArray);
    logEvent("Invited team members", {
      amount: finalEmailsArray?.length
    });
    await inviteEmailMutate({ emails: finalEmailsArray });
  };

  const removeTag = (index) => {
    console.trace("removing", index);
    tags.value.splice(index, 1);
  };

  const handleDuplicate = (tag) => {
    duplicate.value = tag;
    setTimeout(() => (duplicate.value = null), 1000);
    newTag.value = "";
  };

  const addTag = (tag) => {
    console.info({ tag });
    if (!tag) return; // prevent empty
    const isEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(tag);
    console.info({ isEmail });
    if (!isEmail) return; // prevent non-emails from being added
    // if already invited/member already exists show toast/don't send
    const haveUserAlready = companyMembers.value.find((member) => member.email === tag);
    console.info({ haveUserAlready });
    if (haveUserAlready) {
      createToast("This user has already been invited!", {
        position: "bottom-right",
      });
      return;
    }
    if (tags.value.includes(tag)) {
      handleDuplicate(tag);
      return;
    }
    tags.value.push(tag);
    invitesSent.value = false;
    invitesError.value = false;
    newTag.value = ""; // reset newTag
  };

  const onTagsChange = () => {
    // emit value on tags change
    emit("update:modelValue", tags.value);
    console.log("tag:", tags.value);
  };

  watchEffect(tags, () => nextTick(onTagsChange), { deep: true });
</script>

<style scoped>
  @keyframes shake {
    10%,
    90% {
      transform: scale(0.9) translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: scale(0.9) translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: scale(0.9) translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: scale(0.9) translate3d(4px, 0, 0);
    }
  }
  .tag.duplicate {
    @apply bg-amber-500;
    animation: shake 1s;
  }
</style>
