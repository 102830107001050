<script setup>
  const props = defineProps({
    title: [String, Number],
    body: [String, Number],
  });

  const { title, body } = props;
</script>

<template>
  <div class="flex flex-col gap-4">
    <h1
      v-if="title"
      class="text-3xl font-semibold dark:text-gray-300 md:text-4xl"
    >
      {{ title }}
    </h1>
    <p
      v-if="body"
      class="inline-block w-full text-gray-400"
    >
      {{ body }}
    </p>
  </div>
</template>
