<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <div class="flex flex-col gap-8">
        <div class="flex w-full flex-col">
          <h1 class="mb-4 text-4xl font-semibold dark:text-gray-300">
            Verify your email to continue
          </h1>
          <p class="w-full max-w-3xl text-gray-500">
            Make sure your email is verified by clicking the verification link we sent to your
            email, and while you wait for your account to be approved do learn more about Sequestr!
          </p>
        </div>
        <h2 class="text-2xl font-bold text-gray-900 dark:text-gray-300">
          Learn more about Sequestr
        </h2>
        <div class="relative grid grid-cols-1 items-stretch gap-4 md:grid-cols-3">
          <div
            v-for="item in onboardingItems"
            class="relative flex h-auto flex-col overflow-hidden rounded-lg bg-gray-50 text-gray-900 dark:bg-gray-800 dark:text-gray-300 md:h-[28rem]"
          >
            <div class="flex w-full flex-col gap-2 p-4 md:p-8">
              <strong class="text-2xl font-semibold">
                {{ item.title }}
              </strong>
              <p class="opacity-50c text-sm font-normal">
                {{ item.body }}
              </p>
            </div>
            <img
              class="relative right-[-1.5rem] bottom-0 h-64 object-contain md:absolute"
              :src="item.image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from "vue";
  import { useRoute } from "vue-router";
  import { logPageView } from "@/services/analytics";

  const route = useRoute();

  onMounted(() => {
    logPageView(route.name);
  });

  const onboardingItems = ref([
    {
      title: "Sequestr is a managed marketplace",
      body: "Sequestr helps projects get upfront financing by selling future offsets on a transparent marketplace.",
      image: "/img/app-projects.png",
    },
    {
      title: "Project development & management",
      body: "Providing the infrastructure and tools to start creating carbon removal or sequestration projects.",
      image: "/img/app-auction-2.png",
    },
    {
      title: "Upfront financing & future offset revenue",
      body: "We work with projects to secure early financiers to run pilots, and for projects to scale.",
      image: "/img/app-financing.png",
    },
  ]);
</script>
