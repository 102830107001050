import colors from "tailwindcss/colors";

const minSequestrationPotential = (methodologies) => {
  const m = methodologies.length && methodologies[0];
  if (!m) {
    return "Contact us";
  }
  return {
    potential: (m && m.sequestration && m.sequestration[0]) || "Contact us",
    credits: (m && m.credits_outlook && m.credits_outlook[0]) || "Contact us",
  };
};

const tryParseJSONObject = (val) => (val instanceof Array || val instanceof Object ? true : false);

const getRandomTailwindColor = (() => {
  const colorKeys = [
    "slate",
    "amber",
    "yellow",
    "lime",
    "emerald",
    "blue",
    "indigo",
    "fuchsia",
    "pink",
    "lightBlue",
  ];
  let index = 0;
  const usedColorKeys = new Set();
  return () => {
    let randomColorKey = colorKeys[index % colorKeys.length];
    while (usedColorKeys.has(randomColorKey)) {
      index++;
      randomColorKey = colorKeys[index % colorKeys.length];
    }
    usedColorKeys.add(randomColorKey);
    const randomShade = Math.floor(Math.random() * 4) * 100 + 300; // increment by 100 from 300 to 600
    const randomColor = colors[randomColorKey][randomShade];
    if (usedColorKeys.size === colorKeys.length) {
      usedColorKeys.clear();
      index = 0;
    }
    return randomColor;
  };
})();

export { minSequestrationPotential, tryParseJSONObject, getRandomTailwindColor };
