import { api } from "@/utils/axios";
import sleep from "@/utils/sleep";

export async function uploadFile(projectSlug, file) {
  try {
    let formData = new FormData();
    formData.append("file", file.file);
    const { data } = await api.post(`projects/${projectSlug}/upload`, formData);
    return data;
  } catch (error) {
    throw error;
  }
}

export async function uploadFiles(slug, files) {
  try {
    let data;
    for (const file of files) {
      await sleep(750);
      data = await uploadFile(slug, file);
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export default function createUploader() {
  return {
    async uploadFile(slug, file) {
      const fileToUpload = file.value;
      return uploadFile(slug, fileToUpload);
    },
    async uploadFiles(slug, files) {
      const filesToUpload = files.filter((file) => typeof file === "object");
      try {
        return await uploadFiles(slug, filesToUpload);
      } catch (error) {
        throw error;
      }
    },
  };
}
