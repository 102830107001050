<template>
  <hr
    :class="[
      'rounded-md border-2 border-gray-100 dark:border-gray-800 ',
      centered ? 'mx-auto' : '',
      margin ? margin : 'my-6 md:my-8',
      full ? 'w-full' : 'w-8',
    ]"
  />
</template>
<script>
  export default {
    props: {
      margin: String,
      centered: {
        default: false,
        type: Boolean,
      },
      full: {
        default: false,
        type: Boolean,
      },
    },
  };
</script>
