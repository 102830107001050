const analytics = window && window.analytics;
const segmentKey = import.meta.env.VITE_SEGMENT_KEY;

export const logPageView = async (name, properties) => {
  if (segmentKey) {
    try {
      if (name) {
        await analytics.page(name, properties);
      } else {
        await analytics.page();
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export const logEvent = async (eventName, eventObject) => {
  if (segmentKey) {
    const object = eventObject ? eventObject : {};
    try {
      await analytics.track(eventName, object);
    } catch (error) {
      console.error(error);
    }
  }
};

export const identify = async (userId, userObject) => {
  if (segmentKey) {
    const object = userObject ? userObject : {};
    try {
      await analytics.identify(userId, object);
    } catch (error) {
      console.error(error);
    }
  }
};
