<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-3xl px-4 py-12">
      <h1 class="mb-8 text-4xl font-semibold dark:text-gray-300">So you forgot your password...</h1>
      <p class="mb-8 text-base text-gray-500 md:mb-12">
        That's ok, it happens to the best of us. Let's get you back on Sequestr.
      </p>
      <form
        class="mb-4 flex w-full flex-col items-stretch justify-start gap-4 md:flex-row md:items-center"
        @submit.prevent="onFormSubmit"
      >
        <Input
          v-model="email"
          placeholder="Enter your email"
          container-class="w-full md:w-auto"
          classes="w-full md:w-96"
          margin="mb-0"
          name="email"
          type="email"
          id="email"
          hideLabel
          full
        />
        <Button
          :loading="isLoading"
          :disabled="isLoading"
          classes="
            w-full
            md:w-auto
            bg-transparent text-gray-800 dark:text-gray-500
            dark:border-gray-800
            hover:bg-gray-50 hover:text-gray-800
            shadow-none
            focus:outline-none
            focus-visible:ring-2
            focus-visible:ring-offset-2
            focus-visible:ring-gray-800
            mb-0
          "
          text="Submit"
          type="submit"
        />
      </form>

      <small
        class="mt-12 inline-block w-full text-sm text-red-400"
        v-if="isError"
      >
        Error: {{ error }}
      </small>
      <small
        v-show="linkSent"
        class="block text-xs text-emerald-500 dark:text-gray-400"
      >
        A link has been sent to {{ email }}, if the account exists with this address, you'll receive
        an email with a reset link.
      </small>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from "vue";
  import { logEvent, logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import Input from "@/components/Input.vue";
  import { useMutation } from "vue-query";
  import { useAuthStore } from "@/store/authStore";
  import { useRoute } from "vue-router";

  const email = ref("");
  const linkSent = ref(false);
  const authStore = useAuthStore();
  const route = useRoute();

  const { isError, error, isLoading, mutate } = useMutation(authStore.forgotPassword, {
    onSuccess: () => {
      linkSent.value = true;
    },
  });

  const onFormSubmit = () => {
    mutate({ email: email.value });
    logEvent("Reset password");
  };

  onMounted(() => {
    logPageView(route.name);
  });
</script>
