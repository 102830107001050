<template>
  <nav class="sticky right-0 left-0 top-0 z-10 bg-white dark:bg-black">
    <div class="mx-auto w-full xl:container">
      <div class="flex items-center justify-between border-b border-gray-100 p-4">
        <div
          :class="isLoggedIn ? 'w-full' : 'w-auto'"
          class="z-30 flex items-center justify-between md:z-auto md:w-auto"
        >
          <div class="flex flex-row gap-4 align-middle md:gap-8">
            <!-- Logo -->
            <Logo />
            <!-- Dark/light/system -->
            <RadioGroup
              v-model="mode"
              class="text-black-800 flex flex-row items-center justify-end dark:text-gray-300"
            >
              <RadioGroupOption
                v-slot="{ checked }"
                value="system"
              >
                <ComputerDesktopIcon
                  :class="`h-8 w-8 cursor-pointer rounded-md p-2 text-gray-400 ease-in-out hover:text-emerald-500 ${
                    checked ? 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-white' : ''
                  }`"
                />
              </RadioGroupOption>
              <RadioGroupOption
                v-slot="{ checked }"
                value="light"
              >
                <LightBulbIcon
                  :class="`h-8 w-8 cursor-pointer rounded-md p-2 text-gray-400 ease-in-out hover:text-emerald-500 ${
                    checked ? 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-white' : ''
                  }`"
                />
              </RadioGroupOption>
              <RadioGroupOption
                v-slot="{ checked }"
                value="dark"
              >
                <MoonIcon
                  :class="`h-8 w-8 cursor-pointer rounded-md p-2 text-gray-400 ease-in-out hover:text-emerald-500 ${
                    checked ? 'bg-gray-100 text-gray-400 dark:bg-gray-800 dark:text-white' : ''
                  }`"
                />
              </RadioGroupOption>
            </RadioGroup>
          </div>
          <!-- Toggle nav -->
          <div class="flex flex-row items-center gap-4">
            <!-- Mobile create button -->
            <router-link
              v-if="isPD"
              class="flex-start ml-2 flex w-auto min-w-min flex-row items-center gap-2 rounded-lg bg-emerald-500 p-1 align-middle text-base font-bold leading-5 text-white ring-white ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-0 md:ml-0 md:hidden md:w-auto md:py-2 md:pl-3 md:pr-4"
              to="/create"
              @click="closeMenu"
            >
              <PlusCircleIcon
                class="flex h-5 w-5"
                size="24"
              />
              <span class="hidden md:flex">Create a project</span>
            </router-link>
            <button
              v-if="isLoggedIn"
              @click="toggleMenu"
              class="rounded-md bg-gray-50 py-1 px-2 text-sm font-semibold leading-5 text-gray-800 dark:bg-gray-800 dark:text-gray-300 md:hidden"
            >
              {{ menuOpen ? "Close" : "Menu" }}
            </button>
          </div>
        </div>
        <!-- Nav & mode -->
        <div class="flex items-center justify-end gap-4">
          <!-- Open Nav -->
          <div
            class="flex flex-row items-center justify-end gap-4"
            v-if="isLoggedIn"
          >
            <ul
              :class="menuOpen ? 'translate-y-0' : 'translate-y-[-100%] md:translate-y-0'"
              class="list nav-menu fixed right-0 left-0 top-0 z-20 flex h-screen transform-gpu flex-col justify-center gap-4 rounded-none bg-white px-12 py-20 align-middle dark:bg-gray-800 md:relative md:right-auto md:left-auto md:top-auto md:h-auto md:flex-row md:justify-end md:gap-0 md:rounded-full md:bg-gray-100/50 md:px-2 md:py-0 md:dark:bg-gray-600/50"
            >
              <li
                v-for="item in navAuthenticated"
                class="h-auto w-auto"
                :key="item.id"
              >
                <router-link
                  class="router-link list-item w-auto min-w-min rounded-full bg-gray-100/50 p-3 text-center text-base font-semibold leading-5 ring-white ring-opacity-60 ring-offset-0 backdrop-blur-md focus:outline-none focus:ring-0 md:bg-transparent md:p-2 md:text-inherit"
                  @click="closeMenu"
                  :to="item.link"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ul>
            <!-- Default create button (hidden on mobile) -->
            <router-link
              v-if="isPD"
              class="flex-start ml-2 hidden w-auto min-w-min flex-row items-center gap-2 rounded-lg bg-emerald-500 p-1 align-middle text-base font-bold leading-5 text-white ring-white ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-0 md:ml-0 md:flex md:w-auto md:py-2 md:pl-3 md:pr-4"
              to="/create"
            >
              <PlusCircleIcon
                class="flex h-5 w-5"
                size="24"
              />
              <span class="hidden md:flex">Create a project</span>
            </router-link>
          </div>
          <ul
            class="list flex flex-row items-center gap-1"
            v-else
          >
            <li
              v-for="item in navUnauthenticated"
              :key="item.id"
            >
              <router-link
                class="router-link w-full min-w-min rounded-full px-4 py-2 text-base font-semibold leading-5 ring-white ring-opacity-60 ring-offset-0 backdrop-blur-md focus:outline-none focus:ring-0 md:w-auto"
                :to="item.link"
              >
                {{ item.title }}
              </router-link>
            </li>
            <Button
              text="Signup"
              to="/signup"
            />
          </ul>
        </div>
      </div>
    </div>
    <OnboardingNotComplete
      v-if="
        authStore.user &&
        (authStore.hasUserNotStartedOnboarding ||
          authStore.isUserStatusVerified ||
          !authStore.user.name) &&
        route.path !== '/onboarding'
      "
    />
    <PendingVerification
      v-if="authStore.user && authStore.isUserPendingVerification && route.path !== '/onboarding'"
    />
  </nav>
</template>

<script setup>
  import { computed, ref, watch, watchEffect } from "vue";
  import { useRoute, useRouter } from "vue-router";

  import {
    ComputerDesktopIcon,
    PlusCircleIcon,
    MoonIcon,
    LightBulbIcon,
  } from "@heroicons/vue/24/solid";

  import { RadioGroup, RadioGroupOption } from "@headlessui/vue";

  import { useAuthStore } from "@/store/authStore";

  import Button from "@/components/Button.vue";
  import Logo from "@/components/Logo.vue";
  import OnboardingNotComplete from "@/components/UserBannerStates/OnboardingNotComplete.vue";
  import PendingVerification from "@/components/UserBannerStates/PendingVerification.vue";

  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore();
  const companySlug = computed(() => authStore.user?.company?.slug || "");

  let isPD = ref(false);
  let navAuthenticated = ref([]);
  let assetManagersNav = ref(true);

  authStore.$subscribe((mutation, state) => {
    const routes = [
      {
        title: "Projects",
        link: "/projects",
        id: "projects",
      },
      {
        title: "Eligibility",
        link: "/eligibility",
        id: "eligibility",
      },
    ];

    const user = state?.user;
    if (!user) {
      return;
    }

    const userRole = user.userRole;
    const userEmail = user.email;

    // Check if user.email includes "@sequestr.io" if so then push the asset managers route to routes
    if (userEmail?.includes("sequestr.io")) {
      routes.push({
        title: "Asset Managers",
        link: "/asset-managers",
        id: "asset-managers",
      });
    }

    const blocked = userEmail?.includes("rangecarbon.co");

    if (userRole === "project" && !blocked) {
      isPD.value = true;
    }

    if (isPD.value && companySlug.value) {
      routes.push({
        title: "Company",
        link: `/company/${companySlug.value}`,
        id: "company",
      });
    }

    routes.push({
      title: "Settings",
      link: "/settings",
      id: "settings",
    });

    navAuthenticated.value = routes;
  });

  const navUnauthenticated = [
    {
      title: "Log in",
      link: "/login",
      id: 0,
    },
  ];

  const menuOpen = ref(false);
  const mode = ref("light");

  const closeMenu = () => {
    menuOpen.value = false;
  };

  const toggleMenu = () => {
    menuOpen.value = !menuOpen.value;
  };

  const isLoggedIn = computed(() => {
    return !!authStore.user;
  });

  const onLogOutClick = () => {
    router.push("/login");
    authStore.logout();
  };

  const matches = () => (window.matchMedia("(prefers-color-scheme: dark)").matches ? true : false);

  const setDark = () => {
    document.documentElement.classList.add("dark");
  };

  const removeDark = () => {
    document.documentElement.classList.remove("dark");
  };

  const checkSystem = () => {
    if (matches()) {
      setDark();
    } else {
      removeDark();
    }
  };

  watchEffect(() => {
    switch (mode.value) {
      case "dark":
        setDark();
        break;
      case "light":
        removeDark();
        break;
      default:
        checkSystem();
    }
  });

  window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
    if (mode.value === "system") {
      e.currentTarget.matches ? setDark() : removeDark();
    }
  });

  // Watch for route changes, and see if routes.js has a meta key for hideNav
  watch(
    () => route.meta.hideNav,
    (hideNav) => {
      if (hideNav) {
        assetManagersNav.value = false;
      } else {
        assetManagersNav.value = true;
      }
    },
  );

  removeDark();
</script>

<style lang="scss" scoped>
  .router-link {
    &:not(.router-link-active),
    &:not(.flex) {
      @apply text-gray-400 hover:text-gray-900 dark:hover:text-gray-300;
    }
    &.router-link-active {
      @apply text-gray-900 dark:text-emerald-500;
    }
  }

  .nav-menu {
    transition: 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
</style>
