<script setup>
  import { onMounted, reactive, ref, unref } from "vue";
  import { useMutation } from "vue-query";
  import { useRoute } from "vue-router";
  import moment from "moment";

  import { useEligibilityStore } from "@/store/eligibilityStore";
  import { logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import Divider from "@/components/Divider.vue";
  import Loader from "@/components/Loader.vue";
  import PageHeader from "@/components/PageHeader.vue";
  import EligibilityListItem from "@/components/EligibilityListItem.vue";
  import EligibilityStateModal from "@/components/Modals/EligibilityState.vue";

  const route = useRoute();
  const eligibilityStore = useEligibilityStore();
  const haveEligibilities = reactive({
    value: false,
  });

  // Get draftEligibilities from eligibilityStore.getAllDraftEligibilities getter
  const draftEligibilities = unref(ref(eligibilityStore.getAllDraftEligibilities || [])).sort(
    (a, b) => {
      const dateA = moment(a.createdAt);
      const dateB = moment(b.createdAt);
      return dateB.diff(dateA);
    },
  );
  console.info({ draftEligibilities });

  const eligibilityList = ref([]);

  const { isLoading, isError, error, mutate, data } = useMutation(
    () => eligibilityStore.getEligibilityList(),
    {
      onSuccess: (data) => {
        console.info({ data });

        // Sort data by data.createdAt
        const sorted = data.sort((a, b) => {
          const dateA = moment(a.createdAt);
          const dateB = moment(b.createdAt);
          return dateB.diff(dateA);
        });
        eligibilityList.value = sorted || [];
        haveEligibilities.value = data.length > 0 && true;
      },
    },
  );

  onMounted(() => {
    mutate();
    logPageView(route.name);

    haveEligibilities.value = draftEligibilities.length > 0 || eligibilityList.value.length > 0;
    console.info(haveEligibilities.value, draftEligibilities.length, eligibilityList.value.length);
  });
</script>

<template>
  <EligibilityStateModal
    :showModal="eligibilityStore.eligibilityModalOpen"
    type="no_report"
  />
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <div class="flex flex-col gap-6">
        <PageHeader
          title="Eligibility"
          body="You'll find a list of projects you've ran through our eligibility model to see if your project is eligible to generate carbon credits."
        />
        <div
          class="my-0 grid w-full grid-cols-1 items-start justify-start gap-8 md:my-4 md:grid-cols-3 md:gap-16"
        >
          <!-- Content -->
          <div class="col-auto flex w-full justify-center align-middle md:col-span-2">
            <!-- Empty state -->
            <Loader v-if="isLoading" />
            <!-- Empty state -->
            <div
              v-if="!haveEligibilities && !isLoading"
              class="flex w-full items-center justify-center rounded-xl border border-gray-100 p-6 text-center text-gray-500 dark:border-gray-800 dark:text-gray-500 md:min-h-[48rem]"
            >
              <p>Your eligibility list will be displayed here once you've created reports.</p>
            </div>
            <!-- Loaded -->
            <div
              v-else
              class="flex w-full flex-col gap-4"
            >
              <div
                v-if="draftEligibilities.length"
                class="flex flex-col gap-2"
              >
                <h2 class="text-xl font-semibold text-gray-900 dark:text-gray-500">
                  Draft eligibilities
                </h2>
                <EligibilityListItem
                  v-for="draft in draftEligibilities"
                  :eligibility="draft"
                  :key="`draft-${draft.id}`"
                />
              </div>
              <Divider v-if="draftEligibilities.length" />
              <div class="flex flex-col gap-2">
                <h2 class="text-xl font-semibold text-gray-900 dark:text-gray-500">
                  Submitted eligibilities
                </h2>
                <EligibilityListItem
                  v-if="eligibilityList.length"
                  v-for="eligibility in eligibilityList"
                  :eligibility="eligibility"
                  :key="`eligibility-${eligibility.id}`"
                />
                <div
                  v-else
                  class="text-gray-500"
                >
                  No eligibilities submitted
                </div>
              </div>
            </div>
          </div>
          <!-- Create state -->
          <div
            class="relative order-first col-auto flex w-full flex-col items-stretch gap-4 rounded-xl border border-gray-100 bg-gray-50 p-6 text-gray-800 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-500 md:sticky md:top-32 md:order-last md:col-span-1"
          >
            <div class="flex flex-1 flex-col gap-1">
              <strong class="text-lg text-gray-900 dark:text-gray-300">
                Create a new eligibility report
              </strong>
              <p class="text-sm text-gray-500">
                Our eligibility framework is used to quickly assess the eligibility of an early
                stage project to determine if your project will meet certification requirements with
                the supported standards and project types.
              </p>
            </div>
            <Button
              classes="border-indigo-500 bg-indigo-500 text-white hover:bg-indigo-700 dark:border-indigo-800"
              text="Create a report"
              to="/eligibility/create"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
