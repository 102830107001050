<template>
  <TransitionRoot
    appear
    :show="emailChangeModalOpen"
    as="template"
  >
    <Dialog as="div">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-black/20 backdrop-blur-sm">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
            >
              <DialogTitle
                as="h3"
                class="mb-4 text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
              >
                Email change request has been sent
              </DialogTitle>
              <div class="mb-6">
                <p class="mb-4 text-sm text-gray-500">
                  Please click the link in your old email and your new email to confirm the change.
                </p>
              </div>

              <div class="flex flex-col gap-4 md:flex-row">
                <Button
                  classes="
                      bg-transparent text-gray-800 dark:text-gray-500
                      dark:border-gray-800
                      hover:bg-gray-50 hover:text-gray-800
                      shadow-none
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-gray-800
                    "
                  text="Got It"
                  type="button"
                  @click="closeModal"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import { ref, watch } from "vue";

  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";

  import Button from "@/components/Button.vue";

  export default {
    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionRoot,
      TransitionChild,
      Button,
    },
    props: {
      emailChangeModalOpen: {
        default: false,
        type: Boolean,
      },
    },
    setup(props) {
      let emailChangeModalOpen = ref(props.emailChangeModalOpen);

      watch(
        () => props.emailChangeModalOpen,
        () => {
          emailChangeModalOpen.value = props.emailChangeModalOpen;
        },
      );
      return {
        emailChangeModalOpen,
      };
    },
    methods: {
      closeModal() {
        this.$emit("on-close");
        this.emailChangeModalOpen = false;
      },
    },
  };
</script>
