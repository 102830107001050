<script setup>
  import { ref } from "vue";

  let projects = ref([
    {
      id: 1,
      name: "Tanzania Cookstoves",
      startDate: new Date("2023-01-01"),
      endDate: new Date("2023-04-04"),
      nextMilestone: "Q2 2022 - Completion of Construction",
    },
    {
      id: 2,
      name: "Undo Enhanced Weathering",
      startDate: new Date("2023-04-01"),
      endDate: new Date("2023-06-01"),
      nextMilestone: "Q3 2022 - Start of Monitoring and Verification",
    },
    {
      id: 3,
      name: "Eion Carbon Removals",
      startDate: new Date("2023-06-01"),
      endDate: new Date("2023-07-04"),
      nextMilestone: "Q1 2022 - Community Engagement Workshop",
    },
  ]);
</script>

<template>
  <div class="flex flex-col gap-4">
    <h1 class="text-xl font-semibold text-gray-900 dark:text-gray-300">
      Milestones &amp; Deadlines
    </h1>
    <div class="flex flex-col gap-0">
      <div
        v-for="project in projects"
        :key="project.id"
        class="flex flex-row items-start justify-between gap-0 border-b border-gray-100 py-4 last-of-type:border-b-0 dark:border-gray-700 md:flex-row md:items-center md:gap-2"
      >
        <div class="flex w-full flex-col gap-0">
          <div class="flex w-full flex-row items-center justify-between">
            <h2 class="text-lg font-medium text-gray-900 dark:text-gray-300">{{ project.name }}</h2>
            <small class="text-sm text-indigo-500"> Next: {{ project.nextMilestone }} </small>
          </div>
          <div class="flex-start flex flex-row items-center justify-start gap-1 text-gray-500">
            <small>
              Start date:
              {{ project.startDate.toLocaleString("en-US", { month: "long", year: "numeric" }) }}
            </small>
            <small> • </small>
            <small>
              End date:
              {{ project.endDate.toLocaleString("en-US", { month: "long", year: "numeric" }) }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
