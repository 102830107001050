<template>
  <div class="grid auto-rows-auto grid-cols-1 gap-4 gap-6">
    <div
      v-for="property in properties"
      :key="property.id"
      class="flex flex-col gap-4 rounded-md bg-white p-0 shadow-[0_2rem_4rem_0_rgba(0,0,0,0.06)] dark:bg-gray-900 md:flex-row md:gap-6 md:p-6"
    >
      <div
        class="h-full min-h-[16rem] w-full overflow-hidden rounded-md border border-gray-100 bg-gray-50 text-center dark:border-gray-700 md:w-48"
      >
        <img
          v-if="property.image && property.image.url"
          :src="`${property.image.url}?tpl=large`"
          class="h-full w-full object-cover"
        />
        <div
          v-else
          class="flex h-full w-full items-center justify-center text-xs text-gray-400"
        >
          No image
        </div>
      </div>
      <div class="flex flex-1 flex-col px-4 text-sm text-gray-900 md:px-0 md:py-4">
        <div class="flex flex-1 flex-col">
          <strong class="text-xl font-semibold dark:text-gray-500">
            {{ property.title || `No title` }}
          </strong>
          <small
            v-if="property.address_object"
            v-html="property.address_object.adr_address"
            class="my-3 text-gray-400"
          >
          </small>
          <p
            v-if="property.description"
            class="whitespace-preline mb-4 leading-6 text-gray-500"
            v-snip="3"
          >
            {{ property.description }}
          </p>
        </div>
        <div class="flex flex-col gap-2 text-xs">
          <!-- Methodologies -->
          <div
            v-if="property.methodologies.length"
            class="flex flex-col flex-wrap items-baseline gap-2 text-gray-500 md:flex-row"
          >
            <!-- Handle nested array -->
            <div
              v-for="(m, i) in property.methodologies"
              :key="`m-${i}`"
              class="rounded-md border border-blue-100 bg-blue-50 px-2 py-1 font-medium text-blue-800 dark:border-blue-900 dark:bg-blue-800 dark:text-gray-300"
            >
              {{ m.title }}
            </div>
          </div>
          <!-- Property info -->
          <div class="flex flex-col flex-wrap items-baseline gap-2 text-gray-700 md:flex-row">
            <!-- Size -->
            <div
              v-if="property.property_size"
              class="rounded-md border border-gray-100 bg-transparent px-2 py-1 font-medium text-gray-800 dark:border-gray-700 dark:bg-transparent dark:text-gray-300"
            >
              Size: {{ property.property_size }} acres
            </div>
            <div
              v-if="property.duration"
              class="rounded-md border border-gray-100 bg-transparent px-2 py-1 font-medium text-gray-800 dark:border-gray-700 dark:bg-transparent dark:text-gray-300"
            >
              Duration: {{ property.duration }} years
            </div>
            <div
              v-if="property.methodologies.length"
              class="rounded-md border border-gray-100 bg-transparent px-2 py-1 font-medium text-gray-800 dark:border-gray-700 dark:bg-transparent dark:text-gray-300"
            >
              Sequestration potential:
              {{ minSequestrationPotential(property.methodologies).potential }}
            </div>
            <div
              v-if="property.methodologies.length"
              class="rounded-md border border-gray-100 bg-transparent px-2 py-1 font-medium text-gray-800 dark:border-gray-700 dark:bg-transparent dark:text-gray-300"
            >
              Est. credits:
              {{ minSequestrationPotential(property.methodologies).credits }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-row items-center justify-between gap-4 border-t border-gray-50 p-4 dark:border-gray-800 md:flex-row md:justify-end md:border-t-0 md:p-0"
      >
        <Button
          classes="text-indigo-800 bg-indigo-50 hover:bg-indigo-100 shadow-none text-sm"
          text="Finance"
          type="button"
        />
        <Button
          classes="hover:bg-emerald-600 text-sm"
          text="View"
          :to="`/property/${property.slug}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Button from "@/components/Button.vue";
  import { minSequestrationPotential } from "@/utils";

  export default {
    props: {
      properties: {
        default: [],
        type: Array,
      },
    },
    components: {
      Button,
    },
    setup() {
      return {
        minSequestrationPotential,
      };
    },
  };
</script>
