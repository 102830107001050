<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-3xl px-4 py-12">
      <h1 class="mb-8 text-4xl font-semibold dark:text-gray-300">
        {{
          linkExpired && token
            ? "Activation link has expired"
            : !linkExpired && !token
            ? "Verify your email"
            : "Welcome on board"
        }}
      </h1>
      <p class="mb-8 text-base text-gray-500 md:mb-12">
        We're excited to have you on board. If you have any questions feel free to reach out via
        email
        <a
          class="underline hover:text-gray-800 dark:text-gray-400"
          :href="`mailto:${emailLink}`"
          target="_blank"
          rel="noopener noreferrer"
          >{{ emailLink }}</a
        >
        or DM us on
        <a
          class="underline hover:text-gray-800 dark:text-gray-400"
          :href="twitterLink"
          target="_blank"
          rel="noopener noreferrer"
          >Twitter</a
        >.
      </p>
      <div
        v-if="token"
        class="mb-4 flex w-full flex-col items-start justify-start gap-4 md:flex-row"
      >
        <Button
          classes="w-full md:w-auto hover:bg-emerald-600"
          text="Continue to app"
          to="/"
        />
        <Button
          classes="
            w-full
            md:w-auto
            bg-transparent text-gray-800 dark:text-gray-500
            dark:border-gray-800
            hover:bg-gray-50 hover:text-gray-800
            shadow-none
            focus:outline-none
            focus-visible:ring-2
            focus-visible:ring-offset-2
            focus-visible:ring-gray-800
          "
          text="Resend email"
          type="button"
          @click="resend"
        />
      </div>
      <form
        v-else
        class="mb-4 flex w-full flex-col items-center justify-start gap-4 md:flex-row"
        @submit.prevent="onFormSubmit"
      >
        <Input
          v-model="email"
          placeholder="Enter your email"
          container-class="w-full md:w-auto"
          classes="w-full md:w-auto"
          margin="mb-0"
          name="email"
          id="email"
          full
        />
        <Button
          classes="
            w-full
            md:w-auto
            bg-transparent text-gray-800 dark:text-gray-500
            dark:border-gray-800
            hover:bg-gray-50 hover:text-gray-800
            shadow-none
            focus:outline-none
            focus-visible:ring-2
            focus-visible:ring-offset-2
            focus-visible:ring-gray-800
          "
          text="Resend confirmation email"
          type="submit"
        />
      </form>
      <small
        v-show="linkSent"
        class="block text-xs text-emerald-500 dark:text-gray-400"
      >
        A link has been sent to {{ email }}, if the account exists with this address, you'll receive
        an email with a verify link.
      </small>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { useStore } from "vuex";
  import { useRouter, useRoute } from "vue-router";
  import { createToast } from "mosha-vue-toastify";

  import { logEvent, logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import Input from "@/components/Input.vue";

  export default {
    components: {
      Button,
      Input,
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      logPageView(route.name);

      const emailLink = import.meta.env.VITE_EMAIL_LINK;
      const twitterLink = import.meta.env.VITE_TWITTER_LINK;

      let email = ref("");
      let token = ref(router.currentRoute.value.query?.token);
      let linkExpired = ref(false);
      let linkSent = ref(false);

      if (token.value) {
        console.info("token: ", token.value);
        logEvent("Successfully verified");
        store.dispatch("me", token.value).then((me) => {
          console.info({ me });
        });
      }

      const genericToast = () => {
        linkSent.value = true;
        createToast("Link sent to this email if it exists 👋", {
          position: "bottom-right",
        });
      };

      const onFormSubmit = () => {
        if (!email.value) {
          return;
        }

        logEvent("Submitted verification form");

        console.info("email: ", email.value);
        store
          .dispatch("getMagicLink", email.value)
          .then((resp) => {
            console.info({ resp });
            genericToast();
          })
          .catch(() => {
            genericToast();
          });
      };

      return {
        email,
        emailLink,
        twitterLink,
        linkExpired,
        linkSent,
        token,
        onFormSubmit,
      };
    },
  };
</script>
