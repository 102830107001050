<template>
  <div
    class="flex w-full flex-col gap-2 rounded-xl border border-gray-100 p-4 text-base text-gray-900 dark:border-gray-800 dark:text-gray-400"
  >
    <strong
      v-if="title"
      class="mb-2 text-xs font-medium text-gray-600 dark:text-gray-500"
    >
      {{ title }}
    </strong>
    <strong
      v-if="body"
      :class="['text-xl', color ? `text-${color}-500` : null]"
      >{{ body }}</strong
    >
    <small
      v-if="helper"
      class="text-xs text-gray-500 dark:text-gray-500"
      >{{ helper }}</small
    >
  </div>
</template>

<script setup>
  const props = defineProps({
    color: String,
    title: String | Number,
    helper: String | Number,
    body: String | Number,
  });

  const { color, title, helper, body } = props;
</script>
