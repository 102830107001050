<template>
  <TransitionRoot
    appear
    :show="showModal"
    as="template"
  >
    <Dialog as="div">
      <div class="fixed inset-0 z-30 overflow-y-auto bg-black/20 backdrop-blur-sm">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
            >
              <DialogTitle
                as="h3"
                class="mb-4 text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
              >
                {{
                  type === "edit"
                    ? `Your eligibility has been updated`
                    : type === "delete"
                    ? `Are you sure you want to delete this report?`
                    : type === "no_report"
                    ? `Your report has not yet been generated`
                    : `Your eligibility report has been submitted`
                }}
              </DialogTitle>
              <div class="mb-6">
                <p
                  v-if="type === 'delete'"
                  class="mb-4 text-sm text-gray-500"
                >
                  Your report and any answers you have filled out will be removed from your team's
                  account.
                </p>
                <p
                  v-if="type === 'edit'"
                  class="mb-4 text-sm text-gray-500"
                >
                  Your edits have been saved successfully. A downloadable report will be added to
                  your eligibility questionnaire once our team has reviewed your responses.
                </p>
                <p
                  v-if="type === 'no_report'"
                  class="mb-4 text-sm text-gray-500"
                >
                  When your report has been generated an email will be sent and a download link will
                  be available here.
                </p>
                <p
                  v-if="type !== 'delete' && type !== 'edit' && type !== 'no_report'"
                  class="mb-4 text-sm text-gray-500"
                >
                  Nice one! Your report has been submitted, and a downloadable report will be
                  emailed to you and added here once your eligibility questionnaire once our team
                  has reviewed your responses.
                </p>
              </div>

              <div class="flex flex-row gap-4">
                <Button
                  v-if="type === 'delete'"
                  classes="border-red-500 bg-transparent text-red-500 shadow-none hover:bg-red-700 hover:text-white"
                  text="Yes, remove report"
                  type="button"
                  @click="onDeleteModalClick"
                />
                <Button
                  v-if="type === 'edit' || type === 'no_report'"
                  text="OK, got it"
                  type="button"
                  @click="onCloseModalClick"
                />
                <Button
                  v-if="type === 'delete'"
                  classes="border-emerald-500 bg-transparent text-emerald-500 shadow-none hover:text-white hover:bg-emerald-500"
                  text="Cancel"
                  type="button"
                  @click="onCloseDeleteModalClick"
                />
                <Button
                  v-else
                  classes="border-emerald-500 bg-transparent text-emerald-500 shadow-none hover:text-white hover:bg-emerald-500"
                  text="View my reports"
                  type="button"
                  @click="onViewEligibilityList"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { toRefs } from "vue";
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";
  import { useRouter } from "vue-router";

  import { useEligibilityStore } from "@/store/eligibilityStore";

  import Button from "@/components/Button.vue";

  const eligibilityStore = useEligibilityStore();
  const router = useRouter();

  const props = defineProps({
    onDeleteReportConfirm: Function,
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    type: "edit || delete || no_report || submit",
    id: String,
  });

  const { showModal, type } = toRefs(props);

  const onCloseModalClick = () => {
    eligibilityStore.closeModal();
  };

  const onViewEligibilityList = () => {
    eligibilityStore.toggleEligibilityModal();
    router.push("/eligibility");
  };

  // Send to parent function onDeleteReportConfirm, then toggle delete modal, then redirect to eligibility list
  const onDeleteModalClick = () => {
    props.onDeleteReportConfirm(props.id);
    eligibilityStore.toggleDeleteModal();
    router.push("/eligibility");
  };

  const onCloseDeleteModalClick = () => {
    eligibilityStore.toggleDeleteModal();
  };
</script>
