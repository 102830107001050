<script setup>
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";
  import { ref, toRaw, watch } from "vue";
  import { v4 as uuidv4 } from "uuid";

  import { useDemoStore } from "@/store/demoStore";

  import Button from "@/components/Button.vue";
  import CloseModalButton from "../CloseModalButton.vue";
  import Input from "@/components/Input.vue";

  const demoStore = useDemoStore();

  let clients = toRaw(demoStore.globalClients);

  let isModalOpen = ref(false);
  let searchClient = ref("");
  let newClient = ref({
    id: null,
    name: null,
  });
  let selectedClient = ref(null);

  const onModalToggle = () => {
    demoStore.toggleAddNewClientModal();
  };

  const onSubmitNewClient = () => {
    console.info({ newClient: newClient.value, selectedClient: selectedClient.value });

    // If no client is selected and no new client is entered, return
    if (!selectedClient.value && !newClient.value.name) {
      return;
    }

    // otherwise, set the client object to either the selected client or the new client
    // if no new client id, then assign one using uuidv4()
    const client = selectedClient.value || {
      ...newClient.value,
      id: newClient.value.id || uuidv4(),
    };

    console.info({ client });
    demoStore.addClient(client);
    onModalToggle();
  };

  const onSelectCompany = (company) => {
    console.info({ company });
    selectedClient.value = company;
  };

  // Watch for demoStore.showAddClientModal
  watch(
    () => demoStore.showAddClientModal,
    (val) => {
      isModalOpen.value = val;
    },
  );
</script>

<template>
  <TransitionRoot
    appear
    :show="isModalOpen"
    as="template"
  >
    <Dialog
      as="div"
      @close="onModalToggle"
    >
      <div class="fixed inset-0 z-10 overflow-y-auto bg-black/20 backdrop-blur-sm">
        <div class="flex min-h-screen items-center justify-center px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
            >
              <div class="div flex flex-row items-center justify-between">
                <DialogTitle
                  as="h3"
                  class="mb-4 text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
                >
                  Add a new client
                </DialogTitle>
                <CloseModalButton @click="onModalToggle" />
              </div>

              <div class="flex flex-col justify-items-stretch gap-4">
                <v-select
                  v-model="searchClient"
                  :options="clients"
                  label="name"
                  placeholder="Search for a company"
                  class="sq-v-s w-full py-0"
                  :clearable="true"
                  @option:selected="onSelectCompany"
                />
                <!-- Or -->
                <div class="flex w-full items-center justify-center">
                  <hr class="w-full border-gray-100 dark:border-gray-700" />
                  <span class="mx-4 text-sm font-semibold uppercase text-gray-500">Or</span>
                  <hr class="w-full border-gray-100 dark:border-gray-700" />
                </div>

                <!-- Add new client -->
                <div
                  v-show="!selectedClient"
                  class="flex w-full flex-col gap-4"
                >
                  <strong class="text-xl font-semibold text-gray-900 dark:text-gray-300">
                    Add a new client
                  </strong>
                  <form
                    class="flex flex-col gap-4"
                    @submit.prevent="onSubmitNewClient"
                  >
                    <Input
                      type="text"
                      name="client-name"
                      autocomplete="none"
                      id="client-name"
                      v-model="newClient.name"
                      label="Client name"
                      placeholder="Enter client's name"
                      full
                    />
                  </form>
                </div>
                <!-- Add other input fields for additional client properties as needed -->
                <Button
                  text="Create client"
                  type="button"
                  @click="onSubmitNewClient"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
