<template>
  <div class="flex h-auto flex-col items-center justify-center md:min-h-screen">
    <VerifyEmailModal
      :verifyEmailOpen="authStore.verifyEmailOpenModal"
      :email="signUpForm.email"
    />
    <MagicLinkSent
      :magicLinkSentOpen="authStore.magicLinkSetModal"
      :email="signInForm.email"
    />
    <div
      class="grid h-auto w-full max-w-[96rem] grid-rows-2 gap-4 overflow-hidden shadow-2xl md:h-full md:max-h-[96rem] md:grid-cols-2 md:grid-rows-none md:gap-0"
    >
      <!-- Left -->
      <div class="h-auto min-h-screen p-4 lg:h-full lg:px-24 lg:py-24 xl:px-48">
        <div v-if="$route.name === 'Login'">
          <small class="mb-12 inline-block w-full text-sm text-gray-400 md:mb-16">
            Need an account?
            <router-link
              class="font-semibold text-emerald-500 underline"
              to="/signup"
              >Sign up instead</router-link
            >
          </small>
          <h1 class="mb-8 text-3xl font-semibold dark:text-gray-300 md:text-4xl">Log in</h1>
          <div class="flex w-full flex-col gap-4">
            <Button
              social-icon="google"
              text="Continue with Google"
              @click="authStore.oAuth"
              classes="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
              type="button"
              chunky
              full
            />
            <button
              @click="toggleMagicLink"
              class="inline-block w-full cursor-pointer text-center text-base font-medium text-gray-400 hover:text-emerald-500 hover:underline"
            >
              <span v-if="isMagicLinkSet">Log in with email &amp; password</span>
              <span v-if="!isMagicLinkSet">Log in with magic link</span>
            </button>
          </div>
          <!-- Or -->
          <Divider />
          <!-- Other auth -->
          <form
            class="flex flex-col justify-stretch"
            @submit.prevent="onSignInSubmit"
          >
            <Input
              placeholder="Enter your work email"
              v-model="signInForm.email"
              autocomplete="email"
              :hideLabel="true"
              name="email"
              type="email"
              id="email"
              chunky
              full
            />
            <Input
              v-if="!isMagicLinkSet"
              placeholder="Enter your password"
              v-model="signInForm.password"
              :hideLabel="true"
              autocomplete="current-password"
              name="password"
              type="password"
              id="current-password"
              chunky
              full
            />
            <Button
              classes="bg-indigo-500 text-white hover:bg-indigo-700 dark:border-indigo-800"
              color="indigo"
              text="Continue to Sequestr"
              type="submit"
              :disabled="isSignInLoading"
              :loading="isSignInLoading"
              chunky
            />
          </form>
          <small
            class="mb-12 inline-block w-full text-sm text-red-400 md:mb-24"
            v-if="isSignInError"
          >
            Error: {{ signInError }}
          </small>
          <Divider />
          <!-- Forgot password -->
          <small class="mb-12 inline-block w-full text-sm text-gray-400 md:mb-24">
            Forgot your password?
            <router-link
              class="font-semibold text-gray-700 underline dark:text-gray-500"
              to="/reset-password"
              >Reset it here</router-link
            >
          </small>
        </div>
        <div v-else>
          <small class="mb-12 inline-block w-full text-sm text-gray-400 md:mb-16">
            Have an account?
            <router-link
              class="font-semibold text-emerald-500 underline"
              to="/login"
              >Log in instead</router-link
            >
          </small>
          <h1 class="mb-8 text-3xl font-semibold dark:text-gray-300 md:text-4xl">Sign up</h1>
          <Button
            social-icon="google"
            text="Continue with Google"
            @click="authStore.oAuth"
            classes="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
            type="button"
            chunky
            full
          />
          <!-- Or -->
          <Divider />
          <!-- Email sign up -->
          <form
            class="flex flex-col justify-stretch"
            @submit.prevent="onSignUpSubmit"
          >
            <Input
              placeholder="Enter your email"
              v-model="signUpForm.email"
              @keyup="testPersonalEmail"
              :hideLabel="true"
              autocomplete="email"
              type="email"
              name="email"
              id="email"
              chunky
              full
            />
            <Input
              placeholder="Enter a secure password"
              v-model="signUpForm.password"
              :hideLabel="true"
              :required="false"
              :min="8"
              autocomplete="new-password"
              helper="Minimum 8 characters, with at least 1 number, 1 uppercase letter, and 1 special character"
              name="password"
              type="password"
              id="new-password"
              chunky
              full
            />
            <v-select
              placeholder="Select your user type"
              class="sq-v-s mb-4"
              v-model="signUpForm.role"
              :options="userRoles"
            />
            <Button
              text="Continue with email"
              type="submit"
              :disabled="formEmpty || isSignUpLoading || isPersonalEmail"
              :loading="isSignUpLoading"
              chunky
            />
          </form>
          <!-- Errors -->
          <small
            v-if="isPersonalEmail"
            class="mt-8 inline-block w-full text-sm text-amber-500"
          >
            Please use a work or non-personal email to continue signing up for Sequestr.
          </small>
          <small
            v-if="isSignUpError"
            class="mt-8 inline-block w-full text-sm text-red-400"
          >
            Error: {{ signUpError }}
          </small>
        </div>
      </div>
      <!-- Right -->
      <div
        :class="`h-auto overflow-hidden p-4 md:h-full md:px-0 md:py-0 ${
          $route.name === 'Login' ? 'bg-indigo-800' : 'bg-emerald-500'
        }`"
      >
        <div
          class="relative flex h-full flex-col items-stretch justify-center text-white"
          v-if="$route.name === 'Login'"
        >
          <h2 class="relative z-[1] mb-4 px-0 py-4 text-4xl text-emerald-100 md:p-12">
            Let's continue working towards net-zero
          </h2>
          <img
            class="absolute bottom-0 left-0 right-0 top-0 z-0 h-full w-full object-cover"
            src="/img/backgrounds/geo-9.png"
          />
        </div>
        <div
          class="relative flex h-full flex-col items-stretch justify-center text-white"
          v-else
        >
          <div class="px-0 py-4 md:p-12">
            <h2 class="mb-4 text-4xl text-emerald-100">Achieve net-zero</h2>
            <p class="text-sm">
              Co-develop carbon removal projects and build robust, high-quality carbon offset
              portfolios.
            </p>
          </div>
          <img
            class="relative w-full md:absolute md:bottom-[-10%] md:right-[-10%]"
            src="/img/app-projects.png"
            alt="Projects"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, watch, watchEffect } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import { useMutation } from "vue-query";
  import { emailProviders } from "@/utils/emailsBlocker";

  import { useAuthStore } from "@/store/authStore";

  import { logEvent, logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import Divider from "@/components/Divider.vue";
  import Input from "@/components/Input.vue";
  import MagicLinkSent from "@/components/Modals/MagicLinkSent.vue";
  import VerifyEmailModal from "@/components/Modals/VerifyEmail.vue";

  const authStore = useAuthStore();
  const router = useRouter();
  const route = useRoute();

  let isPersonalEmail = ref(false);
  const emailMasked = ref("");

  const formEmpty = ref(false);
  const signUpForm = ref({
    email: "",
    password: "",
    role: "",
  });

  const signInForm = ref({
    email: "",
    password: "",
  });
  const isMagicLinkSet = ref(false);

  const toggleMagicLink = () => {
    isMagicLinkSet.value = !isMagicLinkSet.value;
  };

  const userRoles = ref([
    { label: "I'm looking to buy offsets", code: "common" },
    { label: "I'm a project developer", code: "project" },
  ]);

  onMounted(() => {
    logPageView(route.name);
  });

  watchEffect(route, (route) => {
    logPageView(route.name);
  });

  watch(
    signUpForm,
    (newValues) => {
      const { email, password, role } = newValues;
      console.info({
        email,
        password,
        role,
      });
      if (!email || !password || !role || (!email && !password && !role)) {
        formEmpty.value = true;
        return;
      }
      formEmpty.value = false;
    },
    { deep: true },
  );

  const {
    isLoading: isSignUpLoading,
    isError: isSignUpError,
    error: signUpError,
    mutate: signUpMutate,
  } = useMutation(authStore.signUp, {
    onSuccess: () => {
      authStore.toggleVerifyEmailModal();
    },
  });

  const {
    isLoading: isSignInLoading,
    isError: isSignInError,
    error: signInError,
    mutate: signInMutate,
  } = useMutation(
    ({ body, isMagicLinkSet }) => {
      console.info({
        body,
        isMagicLinkSet,
      });
      return authStore.signIn(body, isMagicLinkSet);
    },
    {
      onSuccess: () => {
        if (isMagicLinkSet.value) {
          authStore.toggleMagicLinkSentModal();
        } else {
          router.push("/");
        }
        logEvent("Login");
      },
    },
  );

  const onSignUpSubmit = () => {
    emailMasked.value = signUpForm.value.email;
    signUpMutate(signUpForm.value);
  };

  const onSignInSubmit = () => {
    // in case of magic link
    if (isMagicLinkSet.value) {
      /**
       * For some reason Vue-Query only allows one param, so have to nest inside object here
       */
      signInMutate({
        body: { email: signInForm.value.email },
        isMagicLinkSet: isMagicLinkSet.value,
      });
      return;
    }

    signInMutate({
      body: {
        email: signInForm.value.email,
        password: signInForm.value.password,
      },
    });
  };

  const testPersonalEmail = (event) => {
    const email = event.target.value;
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    const isEmail = regexExp.test(email);
    // const name = email.substring(0, email.lastIndexOf("@"));
    const domain = email.substring(email.lastIndexOf("@") + 1);
    if (isEmail && domain) {
      const blocked = emailProviders.findIndex((element) => element.includes(domain));
      if (blocked === -1) {
        isPersonalEmail.value = false;
        return;
      }
      isPersonalEmail.value = true;
      return;
    }
  };
</script>
