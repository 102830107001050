<script setup>
  import { computed } from "vue";
  import moment from "moment";
  import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";

  import Button from "@/components/Button.vue";

  import { tryParseJSONObject } from "@/utils";
  import { useEligibilityStore } from "@/store/eligibilityStore";

  const eligibilityStore = useEligibilityStore();

  const props = defineProps({
    key: {
      type: String,
      required: false,
      default: Math.random().toString(),
    },
    eligibility: {
      id: {
        type: String,
        required: false,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: null,
      },
      responses: {
        type: String | Object,
        required: false,
        default: null,
      },
      reportUrl: {
        type: String,
        required: false,
        default: null,
      },
      reportSent: {
        type: Boolean,
        required: false,
        default: null,
      },
      createdAt: {
        type: String,
        required: false,
        default: null,
      },
      draft: {
        type: Boolean,
        default: false,
      },
    },
  });

  const { key, eligibility } = props;

  // Get project type by computed() function using props.eligibility
  const getProjectType = computed(() => {
    if (!eligibility) return;
    if (eligibility.draft) return "No project type";
    const responses = eligibility.responses;
    const { projectType } = responses;
    const label = projectType?.label || `No project type`;
    return label;
  });
</script>

<template>
  <div
    :key="key"
    class="flex h-auto w-full flex-col items-stretch justify-start gap-4 border-b border-gray-100 py-4 align-middle text-gray-900 last-of-type:border-b-0 dark:text-gray-300 md:flex-row md:items-center md:justify-between"
  >
    <div class="flex flex-shrink flex-grow-0 flex-col gap-0 overflow-hidden">
      <strong
        class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-lg font-semibold"
        >{{ eligibility.title || `No title` }}</strong
      >
      <div class="flex flex-row flex-wrap gap-2 text-sm text-gray-500">
        <small class="normal-case text-indigo-500">
          {{ getProjectType }}
        </small>
        <span>•</span>
        <small>
          {{ eligibility.draft ? `Draft saved` : `Created` }}:
          {{
            eligibility.draft
              ? moment(eligibility.createdAt).format("LLLL")
              : moment(eligibility.createdAt).format("MMMM DD YYYY")
          }}
        </small>
      </div>
    </div>
    <div
      class="flex flex-shrink-0 flex-grow flex-row items-center justify-between gap-4 md:items-center md:justify-end"
    >
      <a
        v-if="eligibility.reportUrl && eligibility.reportSent"
        :href="eligibility.reportUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="rounded-lg border-transparent border-emerald-100 bg-emerald-100 p-2 text-emerald-500 shadow-none hover:bg-emerald-200 hover:text-emerald-800 dark:bg-emerald-800 dark:hover:text-white"
      >
        <ArrowDownTrayIcon class="h-6 w-6" />
      </a>
      <button
        v-else
        @click="eligibilityStore.toggleEligibilityModal()"
        class="rounded-lg bg-gray-50 p-2 text-center text-sm font-medium text-gray-400 hover:text-gray-700 dark:bg-gray-800 dark:text-gray-500 hover:dark:text-gray-300 md:text-right"
      >
        <ArrowDownTrayIcon class="h-6 w-6" />
      </button>
      <Button
        v-if="eligibility.draft"
        :to="`/eligibility/create?e=${eligibility.id}&draft=true`"
        classes="border-gray-200 bg-transparent text-gray-800 text-emerald-500 hover:text-white dark:border-gray-800 dark:text-gray-300 hover:bg-emerald-500"
        width="w-32"
        text="Edit"
        iconRight="ChevronRightIcon"
      />
      <Button
        v-else
        :to="`/eligibility/${eligibility.id}`"
        classes="border-gray-200 bg-transparent text-gray-800 text-emerald-500 hover:text-white dark:border-gray-800 dark:text-gray-300 hover:bg-emerald-500"
        width="w-32"
        :text="`${eligibility.reportSent ? 'View' : `Manage`}`"
        iconRight="ChevronRightIcon"
      />
    </div>
  </div>
</template>
