import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { VueQueryPlugin } from "vue-query";
import { createPinia } from "pinia";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueSnip from "vue-snip";
import vSelect from "vue-select";

import App from "./App.vue";
import router from "./router";

import VueCookies from "vue3-cookies";
// import pinia from "@/pinia";

import "./tailwind.css";
import "vue-select/dist/vue-select.css";

const app = createApp(App);
const head = createHead();
const pinia = createPinia();

const mapsKey = `${import.meta.env.VITE_GOOGLE_MAPS_KEY}`;

app.component("v-select", vSelect);

app.use(head);
app.use(VueGoogleMaps, {
  load: {
    key: mapsKey,
    libraries: "places",
  },
});
app.use(VueSnip);
app.use(pinia);
app.use(router);
app.use(VueCookies);
app.use(VueQueryPlugin);

app.mount("#app");
