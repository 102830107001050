<template>
  <div class="relative w-full">
    <!-- Auth blur modal -->
    <PermissionedAccessVue :permissionedModalOpen="unauthModalOpen" />
    <PermissionedAccessVue
      :authenticated="authStore.user && authStore.user.id ? true : false"
      :permissionedModalOpen="permissionedAuthModalOpen"
      title="Sequestr is currently reviewing your account."
      body="When you are approved you'll receive an email welcoming you to Sequestr."
    />

    <div
      v-if="isLoading || (isLoading && !projectsStore.project)"
      class="mx-auto max-w-7xl py-6 px-4 text-white"
    >
      <Loader />
    </div>

    <div
      class="mx-auto max-w-7xl py-6 px-4"
      v-if="noProjectFound"
    >
      <span
        class="block w-full py-20 text-center text-lg font-semibold text-white dark:text-gray-500"
        >No project found</span
      >
    </div>

    <div
      v-if="!noProjectFound"
      class="relative flex w-full flex-col gap-0"
    >
      <TransitionRoot
        appear
        :show="purchaseScreenOpen"
        as="template"
      >
        <Dialog
          as="div"
          @close="closeModal"
        >
          <div class="fixed inset-0 z-40 overflow-y-auto bg-black/20 backdrop-blur-sm">
            <div class="flex h-full items-center justify-center p-4">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="flex h-full w-full max-w-3xl transform items-start justify-start overflow-hidden overflow-y-auto rounded-2xl bg-white text-left align-middle shadow-xl transition-all dark:bg-gray-900 md:h-auto md:items-center md:justify-center"
                >
                  <!-- Close modal -->
                  <CloseModalButton @click="closeModal" />
                  <!-- Purchase confirmed sub modal -->
                  <div
                    v-if="isPurchaseConfirmed"
                    class="w-full dark:bg-gray-700"
                  >
                    <div class="flex w-full flex-col">
                      <div class="flex w-full flex-col gap-4 bg-emerald-600 p-8 text-white">
                        <CheckCircleIcon class="h-8 w-8" />
                        <h1 class="text-2xl font-semibold">Thank you for your purchase</h1>
                        <p>
                          In order to finalize your transaction you must complete the legal
                          contract, defined as a Voluntary Emission Reduction Purchase Agreement
                          (VERPA), which we will be sending to your inbox shortly.
                        </p>
                      </div>
                      <div
                        v-if="isPurchasingAuctionError"
                        class="font-lg w-full p-8 text-center text-amber-600"
                      >
                        <span
                          class="block pb-5 text-sm text-pink-500"
                          v-if="isPurchaseAuctionError"
                        >
                          {{ purchaseAuctionError }}
                        </span>
                      </div>
                      <div class="w-full p-8">
                        <small class="text-xs font-semibold uppercase tracking-wider text-gray-500">
                          Order details
                        </small>
                        <ProjectListItem
                          title="Amount in credits (tonnes)"
                          :detail="`${
                            volumeAmountInput.toLocaleString('en', {
                              maximumFractionDigits: 2,
                            }) || 0
                          } tonnes`"
                        />
                        <ProjectListItem
                          title="Amount in dollars (USD)"
                          :detail="`$${
                            purchaseConfirmedData?.grossTotalUSD.toLocaleString('en', {
                              maximumFractionDigits: 2,
                            }) || 0
                          } `"
                        />
                        <ProjectListItem
                          title="Sequestr fees"
                          :detail="`$${fees.toLocaleString('en', {
                            maximumFractionDigits: 2,
                          })}`"
                        />
                        <ProjectListItem
                          title="Auction ends in"
                          :detail="`
                            ${moment(projectsStore.project.auction.endDate, 'YYYY-MM-DD').diff(
                              moment(projectsStore.project.auction.createdAt, 'YYYY-MM-DD'),
                              'days',
                            )}
                          days `"
                        />
                        <ProjectListItem
                          title="Total cost ($USD)"
                          :detail="`$${dollarAmountInput.toLocaleString('en', {
                            maximumFractionDigits: 2,
                          })}`"
                        />
                      </div>
                      <div
                        class="flex w-full flex-col items-center justify-start gap-4 px-8 pt-0 pb-8 md:flex-row md:gap-8"
                      >
                        <Button
                          text="Back to project"
                          type="button"
                          @click="forceReload"
                          full
                        />
                        <Button
                          text="View bid history"
                          type="button"
                          classes="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
                          @click="pushToSettings"
                          full
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Place bid sub modal -->
                  <div
                    v-if="!isPurchaseConfirmed"
                    class="flex h-full flex-col gap-0 overflow-auto md:h-auto md:max-h-[40rem] md:flex-row md:gap-4 md:overflow-hidden"
                  >
                    <!-- Left -->
                    <div class="flex w-full flex-col p-8">
                      <DialogTitle class="mb-3 text-2xl font-semibold dark:text-gray-100">
                        Purchase units
                      </DialogTitle>
                      <p class="text-md mb-4 block dark:text-gray-500">
                        How many units you you like to bid on?
                      </p>
                      <div class="flex flex-row items-center justify-start gap-2">
                        <Input
                          autocomplete="off"
                          :modelValue="unitAmountInput"
                          @update:modelValue="onUnitAmountInputUpdate"
                          :hideLabel="true"
                          class="mb-0 flex-1"
                          placeholder="# of units"
                          type="number"
                          name="amountTons"
                          :min="0"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          :step="1"
                          full
                        />
                        <XMarkIcon
                          v-if="minIntegerAndProject"
                          size="24"
                          class="h-4 w-4 dark:text-gray-400"
                        />
                        <!-- Multiple button -->
                        <small
                          v-if="minIntegerAndProject"
                          class="flex h-full w-auto flex-shrink items-center rounded-lg border border-gray-200 py-1 px-4 align-middle font-semibold hover:cursor-not-allowed dark:border-gray-700 dark:text-gray-500"
                        >
                          {{ minIntegerAndProject.toLocaleString("en-US") }} t
                        </small>
                      </div>
                      <div class="relative w-full flex-1">
                        <div class="flex w-full flex-col gap-4 py-4 text-gray-500">
                          <p class="w-full text-sm font-normal">
                            This auctions credits are sold in units of size
                            {{ minIntegerAndProject.toLocaleString("en-US") }}.
                          </p>
                          <div
                            class="relative flex w-full flex-col items-start justify-start gap-2"
                          >
                            <Checkbox
                              id="purchaseConfirm"
                              name="purchaseConfirm"
                              textClass="text-sm font-normal"
                              checkboxClass="mt-1"
                              :text="`By submitting your bid you agree to purchasing an amount up to the ${volumeAmountInput?.toLocaleString()} selected on time of delivery, depending on how much inventory currently remains in the auction.`"
                              @change="onPurchaseToggleChecked"
                            />
                            <Checkbox
                              id="emailConfirm"
                              name="emailConfirm"
                              textClass="text-sm font-normal"
                              checkboxClass="mt-1"
                              :text="`By submitting your bid you agree to pay the full amount invoiced to your email ${authStore?.user.email} for the inventory allocated from this auction on time of delivery.`"
                              @change="onEmailConfirmChecked"
                            />
                            <button
                              class="my-2 flex w-full cursor-pointer flex-row items-center justify-start text-center text-sm font-normal text-gray-500 hover:text-emerald-500 dark:text-gray-300 md:w-auto"
                              @click="openTerms"
                              type="button"
                            >
                              <Checkbox
                                readonly
                                :checked="readTerms"
                              />
                              View terms &amp; condition
                            </button>
                          </div>
                        </div>
                      </div>
                      <p
                        v-if="showVolumeExceedsError"
                        class="mb-6 text-sm font-normal text-amber-600"
                      >
                        Your volume purchase amount exceeds total amount of inventory up for
                        auction.
                      </p>
                      <Button
                        :disabled="
                          !readTerms ||
                          !volumeAmountInput ||
                          volumeAmountInput < projectsStore.project.auction.minPurchaseAmount ||
                          disableButton
                        "
                        text="Submit bid"
                        type="button"
                        @click="purchaseAuctionMutate"
                        full
                      />
                    </div>
                    <!-- Right -->
                    <div class="w-full bg-emerald-50 p-8 dark:bg-gray-800 md:overflow-y-auto">
                      <strong class="mb-3 block text-2xl font-semibold dark:text-gray-100">
                        Bid summary
                      </strong>
                      <p class="text-md mb-4 block dark:text-gray-500">
                        Review vintage distribution and bid summary.
                      </p>
                      <div class="flex flex-col">
                        <table class="mb-2 w-full table-auto">
                          <thead class="text-left text-xs text-gray-500 dark:text-gray-400">
                            <tr>
                              <th class="py-2 font-medium">Year</th>
                              <th class="py-2 font-medium">Distribution</th>
                              <th class="py-2 text-right font-medium">Purchasing (t)</th>
                              <th class="py-2 text-right font-medium">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(inventory, index) in projectsStore.project.auction
                                .inventoryPricingSchedule"
                              :key="inventory.year"
                              class="text-sm font-normal dark:text-gray-500"
                            >
                              <td class="border-b border-gray-200 py-2 dark:border-gray-600">
                                {{ inventory.year }}
                              </td>
                              <td class="border-b border-gray-200 py-2 dark:border-gray-600">
                                {{ calcInventoryDistribution(index) }}%
                              </td>
                              <td
                                class="border-b border-gray-200 py-2 text-right dark:border-gray-600"
                              >
                                {{ calcInventoryDistribution(index, "purchasing") || `___` }} t
                              </td>
                              <td
                                class="border-b border-gray-200 py-2 text-right dark:border-gray-600"
                              >
                                ${{
                                  currentSchedule[index].toLocaleString("en", {
                                    maximumFractionDigits: 2,
                                  })
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="flex w-full flex-col gap-0 dark:text-gray-500">
                          <div
                            class="flex w-full flex-row items-center justify-between py-1 text-sm"
                          >
                            <strong class="font-normal">Total tonnage</strong>
                            <p>
                              {{
                                volumeAmountInput?.toLocaleString("en", {
                                  maximumFractionDigits: 2,
                                }) || `___`
                              }}
                              tonnes
                            </p>
                          </div>
                          <div
                            class="flex w-full flex-row items-center justify-between py-1 text-sm"
                          >
                            <strong class="font-normal">Average weighted price</strong>
                            <p>
                              ${{
                                weightedPrice.toLocaleString("en", {
                                  maximumFractionDigits: 2,
                                }) || `___`
                              }}
                              USD
                            </p>
                          </div>
                          <div
                            class="flex w-full flex-row items-center justify-between py-1 text-sm"
                          >
                            <strong class="font-normal">Sequestr fees</strong>
                            <p>
                              ${{
                                fees.toLocaleString("en", {
                                  maximumFractionDigits: 2,
                                })
                              }}
                            </p>
                          </div>
                          <div
                            class="mt-2 flex w-full flex-row items-center justify-between border-t border-gray-200 py-2 text-base dark:border-gray-600 dark:text-gray-300"
                          >
                            <strong class="font-semibold">Total cost</strong>
                            <p>
                              ${{
                                dollarAmountInput?.toLocaleString("en", {
                                  maximumFractionDigits: 2,
                                }) || `___`
                              }}
                              USD
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <div
        v-if="!isLoading && !unauthModalOpen && !permissionedAuthModalOpen"
        class="relative"
      >
        <!-- Project status -->
        <div
          v-show="projectClosed"
          class="sticky left-0 right-0 top-[80px] z-30 w-full bg-gray-50 text-center"
        >
          <div class="mx-auto w-full max-w-md">
            <p class="mx-auto my-0 p-3 text-gray-500">This project is closed!</p>
          </div>
        </div>
        <!-- Images -->
        <div
          v-if="projectsStore.project.images?.length"
          class="flex h-96 w-full flex-row gap-4 overflow-x-auto border-b border-gray-100 p-4 dark:border-gray-900 md:h-[50vh] md:max-h-[56rem]"
        >
          <img
            class="h-auto w-full max-w-4xl rounded object-cover md:h-full md:w-auto"
            v-for="image in projectsStore.project.images"
            :src="image"
          />
        </div>
        <div
          class="relative mx-auto grid w-full max-w-7xl grid-cols-1 items-start gap-8 py-6 px-4 md:grid-cols-3"
        >
          <!-- Content -->
          <div
            class="relative col-span-full mb-0 flex w-full flex-col items-start gap-6 text-gray-500 dark:text-gray-500 md:col-span-2 md:mb-12"
          >
            <!-- Sticky project nav on scroll -->
            <div
              :class="[
                'relative top-auto z-auto block w-full justify-start bg-white py-0 dark:bg-black md:sticky md:top-20 md:z-10 md:pt-8',
                scrolled ? 'md:pb-8' : ' md:pb-0',
              ]"
            >
              <div class="relative mb-4 flex w-full flex-col gap-2">
                <h1
                  class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-4xl font-bold leading-tight text-gray-900 dark:text-gray-300"
                >
                  {{ projectsStore.project.name }}
                </h1>
                <div
                  class="flex w-full flex-col items-start justify-start gap-2 align-middle md:flex-row md:items-center md:justify-between"
                >
                  <div class="flex flex-row align-middle">
                    <p v-if="projectsStore.project.location">
                      {{
                        projectsStore.project.location.formatted_address || "No location specified"
                      }}
                    </p>
                    <p v-if="projectsStore.project.createdAt">
                      &nbsp;•&nbsp;Created:
                      {{ moment(projectsStore.project.createdAt).format("LL") }}
                    </p>
                    <p v-if="isMyProject && projectsStore.project.updatedAt">
                      &nbsp;•&nbsp;Updated:
                      {{ moment(projectsStore.project.updatedAt).format("LL") }}
                    </p>
                  </div>
                  <span v-if="projectsStore.project.url">• {{ projectsStore.project.url }}</span>
                  <div class="flex-rpw flex items-center justify-end gap-4">
                    <Tag
                      v-if="projectsStore.getProjectStatus"
                      :type="projectsStore.getProjectStatus.toLowerCase()"
                    >
                      Status: {{ projectsStore.getProjectStatus }}
                    </Tag>
                    <Tag type="informational">{{ projectsStore.project.type }}</Tag>
                  </div>
                </div>
              </div>
              <!-- Creator/project owner -->
              <ProjectCreator :company="projectsStore.project.company" />
            </div>
            <!-- Tabs -->
            <TabGroup
              :key="`tab-${selectedTab}`"
              :defaultIndex="selectedTab"
              @change="(index) => setSelectedTabIndex(index)"
            >
              <TabList
                class="relative top-auto z-10 flex w-full flex-row items-center justify-between gap-4 align-middle md:sticky md:top-72"
              >
                <div
                  class="flex w-auto space-x-4 overflow-x-auto whitespace-nowrap rounded-full bg-gray-100/50 px-4 backdrop-blur-lg dark:bg-gray-600/50"
                >
                  <Tab
                    v-for="(tab, i) in tabs"
                    as="template"
                    :key="`tab-${i}`"
                    v-slot="{ selected }"
                  >
                    <button
                      :class="[
                        'w-full min-w-min py-2 text-base font-semibold leading-5 md:w-auto',
                        'ring-white ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-0',
                        selected
                          ? ' text-gray-900 dark:border-b-gray-300 dark:text-gray-300'
                          : ' text-gray-400 hover:text-gray-900 dark:hover:text-gray-300',
                      ]"
                    >
                      {{ tab }}
                    </button>
                  </Tab>
                </div>
                <Tab
                  v-if="projectsStore.project.auction"
                  class="rounded-full bg-gray-100/50 px-4 backdrop-blur-md dark:bg-gray-600/50"
                  v-slot="{ selected }"
                >
                  <button
                    :class="[
                      'w-full min-w-min py-2 text-base font-semibold leading-5 md:w-auto',
                      'whitespace-nowrap ring-white ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-0',
                      selected
                        ? ' text-gray-900 dark:border-b-gray-300 dark:text-gray-300'
                        : ' text-gray-400 hover:text-gray-900 dark:hover:text-gray-300',
                    ]"
                  >
                    {{ projectClosed ? `Past auction` : `Current auction` }}
                  </button>
                </Tab>
              </TabList>

              <TabPanels class="relative z-0 mt-2 w-full">
                <!-- Project overview -->
                <TabPanel class="flex w-full flex-col gap-4 md:gap-8">
                  <p
                    v-if="projectsStore.project.projectData.story.context"
                    class="w-full"
                  >
                    {{ projectsStore.project.projectData.story.context }}
                  </p>
                  <p
                    v-if="projectsStore.project.projectData.story.lead"
                    class="w-full"
                  >
                    {{ projectsStore.project.projectData.story.lead }}
                  </p>
                  <p
                    v-if="projectsStore.project.projectData.story.activities"
                    v-html="projectsStore.project.projectData.story.activities"
                    class="raw-html w-full"
                  ></p>
                  <!-- Project mechanism ie: Avoidance -->
                  <div class="flex-start mb-4 flex w-auto max-w-full flex-row">
                    <Tag class="capitalize">Mechanism: {{ projectsStore.project.mechanism }}</Tag>
                  </div>

                  <!-- Project highlights -->
                  <div
                    class="flex flex-col gap-4"
                    v-if="projectsStore.project.projectData.story.highlights.length"
                  >
                    <h6 class="text-lg font-semibold">Project highlights</h6>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                      <ProjectHighlightsItemVue
                        v-for="item in projectsStore.project.projectData.story.highlights"
                        :title="item.title"
                        :iconLeft="item.icon"
                      />
                    </div>
                  </div>

                  <!-- Project summary carbon details -->
                  <div class="flex flex-col gap-4">
                    <h6 class="text-lg font-semibold">Project details</h6>
                    <div class="flex flex-col gap-4">
                      <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                        <GridBox
                          v-for="(item, index) in projectsStore.project.projectData.story
                            ?.projectDetails"
                          :title="item.category"
                          :body="item.title"
                          :helper="item.subtitle"
                        />
                      </div>
                    </div>
                    <div
                      v-if="projectsStore.project.projectData.story.verification"
                      class="flex flex-col gap-4"
                    >
                      <GridBox
                        title="Verification"
                        :body="projectsStore.project.projectData.story.verification"
                        helper="Verification method for this project"
                      />
                      <GridBox
                        title="Verification comment"
                        :body="projectsStore.project.projectData.story.verificationComment"
                        :helper="projectsStore.project.projectData.story.verificationContext"
                      />
                    </div>
                  </div>

                  <!-- Carbon details -->
                  <div class="flex flex-col gap-4">
                    <h6 class="text-lg font-semibold">Carbon details</h6>
                    <div class="block w-full">
                      <ProjectListItem
                        v-for="item in projectsStore.project.projectData.story.carbonDetails"
                        :title="item.category"
                        :detail="item.value"
                      />
                    </div>
                  </div>
                </TabPanel>

                <!-- Projections -->
                <TabPanel class="flex w-full flex-col gap-4 md:gap-8">
                  <p class="w-full">
                    Carbon price outlooks, and carbon sequestration potential are used as a
                    guideline and for estimation purposes. Real-world analysis via soil samples,
                    satellite imagery, and other verification methods are done yearly for the first
                    5 years, then every other year between years 5-20.
                  </p>

                  <!-- Outlook -->
                  <div
                    class="flex w-full flex-col gap-4 overflow-hidden"
                    v-if="projectsStore.project.projectData.projectionsData"
                  >
                    <h6 class="text-lg font-semibold">Carbon spot price outlook ($USD)</h6>
                    <Line
                      :data="projectsStore.project.projectData.projectionsData"
                      :options="projectionsChartOptions"
                    />
                  </div>

                  <!-- Sequestration -->
                  <div
                    class="flex flex-col gap-4"
                    v-if="projectsStore.project.projectData.sequestrationSchedule"
                  >
                    <div class="flex flex-col gap-2">
                      <h6 class="text-lg font-semibold">
                        Emissions
                        {{
                          projectsStore.project.mechanism === "avoidance"
                            ? `avoidance`
                            : `reduction` || `Sequestration`
                        }}
                        offsets per year (tonnes)
                      </h6>
                      <p class="w-full">
                        This is total available inventory, and not all is available through Sequestr
                      </p>
                    </div>
                    <div class="block w-full">
                      <ProjectListItem
                        v-for="item in projectsStore.project.projectData.sequestrationSchedule"
                        :title="item.title"
                        :detail="`${item.detail.toLocaleString()} t`"
                      />
                    </div>
                  </div>
                </TabPanel>

                <!-- Timelines & details -->
                <!-- <TabPanel class="flex w-full flex-col gap-4 md:gap-8">
                  <p class="w-full">TODO</p>
                </TabPanel> -->

                <!-- Financials -->
                <!-- <TabPanel class="flex w-full flex-col gap-4 md:gap-8">
                  <p class="w-full">TODO</p>
                </TabPanel> -->

                <!-- SDG -->
                <TabPanel class="flex w-full flex-col gap-4 md:gap-8">
                  <p class="w-full">
                    While we strive for most projects to achieve a handful of SDG goals, the goals
                    are also pending verification from the United Nations.
                  </p>

                  <!-- SDG Summary card -->
                  <div
                    class="flex w-full flex-row items-center justify-start gap-4 rounded-lg border border-gray-200 p-4 font-normal dark:border-gray-700 md:p-8"
                  >
                    <FaceSmileIcon class="h-6 w-6" />
                    <p>
                      This project enables
                      <strong>{{ projectsStore.project.projectData.sdg.length }}</strong> goals
                    </p>
                  </div>

                  <!-- List -->
                  <div class="block w-full">
                    <ProjectListItem
                      v-for="item in projectsStore.project.projectData.sdg"
                      :title="item.title"
                      :helper="item.detail"
                      :iconLeft="item.icon"
                    />
                  </div>
                </TabPanel>

                <!-- Auction -->
                <TabPanel class="flex w-full flex-col gap-4 md:gap-8">
                  <p class="w-full">
                    To learn more about our auction or want to look at our FAQ please visit
                    <a
                      class="underline hover:text-emerald-500"
                      href="https://www.sequestr.io/faq"
                      target="_blank"
                      rel="noopener noreferrer"
                      >our FAQs</a
                    >
                  </p>

                  <!-- Offset details -->
                  <div class="flex flex-col gap-4">
                    <h6 class="text-lg font-semibold">Offset details</h6>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                      <GridBox
                        title="Offsets being pre-sold"
                        :body="projectsStore.project.auction?.startingInventory?.toLocaleString()"
                      />
                      <GridBox
                        title="First issuance"
                        :body="projectsStore.project.auction?.inventoryPricingSchedule[0].year"
                      />
                    </div>
                  </div>

                  <!-- Pricing -->
                  <div class="flex flex-col gap-4">
                    <h6 class="text-lg font-semibold">Pricing ($USD)</h6>
                    <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                      <GridBox
                        color="blue"
                        title="Current price"
                        :body="`$${currentPrice}`"
                      />
                      <GridBox
                        color="blue"
                        title="Next price"
                        :body="`$${nextPrice}`"
                      />
                      <GridBox
                        title="Time to next price"
                        :body="timeToNextPrice"
                      />
                    </div>
                  </div>

                  <!-- Issuance years -->
                  <div
                    class="flex max-h-96 w-full flex-col gap-4 overflow-auto rounded-2xl bg-gray-50 p-4 dark:bg-gray-800"
                  >
                    <div class="flex w-full flex-col">
                      <h6 class="mb-4 font-semibold">Auction inventory breakdown</h6>
                      <div class="flex w-full flex-row items-baseline justify-between align-middle">
                        <small class="w-auto text-base font-medium text-gray-600 dark:text-gray-500"
                          >Vintage year</small
                        >
                        <div class="flex flex-row items-center text-right align-middle">
                          <small class="w-24 text-base font-medium text-gray-600 dark:text-gray-500"
                            >Issuance year</small
                          >
                          <small class="w-24 text-base font-medium text-gray-600 dark:text-gray-500"
                            >Amount</small
                          >
                        </div>
                      </div>
                    </div>
                    <div class="div flex flex-col gap-0">
                      <IssuanceYearListItem
                        v-for="(item, index) in projectsStore.project.auction
                          .inventoryRemainingSchedule"
                        :title="`${item.year}`"
                        :year="
                          projectsStore.project.auction.inventoryRemainingSchedule[index]
                            .issuanceYear
                        "
                        :amount="
                          Math.round(
                            projectsStore.project.auction.startingInventory *
                              weightPercentages[index],
                          )
                        "
                      />
                    </div>
                  </div>

                  <!-- Pricing schedule table -->
                  <div
                    class="flex max-h-96 w-full flex-col gap-4 overflow-auto rounded-2xl bg-gray-50 p-4 dark:bg-gray-800"
                  >
                    <h6 class="text-lg font-semibold">Auction pricing schedule by Vintage Year</h6>
                    <table class="relative table w-full">
                      <thead class="table-header-group">
                        <tr
                          class="sticky left-0 top-0 table-row border-b border-gray-100 bg-gray-50 dark:border-gray-800 dark:bg-gray-800"
                        >
                          <th class="w-60 text-left">Date</th>
                          <th
                            v-for="year in projectsStore.project.pricingSchedule.vintageYears"
                            :key="year"
                            class="p-2 text-right"
                          >
                            {{ year }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in projectsStore.project.pricingSchedule.schedule"
                          :key="item"
                          :class="[
                            'dark:border-gray-800, border-b border-gray-100',
                            moment(item.auctionDate).isBefore(currentTimeDiff) ? 'opacity-25' : '',
                          ]"
                        >
                          <td class="inline-table w-60 text-left text-gray-900 dark:text-gray-500">
                            <span class="flex flex-row items-center justify-start gap-2 py-2">
                              <CheckCircleIcon
                                v-show="moment(item.auctionDate).isBefore(currentTimeDiff)"
                                class="h-5 w-5 text-emerald-500"
                              />
                              <p>
                                {{ moment(item.auctionDate).format("LLL") }}
                              </p>
                            </span>
                          </td>
                          <td
                            v-for="price in item.vintagePrices"
                            :key="price"
                            class="p-2 text-right"
                          >
                            ${{ price }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- Offsets timeline -->
                  <div
                    class="flex w-full flex-col gap-4 overflow-hidden"
                    v-if="projectsStore.project.projectData.offsetTimelineData"
                  >
                    <h6 class="text-lg font-semibold">Offsets timeline</h6>
                    <Bar
                      :data="projectsStore.project.projectData.offsetTimelineData"
                      :options="projectionsChartOptions"
                    />
                  </div>

                  <!-- Purchases -->
                  <div
                    class="flex flex-col gap-4"
                    v-if="isMyProject"
                  >
                    <h6 class="text-lg font-semibold">Purchases</h6>
                    <Loader v-if="isAuctionPurchasesLoading" />
                    <div
                      class="block w-full"
                      v-if="!isAuctionPurchasesLoading && auctionPurchases"
                    >
                      <AuctionBidItem
                        v-for="item in auctionPurchases"
                        :title="item.user.company?.title || `Hidden`"
                        :amount="Number(item.amountInTonnes)"
                        :price="item.purchasePrice"
                        :createdAt="moment(item.createdAt).format('DD-MM-YYYY')"
                      />
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
          <!-- Sticky Box -->
          <div
            v-if="projectsStore.project.auction"
            class="relative top-auto z-10 col-span-full mt-[-1rem] flex w-full flex-col items-start justify-start gap-4 overflow-hidden rounded-lg border-gray-50 bg-white p-4 text-gray-500 shadow-xl dark:border-gray-700 dark:bg-gray-900 dark:text-gray-500 md:sticky md:top-24 md:z-20 md:col-span-1 md:mt-[-8rem] md:max-h-[48rem] md:p-6"
          >
            <div class="flex w-full flex-col gap-0">
              <div
                v-if="projectsStore.project.auction.inventoryPricingSchedule"
                class="flex flex-col gap-2"
              >
                <div
                  v-if="projectsStore.project.auction.startingInventory"
                  class="flex w-full flex-col gap-1"
                >
                  <h6 class="text-lg font-semibold text-gray-600 dark:text-gray-200">
                    Total inventory available
                  </h6>
                  <small class="text-sm font-normal text-gray-500">
                    {{
                      projectsStore.project.auction.startingInventory.toLocaleString("en")
                    }}</small
                  >
                </div>
                <Divider margin="my-4" />
                <div class="flex w-full flex-col gap-1">
                  <div class="mb-2 max-h-80 w-full overflow-y-auto">
                    <h6 class="text-lg font-semibold text-gray-600 dark:text-gray-200">
                      Current price
                    </h6>
                    <div class="mb-2 flex flex-row flex-wrap items-center justify-start gap-2">
                      <small class="text-sm font-normal text-gray-500">By vintage years</small>
                      <small> • </small>
                      <small class="text-sm font-normal text-gray-500"
                        >Paid upon time of delivery</small
                      >
                    </div>
                    <ProjectListItem
                      v-for="(remaining, index) in projectsStore.project.auction
                        .inventoryPricingSchedule"
                      :key="remaining.year"
                      :title="remaining.year"
                      :detail="`$${currentSchedule[index]}`"
                    />
                  </div>
                </div>
              </div>
              <Divider margin="my-4" />
              <div class="flex flex-col gap-2">
                <h6 class="text-lg font-semibold text-gray-600 dark:text-gray-200">
                  Remaining auction time
                </h6>
                <span
                  v-if="!projectClosed"
                  class="flex w-full flex-col items-start justify-start gap-2 pb-5"
                >
                  <p class="text-xl font-bold text-gray-900 dark:text-gray-400">
                    {{ totalDaysLeft }} days, {{ totalHoursLeft }} hours and
                    {{ totalMinutesLeft }} minutes
                  </p>
                  <small class="mb-3 block text-sm text-gray-500">While inventory lasts </small>
                </span>
                <span
                  v-else
                  class="mb-4"
                >
                  Project auction is closed
                </span>
              </div>
              <!-- Buttons -->
              <div
                v-if="!isMyProject"
                class="flex w-full flex-col gap-4"
              >
                <Button
                  :disabled="authStore.user?.verificationStatus !== 'complete' || projectClosed"
                  text="Commit to purchase"
                  type="button"
                  @click="setPurchaseScreenOpen(true)"
                  chunky
                  full
                />
                <small
                  v-if="!projectClosed"
                  class="w-full text-center text-xs text-gray-500"
                  >Check auction tab for pricing schedule</small
                >
                <small
                  v-else
                  class="w-full text-center text-xs text-gray-500"
                  >If you are curious about this project or want to learn more please email
                  <a
                    class="underline"
                    :href="`mailto:hello@sequestr.io?subject=I want to learn more about a project!&body=${projectsStore.project.name}`"
                    >hello@sequestr.io</a
                  ></small
                >
              </div>
            </div>
            <div
              v-if="isMyProject"
              class="flex w-full flex-col gap-4"
            >
              <p>
                To run an auction for this project please get in touch with the Sequestr team at
                <a
                  class="text-emerald-500 underline"
                  :href="`mailto:hello@sequestr.io?subject=Running an auction for '${slug}'`"
                  >hello@sequestr.io</a
                >
              </p>
              <Button
                class="border-blue-100 bg-transparent text-blue-600 hover:border-blue-200 hover:bg-blue-50 dark:border-blue-500 dark:text-blue-400 dark:hover:border-blue-900 dark:hover:bg-blue-800"
                text="Edit project"
                type="button"
                @click="onContinueEditClick"
                full
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
  import moment from "moment";
  import { useRoute, useRouter } from "vue-router";
  import { useMutation } from "vue-query";
  import { Bar, Line } from "vue-chartjs";
  import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
  } from "chart.js";
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
  } from "@headlessui/vue";
  import { CheckCircleIcon, FaceSmileIcon, XMarkIcon } from "@heroicons/vue/24/outline";
  import { useHead } from "@vueuse/head";

  import { useAuthStore } from "@/store/authStore";
  import { useProjectStore } from "@/store/projectStore";

  import { logPageView, logEvent } from "@/services/analytics";

  import AuctionBidItem from "@/components/AuctionBidItem.vue";
  import Checkbox from "@/components/Form/Checkbox.vue";
  import CloseModalButton from "@/components/CloseModalButton.vue";
  import Button from "@/components/Button.vue";
  import Divider from "@/components/Divider.vue";
  import GridBox from "@/components/GridBox.vue";
  import IssuanceYearListItem from "@/components/IssuanceYearListItem.vue";
  import Loader from "@/components/Loader.vue";
  import PermissionedAccessVue from "@/components/Modals/PermissionedAccess.vue";
  import ProjectCreator from "@/components/ProjectCreator.vue";
  import ProjectHighlightsItemVue from "@/components/ProjectHighlightsItem.vue";
  import ProjectListItem from "@/components/ProjectListItem.vue";
  import Tag from "@/components/Tag.vue";
  import Input from "@/components/Input.vue";

  const route = useRoute();
  const router = useRouter();

  const authStore = useAuthStore();
  const projectsStore = useProjectStore();

  const purchaseScreenOpen = ref(false);
  const isPurchaseConfirmed = ref(false);
  const purchaseConfirmedData = ref(null);
  let unauthModalOpen = ref(false);
  let permissionedAuthModalOpen = ref(false);
  let minInteger = ref(null);
  let minIntegerAndProject = ref(null);
  let disableButton = ref(false);
  let showVolumeExceedsError = ref(false);
  let purchaseToggleChecked = ref(false);
  let emailToggleChecked = ref(false);
  let fees = ref(0);
  let currentTimeDiff = ref(null);
  let sequestrationSchedule = ref(0);
  let sequestrationScheduleYears = ref(0);
  let readTerms = ref(false);
  let projectClosed = ref(false);

  function setDisableButton(purchaseCheckedValue, emailCheckedValue) {
    const volumeExceedsError = showVolumeExceedsError.value;
    console.info(
      `setDisableButton checked: ${
        (purchaseCheckedValue, emailCheckedValue)
      }, volumeExceedsError: ${volumeExceedsError}`,
    );
    if (!purchaseCheckedValue || !emailCheckedValue || volumeExceedsError) {
      disableButton.value = true;
      return;
    }
    disableButton.value = false;
  }

  watch(
    () => purchaseToggleChecked.value,
    () => {
      setDisableButton(purchaseToggleChecked.value, emailToggleChecked.value);
    },
    () => emailToggleChecked.value,
    () => {
      setDisableButton(purchaseToggleChecked.value, emailToggleChecked.value);
    },
  );

  const onPurchaseToggleChecked = (event) => {
    const targetValue = event.target.checked;
    purchaseToggleChecked.value = targetValue;
    setDisableButton(targetValue, emailToggleChecked.value);
  };

  const onEmailConfirmChecked = (event) => {
    const targetValue = event.target.checked;
    emailToggleChecked.value = targetValue;
    setDisableButton(purchaseToggleChecked.value, targetValue);
  };

  function setPurchaseScreenOpen(value) {
    purchaseScreenOpen.value = value;
    purchaseToggleChecked.value = false;
    emailToggleChecked.value = false;
    setDisableButton(false, false);
    logEvent("Purchase modal open");
  }

  // Force reload
  const forceReload = () => {
    nextTick(() => {
      router.go();
    });
  };

  const pushToSettings = () => {
    router.push({
      path: "/settings",
      query: {
        tab: "bids",
      },
    });
  };

  const closeModal = () => {
    setPurchaseScreenOpen(false);
    setTimeout(() => {
      purchaseScreenOpen.value = false;
      purchaseToggleChecked.value = false;
      emailToggleChecked.value = false;
      isPurchaseConfirmed.value = false;
      readTerms.value = false;
      unitAmountInput.value = 0;
      onUnitAmountInputUpdate(0);
      setDisableButton(false, false);
    }, 250);
  };

  const openTerms = () => {
    window.open("https://sequestr.io/terms");
    readTerms.value = true;
  };

  const tabs = ["Overview", "Projections", "SDG"];

  let noProjectFound = ref(false);
  let selectedTab = ref(0);
  let scrolled = ref(false);
  const slug = ref("");

  // Project weights and sums
  const sum = ref(null);
  const weightsArray = ref([]);
  const weightPercentages = ref([]);

  const isMyProject = computed(() => {
    const companyId = authStore.user?.company?.id;

    // Company ID for projects should always be set, otherwise a project should not be live
    const projectCompanyId = projectsStore.project.company.id;

    if (!companyId) {
      return false;
    }

    return companyId === projectCompanyId ? true : false;
  });

  const { isLoading, isError, error, mutate } = useMutation(
    (slug) => projectsStore.getProjectBySlug(slug),
    {
      isError: () => {
        console.info("ERROR");
      },
      onSuccess: () => {
        console.info("Project fetched successfully", projectsStore.project);
        noProjectFound.value = false;
        const pricingSchedule = projectsStore.project?.auction?.inventoryPricingSchedule;
        if (!pricingSchedule) return;

        const startingInventory = projectsStore.project.auction.startingInventory;

        const minPurchaseablePercentage =
          projectsStore.project?.auction?.minPurchasePercentage || 0.01;
        console.info({ minPurchaseablePercentage });

        // Create new array for all amounts
        pricingSchedule.map((pricing) => {
          console.info({ pricing });
          weightsArray.value.push(pricing.weight * startingInventory);
        });

        // This is the sum of the amounts
        sum.value = weightsArray.value.reduce((a, b) => b + a, 0);
        console.info("sum:", sum.value);

        // Weights based on amounts instead of the weight key from db
        weightPercentages.value = weightsArray.value.map((amount) => {
          console.info({ amount });
          return amount / sum.value;
        });
        console.info("weightPercentages:", weightPercentages.value);

        // Take the minimum percentage from weightPercentage and do a 1 over minimum
        const minCalc = weightPercentages.value.reduce((a, b) => Math.min(a, b));
        console.info({ minCalc });

        // If minCalc is 0, we need to find the minimum > 0 from weightPercentages.value and use that, otherwise use minCalc
        if (minCalc === 0) {
          console.info("minCalc is 0, finding minimum > 0");
          const minCalc2 = weightPercentages.value.reduce((a, b) => {
            if (a === 0) return b;
            if (b === 0) return a;
            return Math.min(a, b);
          });
          console.info({ minCalc2 });
          minInteger.value = 1 / minCalc2;
        } else {
          minInteger.value = 1 / minCalc;
        }
        console.info("minInteger after minCalc check:", minInteger.value);

        // If minimum purchase amount is 0 or null, use minInteger, otherwise use minPurchaseablePercentage
        const topCalc = startingInventory * minPurchaseablePercentage;
        const ceiling = Math.ceil((topCalc / minInteger.value) * minInteger.value); // was sum.value instead of `startingInventory`
        console.info({ topCalc, ceiling });
        minIntegerAndProject.value = Math.round(
          !minPurchaseablePercentage ? ceiling : minInteger.value,
        );
        console.info(
          "startingInventory, minPurchaseablePercentage, minIntegerAndProject:",
          startingInventory,
          minPurchaseablePercentage,
          minIntegerAndProject.value,
        );

        // Calculate time diff
        const today = new Date();
        currentTimeDiff.value = today.setDate(today.getDate() - 1);

        // Calculate the totals
        const schedule =
          projectsStore.project.projectData.sequestrationSchedule.reduce(
            (a, b) => a + b.detail,
            0,
          ) || 0;
        const years = projectsStore.project.projectData.sequestrationSchedule.map(({ title }) =>
          parseFloat(title),
        );
        const yearsDiff = years
          .slice(1)
          .map(function (n, i) {
            return n - years[i];
          })
          .reduce((a, b) => a + b, 0);
        console.info({ years, yearsDiff });
        sequestrationSchedule.value = schedule.toLocaleString("en", {
          maximumFractionDigits: 2,
        });
        sequestrationScheduleYears.value = yearsDiff;

        const projectStatus = projectsStore.project.auction?.status;
        if (!projectStatus || projectStatus === "open") {
          projectClosed.value = false;
          return;
        }
        projectClosed.value = true;
        console.info("Project status:", projectStatus, projectClosed.value);
      },
      onError: (error) => {
        if (error === "Not Found") {
          noProjectFound.value = true;
        }
      },
    },
  );

  const vh = (v) => {
    let h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
  };

  const handleScroll = () => {
    if (scrollY <= vh(60)) {
      scrolled.value = false;
      return;
    }
    scrolled.value = true;
  };

  onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
  });

  const current = ref(0);

  onMounted(() => {
    const user = authStore.user;
    if (!user) {
      unauthModalOpen.value = true;
      return;
    }

    console.info({ user });

    if (user.verificationStatus === "pending") {
      permissionedAuthModalOpen.value = true;
      return;
    }

    const id = route.params.slug;
    slug.value = id;
    mutate(id);

    current.value = moment().format();
    window.addEventListener("scroll", handleScroll);

    logPageView("Project", {
      id: id,
    });
  });

  const onContinueEditClick = () => {
    router.push(`/project/${slug.value}/edit`);
    logEvent("Edit project");
  };

  const setSelectedTabIndex = (index) => {
    console.info("setSelectedTabIndex() index: ", index);
    logEvent("Project view: Switch tab", {
      tabIndex: index,
    });

    if (index === 3 && !auctionPurchases.value) {
      auctionPurchasesMutate();
    }

    return (selectedTab.value = index);
  };

  // Register with chart.js
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
  );

  // ChartJS config
  ChartJS.defaults.font.family =
    "'InterDisplay var', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif";
  ChartJS.defaults.plugins.legend.display = false;

  const projectionsChartOptions = {
    responsive: false,
    maintainAspectRatio: false,
  };

  // input value
  const dollarAmountInput = ref(0);
  const volumeAmountInput = ref(0);
  const unitAmountInput = ref(0);

  const onUnitAmountInputUpdate = (value) => {
    console.info("Checked:", purchaseToggleChecked.value, emailToggleChecked.value, { value });

    disableButton.value = false;
    showVolumeExceedsError.value = false;

    unitAmountInput.value = value;
    volumeAmountInput.value = value * minIntegerAndProject.value;
    console.info("weightedPrice", weightedPrice.value);

    // Calculate fees: 5%
    if (!weightedPrice.value) return;
    const feesCalc = volumeAmountInput.value * 0.05 * weightedPrice.value;
    console.info({ feesCalc });
    fees.value = feesCalc;

    dollarAmountInput.value = volumeAmountInput.value * weightedPrice.value + feesCalc;

    // Disabled due to https://app.asana.com/0/1202474125709876/1202955682329405/f
    if (volumeAmountInput.value > projectsStore.project.auction.startingInventory) {
      console.info("volume exceeds inventory");
      disableButton.value = true;
      showVolumeExceedsError.value = true;
    }

    setDisableButton(purchaseToggleChecked.value, emailToggleChecked.value);
  };

  const round = (value, decimals) => {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  };

  const calcInventoryDistribution = (index, type) => {
    // Scoped vars
    const foundIndex = projectsStore.project.auction.inventoryPricingSchedule[index];
    if (!foundIndex) return;
    console.info({ foundIndex });

    // Calc purchasing inventory
    if (type === "purchasing") {
      const weightIndexValue = weightPercentages.value[index];
      const result = volumeAmountInput.value * weightIndexValue;
      return result.toLocaleString("en", {
        maximumFractionDigits: 2,
      });
    }

    // Calculate remaining inventory otherwise
    const startingInventory = projectsStore.project.auction.startingInventory;
    const topValue = startingInventory * weightPercentages.value[index];
    console.info({ topValue });
    const result = topValue / sum.value;
    console.info({ result });
    const rounded = round(result, 3);
    console.info({ rounded });
    const finalResult = (rounded * 100).toLocaleString("en");
    console.info({ finalResult });
    return finalResult;
  };

  const { mutate: getAuctionMutate } = useMutation(() =>
    projectsStore.getLatestAuctionBySlug(slug.value),
  );

  const {
    isLoading: isPurchaseAuctionLoading,
    isError: isPurchaseAuctionError,
    error: purchaseAuctionError,
    mutate: purchaseAuctionMutate,
  } = useMutation(() => projectsStore.purchaseAuction(slug.value, volumeAmountInput.value), {
    onSuccess: (data) => {
      console.info({ data });
      isPurchaseConfirmed.value = true;
      purchaseConfirmedData.value = data;
      getAuctionMutate();
      logEvent("Purchase confirmed", {
        amount: volumeAmountInput.value,
        tonnes: unitAmountInput.value,
      });
    },
  });

  const totalDaysLeft = computed(() =>
    Math.floor(
      moment
        .duration(
          moment(projectsStore.project.auction.endDate, "YYYY-MM-DD").diff(moment().startOf("day")),
        )
        .asDays(),
    ),
  );

  const totalHoursLeft = computed(() => {
    const now = moment();
    const expiration = moment(projectsStore.project.auction.endDate);
    const diff = expiration.diff(now);
    const diffDuration = moment.duration(diff);
    return diffDuration.hours();
  });

  const totalMinutesLeft = computed(() => {
    const now = moment();
    const expiration = moment(projectsStore.project.auction.endDate);
    const diff = expiration.diff(now);
    const diffDuration = moment.duration(diff);
    return diffDuration.minutes();
  });

  const closestTime = computed(() => {
    if (projectsStore.project.pricingSchedule?.schedule?.length) {
      let times = projectsStore.project.pricingSchedule.schedule.filter((time) => {
        return (
          moment(current.value).isAfter(time.auctionDate) &&
          !moment(current.value).isBefore(time.auctionDate)
        );
      });

      if (!times.length) {
        times = [projectsStore.project.pricingSchedule.schedule[0]];
      }

      return times[times.length - 1];
    }
    return null;
  });

  const currentSchedule = computed(() => {
    console.info("Closest time:", closestTime.value);
    if (!closestTime.value) return [];
    const prices = closestTime.value.vintagePrices;
    console.info({ prices });
    return prices;
  });

  const weightedPrice = computed(() => {
    let weightedPrice = 0;

    for (const [
      index,
      inventoryPricingSchedule,
    ] of projectsStore.project.auction?.inventoryPricingSchedule.entries()) {
      weightedPrice += inventoryPricingSchedule.weight * currentSchedule.value[index];
    }

    return Math.round(weightedPrice);
  });

  const currentPrice = computed(() => {
    if (!closestTime.value) return 0;
    const currentYear = moment().year();
    console.info("currentPrice() currentYear:", currentYear);
    let index = projectsStore.project.pricingSchedule.vintageYears.indexOf(currentYear);
    index < 0 ? (index = 0) : index;
    console.info("currentPrice() index:", index);
    return closestTime.value.vintagePrices[index];
  });

  const nextPrice = computed(() => {
    if (!closestTime.value) return 0;
    const currentYear = moment().year();
    console.info("nextPrice() currentYear:", currentYear);
    let index = projectsStore.project.pricingSchedule.schedule.indexOf(closestTime.value);
    console.info("nextPrice() index:", index);
    let nextIndex = index + 1;
    if (nextIndex >= projectsStore.project.pricingSchedule.schedule.length) {
      nextIndex = index;
    }
    console.info("nextPrice() nextIndex:", nextIndex);
    const next = projectsStore.project.pricingSchedule.schedule[nextIndex];
    console.info("nextPrice() next:", next);
    let currentYearIndex = projectsStore.project.pricingSchedule.vintageYears.indexOf(currentYear);
    currentYearIndex < 0 ? (currentYearIndex = 0) : currentYearIndex;
    console.info("nextPrice() currentYearIndex:", currentYearIndex);
    return next.vintagePrices[currentYearIndex];
  });

  const timeToNextPrice = computed(() => {
    const difference = moment(current.value).diff(closestTime?.value?.auctionDate);
    console.info("timeToNextPrice() difference:", difference);
    return moment.duration(difference).humanize();
  });

  const {
    mutate: auctionPurchasesMutate,
    isLoading: isAuctionPurchasesLoading,
    isError: isAuctionPurchasesError,
    error: auctionPurchasesError,
    data: auctionPurchases,
  } = useMutation(() => projectsStore.getAuctionPurchasesBySlug(slug.value), {
    onSuccess: (data) => {
      return data;
    },
  });

  useHead({
    // Can be static or computed
    title: computed(() =>
      projectsStore.project.name ? `Project: ${projectsStore.project.name}` : "Project on Sequestr",
    ),
    meta: [
      {
        name: `description`,
        content: computed(
          () =>
            projectsStore.project.projectData?.story?.context ||
            "No description provided by project",
        ),
      },
    ],
  });
</script>

<style lang="scss">
  .raw-html {
    ul {
      @apply max-w-lg list-inside list-disc pt-2;
    }
  }
</style>
