<script setup>
  import { ref } from "vue";

  let projects = ref([
    {
      id: 1,
      name: "Undo Enhanced Weathering",
      approved: true,
      disbursement: 500000,
    },
    {
      id: 2,
      name: "India Direct Air Capture Project",
      approved: false,
      disbursement: 250000,
    },
    {
      id: 3,
      name: "Canada ARR Project",
      approved: true,
      disbursement: 150000,
    },
  ]);
</script>

<template>
  <div class="flex flex-col gap-4">
    <h1 class="text-xl font-semibold text-gray-900 dark:text-gray-300">
      Loan Approvals &amp; Disbursements
    </h1>
    <div class="flex flex-col gap-0">
      <div
        v-for="project in projects"
        :key="project.id"
        class="flex flex-row items-start justify-between gap-0 border-b border-gray-100 py-4 last-of-type:border-b-0 dark:border-gray-700 md:flex-row md:items-center md:gap-2"
      >
        <div class="flex flex-col gap-0">
          <h2 class="text-lg font-medium text-gray-900 dark:text-gray-300">{{ project.name }}</h2>
          <div class="flex w-full flex-row items-center justify-between gap-2">
            <span class="text-gray-500">
              Loan Disbursement:
              {{
                project.disbursement?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }) ?? "N/A"
              }}
            </span>
          </div>
        </div>
        <button
          :class="[
            'rounded-lg border bg-transparent px-4 py-2 font-semibold',
            'hover:border-emerald-600 hover:bg-emerald-500 hover:text-white',
            'box-shadow-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50',
            project.approved
              ? 'border-gray-100 text-gray-900 dark:border-gray-700 dark:text-gray-300'
              : 'border-emerald-200 text-emerald-500',
          ]"
        >
          {{ project.approved ? "View" : "Approve" }}
        </button>
      </div>
    </div>
  </div>
</template>
