<template>
  <router-link
    to="/"
    :class="`flex h-12 w-12 items-center justify-center rounded-full bg-gray-50 p-3 text-gray-900 backdrop-blur-xl hover:bg-emerald-100 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-emerald-900`"
  >
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M14.5 28.1213V39.9306C14.9938 39.9765 15.4942 40 16 40C16.5058 40 17.0062 39.9765 17.5 39.9306V28.1213L24.3581 34.9794C25.1731 34.3907 25.9305 33.7272 26.6201 32.9988L19.6213 26H31.7192C31.9035 25.0282 32 24.0254 32 23H0C0 24.0254 0.0964569 25.0282 0.280792 26H12.3787L5.37987 32.9988C6.06951 33.7272 6.82689 34.3907 7.64193 34.9794L14.5 28.1213Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 16.3333C18.9455 16.3333 21.3333 13.9455 21.3333 11C21.3333 8.05448 18.9455 5.66667 16 5.66667C13.0545 5.66667 10.6667 8.05448 10.6667 11C10.6667 13.9455 13.0545 16.3333 16 16.3333ZM16 19C20.4183 19 24 15.4183 24 11C24 6.58172 20.4183 3 16 3C11.5817 3 8 6.58172 8 11C8 15.4183 11.5817 19 16 19Z"
          fill="currentColor"
        />
      </g>
    </svg>
  </router-link>
</template>
