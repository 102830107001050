<script setup>
  import { toRefs } from "vue";

  import CompanyEventItem from "./CompanyEventItem.vue";

  const props = defineProps({
    events: {
      type: Object,
      required: false,
    },
  });

  let { events } = toRefs(props);
</script>
<template>
  <div
    v-if="events"
    class="flex flex-col gap-4"
  >
    <CompanyEventItem
      v-for="event in events"
      :event="event"
      :key="Math.random()"
    />
  </div>
  <div
    v-else
    class="text-gray-500"
  >
    <p>No events simulated or added</p>
  </div>
</template>
