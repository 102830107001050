<template>
  <div
    class="mb-8 grid grid-cols-1 items-stretch gap-4"
    :class="expanded ? 'sm:grid-cols-2 md:grid-cols-3' : 'md:grid-cols-2'"
  >
    <div
      class="relative h-auto w-full overflow-hidden rounded-md bg-gray-50 dark:bg-gray-900 md:h-full"
      v-for="(methodology, i) in methodologies"
      :key="methodology.id"
    >
      <div class="relative">
        <div
          class="z-1 relative"
          v-if="selectable"
        >
          <button
            v-if="methodology.type === 'supported'"
            :class="
              methodology.selected
                ? 'bg-emerald-500 hover:bg-emerald-600'
                : 'bg-black/50 hover:bg-emerald-500'
            "
            class="absolute right-0 top-0 mt-2 mr-2 flex flex-row items-center gap-2 rounded-md py-1 px-2 text-sm font-medium text-white backdrop-blur-md"
            type="button"
            @click="onToggleSelect(i)"
          >
            <span v-if="methodology.selected"> Selected </span>
            <span v-else> Select </span>
            <span class="w-4">
              <CheckCircleIcon v-if="methodology.selected" />
              <PlusCircleIcon v-else />
            </span>
          </button>
          <button
            v-else
            class="absolute right-0 top-0 mt-2 mr-2 flex flex-row items-center gap-2 rounded-md bg-black/20 py-1 px-2 text-sm font-medium text-white backdrop-blur-md"
            disabled
          >
            Coming soon
          </button>
        </div>
        <img
          v-if="methodology.img_path"
          class="h-56 w-full object-cover"
          :src="methodology.img_path"
        />
      </div>
      <div class="flex flex-col items-stretch p-4 md:p-6">
        <!-- Basic info -->
        <div class="flex w-full flex-1 flex-col">
          <strong class="mb-4 block text-base font-semibold dark:text-gray-500">
            {{ methodology.title }}
          </strong>
          <p class="block whitespace-pre-line text-xs text-gray-500">
            {{ methodology.description }}
          </p>
          <Divider v-if="methodology.type !== 'other'" />
        </div>
        <!-- Metadata -->
        <div
          class="flex w-full flex-auto flex-col"
          v-if="methodology.type !== 'other'"
        >
          <div
            v-if="methodology.sequestration"
            class="flex w-full flex-col"
          >
            <strong class="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-500">
              Carbon sequestration potential
            </strong>
            <p
              class="block text-xs leading-6 text-gray-500"
              v-for="(s, i) in methodology.sequestration"
              :key="`s-${i}`"
            >
              {{ s }}
            </p>
          </div>
          <Divider />
          <div
            v-if="methodology.credits_outlook"
            class="mb-6 flex w-full flex-col"
          >
            <strong class="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-500">
              Carbon credits generated over 10 years
            </strong>
            <p
              class="block text-xs leading-6 text-emerald-600"
              v-for="(c, i) in methodology.credits_outlook"
              :key="`c-${i}`"
            >
              {{ c }}
            </p>
          </div>
          <div
            v-if="methodology.avg_price"
            class="flex w-full flex-col text-gray-700 dark:text-gray-500"
          >
            <strong class="mb-2 text-sm font-semibold"> Average price </strong>
            <p class="block text-xs leading-6">{{ methodology.avg_price }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watchEffect } from "vue";
  import { useStore } from "vuex";
  import { CheckCircleIcon, PlusCircleIcon } from "@heroicons/vue/24/outline";
  import Divider from "@/components/Divider.vue";

  export default {
    components: {
      CheckCircleIcon,
      PlusCircleIcon,
      Divider,
    },
    props: {
      expanded: {
        default: false,
        type: Boolean,
      },
      methodologies: {
        default: [],
        type: Array,
      },
      selectable: {
        default: false,
        type: Boolean,
      },
      selected: {
        default: [],
        type: Array,
      },
      fnc: Function,
    },
    setup(props) {
      const store = useStore();

      const fnc = props.fnc;

      let expanded = ref(props.expanded);
      let methodologies = ref([]);
      let selectable = ref(props.selectable);
      let selected = ref(props.selected);

      console.info("props.selected: ", selected.value);

      if (props.methodologies) {
        methodologies.value = props.methodologies;
      }

      if (!methodologies.value.length) {
        console.info("Fetch methodologies");
        // Get methodologies from localStorage, if it's not there then fetch from store
        methodologies.value = JSON.parse(localStorage.getItem("methodologies"));
        if (!methodologies.value || !methodologies.value.length) {
          console.info("Get methodologies from store");
          store.dispatch("methodologies").then((resp) => {
            methodologies.value = resp;
          });
        }
      }

      watchEffect(() => {
        methodologies.value &&
          methodologies.value.map((m) => ({
            ...m,
            selected: false,
          }));
        console.info("mapped: ", methodologies.value);

        if (selected.value.length) {
          const result = methodologies.value.map((x) => {
            var result = selected.value.filter((a1) => a1.id == x.id);
            if (result.length > 0) {
              x.selected = result[0].selected;
            }
            return x;
          });
          methodologies.value = result;
        }
      });

      const onToggleSelect = (index) => {
        methodologies.value[index].selected = !methodologies.value[index].selected;

        if (methodologies.value[index].selected) {
          selected.value.push(methodologies.value[index]);
        } else {
          selected.value = selected.value.filter((m) => {
            return m.id !== methodologies.value[index].id;
          });
        }

        // Return data to parent
        fnc(selected.value);
      };

      return {
        expanded,
        methodologies,
        onToggleSelect,
        selectable,
        fnc,
      };
    },
  };
</script>
