<template></template>

<script setup>
  import { onMounted } from "vue";
  import { useRouter } from "vue-router";

  const router = useRouter();

  onMounted(() => {
    router.replace("/projects");
  });
</script>
