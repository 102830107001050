<template>
  <div
    v-if="props.currentUserId !== props.memberId"
    class="flex max-w-xs flex-row items-center justify-start gap-4 text-sm font-semibold md:max-w-none md:justify-end md:text-inherit"
  >
    <Button
      :loading="isLoading"
      v-show="confirmToShow === props.index && !props.disabled"
      classes="rounded-md border-0 border-amber-700 bg-amber-500 px-2 py-1 text-sm text-white hover:border-amber-600 hover:bg-amber-400 shadow-none"
      @click="confirmRemoveMember(props.index)"
      text="Are you sure?"
    />
    <Button
      :square="true"
      v-if="confirmToShow !== props.index && !props.disabled"
      @click="removeMember(props.index)"
      classes="h-8 w-8 rounded-md border-0 bg-gray-50 px-2 text-gray-400 hover:bg-amber-500 hover:text-white dark:bg-gray-800 dark:text-gray-400 shadow-none"
      icon-left="TrashIcon"
    />
    <Button
      v-if="confirmToShow === props.index && !props.disabled"
      classes="rounded-md border-0 border-gray-200 bg-gray-100 px-2 py-1 text-sm text-gray-900 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700 shadow-none"
      @click="cancelRemove(props.index)"
      text="Cancel"
    />
    <span
      v-if="isError"
      class="text-sm font-normal text-red-500"
      >{{ error }}</span
    >
  </div>
</template>

<script setup>
  import { ref } from "vue";
  import { useMutation } from "vue-query";

  import Button from "./Button.vue";

  const confirmToShow = ref(-1);
  const props = defineProps({
    currentUserId: String,
    memberId: String,
    index: Number,
    companyMembers: Array,
    deleteMembersApiCall: Function,
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  const cancelRemove = (index) => {
    confirmToShow.value = -1;
  };

  const confirmRemoveMember = (index) => {
    mutate(props.currentUserId);
  };

  const removeMember = (index) => {
    confirmToShow.value = index;
  };

  const { isLoading, isError, error, mutate } = useMutation(
    () => props.deleteMembersApiCall(props.memberId),
    {
      onSuccess: () => {
        props.companyMembers.splice(props.index, true);
        confirmToShow.value = -1;
      },
    },
  );
</script>
