import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/routes";
import { useAuthStore } from "@/store/authStore";
import { useCookies } from "vue3-cookies";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { cookies } = useCookies();
  const hasCookie = cookies.get("session");
  const authStore = useAuthStore();

  // handle failed oauth logins
  if (to.path === "/onboarding") {
    if (to.query?.error_description === "Database error saving new user") {
      next({ name: "Work emails only" });
    }
  }

  if (to.meta.requiresAuth) {
    if (hasCookie && !authStore.user) {
      try {
        await authStore.getUser();
        next();
      } catch (error) {
        console.error("Router beforeEach: ", error);
        if (error === "Unauthorized") {
          // TODO: show popup saying that you've been logged out
          authStore.clearAuth();
        }
        next({ name: "Login" });
      }
    } else if (hasCookie && authStore.user) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    if (["/new-password", "/projects", "/login"].indexOf(to.path) !== -1) {
      next();
    } else {
      if (hasCookie && !authStore.user) {
        try {
          await authStore.getUser();
          next();
        } catch (error) {
          console.error("Route does not require auth, but failed", error);
          router.push("/login");
          if (error === "Unauthorized") {
            authStore.clearAuth();
            // TODO: show popup saying that you've been logged out
            next({ name: "Login" });
          }
        }
      } else {
        next();
      }
    }
  }
});

export default router;
