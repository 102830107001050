<script setup>
  import { computed } from "vue";
  const props = defineProps({
    name: String,
    logo: String,
    size: {
      type: String,
      default: "small",
    },
  });

  let { name, logo, size } = props;

  const formattedLogo = computed(() => {
    if (!name) return "";
    const words = name.split(" ");
    if (words.length === 1) return words[0].charAt(0);
    return words[0].charAt(0) + words[1].charAt(0);
  });
</script>

<template>
  <div class="flex flex-row items-center justify-start gap-4">
    <div
      :class="`flex aspect-1 items-center justify-center overflow-hidden rounded-full border border-gray-200 bg-white text-center uppercase dark:border-gray-700 dark:bg-gray-800
        ${size === 'large' ? 'h-16' : 'h-12'}`"
    >
      <img
        v-if="logo"
        class="h-full w-full object-cover"
        :src="logo"
        :alt="name"
      />
      <small v-else>
        {{ formattedLogo }}
      </small>
    </div>
    <strong
      :class="`font-semibold text-gray-900 dark:text-gray-200 ${
        size === 'large' ? 'text-2xl' : 'text-xl'
      }`"
    >
      {{ name }}
    </strong>
  </div>
</template>
