<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <h1 class="mb-4 text-4xl font-semibold dark:text-gray-300">Project types</h1>
      <p class="mb-8 text-base text-gray-500 md:mb-12">
        We're expanding our project types as we go through our journey. If there's something
        specific you're looking for, feel free to get in touch via
        <a
          class="underline hover:text-gray-800 dark:text-gray-400"
          :href="`mailto:${email}`"
          target="_blank"
          rel="noopener noreferrer"
          >{{ email }}</a
        >
      </p>
      <MethodologiesGrid expanded />
    </div>
  </div>
</template>

<script>
  import { useRoute } from "vue-router";
  import { logPageView } from "@/services/analytics";
  import MethodologiesGrid from "@/components/MethodologiesGrid.vue";

  export default {
    components: {
      MethodologiesGrid,
    },
    setup() {
      const route = useRoute();
      logPageView(route.name);
      let email = import.meta.env.VITE_EMAIL_LINK;
      return {
        email,
      };
    },
  };
</script>
