<script setup>
  import { ref, computed } from "vue";
  import { storeToRefs } from "pinia";

  import { useDemoStore } from "@/store/demoStore";

  import DoughnutChart from "@/components/Charts/DoughnutChart.vue";

  const demoStore = useDemoStore();
  const { getTransactionsByVintageYear, getProjectStats } = storeToRefs(demoStore);
  const totalInvestmentAmount = getProjectStats.value.totalInvestmentAmount;
  const transactionsByVintageYear = getTransactionsByVintageYear.value;
  console.info({ transactionsByVintageYear });

  // transactionsByVintageYear = { 2023: { credits: Number, investmentAmount: Number } }

  // We need to create aen array for investmentAmountByYear based on the keys of transactionsByVintageYear
  const investmentAmountByYear = computed(() => {
    return Object.keys(transactionsByVintageYear).map((key) => {
      return {
        year: key,
        outstandingLoan: transactionsByVintageYear[key].investmentAmount,
      };
    });
  });

  // Map the transactionsByVintageYear by key and value to the doughnut chart ie: 2024: { investmentAmount: 1000 } to chartData
  const colors = ["#94a3b8", "#a1a1aa", "#a8a29e", "#fb923c", "#facc15", "#4ade80", "#2dd4bf"];
  const chartData = ref({
    labels: Object.keys(transactionsByVintageYear),
    datasets: [
      {
        // Generate background colors for each location depending on the number of locations from the colors array
        backgroundColor: Object.keys(transactionsByVintageYear).map((_, index) => colors[index]),
        data: Object.values(transactionsByVintageYear).map((year) => year.investmentAmount),
        cutout: "80%",
        spacing: 2,
      },
    ],
  });
</script>

<template>
  <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
    <!-- Doughnut Chart -->
    <DoughnutChart
      :chartData="chartData"
      :floatingLabel="totalInvestmentAmount"
      title="Current Loans"
      format="currency"
    />
    <!-- Table Data -->
    <table class="w-full table-auto text-left">
      <thead>
        <tr class="h-8 text-sm text-gray-500">
          <th class="font-medium">Year</th>
          <th class="text-right font-medium">Outstanding Loan</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in investmentAmountByYear">
          <tr
            class="h-12 border-t border-gray-100 py-3 text-sm text-gray-900 dark:border-gray-700 dark:text-gray-400"
          >
            <td class="font-semibold">{{ row.year }}</td>
            <td class="text-right">
              {{
                row.outstandingLoan.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                })
              }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
