<template>
  <div
    class="sticky right-0 left-0 top-0 w-full bg-gray-50/50 text-sm text-gray-600 backdrop-blur-sm dark:bg-gray-900 dark:text-gray-300"
  >
    <div class="container mx-auto">
      <div class="flex items-center justify-between py-2 px-4">
        <span class="flex flex-row justify-start md:block">
          Please complete onboarding in order to use Sequestr.
        </span>
        <router-link
          class="rounded bg-white px-2 py-1 font-semibold shadow-md dark:bg-gray-600 dark:text-white"
          to="/onboarding"
          >Continue</router-link
        >
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useAuthStore } from "@/store/authStore";

  const authStore = useAuthStore();
</script>
