<script setup>
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from "chart.js";
  import { Bar } from "vue-chartjs";
  import { watch } from "vue";

  const props = defineProps({
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  });

  let { chartData, options } = props;

  // Watch for chartData prop changes
  // watch(
  //   chartData,
  //   (chartData) => {
  //     console.info("chartData", chartData);
  //   },
  //   { immediate: true, deep: true },
  // );

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
</script>

<template>
  <div
    v-if="chartData && chartData.labels.length"
    class="relative h-auto md:h-96"
  >
    <Bar
      :data="chartData"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        ...options,
      }"
    />
  </div>
  <div
    v-else
    class="flex items-center justify-center rounded-lg border-gray-100 bg-gray-50 px-4 py-2 text-center text-gray-500 dark:border-gray-700 dark:bg-gray-800"
  >
    No data provided
  </div>
</template>
