<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-3xl px-4 py-12">
      <h1 class="mb-8 text-4xl font-semibold dark:text-gray-300">
        Ooohhh a magic link, how fancy! 🪄
      </h1>
      <p class="mb-8 text-base text-gray-500 md:mb-12">
        Running some robot calculations, we'll redirect you to the right page
        shortly
      </p>
    </div>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import { useRouter, useRoute } from "vue-router";
  import { logEvent, logPageView } from "@/services/analytics";

  export default {
    setup() {
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      logPageView(route.name);

      const token = router.currentRoute.value.query.token;
      console.info(token);
      if (!token) {
        return router.push("/");
      }
      console.info({ token });

      const object = {
        magic_token: token,
      };

      store
        .dispatch("magicLogin", object)
        .then((resp) => {
          console.info(resp);
          if (resp.status === 200) {
            logEvent("Magic login", {
              type: "success",
            });
            router.push("/");
          }
        })
        .catch((err) => {
          console.info({ err });
          if (err.response.status === 500) {
            window.alert(err.response.data.message);
          }
          logEvent("Magic login", {
            type: "verify",
          });
          router.push("/verify");
        });
    },
  };
</script>
