<template>
  <div class="flex flex-col gap-4">
    <strong class="mb-4 block text-2xl font-semibold text-gray-900 dark:text-gray-300"
      >Bids &amp; purchases</strong
    >
    <Loader v-if="isLoading" />

    <span
      v-if="isError"
      class="block text-sm text-amber-600"
      >Something went wrong loading transactions: {{ error }}</span
    >

    <div
      v-if="!isLoading && data"
      class="flex flex-col gap-8"
    >
      <!-- Current bids -->
      <div class="relative w-full">
        <small class="mb-4 block text-xl font-semibold text-gray-800 dark:text-gray-400">
          Current bids
        </small>
        <span
          v-if="!currentBids.length"
          class="text-gray-500"
          >There are no current bids</span
        >
        <PurchaseHistory
          v-if="currentBids.length"
          v-for="item in currentBids"
          :key="item.id"
          :title="item.auction.project?.name || `No title`"
          :endDate="item.auction.endDate"
          :amountDollars="item.grossTotalUSD"
          :amountTonnes="item.amountInTonnes"
          :user="authStore.user"
        />
      </div>
      <!-- Historical bids -->
      <div class="relative w-full">
        <small class="mb-4 block text-xl font-semibold text-gray-800 dark:text-gray-400">
          Past transactions
        </small>
        <span
          v-if="!historicalBids.length"
          class="text-gray-500"
          >There are no past transactions</span
        >
        <PurchaseHistory
          v-if="historicalBids.length"
          v-for="item in historicalBids"
          :key="item.id"
          :title="item.auction.project?.name || `No title`"
          :endDate="item.auction.endDate"
          :amountDollars="item.grossTotalUSD"
          :amountTonnes="item.amountInTonnes"
          :user="authStore.user"
        />
      </div>
    </div>

    <div class="block">
      <Button
        v-if="currentPage < totalPages"
        :loading="isLoading"
        :disabled="isLoading"
        @click="currentPage++"
        classes="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
        text="Fetch More"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref } from "vue";
  import { useQuery } from "vue-query";

  import { useAuthStore } from "@/store/authStore";

  import Button from "@/components/Button.vue";
  import Loader from "@/components/Loader.vue";
  import PurchaseHistory from "@/components/PurchaseHistory.vue";

  const totalPages = ref(1);
  const currentPage = ref(1);
  const currentBids = ref([]);
  const historicalBids = ref([]);
  const authStore = useAuthStore();

  const { data, error, isLoading, isError } = useQuery(
    ["purchases", currentPage],
    () => authStore.getBidsAndPurchases(currentPage.value),
    {
      variables: {
        page: currentPage.value,
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: ({ data }) => {
        totalPages.value = data.meta.totalPages;
        for (let i = 0; i < data.items.length; i++) {
          if (data.items[i].status === "processing") {
            console.info({ data });
            currentBids.value.push(data.items[i]);
          } else {
            historicalBids.value.push(data.items[i]);
          }
        }
      },
    },
  );
</script>
