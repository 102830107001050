<template>
  <div class="container mx-auto">
    <div
      v-if="isValidateTokenLoading"
      class="mx-auto mt-16 flex max-w-3xl justify-center"
    >
      <Loader />
    </div>

    <div
      v-else-if="!isTokenValid"
      class="mx-auto mt-16 flex max-w-3xl justify-center"
    >
      <strong class="text-amber-700">You are not authorized to use this page.</strong>
    </div>

    <div
      class="mx-auto max-w-3xl px-4 py-12"
      v-else
    >
      <h1 class="mb-8 text-4xl font-semibold dark:text-gray-300">Almost there...</h1>
      <p class="mb-8 text-base text-gray-500 md:mb-8">
        Let's get you back on Sequestr by setting a new password for your account.
        <strong class="block pt-5"
          >Requirements: Minimum 8 characters, with at least 1 number, 1 special character, and 1
          uppercase letter.</strong
        >
      </p>
      <form
        class="mb-4 flex w-full flex-col items-center justify-start gap-4 md:flex-row"
        @submit.prevent="onFormSubmit"
      >
        <Input
          v-model="form.password"
          placeholder="Enter your new password"
          margin="mb-0"
          name="password"
          type="password"
          id="password"
          hideLabel
          full
        />
        <Input
          v-model="form.confirmPassword"
          placeholder="Confirm your new password"
          margin="mb-0"
          name="confirm-password"
          type="password"
          id="confirm-password"
          hideLabel
          full
        />
        <Button
          :loading="isConfirmForgotPasswordLoading"
          :disabled="isConfirmForgotPasswordLoading"
          classes="w-full md:w-auto"
          text="Submit"
          type="submit"
        />
      </form>
      <p
        v-if="isConfirmForgotPasswordError || isValidateTokenError"
        class="text-base text-amber-500"
      >
        <strong class="text-amber-700"
          >Error: {{ confirmForgotPasswordError || isValidateTokenError }}</strong
        >
      </p>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useMutation } from "vue-query";
  import { createToast } from "mosha-vue-toastify";

  import { useAuthStore } from "@/store/authStore";

  import { logEvent, logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import Input from "@/components/Input.vue";
  import Loader from "@/components/Loader.vue";

  const authStore = useAuthStore();
  const route = useRoute();
  const router = useRouter();
  const isTokenValid = ref(true);
  const accessToken = ref("");

  const form = ref({
    password: "",
    confirmPassword: "",
  });

  const onFormSubmit = () => {
    if (form.value.password !== form.value.confirmPassword) {
      createToast("Passwords must match", {
        position: "bottom-right",
        type: "danger",
      });
    } else {
      confirmForgotPasswordMutate({
        token: accessToken.value,
        password: form.value.password,
      });
    }
  };

  const {
    isLoading: isValidateTokenLoading,
    isError: isValidateTokenError,
    error: validateTokenError,
    mutate: validateTokenMutate,
  } = useMutation((token) => authStore.getUserWithToken(token), {
    onSuccess: () => {
      isTokenValid.value = true;
    },
    onError: () => {
      isTokenValid.value = false;
    },
  });

  const {
    isLoading: isConfirmForgotPasswordLoading,
    isError: isConfirmForgotPasswordError,
    error: confirmForgotPasswordError,
    mutate: confirmForgotPasswordMutate,
  } = useMutation(({ token, password }) => authStore.confirmForgotPassword(token, password), {
    onSuccess: () => {
      authStore.clearAuth();
      createToast("Your password has been reset, you may now log in", {
        position: "bottom-right",
        type: "success",
      });
      router.push("/login");
      logEvent("Change password");
    },
    onError: () => {},
  });

  onMounted(() => {
    // <SITE_URL>#access_token=x&refresh_token=y&expires_in=z&token_type=bearer&type=recovery
    if (route.hash === "" || authStore.user) {
      router.replace("/login");
    } else {
      const [, hash] = route?.hash.split("#");
      const forgetPasswordParams = new URLSearchParams(hash);
      const token = forgetPasswordParams.get("access_token");
      const type = forgetPasswordParams.get("type");
      accessToken.value = token;

      if (type === "recovery" && token) {
        validateTokenMutate(token);
      }
    }

    logPageView(route.name);
  });
</script>
