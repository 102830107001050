<script setup>
  import { computed, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import { useHead } from "@vueuse/head";

  import Nav from "@/components/Navbar.vue";

  let route = useRoute();
  let title = ref(route.meta.title);

  watch(
    () => route.meta.title,
    async (newTitle) => {
      title.value = newTitle;
    },
  );

  useHead({
    // Can be static or computed
    title: computed(() => title.value),
    meta: [
      {
        name: `description`,
        content: computed(
          () =>
            route.meta.description || `Sequestr: Structured financial products for carbon markets`,
        ),
      },
      {
        name: "og:image",
        content: "https://i.imgur.com/Ew9MehN.png",
      },
      {
        name: "twitter:description",
        content:
          route.meta.description || `Sequestr: Structured financial products for carbon markets`,
      },
    ],
  });
</script>

<template>
  <div>
    <Nav />
    <main>
      <router-view :key="$route.fullPath" />
    </main>
  </div>
</template>

<style lang="scss">
  @import "./src/styles/app.scss";
  @import "./src/styles/toast.scss";
</style>
