<script setup>
  import { computed } from "vue";
  import { storeToRefs } from "pinia";

  import { useDemoStore } from "@/store/demoStore";

  import DoughnutChart from "@/components/Charts/DoughnutChart.vue";

  const demoStore = useDemoStore();
  const { getProjectStats } = storeToRefs(demoStore);
  const totalInvestmentAmount = getProjectStats.value.totalInvestmentAmount;
  const investmentAmountByType = getProjectStats.value.investmentAmountByType;

  const formatTonnageAmount = (value) => {
    return `${value.toLocaleString("en-US")} t`;
  };

  const formatDollars = (value) => {
    return `$${value.toLocaleString("en-US")}`;
  };

  const permanentCdr = computed(() => {
    return Object.entries(investmentAmountByType)
      .filter(([key, value]) =>
        ["Direct Air Capture", "Enhanced Weathering", "Biochar"].includes(key),
      )
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  });

  const nbsRemovals = computed(() => {
    return Object.entries(investmentAmountByType)
      .filter(([key, value]) => ["Reforestation", "Mangrove Restoration"].includes(key))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  });

  const nbsAvoided = computed(() => {
    return Object.entries(investmentAmountByType)
      .filter(
        ([key, value]) =>
          ![
            "Direct Air Capture",
            "Reforestation",
            "Biochar",
            "Enhanced Weathering",
            "Mangrove Restoration",
          ].includes(key),
      )
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  });

  // Merge the three computed values into one object and add a key called "projectType", sort by methodology key alphabetically to be used in the table
  const investmentAmountByTypeTable = computed(() => {
    const permanentCdrArray = Object.entries(permanentCdr.value).map(([key, value]) => {
      return {
        methodology: "Permanent CDR",
        projectType: key,
        outstandingLoan: formatDollars(value),
      };
    });

    const nbsRemovalsArray = Object.entries(nbsRemovals.value).map(([key, value]) => {
      return {
        methodology: "NBS Removals",
        projectType: key,
        outstandingLoan: formatDollars(value),
      };
    });

    const nbsAvoidedArray = Object.entries(nbsAvoided.value).map(([key, value]) => {
      return {
        methodology: "NBS Avoided",
        projectType: key,
        outstandingLoan: formatDollars(value),
      };
    });

    const mergedArray = [...permanentCdrArray, ...nbsRemovalsArray, ...nbsAvoidedArray];

    return mergedArray.sort((a, b) => (a.methodology > b.methodology ? 1 : -1));
  });

  // Map the investmentAmountByTypeTable to the three computed properties and their associated total outstandingLoan values to be used in the Doughnut chart with the 3 categories (Permanent CDR, NBS Removals, NBS Avoided)
  const chartData = computed(() => {
    const permanentCdrTotal = Object.values(permanentCdr.value).reduce(
      (acc, value) => acc + value,
      0,
    );
    const nbsRemovalsTotal = Object.values(nbsRemovals.value).reduce(
      (acc, value) => acc + value,
      0,
    );
    const nbsAvoidedTotal = Object.values(nbsAvoided.value).reduce((acc, value) => acc + value, 0);

    return {
      labels: ["Permanent CDR", "NBS Removals", "NBS Avoided"],
      datasets: [
        {
          backgroundColor: ["#FBBF24", "#10B981", "#3B82F6"],
          data: [permanentCdrTotal, nbsRemovalsTotal, nbsAvoidedTotal],
          cutout: "80%",
          spacing: 2,
        },
      ],
    };
  });
</script>

<template>
  <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
    <!-- Doughnut Chart -->
    <DoughnutChart
      :chartData="chartData"
      :floatingLabel="totalInvestmentAmount"
      title="Current Loans"
      format="currency"
    />
    <!-- Table Data -->
    <table class="w-full table-auto text-left">
      <thead>
        <tr class="h-8 text-sm text-gray-500">
          <th class="font-medium">Project Type</th>
          <th class="font-medium">Credit Type</th>
          <th class="text-right font-medium">Outstanding Loan</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in investmentAmountByTypeTable">
          <tr
            class="h-12 border-t border-gray-100 py-3 text-sm text-gray-900 dark:border-gray-700 dark:text-gray-400"
          >
            <td class="font-semibold">{{ row.projectType }}</td>
            <td>{{ row.methodology }}</td>
            <td class="text-right">
              {{ row.outstandingLoan }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
