<script setup>
  import { ref } from "vue";
  import { TabGroup, TabList, TabPanels, TabPanel, Tab } from "@headlessui/vue";

  import LoanApprovals from "@/components/ActionItems/LoanApprovals.vue";
  import Milestones from "@/components/ActionItems/Milestones.vue";
  import NewInvestmentNotifications from "@/components/ActionItems/NewInvestmentNotifications.vue";
  import RegulatoryAlerts from "@/components/ActionItems/RegulatoryAlerts.vue";

  const currentTab = ref(0);
  const tabs = [
    {
      name: "Loan Approvals",
    },
    {
      name: "Milestones",
    },
    {
      name: "Notifications",
    },
    {
      name: "Alerts",
    },
  ];

  const setSelectedTabIndex = (index) => {
    currentTab.value = index;
  };
</script>

<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <div class="flex flex-col gap-8 md:gap-12">
        <div class="flex flex-col justify-between gap-4 md:flex-row">
          <h1 class="text-3xl font-semibold dark:text-gray-300 md:text-4xl">Action Items</h1>
        </div>
        <div class="w-full">
          <TabGroup
            :key="`tab-${currentTab}`"
            :defaultIndex="currentTab"
            @change="(index) => setSelectedTabIndex(index)"
          >
            <TabList
              class="mb-4 flex space-x-4 overflow-x-auto whitespace-nowrap bg-transparent md:mb-8 md:whitespace-normal"
            >
              <Tab
                v-for="(tab, i) in tabs"
                as="template"
                :key="`tab-${i}`"
                v-slot="{ selected }"
              >
                <button
                  :class="[
                    'w-auto border-b-2 py-2 font-semibold',
                    'ring-transparent focus:outline-none',
                    'hover:text-emerald-500',
                    selected
                      ? 'border-gray-900 text-gray-900 dark:border-gray-300 dark:text-gray-300'
                      : 'border-transparent text-gray-400 dark:text-gray-500',
                  ]"
                >
                  {{ tab.name }}
                </button>
              </Tab>
            </TabList>

            <TabPanels class="mt-2">
              <TabPanel>
                <LoanApprovals />
              </TabPanel>
              <TabPanel>
                <Milestones />
              </TabPanel>
              <TabPanel>
                <NewInvestmentNotifications />
              </TabPanel>
              <TabPanel>
                <RegulatoryAlerts />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </div>
  </div>
</template>
