<script setup>
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";
  import { ref, toRaw, watch } from "vue";
  import { useRoute } from "vue-router";
  import { TabGroup, TabList, TabPanels, TabPanel, Tab } from "@headlessui/vue";

  import { useDemoStore } from "@/store/demoStore";

  import Button from "@/components/Button.vue";
  import Checkbox from "@/components/Form/Checkbox.vue";
  import CloseModalButton from "@/components/CloseModalButton.vue";
  import Input from "@/components/Input.vue";
  import ListItem from "@/components/ListItem.vue";
  import Logo from "@/components/Logo.vue";

  import RecommendedProjects from "@/utils/recommended_projects.json";

  const demoStore = useDemoStore();
  const route = useRoute();
  const clientId = route.params?.id;

  const emit = defineEmits(["reload-tabs"]);

  let isModalOpen = ref(false);
  let isReviewStep = ref(false);
  let submitSuccess = ref(false);
  let contractTypes = [
    {
      label: "Pay on Delivery",
      value: "pay-on-delivery",
    },
    {
      label: "Pay fixed price yearly",
      value: "yearly",
    },
    {
      label: "Pay up front",
      value: "upfront",
    },
  ];
  let projectTypes = [
    {
      label: "Nature-based Avoidance",
      value: "nbs-avoidance",
      checked: false,
    },
    {
      label: "Nature-based Removals",
      value: "nbs-removals",
      checked: false,
    },
    {
      label: "Permanent CDR",
      value: "cdr",
      checked: false,
    },
  ];
  let procurement = ref({
    interest: 0,
    contractType: contractTypes[0],
    vintages: [
      {
        label: "2023",
        value: 2023,
        checked: false,
        amount: null,
      },
      {
        label: "2024",
        value: 2024,
        checked: false,
        amount: null,
      },
      {
        label: "2025",
        value: 2025,
        checked: false,
        amount: null,
      },
      {
        label: "2026",
        value: 2026,
        checked: false,
        amount: null,
      },
      {
        label: "2027",
        value: 2027,
        checked: false,
        amount: null,
      },
      {
        label: "2028",
        value: 2028,
        checked: false,
        amount: null,
      },
      {
        label: "2029",
        value: 2029,
        checked: false,
        amount: null,
      },
      {
        label: "2030",
        value: 2030,
        checked: false,
        amount: null,
      },
    ],
    sequestrPortfolio: true,
    projectTypes: projectTypes,
    projects: RecommendedProjects,
    avgPrices: null,
    sdgs: null,
  });

  const currentTab = ref(0);
  const tabs = [
    {
      name: "Sequestr Portfolio",
    },
    {
      name: "Select Project Types",
    },
  ];

  const setSelectedTabIndex = (index) => {
    currentTab.value = index;
  };

  const onModalToggle = () => {
    demoStore.toggleProcurementModal();
    isReviewStep.value = false;
    submitSuccess.value = false;
    procurement.value = {
      interest: 0,
      contractType: contractTypes[0],
      vintages: [
        {
          label: "2023",
          value: 2023,
          checked: false,
          amount: null,
        },
        {
          label: "2024",
          value: 2024,
          checked: false,
          amount: null,
        },
        {
          label: "2025",
          value: 2025,
          checked: false,
          amount: null,
        },
        {
          label: "2026",
          value: 2026,
          checked: false,
          amount: null,
        },
        {
          label: "2027",
          value: 2027,
          checked: false,
          amount: null,
        },
        {
          label: "2028",
          value: 2028,
          checked: false,
          amount: null,
        },
        {
          label: "2029",
          value: 2029,
          checked: false,
          amount: null,
        },
        {
          label: "2030",
          value: 2030,
          checked: false,
          amount: null,
        },
      ],
      sequestrPortfolio: true,
      projectTypes: projectTypes,
      projects: RecommendedProjects,
      avgPrices: null,
      sdgs: null,
    };
  };

  const onVintageCheckboxChange = (event, vintageId) => {
    let checked = event.target.checked;
    console.info("onVintageCheckboxChange", event, vintageId, checked);
    procurement.value.vintages.forEach((vintage) => {
      if (vintage.value === vintageId) {
        vintage.checked = checked;
      }
    });
  };

  const onProjectTypesCheckboxChange = (event, projectTypeId) => {
    let checked = event.target.checked;
    console.info("onProjectTypesCheckboxChange", event, projectTypeId, checked);
    procurement.value.projectTypes.forEach((projectType) => {
      if (projectType.value === projectTypeId) {
        projectType.checked = checked;
      }
    });

    // If any procurement.value.projectTypes are checked, set procurement.sequestrPortfolio to false
    let checkedProjectTypes = procurement.value.projectTypes.filter(
      (projectType) => projectType.checked,
    );
    if (checkedProjectTypes.length > 0) {
      procurement.value.sequestrPortfolio = false;
    } else {
      procurement.value.sequestrPortfolio = true;
    }

    filterProjects();
  };

  const toggleReviewStep = () => {
    isReviewStep.value = !isReviewStep.value;
  };

  const filterProjects = () => {
    let projects = RecommendedProjects;
    const sequestrRecommended = currentTab.value === 0 ? true : false;

    console.info({ projects, sequestrRecommended });

    // Filter projects by project type if sequestr portfolio is not selected and set projects to the filtered projects
    if (!sequestrRecommended) {
      projects = projects.filter((project) => {
        return procurement.value.projectTypes.some((projectType) => {
          return (
            projectType.checked && projectType.value === project.mechanism && projectType.checked
          );
        });
      });
    }

    // Filter projects by the # of sdgs set by procurement.value.sdgs if procurement.value.sdgs is not null - this is a minimum number of sdgs not an exact number
    if (procurement.value.sdgs) {
      projects = projects.filter((project) => {
        return project.sdgs.length >= procurement.value.sdgs;
      });
    }

    // Set procurement.value.projects to the filtered projects
    procurement.value.projects = projects;

    return projects;
  };

  const calculateAveragePrice = (vintage) => {
    let avg = 50;
    let projects = procurement.value.projects;

    // Map through all the recommended projects, and find the average price for the vintage based on the credits key and investment_amount key, then set the "avg" variable the array looks like this: [ { mechanism: "cdr", sdgs: [8, 13, 15], annual_data: { 2023: { credits: Number, investment_amount: Number } } } ]
    projects.map((project) => {
      let vintageData = project.annual_data[vintage.value];
      if (vintageData) {
        avg = vintageData.investment_amount / vintageData.credits;
      } else {
        avg = 50;
      }
    });

    const avgPrice = avg?.toFixed(2);

    // Calculate revenue by multiplying the average price by the amount of credits in vintage.amount and adding the interest rate (procurement.interest). If interest rate is 0 do not add it.
    let revenue = vintage.amount * avgPrice;

    if (procurement.value.interest > 0) {
      revenue = revenue + revenue * (procurement.value.interest / 100);
    }

    return {
      avgPrice: avgPrice,
      revenue: revenue,
    };
  };

  const calculateAnnualData = () => {
    let projects = procurement.value.projects;
    let vintages = procurement.value.vintages;
    let avgPrices = procurement.value.avgPrices;

    const checkedVintages = vintages.filter((v) => v.checked && v.amount !== null);
    const vintageAmounts = checkedVintages.reduce((acc, v) => {
      acc[v.label] = v.amount;
      return acc;
    }, {});

    console.info({
      checkedVintages,
      vintageAmounts,
    });

    // Map all projects and update the annual_data object with the vintageAmounts by year (average by vintages.length), and use avgPrices to update the investment_amount key. For any non-checked vintages, set the credits to 0, and investment_amount to 0.
    projects.map((project) => {
      let annualData = project.annual_data;
      let vintageAmountsKeys = Object.keys(vintageAmounts);
      let avgPricesKeys = Object.keys(avgPrices);

      // Loop through the vintageAmountsKeys and update the annual_data object with the vintageAmounts by year (average by vintages.length)
      // If the vintage is not checked, set the credits and investment_amount to 0
      vintageAmountsKeys.forEach((key) => {
        let vintageAmount = vintageAmounts[key];
        let vintageAmountAvg = vintageAmount / projects.length;
        annualData[key].credits = vintageAmountAvg;
        if (!checkedVintages.some((v) => v.label === key)) {
          annualData[key].credits = 0;
          annualData[key].investment_amount = 0;
        } else {
          annualData[key].credits = vintageAmountAvg;
        }
      });

      // If the vintage is not checked, set the credits and investment_amount to 0
      vintages.forEach((vintage) => {
        if (!vintage.checked) {
          annualData[vintage.value].credits = 0;
          annualData[vintage.value].investment_amount = 0;
        }
      });

      // Loop through the avgPricesKeys and update the annual_data object with the avgPrices by year
      avgPricesKeys.forEach((key) => {
        let avgPrice = avgPrices[key];
        annualData[key].investment_amount = avgPrice * annualData[key].credits;
      });
    });
    console.info({ projects });
    return projects;
  };

  const onSubmitRequest = () => {
    console.info({ procurement: procurement.value });
    isReviewStep.value = true;

    const vintages = procurement.value.vintages;

    // Avg prices
    let avgPrices = {};
    vintages.forEach((vintage) => {
      console.info({ vintage });
      if (vintage.checked) {
        avgPrices[vintage.value] = calculateAveragePrice(vintage).avgPrice;
        console.info({ avgPrices });
      }
    });
    procurement.value.avgPrices = avgPrices;

    const averageCredits = calculateAnnualData();
    console.info({ averageCredits });
  };

  const onSubmitReview = async () => {
    const averageCredits = calculateAnnualData();
    console.info({ clientId: clientId, averageCredits: averageCredits });

    const rawProjects = toRaw(averageCredits);

    if (rawProjects) {
      try {
        await demoStore.setPurchasesByClient(clientId, rawProjects);
        console.log("Purchases updated successfully");
        submitSuccess.value = true;
        setTimeout(() => {
          onModalToggle();
          emit("reload-tabs");
        }, 3000);
      } catch (error) {
        console.error("Error updating purchases:", error);
        submitSuccess.value = false;
      }
    }
  };

  // Watch for demoStore.showProcurementModal
  watch(
    () => demoStore.showProcurementModal,
    (val) => {
      isModalOpen.value = val;
    },
  );
</script>

<template>
  <TransitionRoot
    appear
    :show="isModalOpen"
    as="template"
  >
    <Dialog
      as="div"
      @close="onModalToggle"
    >
      <div class="fixed inset-0 z-10 bg-black/20 backdrop-blur-sm">
        <div class="flex min-h-screen items-center justify-center px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
            >
              <div class="div flex flex-row items-center justify-between">
                <div class="flex-start mb-4 flex flex-row items-center justify-start gap-4">
                  <button
                    v-show="isReviewStep"
                    @click="toggleReviewStep()"
                    class="text-xs text-gray-500"
                  >
                    Back
                  </button>
                  <DialogTitle
                    as="h3"
                    class="text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
                  >
                    {{ isReviewStep ? "Review Procurement" : "Procure Additional Inventory" }}
                  </DialogTitle>
                </div>
                <CloseModalButton @click="onModalToggle" />
              </div>

              <div
                v-if="isReviewStep"
                class="mb-4 flex flex-col items-stretch gap-0"
              >
                <div class="flex flex-col gap-0">
                  <ListItem
                    title="Interest rate"
                    :body="`${procurement.interest}%`"
                  />
                  <ListItem
                    title="Contract type"
                    :body="procurement.contractType.label"
                  />
                  <!-- Vintage years and amounts table -->
                  <table class="mt-4 table-auto">
                    <thead>
                      <tr>
                        <th
                          class="text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Vintage
                        </th>
                        <th
                          class="text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Inventory
                        </th>
                        <th
                          class="text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Avg. cost
                        </th>
                        <th
                          class="text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Revenue
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="vintage in procurement.vintages"
                        :key="vintage.value"
                        v-show="vintage.checked"
                        class="border-b border-gray-200 last-of-type:border-0 dark:border-gray-700"
                      >
                        <td
                          class="whitespace-nowrap py-3 text-left font-semibold text-gray-900 dark:text-gray-300"
                        >
                          {{ vintage.value }}
                        </td>
                        <td class="whitespace-nowrap py-3 text-right text-gray-500">
                          {{
                            vintage.amount?.toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })
                          }}
                        </td>
                        <td class="whitespace-nowrap py-3 text-right text-gray-500">
                          {{
                            calculateAveragePrice(vintage).avgPrice.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          }}
                        </td>
                        <td class="whitespace-nowrap py-3 text-right text-emerald-500">
                          {{
                            calculateAveragePrice(vintage).revenue.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- Sequestr portfolio -->
                  <ListItem
                    v-if="procurement.sequestrPortfolio"
                    title="Project Types"
                    body="Sequestr's Recommendations"
                  />
                  <div
                    v-else
                    class="mt-4 flex flex-col gap-0"
                  >
                    <label class="block text-base font-medium text-gray-600 dark:text-gray-500">
                      Project types
                    </label>
                    <div
                      v-for="project in procurement.projectTypes"
                      v-show="project.checked"
                      class="flex flex-row items-center justify-between gap-2 border-b border-gray-200 py-3 last-of-type:border-b-0 dark:border-gray-700"
                    >
                      <div class="flex flex-1 flex-row items-center justify-start gap-2">
                        <div
                          :class="`
                          aspect-1 h-3 w-3 rounded-full bg-gradient-to-b
                          ${
                            project.value === 'nbs-avoidance'
                              ? ' from-emerald-100 to-emerald-700'
                              : project.value === 'nbs-removals'
                              ? 'from-blue-100 to-blue-700'
                              : 'from-indigo-100 to-indigo-700'
                          }
                        `"
                        ></div>
                        <span class="font-semibold text-gray-900 dark:text-gray-300">{{
                          project.label
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-items-stretch gap-4">
                <div
                  v-if="!isReviewStep"
                  class="flex max-h-[32rem] w-full flex-col gap-4 overflow-auto"
                >
                  <form
                    class="flex flex-col gap-4"
                    @submit.prevent="onSubmitRequest"
                  >
                    <Input
                      type="number"
                      name="interest-rate"
                      autocomplete="none"
                      id="interest-rate"
                      v-model="procurement.interest"
                      label="Interest rate"
                      placeholder="Enter interest rate"
                      helper="This amount in percentage will be added to the pay on delivery price. For example: 100 units which cost $50/unit with an interest rate of 5% to be paid next year will be $5,250 instead of $5,000."
                      margin="mb-0"
                      full
                    />
                    <Input
                      type="number"
                      name="sdgs"
                      autocomplete="none"
                      id="sdgs"
                      v-model="procurement.sdgs"
                      label="Minimum SDGs"
                      placeholder="Enter minimum SDGs"
                      margin="mb-0"
                      :step="1"
                      :max="17"
                      full
                    />
                    <div class="flex flex-col gap-2">
                      <label class="block text-base font-medium text-gray-600 dark:text-gray-500">
                        Select contract type
                      </label>
                      <v-select
                        v-model="procurement.contractType"
                        :options="contractTypes"
                        placeholder="Select Contract Type"
                        class="sq-v-s w-full py-0"
                        :clearable="false"
                      />
                    </div>
                    <div class="flex w-full flex-col gap-0">
                      <label class="block text-base font-medium text-gray-600 dark:text-gray-500">
                        Vintage years
                      </label>
                      <div
                        v-for="vintage in procurement.vintages"
                        class="flex flex-row items-center justify-between gap-0 border-b border-gray-100 py-2 last-of-type:border-0 dark:border-gray-700"
                      >
                        <Checkbox
                          class="items-center justify-start"
                          text-class="text-base font-normal text-gray-700 dark:text-gray-500"
                          :key="vintage.value"
                          :id="`${vintage.value}-${vintage.label}`"
                          :name="`${vintage.value}-${vintage.label}`"
                          :text="vintage.label"
                          :checked="vintage.checked"
                          @change="onVintageCheckboxChange($event, vintage.value)"
                        />
                        <Input
                          type="number"
                          :name="`amount-${vintage.value}`"
                          autocomplete="none"
                          :id="`amount-${vintage.value}`"
                          v-model="vintage.amount"
                          placeholder="Enter amount"
                          classes="min-w-[12rem]"
                          margin="mb-0"
                        />
                      </div>
                    </div>

                    <TabGroup
                      :key="`tab-${currentTab}`"
                      :defaultIndex="currentTab"
                      @change="(index) => setSelectedTabIndex(index)"
                    >
                      <TabList class="flex items-center justify-start gap-4 md:justify-between">
                        <div
                          class="flex space-x-4 overflow-x-auto whitespace-nowrap bg-transparent md:whitespace-normal"
                        >
                          <Tab
                            v-for="(tab, i) in tabs"
                            as="template"
                            :key="`tab-${i}`"
                            v-slot="{ selected }"
                          >
                            <button
                              :class="[
                                'w-auto border-b-2 py-2 font-semibold',
                                'ring-transparent focus:outline-none',
                                'hover:text-emerald-500',
                                selected
                                  ? 'border-gray-900 text-gray-900 dark:border-gray-300 dark:text-gray-300'
                                  : 'border-transparent text-gray-400 dark:text-gray-500',
                              ]"
                            >
                              {{ tab.name }}
                            </button>
                          </Tab>
                        </div>
                      </TabList>

                      <TabPanels class="mb-4">
                        <TabPanel class="flex flex-row gap-4 rounded-lg bg-indigo-50 p-4">
                          <Logo />
                          <strong class="font-medium text-indigo-500"
                            >Let Sequestr build a portfolio of projects automatically or pick your
                            own projects.</strong
                          >
                        </TabPanel>
                        <TabPanel class="flex flex-col gap-4">
                          <Checkbox
                            v-for="type in projectTypes"
                            class="items-center justify-start"
                            text-class="text-base font-normal text-gray-700 dark:text-gray-500"
                            :key="type.value"
                            :id="`${type.value}-${type.label}`"
                            :name="`${type.value}-${type.label}`"
                            :text="type.label"
                            :checked="type.checked"
                            @change="onProjectTypesCheckboxChange($event, type.value)"
                          />
                        </TabPanel>
                      </TabPanels>
                    </TabGroup>
                  </form>
                </div>
                <Button
                  v-if="!isReviewStep"
                  text="Continue to review"
                  type="button"
                  @click="onSubmitRequest"
                />
                <Button
                  v-else
                  text="Submit order"
                  type="button"
                  @click="onSubmitReview"
                  :disabled="submitSuccess"
                />
                <small
                  v-show="submitSuccess"
                  class="text-sm text-indigo-500"
                >
                  Submitted successfully, your dashboard has been updated, and you will receive an
                  email shortly with your order details and next steps.
                </small>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
