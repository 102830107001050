<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <!-- Auth blur modal -->
      <PermissionedAccessVue :permissionedModalOpen="permissionedModalOpen" />

      <!-- Header -->
      <div
        class="md:items-middle flex w-full flex-col items-start justify-start md:flex-row md:justify-between"
      >
        <h1 class="mb-4 text-4xl font-semibold dark:text-gray-300">Projects</h1>
        <router-link
          v-if="isPD"
          class="flex w-auto flex-row items-center gap-2 text-sm font-medium text-gray-400 hover:text-emerald-500 dark:text-gray-600"
          :to="`/company/${companySlug}`"
        >
          View {{ companyName }} projects
          <ArrowRightIcon
            class="h-4"
            size="24"
          />
        </router-link>
      </div>

      <!-- Scroller – hidden for now -->
      <!-- <div
        v-show="!isProjectTypesLoading"
        class="flex flex-row gap-4 overflow-x-auto overflow-y-hidden whitespace-nowrap"
      >
        <button
          v-for="(p, i) in projectTypes"
          :key="p.id"
          class="rounded-md border px-3 py-1 text-sm font-[550]"
          :class="
            p.active
              ? 'border-blue-100 bg-blue-50 text-blue-800 hover:bg-blue-100 hover:text-blue-900 dark:border-blue-900 dark:bg-blue-800 dark:text-gray-300'
              : 'border-gray-100 bg-white text-gray-800 hover:bg-gray-50 hover:text-gray-900 dark:border-gray-700 dark:bg-transparent dark:text-gray-300'
          "
          @click="onFilterProjectType(i)"
        >
          {{ p.title }}
        </button>
      </div> -->

      <div
        v-if="isUserLoading || isProjectsLoading"
        class="flex h-64 animate-pulse flex-col items-center justify-center text-center text-5xl font-bold text-gray-300"
      >
        <small class="mx-auto w-full"> Loading projects </small>
      </div>
      <div v-else>
        <!-- Projects error -->
        <div
          v-if="(!authenticated || isProjectsError) && !isUserLoading && !isProjectsLoading"
          class="flex flex-col gap-8"
        >
          <p class="w-full max-w-3xl text-gray-500">
            {{
              projectsError ||
              `Make sure your email is verified by clicking the verification link we sent to your email, and while you wait for your account to be approved do learn more about Sequestr!`
            }}
          </p>
          <div class="flex flex-col items-stretch gap-4 py-4 md:flex-row md:items-start">
            <Button
              chunky
              classes="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
              text="Check my verification status"
              to="/onboarding"
            />
            <Button
              chunky
              classes="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
              text="Login or sign up"
              to="/login"
            />
          </div>
          <h2 class="text-2xl font-bold text-gray-900 dark:text-gray-300">
            Learn more about Sequestr
          </h2>
          <div class="relative grid grid-cols-1 items-stretch gap-4 md:grid-cols-3">
            <div
              v-for="item in onboardingItems"
              class="relative flex h-auto flex-col overflow-hidden rounded-lg bg-gray-50 text-gray-900 dark:bg-gray-800 dark:text-gray-300 md:h-[28rem]"
            >
              <div class="flex w-full flex-col gap-2 p-4 md:p-8">
                <strong class="text-2xl font-semibold">
                  {{ item.title }}
                </strong>
                <p class="opacity-50c text-sm font-normal">
                  {{ item.body }}
                </p>
              </div>
              <img
                class="relative right-[-1.5rem] bottom-0 h-64 object-contain md:absolute"
                :src="item.image"
              />
            </div>
          </div>
        </div>

        <!-- Projects -->
        <div
          v-else
          class="my-6 w-full md:my-12"
        >
          <div
            v-if="!authenticated && !isUserLoading"
            class="mx-auto flex max-w-md flex-col items-center gap-4 border border-gray-100 p-12 text-center"
          >
            <strong class="text-xl font-medium text-gray-800 dark:text-gray-500">
              Please log in with an approved account to view projects
            </strong>
            <p class="text-sm text-gray-500">
              Sequestr is a managed marketplace, please log in with a Sequestr-approved account.
            </p>
            <Button
              classes="bg-transparent border-2 border-emerald-100 text-emerald-500 shadow-none text-sm w-full py-1 hover:bg-emerald-500 hover:border-emerald-500 hover:text-white dark:border-emerald-900"
              text="Log in"
              to="/login"
            />
          </div>
          <div
            v-else-if="authenticated && isVerified && !isProjectsLoading && !projects?.length"
            class="mx-auto flex max-w-md flex-col items-center gap-4 border border-gray-100 p-12 text-center"
          >
            <strong class="text-xl font-medium text-gray-800 dark:text-gray-500">
              Ah nothing here yet...
            </strong>
            <p class="text-sm text-gray-500">
              Since we're all in this together, why not add a project you're running or refer a
              friend to add theirs?
            </p>
            <Button
              classes="bg-transparent border-2 border-emerald-100 text-emerald-500 shadow-none text-sm w-full py-1 hover:bg-emerald-500 hover:border-emerald-500 hover:text-white dark:border-emerald-900"
              text="Add a project"
              to="/create/project"
            />
          </div>
          <ProjectsGrid
            v-if="authenticated && !isProjectsLoading && projects"
            :projects="projects"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from "vue";
  import { useMutation } from "vue-query";
  import { useRoute, useRouter } from "vue-router";

  import { ArrowRightIcon } from "@heroicons/vue/24/outline";

  import { useAuthStore } from "@/store/authStore";
  import { useProjectStore } from "@/store/projectStore";

  import { logEvent, logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import PermissionedAccessVue from "@/components/Modals/PermissionedAccess.vue";
  import ProjectsGrid from "@/components/ProjectsGrid.vue";

  const projectStore = useProjectStore();
  const authStore = useAuthStore();
  const router = useRouter();
  const route = useRoute();

  const companySlug = computed(() => authStore.user?.company?.slug || "");
  const companyName = computed(() => authStore.user?.company?.name || "my company");

  let authenticated = ref(false);
  let projects = ref([]);
  let permissionedModalOpen = ref(false);
  let isVerified = ref(false);
  let projectTypes = ref([]);
  let user = ref(null);
  let isPD = ref(false);

  const onboardingItems = ref([
    {
      title: "Sequestr is a managed marketplace",
      body: "Sequestr helps projects get upfront financing by selling future offsets on a transparent marketplace.",
      image: "/img/app-projects.png",
    },
    {
      title: "Project development & management",
      body: "Providing the infrastructure and tools to start creating carbon removal or sequestration projects.",
      image: "/img/app-auction-2.png",
    },
    {
      title: "Upfront financing & future offset revenue",
      body: "We work with projects to secure early financiers to run pilots, and for projects to scale.",
      image: "/img/app-financing.png",
    },
  ]);

  const {
    isLoading: isUserLoading,
    isError: isUserError,
    error: userError,
    mutate: userMutate,
  } = useMutation(
    () => {
      return authStore.getUser();
    },
    {
      onSuccess: () => {
        const authUser = authStore.user;
        user.value = authUser;
        console.info({ authUser });

        if (!authUser) {
          authenticated.value = false;
          router.push("/login");
          return;
        }

        if (
          authUser.verificationStatus === "verified" ||
          authUser.verificationStatus === "complete"
        ) {
          isVerified.value = true;
        }

        const userRole = authUser?.userRole;
        isPD.value = userRole === "project" ? true : false;
        authenticated.value = true;
        projectsMutate();
      },
    },
  );

  const {
    isLoading: isProjectTypesLoading,
    isError: isProjectTypesError,
    error: projectTypesError,
    mutate: projectTypesMutate,
  } = useMutation(
    () => {
      return projectStore.getProjectTypes();
    },
    {
      onSuccess: (data) => {
        projectTypes.value = data;
      },
    },
  );

  const {
    isLoading: isProjectsLoading,
    isError: isProjectsError,
    error: projectsError,
    mutate: projectsMutate,
  } = useMutation(
    (projectType) => {
      return projectStore.getAllProjects(projectType);
      // return [
      //   projectsListStore.getFakeProjects(),
      //   projectsListStore.getProjects(projectType ? projectType : ""),
      // ];
    },
    {
      onSuccess: (data) => {
        console.info({ data });
        // Add upcoming projects
        data.push(
          {
            soon: true,
            id: Math.random(),
            name: "Canada Biochar",
            mechanism: "removal",
            type: "Biochar",
            slug: null,
            location: {
              address_components: [
                {
                  long_name: "Saskatchewan",
                  short_name: "Saskatchewan",
                  types: ["locality", "political"],
                },
                {
                  long_name: "Canada",
                  short_name: "CA",
                  types: ["country", "political"],
                },
              ],
            },
            images: ["/img/methodologies/supported/biochar.jpg"],
            projectData: {
              story: {
                context:
                  "Project owner is committed to the exclusive provision, maintenance and upgrades of patented technologies in the bio-carbon processes.",
              },
            },
            projectStatus: "soon",
            createdAt: "2022-09-07T20:28:22.000Z",
            company: {
              id: Math.random(),
              title: "Launching soon",
              slug: null,
              imageUrl: null,
              websiteUrl: "https://sequestr.io",
            },
          },
          {
            soon: true,
            id: Math.random(),
            name: "Cameroon Cookstoves Project",
            mechanism: "avoidance",
            type: "Cookstoves",
            slug: null,
            location: {
              address_components: [
                {
                  long_name: "Bangang Bamboutos",
                  short_name: "Bangang Bamboutos",
                  types: ["locality", "political"],
                },
                {
                  long_name: "Cameroon",
                  short_name: "CM",
                  types: ["country", "political"],
                },
              ],
            },
            images: ["/img/methodologies/supported/cookstoves.jpg"],
            projectData: {
              story: {
                context:
                  "This phase of the project will be to design, manufacture, register and distribute 150,000 (one hundred fifty thousand) Cookstoves with a fuel efficiency of at least 60%. The distribution of the energy-efficient Cookstoves shall be divided into three (03) tranches of fifty thousand (50,000) Cookstoves each annually, for three consecutive years.",
              },
            },
            projectStatus: "soon",
            createdAt: "2022-09-07T20:28:22.000Z",
            company: {
              id: Math.random(),
              title: "Launching soon",
              slug: null,
              imageUrl: null,
              websiteUrl: "https://sequestr.io",
            },
          },
          // {
          //   soon: true,
          //   id: Math.random(),
          //   name: "Bhutan BCC Project",
          //   mechanism: "removal",
          //   type: "Cookstoves",
          //   slug: null,
          //   location: {
          //     address_components: [
          //       {
          //         long_name: "Thimphu",
          //         short_name: "Thimphu",
          //         types: ["locality", "political"],
          //       },
          //       {
          //         long_name: "Bhutan",
          //         short_name: "BT",
          //         types: ["country", "political"],
          //       },
          //     ],
          //   },
          //   images: ["/img/methodologies/supported/cookstoves.jpg"],
          //   projectData: {
          //     story: {
          //       context: "Clean cookstoves project in Bhutan",
          //     },
          //   },
          //   projectStatus: "soon",
          //   createdAt: "2022-09-07T20:28:22.000Z",
          //   company: {
          //     id: Math.random(),
          //     title: "Launching soon",
          //     slug: null,
          //     imageUrl: null,
          //     websiteUrl: "https://sequestr.io",
          //   },
          // },
        );
        projects.value = data;
      },
    },
  );

  onMounted(() => {
    userMutate();
    logPageView(route.name);
  });

  const onFilterProjectType = (index) => {
    if (projectTypes.value[index].active) {
      projectTypes.value[index].active = false;
      return projectsMutate();
    }

    projectTypes.value.map((p) => {
      p.active = false;
      return p;
    });
    projectTypes.value[index].active = true;
    if (projectTypes.value[index].id !== 0) {
      logEvent("Filtered projects", {
        title: projectTypes.value[index].title,
      });
      return projectsMutate(projectTypes.value[index].title);
    }
    projectsMutate();
  };
</script>
