import { defineStore } from "pinia";
import { api } from "@/utils/axios";
import { useStorage } from "@vueuse/core";

const standards = [
  { code: "goldstandard", label: "GoldStandard" },
  { code: "verra", label: "Verra VCS" },
];

export const useEligibilityStore = defineStore("eligibilityStore", {
  state: () => ({
    draftEligibilities: useStorage("draftEligibilities", []),
    eligibility: {
      title: "",
      standard: {
        code: "",
        label: "",
      },
      projectType: {
        code: null,
        label: "",
      },
      questionnaire: "",
      answers: {},
      fileUpload: {},
    },
    standards: standards,
    projectTypes: [
      {
        code: 0,
        label: "Afforestation & Reforestation (including Agroforestry)",
        standard: standards[0].code,
        questionnaire: "gsARR",
      },
      {
        code: 1,
        label: "Cookstoves & Similar Technologies",
        standard: standards[0].code,
        questionnaire: "gsICS",
      },
      {
        code: 2,
        label: "Efficient Cookstoves - Microscale",
        standard: standards[0].code,
        questionnaire: "gsICSSimplified",
      },
      {
        code: 3,
        label: "Electric Cookstoves",
        standard: standards[0].code,
        questionnaire: "gsECS",
      },
      {
        code: 4,
        label: "Inland Afforestation, Reforestation, & Revegetation (including Agroforestry)",
        standard: standards[1].code,
        questionnaire: "vcsARR",
      },
      {
        code: 5,
        label: "Cookstoves & Similar Technologies",
        standard: standards[1].code,
        questionnaire: "vcsvmr0006",
      },
      {
        code: 6,
        label: "Coastal & Seagrass Afforestation, Reforestation & Restoration",
        standard: standards[1].code,
        questionnaire: "vcsvm0033",
      },
    ],
    eligibilityModalOpen: false,
    deleteModalOpen: false,
  }),
  getters: {
    getAllDraftEligibilities() {
      return this.draftEligibilities;
    },
    draftEligibilitiesEmpty() {
      return this.draftEligibilities.length <= 0;
    },
    getDraftEligibilityById: (state) => {
      return (id) => state.draftEligibilities.find((item) => item.id === id);
    },
    deleteDraftEligibilityById: (state) => {
      return (id) => {
        const index = state.draftEligibilities.findIndex((item) => item.id === id);
        console.info({ index });
        state.draftEligibilities.splice(index, 1);
      };
    },
  },
  actions: {
    closeModal() {
      this.eligibilityModalOpen = false;
      this.deleteModalOpen = false;
    },
    toggleEligibilityModal() {
      this.eligibilityModalOpen = !this.eligibilityModalOpen;
      console.info("toggleEligibilityModal()", this.eligibilityModalOpen);
    },
    toggleDeleteModal() {
      this.deleteModalOpen = !this.deleteModalOpen;
      console.info("toggleDeleteModal()", this.deleteModalOpen);
    },
    async getEligibilityList() {
      const { data } = await api.get(`eligibility`);
      this.eligibilityList = data;
      return data;
    },
    async createEligibility() {
      console.info("createEligibility()", this.eligibility);
      const draftId = this.eligibility.draft && this.eligibility.id ? this.eligibility.id : null;
      const { data } = await api.post("eligibility", {
        title: this.eligibility.title,
        // Stringify to a JSON string for the responses object
        responses: {
          standard: this.eligibility.standard,
          projectType: this.eligibility.projectType,
          questionnaire: this.eligibility.questionnaire,
          answers: this.eligibility.answers,
        },
        ...(this.eligibility.fileUpload && {
          fileUpload: this.eligibility.fileUpload,
        }),
      });
      this.eligibility = data;
      this.lastSaved = data.updatedAt;

      // If all good, remove this eligibility from the draftEligibilities array using getters.deleteDraftEligibilityById(draftId)
      if (draftId) {
        console.info({ draftId });
        this.deleteDraftEligibilityById(draftId);
      }
      return data;
    },
    async updateEligibility(id) {
      const { data } = await api.patch(`eligibility/${id}`, {
        title: this.eligibility.title,
        // Stringify to a JSON string for the responses object
        responses: {
          standard: this.eligibility.standard,
          projectType: this.eligibility.projectType,
          questionnaire: this.eligibility.questionnaire,
          answers: this.eligibility.answers,
        },
        ...(this.eligibility.fileUpload && {
          fileUpload: this.eligibility.fileUpload,
        }),
      });
      this.eligibility = data;
      this.lastSaved = data.updatedAt;
      return data;
    },
    async getEligibility(id) {
      const { data } = await api.get(`eligibility/${id}`);
      return data;
    },
    async deleteEligibility(id, draft) {
      const { data } = await api.delete(`eligibility/${id}`);
      return data;
    },
    clear() {
      this.eligibility = null;
    },
    addDraftEligibility(eligibility) {
      console.info({ eligibility });
      // Check if the eligibility already exists in this.draftEligibilities array using eligibility.id and draft = true, if so, update it, otherwise add it
      const index = this.draftEligibilities.findIndex((item) => item.id === eligibility.id);
      console.info({ index });
      if (index === -1) {
        this.draftEligibilities.push(eligibility);
      } else {
        this.draftEligibilities[index] = eligibility;
      }
    },
    removeDraftEligibility(id) {
      // Find the matching key in the this.eligibilities array and remove it
      this.draftEligibilities = this.eligibilityList.filter((item) => item.id !== id);
    },
  },
});
