// Updated Mar 2, 2023
export const gsARR = [
  {
    id: 0,
    label:
      "What is the project site's size? The size you select should only consider land not covered by existing vegetation.",
    project_size: true,
  },
  {
    id: 1,
    label:
      "Have any activities started? This includes activities such as land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, expenditures for the project, or dissemination of technology.",
    options: ["Yes", "No"],
    qualifier: true,
    helper:
      "For Afforestation & Reforestation (A/R) projects, Gold Standard considers the start of planting as the project start date.",
  },
  {
    id: 2,
    label:
      "If the project planting has already started, is it still possible to submit for preliminary review within 1 year of the first day of planting?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.02,
      No: 0.0,
      NA: 0.0,
    },
    indent: true,
  },
  {
    id: 3,
    label:
      "If the project has already started, is there evidence of prior consideration of credit revenues?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    indent: true,
  },
  {
    id: 4,
    label: "Is the project afforestation or reforestation?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "Gold Standard recognizes single-species plantations, silvicultural systems, and agroforestry as valid afforestation and reforestation projects. Selective harvesting and rotational forestry are also allowed.",
  },
  {
    id: 5,
    label: "Is the project site (within) a wetland?",
    options: ["Yes", "No"],
    blocker: true,
    info: "Please select Verra's Coastal ARR and Seagrass methodology instead.",
  },
  {
    id: 6,
    label:
      "Will the project include the creation of a commercial-use forest, either for timber or for non-timber forest products?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.04,
    },
    info: "Non-timber forest products include such items as fruit, nuts, seeds, oils, other food products, bamboo, medicinal plants, etc.",
  },
  {
    id: 7,
    label:
      "Are the project activities in this area already mandated by any law or regulation which is systematically enforced?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.03,
    },
    info: "This is true in the case that any state law or regulation already demands the project activity occurs (e.g. a law was passed to enforce the reforestation of a specific area). For developing countries, this only applies if such laws are generally enforced.  ",
  },
  {
    id: 8,
    label:
      "Does the project include working with smallholders and implementing project activities on their land?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.02,
    },
  },
  {
    id: 9,
    label:
      "Does each smallholder own or lease no more than 3 hectares of land? Or if it is community-land, is the average land per household 3 hectares or less?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
  },
  {
    id: 10,
    label:
      "Does the project proponent (and/or the project developer, if different) have all legal rights required to use the project area to operate a carbon offset project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "In terms of legal rights, please consider: government authorizations, traditional authority authorizations, carbon rights, land title or transfer of access and usage rights, acquisition of all needed permits, and ownership of any products generated by the project. Furthermore, if the project developer is a company or NGO, it must have the legal ability to operate in the region.",
  },
  {
    id: 11,
    label:
      "Can the project proponent provide evidence of ownership over carbon rights (i.e. ownership of carbon credits generated by the project)? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.01,
      No: 0.0,
    },
  },
  {
    id: 12,
    label:
      "Can the project developer provide evidence of uncontested land title/ ownership and (if relevant) transfer of land access and use rights? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.01,
      No: 0.0,
    },
  },
  {
    id: 13,
    label:
      "Can the project developer provide evidence of legal authorization to operate the proposed project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.01,
      No: 0.0,
    },
  },
  {
    id: 14,
    label:
      "Can the project developer provide evidence of holding all the necessary permits needed for the project, including for the use of required resources such as water?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.01,
      No: 0.0,
    },
  },
  {
    id: 15,
    label:
      "Can the project developer provide evidence of rights over any timber and/or non-timber forest products produced by the project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.01,
      No: 0.0,
    },
  },
  {
    id: 16,
    label:
      "Has / will the project be able to conduct a process of Free, Prior and Informed Consent with all landowners and/or community members that will be affected by it? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "This is particularly necessary in the case of community land - all community members who access and/or use the project land will need to consent to the project.",
  },
  {
    id: 17,
    label: "Will soil disturbance due to your project affect more than 10% of the project area?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.04,
    },
    info: "Generally, if project implementation will include use of a traditional-style tractor or tiller, your soil disturbance is likely over 10%.",
  },
  {
    id: 18,
    label:
      "Do project activities take place on organic soils (peatlands or wetlands) and result in soil drainage?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.04,
    },
    info: "If project implementation includes canal digging in wetlands or peatlands, then select yes for this question.",
  },
  {
    id: 19,
    label:
      "Is the project area outside any already registered voluntary carbon market AFOLU* project site?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: -0.02,
    },
    info: "AFOLU refers to agriculture, forestry and other land uses. It incorporates ARR (afforestation, reforestation and revegetation) projects, as well as agriculture projects.",
  },

  {
    id: 20,
    label:
      "If you answered no to the above question, can the project proponent demonstrate that there will be no double-counting of emissions reductions or removals from the overlapping area of the two project sites?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.2,
    },
  },
  {
    id: 21,
    label:
      "Is it possible to prove that there are general barriers preventing this type of project activity in the project area from occurring without the support of carbon credit finance?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 22,
    label:
      "Can transparent document evidence for the existence and significance of the identified barriers be provided?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
  },
  {
    id: 23,
    label:
      "Has a full community benefit sharing program been developed with input from the local communities?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
  },
  {
    id: 24,
    label:
      "Does the organization that will operate as the project developer have a legal registration and documentation of good standing?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "The project developer can be registered anywhere, but must be allowed to operate within the project site's jurisdiction.",
  },
  {
    id: 25,
    label:
      "Is the project located in a region with a mean annual precipitation of less than 600 mm?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 26,
    label: "Is the soil pH of the planting area of the project site less than 4.0?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 27,
    label:
      "Will the planting area of the project site be planted with a minimum of 5 different native tree species in mixed stands covering at a minimum 50% of the planting area?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 28,
    label:
      "Does the most likely scenario without the project (referred to as the baseline) include significant biomass (tree and non-tree) increase? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.02,
    },
    info: "Significant here refers to an increase of more than 5% in the long-term CO2 fixation of the site's biomass.",
  },
  {
    id: 29,
    label:
      "Have you checked local, regional and national laws in order to ensure that the project will not violate any applicable regulations?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
  },
  {
    id: 30,
    label: "Are the implementation partners already identified?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "Project partners will need to be listed in the project's description at the time of submission.",
  },
  {
    id: 31,
    label:
      "Is it possible to maintain a buffer zone of 15 meters on both sides of all water bodies?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "Water bodies may be both permanent or temporary lakes, streams, rivers, neighboring wetlands, etc. Irrigation canals are excluded.\nA buffer zone means that all existing native trees are kept, no fertilizers/pesticides are used, no logging, no heavy machinery, no cropping, and if trees are planted, these need to be native species.",
  },
  {
    id: 32,
    label:
      "Can a minimum of 10% of the total Project Area be used to protect or enhance biological diversity following the High Conservation Value (HCV) approach?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "This area must be within the project area and managed by the project developer. Water body buffer zones can count towards this area. You can read about the High Conservation Value approach here: https://hcvnetwork.org/",
  },
  {
    id: 33,
    label:
      "Can you confirm that the Project Developer will implement adaptation activities appropriate to the context and need of the respective project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
  },
  {
    id: 34,
    label: "Which Sustainable Development Goals (SDGs) will the project contribute to?",
    sdgs: true,
    multiple: true,
  },
  {
    id: 35,
    label:
      "Does the project proponent have capacity and budget to conduct the local stakeholder consultation before project validation?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "The local stakeholder consultation has a few specific requirements for how it must be conducted, one of which is an in-person meeting with community members.",
  },
  {
    id: 36,
    label:
      "Does the project management team have expertise and prior experience with land management, carbon projects, and community engagement, either through the project proponent or implementation partners?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
  },
  {
    id: 37,
    label:
      "Are you planning to count the project's emissions reductions towards any other programs apart from the voluntary carbon market, such as an Emissions Trading Program? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.0,
    },
  },
  {
    id: 38,
    label:
      "Is the country of implementation potentially planning on accounting for voluntary market carbon credits in its Nationally Determined Contribution?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.02,
      No: 0.0,
      Unknown: 0.0,
    },
  },
];

// Updated Mar 2, 2023
export const gsICS = [
  {
    id: 0,
    label:
      "Have any activities started? This includes activities such as land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, expenditures for the project, or dissemination of technology.",
    options: ["Yes", "No"],
    qualifier: true,
    helper:
      "For Afforestation & Reforestation (A/R) projects, Gold Standard considers the start of planting as the project start date.",
  },
  {
    id: 1,
    label:
      "If the project has already started, was validation completed (or can/will it be completed) within 2 years of the project start date?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.0,
      No: -1.0,
      NA: 0.0,
    },
    indent: true,
  },
  {
    id: 2,
    label: "Does the project disseminate a technology related to improving thermal energy?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "Relevant technologies include stoves (improved biomass, ethanol, Liquefied Petroleum Gas, gasifier, electric), (solar) ovens, dryers, heaters, etc. Clean water projects can also use this methodology. ",
  },
  {
    id: 3,
    label:
      "Does the project area overlap with another listed, validated, or verified project area under any standard?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
  {
    id: 4,
    label:
      "Is it possible the project might displace cookstoves, or other relevant technology, from another Voluntary Carbon Market or CDM project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
  {
    id: 5,
    label:
      "Does the project undermine or conflict with any regulation or guidance on thermal energy supply or fuel supply/use in the project area/country?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.04,
    },
    info: "Regulation or guidance on thermal energy or fuel supply may revolve around a government's approach to electrification, access to clean cooking fuels, cooling and heating, etc.",
  },
  {
    id: 6,
    label:
      "Has / will the project be able to conduct a process of Free, Prior and Informed Consent with the potential technology end users?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 7,
    label:
      "Does the organization that will operate as the project developer have a legal registration and documentation of good standing?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "The project developer can be registered anywhere, but must be allowed to operate within the project site's jurisdiction.",
  },
  {
    id: 8,
    label: "Which Sustainable Development Goals (SDGs) will the project contribute to?",
    sdgs: true,
    multiple: true,
  },
  {
    id: 9,
    label: "Does the project technology have a rated thermal efficiency of at least 20%?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "The thermal efficiency rating should be provided by the technology's manufacturer. In the case of a stove, typically the results of a Water Boil Test (WBT) should be provided.",
  },
  {
    id: 10,
    label: "Is there evidence to prove this efficiency?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 11,
    label: "Does the technology have continuous, useful energy output less than 150kW per unit?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "This should be the case for units that don't have industrial strength. The exact value should also be available in the manufacturer's specifications, certification, commercial guarantee, or installer technical report.",
  },
  {
    id: 12,
    label:
      "Does the project technology require the continued use of biomass resources (e.g. wood, charcoal)? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.04,
    },
  },
  {
    id: 13,
    label:
      "Do you have access to an up-to-date fraction of non-renewable biomass (fNRB) value for the project area?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "We recommend checking if there is an up-to-date government-approved fNRB value for the project region.",
  },
  {
    id: 14,
    label:
      "Is/will there be an incentive mechanism in place to get rid of inefficient baseline technologies?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "Baseline technologies refer to those counterpart technologies that were being used by the project participants before the start of the project, e.g. their previous stoves.",
  },
  {
    id: 15,
    label:
      "Can you identify all fuels and cooking (or other relevant) technologies used by the target population?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 16,
    label:
      "Does the project proponent have capacity to regularly conduct a random baseline survey for each baseline scenario with 30 - 100 people per technology cohort, carried out through in-person interviews?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "Baseline scenarios must consider all technologies and fuels used. \nTechnology cohort refers to the distinct end user groups who received the project's technologies at different times, and thus have technologies of different ages.",
  },
  {
    id: 17,
    label:
      "Does the project proponent have capacity to regularly carry out a random sampling of Kitchen Performance Tests, which may comprise of between 30 and 300 samples?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "A Kitchen Performance Test is a specific type of test with a set of guidelines available. This is relevant only to projects based around cooking technologies.",
  },
  {
    id: 18,
    label: "Can the project proponent demonstrate a need for carbon finance? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "This may include demonstrating the unaffordability of the project technology for the target population.",
  },
  {
    id: 19,
    label:
      "Does the project technology have less than 20% adoption rate amongst the target population, according to the most recent country data (which must be no more than 3 years old)?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.04,
      No: 0.0,
      Unknown: 0.0,
    },
  },
  {
    id: 20,
    label:
      "Is the country of implementation potentially planning on accounting for voluntary market carbon credits in its Nationally Determined Contribution?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.0,
      No: 0.04,
      Unknown: 0.0,
    },
  },
  {
    id: 21,
    label:
      "If the project technology's lifespan is shorter than the crediting period, can the project proponent provide replacement or retrofitting during the crediting period?",
    options: ["Yes", "No", "N/A - Project technology's lifespan is longer than crediting period"],
    logic: {
      Yes: 0.04,
      No: 0.0,
      NA: 0.0,
    },
    info: "The default crediting period of this project type is 5 years, so this must be considered if the project technology's useful lifespan is less than 5 years.",
  },
  {
    id: 22,
    label:
      "Can the project proponent provide adequate evidence that indoor air pollution (incl. PM 2.5 & carbon monoxide) is not worsened by the project compared to baseline, and that GHGs from the project's fuel and stove combination can be estimated with adequate precision? ",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.04,
      No: 0.0,
      Unknown: 0.0,
    },
    info: "Adequate evidence of this can be demonstrated using certification from the manufacturer's test, field testing reports, lab testing reports, or results modelling the tech's operation under field conditions; or if none of those are available, independent agency reports or published literature from within the last 5 years. ",
  },
  {
    id: 23,
    label:
      "Can the project proponent collect contact information from both bulk purchasers and the project technology's end users which would allow a project auditor to easily contact and visit them.",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "Contact information includes phone numbers and GPS coordinates of where these project participants can be found.",
  },
];

// Updated Feb *, 2022
export const gsICSSimplified = [
  {
    id: 0,
    label:
      "Have any activities started? This includes activities such as land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, expenditures for the project, or dissemination of technology.",
    options: ["Yes", "No"],
    qualifier: true,
    helper:
      "For Afforestation & Reforestation (A/R) projects, Gold Standard considers the start of planting as the project start date.",
  },
  {
    id: 1,
    label:
      "If the previous question was answered as yes, were all required documents for preliminary review submitted within 1 year of the project start date, or is this still possible?",
    options: ["Yes", "No", "Project has not started"],
    logic: {
      Yes: 0.0,
      No: -1.0,
      NA: 0.0,
    },
    info: "The project start date in this case is the earliest date on which the project developer has committed to expenditures related to the implementation of the project. Once the project has started, you have one year to submit for preliminary review.",
    indent: true,
  },
  {
    id: 2,
    label:
      "Does the project disseminate a biomass stove which improves on the thermal energy usage of baseline biomass stoves?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "Biomass refers to organic material (i.e. plant or animal material). The project stoves should be wood or charcoal-based, the baseline stoves could be any biomass such as wood, charcoal, agricultural residue, manure, etc..",
  },
  {
    id: 3,
    label:
      "Does the project area overlap with another listed, validated, or verified project area under any standard?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
  {
    id: 4,
    label:
      "Is it possible the project might displace cookstoves, or other relevant technology, from another Voluntary Carbon Market or CDM project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
  {
    id: 5,
    label:
      "Does the project undermine or conflict with any regulation or guidance on thermal energy supply or fuel supply/use in the project area/country?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
    info: "Regulation or guidance on thermal energy or fuel supply may revolve around a government's approach to electrification, access to clean cooking fuels, cooling and heating, etc.",
  },
  {
    id: 6,
    label:
      "Has / will the project be able to conduct a process of Free, Prior and Informed Consent with the potential technology end users?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
  },
  {
    id: 7,
    label:
      "Does the organization that will operate as the project developer have a legal registration and documentation of good standing?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "The project developer can be registered anywhere, but must be allowed to operate within the project site's jurisdiction.",
  },
  {
    id: 8,
    label: "Which Sustainable Development Goals (SDGs) will the project contribute to?",
    sdgs: true,
    multiple: true,
  },
  {
    id: 9,
    label:
      "Does the project technology have a rated thermal efficiency of at least 20% if wood-fired or 22.5% if charcoal-fired?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "Please contact the technology manufacturer to receive documentation of efficiency specifications.",
  },
  {
    id: 10,
    label: "Is there evidence to prove this efficiency?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 11,
    label: "Does the project technology use a woody biomass fuel (either wood or charcoal)?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: -1.0,
    },
  },
  {
    id: 12,
    label:
      "Does the project activity avoid no more than 10,000 tCO2e/crediting year, total across all units? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: -1.0,
    },
    info: "This methodology is only applicable to the micro-scale. If you think the emissions reductions from your project will be higher, select another cookstove methodology type.",
  },
  {
    id: 13,
    label:
      "Is/will there be an incentive mechanism in place to get rid of inefficient baseline technologies?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "Baseline technologies refer to those counterpart technologies that were being used by the project participants before the start of the project, e.g. their previous stoves.",
  },
  {
    id: 14,
    label:
      "Can you identify all fuels and cooking (or other relevant) technologies used by the target population?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
  },
  {
    id: 15,
    label: "Is the primary baseline fuel for the project participants either wood or charcoal?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.05,
      No: -1.0,
      Unknown: -1,
    },
    info: "Primary refers to the fuel(s) that covered over 90% of thermal energy needs for the target population, prior to the project. ",
  },
  {
    id: 16,
    label:
      "Is the baseline stove a conventional cooking device without ventilation (so no grate or chimney)",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.05,
      No: -1.0,
      Unknown: -1.0,
    },
  },
  {
    id: 17,
    label: "Can the project proponent demonstrate a need for carbon finance? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "This may include demonstrating the unaffordability of the project technology for the target population.",
  },
  {
    id: 18,
    label:
      "Is the country of implementation potentially planning on accounting for voluntary market carbon credits in its Nationally Determined Contribution?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.0,
      No: 0.04,
      Unknown: 0.0,
    },
  },
  {
    id: 19,
    label:
      "If the project technology's lifespan is shorter than the crediting period, can the project proponent provide replacement or retrofitting during the crediting period?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "The default crediting period of this project type is 5 years, so this must be considered if the project technology's useful lifespan is less than 5 years.",
  },
  {
    id: 20,
    label:
      "Can the project proponent provide adequate evidence that indoor air pollution (incl. PM 2.5 & carbon monoxide) is not worsened by the project compared to baseline, and that GHGs from the project's fuel and stove combination can be estimated with adequate precision? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "Adequate evidence of this can be demonstrated using certification from the manufacturer's test, field testing reports, lab testing reports, or results modelling the tech's operation under field conditions; or if none of those are available, independent agency reports or published literature from within the last 5 years. ",
  },
  {
    id: 21,
    label:
      "Can the project proponent collect contact information from both bulk purchasers and the project technology's end users which would allow a project auditor to easily contact and visit them?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "Contact information includes phone numbers and GPS coordinates of where these project participants can be found.",
  },
];

// Updated Mar 2, 2023
export const vcsARR = [
  {
    id: 0,
    label:
      "What is the project site's size? The size you select should only consider land not covered by existing vegetation.",
    project_size: true,
  },
  {
    id: 1,
    label:
      "Have any activities started? This includes activities such as land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, expenditures for the project, or dissemination of technology.",
    options: ["Yes", "No"],
    qualifier: true,
    helper:
      "For Afforestation & Reforestation (A/R) projects, Gold Standard considers the start of planting as the project start date.",
  },
  {
    id: 2,
    label:
      "If there have been activities - Is it still possible to start the listing process within 3 years of the project start date?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.02,
      No: 0.0,
      NA: 0.0,
    },
    indent: true,
    info: "For Verra's afforestation, reforestation and revegetation projects, the start date is the first date of any activity that will generate reductions or removals. This includes land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, etc.",
  },
  {
    id: 3,
    label:
      "If there have been activities - Is it still possible to complete project validation within 8 years of the start date?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.01,
      No: 0.0,
      NA: 0.0,
    },
    indent: true,
    info: "For Verra's afforestation, reforestation and revegetation projects, the start date is the first date of any activity that will generate reductions or removals. This includes land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, etc.",
  },
  {
    id: 4,
    label: "Is the project afforestation, reforestation, or re-vegetation?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "This includes planting, sowing and/or the human-assisted natural regeneration of woody vegetation",
  },
  {
    id: 5,
    label: "Is the area a coastal wetland?",
    options: ["Yes", "No"],
    blocker: true,
    info: "If yes, please select Verra's Coastal ARR and Seagrass methodology instead.",
  },
  {
    id: 6,
    label:
      "Does the project proponent (and/or the project developer, if different) have all legal rights required to use the project area to operate a carbon offset project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "In terms of legal rights, please consider: government authorizations, traditional authority authorizations, land title or transfer of access and usage rights. Furthermore, if the project developer is a company or NGO, it must have the legal ability to operate in the region.",
  },
  {
    id: 7,
    label:
      "Can the project developer provide evidence either of land title/ ownership or transfer of land access and use rights? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 8,
    label:
      "Can the project developer provide evidence of legal authorization to operate the proposed project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
  },
  {
    id: 9,
    label:
      "Can the project proponent provide evidence of ownership over carbon rights (i.e. ownership of carbon credits generated by the project)? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
  },
  {
    id: 10,
    label: "Will soil disturbance due to your project affect more than 10% of the project area? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
    info: "Generally, if project implementation will include use of a traditional-style tractor or tiller, your soil disturbance is likely over 10%. Other activities, such as canal digging, stump removal, some infrastructure installation, etc. will also cause soil disturbance.",
  },
  {
    id: 11,
    label:
      "Do project activities take place on organic soils (peatlands or wetlands) and result in an intentional manipulation of the water table?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.03,
    },
    info: "Intentional wetland/ peatland water table manipulation generally means drainage of wetlands. If project implementation includes canal digging in wetlands or peatlands, then select yes for this question.",
  },
  {
    id: 12,
    label:
      "Do project activities include using any species other than those likely to have occurred under historic natural conditions in the project area?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.03,
    },
    info: "Select yes for this question if you are using any species that are not native/indigenous to the project's specific region.",
  },
  {
    id: 13,
    label:
      "Can the project area be clearly delineated spatially, and the area assessed using GIS technology?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "This is true if the area can be outlined in the above map (or another mapping site such as Google Maps), or if there is already a shapefile available.",
  },
  {
    id: 14,
    label:
      "Are the project activities in this area already mandated by any law or regulation which is systematically enforced?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.03,
    },
    info: "This is true in the case that any state law or regulation already demands the project activity occurs (e.g. a law was passed to enforce the reforestation of a specific area). For developing countries, this only applies if such laws are generally enforced.  ",
  },
  {
    id: 15,
    label: "Is the project area outside any already validated Verra AFOLU* project site?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "AFOLU refers to agriculture, forestry and other land uses. It incorporates ARR (afforestation, reforestation and revegetation) projects, as well as agriculture projects.",
  },
  {
    id: 16,
    label:
      "Are there any control areas (similar, nearby pieces of land without any projects or other protective measures) that your project can be compared to? (Required for using Verra's area-approach)",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "This is a condition of using Verra's area-approach to quantifying emissions reductions. For smaller plantings, the census-approach is also possible.",
  },
  {
    id: 17,
    label:
      "Is it possible to prove that there are general barriers preventing this type of project activity in the project area from occurring without the support of carbon credit finance?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
  },
  {
    id: 18,
    label:
      "Can transparent document evidence for the existence and significance of the identified barriers be provided?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 19,
    label:
      "Is it possible to clearly define and identify individual project plants (e.g. trees, bamboo clumps, shrubs) with a unique ID and recorded GPS locations? (Required for using Verra's census-approach)",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "This is a condition of using Verra's census-approach to quantifying emissions reductions. For larger plantings, the area-approach is more applicable.",
  },
  {
    id: 20,
    label:
      "Does the project activity result in a change in land use? (Required for using Verra's census-approach)",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "This is also a condition of using Verra's census-approach. For instance, planting trees in a state forest is applicable as land use stays the same.",
  },
  {
    id: 21,
    label:
      "Can a complete census of all project plants (trees or other) be maintained? (Required for using Verra's census-approach)",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "This is also a condition of using Verra's census-approach. This condition requires maintaining a database of each project plant.",
  },
  {
    id: 22,
    label:
      "Does the project include removing pre-existing woody biomass (e.g., trees or shrubs) to provide space for the project plantings?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.03,
    },
  },
  {
    id: 23,
    label:
      "Have you checked local, regional and national laws in order to ensure that the project will not violate any applicable regulations?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 24,
    label: "Are the implementation partners already identified?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "Project partners will need to be listed in the project's description at the time of submission.",
  },
  {
    id: 25,
    label:
      "Has / will the project be able to conduct a process of Free, Prior and Informed Consent with all landowners and/or community members that will be affected by it? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "This is particularly necessary in the case of community land - all community members who access and/or use the project land will need to consent to the project.",
  },
  {
    id: 26,
    label: "Which Sustainable Development Goals (SDGs) will the project contribute to?",
    sdgs: true,
    multiple: true,
  },
  {
    id: 27,
    label:
      "Does the project proponent have capacity and budget to conduct the local stakeholder consultation before project validation?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "The local stakeholder consultation has a few specific requirements for how it must be conducted, one of which is an in-person meeting with community members.",
  },
  {
    id: 28,
    label:
      "Does the project management team have expertise and prior experience with land management, carbon projects, and community engagement, either through the project proponent or implementation partners?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 29,
    label:
      "Are you planning to count the project's emissions reductions towards any other programs apart from the voluntary carbon market, such as an Emissions Trading Program? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 30,
    label:
      "Is the country of implementation potentially planning on accounting for voluntary market carbon credits in its Nationally Determined Contribution?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
];

// Updated Mar 2, 2023
export const vcsvm0033 = [
  {
    id: 0,
    label:
      "What is the project site's size? The size you select should only consider land not covered by existing vegetation.",
    project_size: true,
  },
  {
    id: 1,
    label:
      "Have any activities started? This includes activities such as land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, expenditures for the project, or dissemination of technology.",
    options: ["Yes", "No"],
    qualifier: true,
    helper:
      "For Afforestation & Reforestation (A/R) projects, Gold Standard considers the start of planting as the project start date.",
  },
  {
    id: 2,
    label:
      "If there have been activities - Is it still possible to start the listing process within 3 years of the project start date?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      NA: 0.0,
    },
    info: "For Verra's afforestation, reforestation and revegetation projects, the start date is the first date of any activity that will generate reductions or removals. This includes land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, etc.",
    indent: true,
  },
  {
    id: 3,
    label:
      "If there have been activities - Is it still possible to complete project validation within 8 years of the start date?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      NA: 0.0,
    },
    info: "For Verra's afforestation, reforestation and revegetation projects, the start date is the first date of any activity that will generate reductions or removals. This includes land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, etc.",
    indent: true,
  },
  {
    id: 4,
    label:
      "Is the project taking place in a tidal wetland system (i.e., tidal forest such as mangrove, tidal marsh or seagrass meadow)?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "This methodology is specifically for tidal wetland projects. If no: Please select a different ARR methodology.",
  },
  {
    id: 5,
    label: "Will the project activities include any, or combinations, of the following:",
    options: [
      { id: 0, label: "A) Creating, restoring and/or managing hydrological conditions" },
      { id: 1, label: "B) Altering sediment supply" },
      { id: 2, label: "C) Changing salinity characteristics" },
      { id: 3, label: "D) Improving water quality" },
      { id: 4, label: "E) (Re-)introducing native plant communities" },
      { id: 5, label: "F) Improving management practice(s)" },
    ],
    logic: {
      0: 0.01,
      1: 0.01,
      2: 0.01,
      3: 0.01,
      4: 0.01,
      5: 0.01,
    },
    multiple: true,
    info: "Activity A may include removing tidal barriers, improving hydrological connectivity, restoring tidal flow to wetlands and/or lowering water levels on impounded wetlands. Activity B may include the beneficial use of dredge material or diverting river sediments to sediment-starved areas. Activity C may include restoring tidal flow to tidally restricted areas.  Activity D may include reducing nutrient loads leading to improved water clarity to expand seagrass meadows, recovering tidal and other hydrologic flushing and exchange, or reducing nutrient residence time. Activity E may include re-seeding or replanting. Activity F may include removing invasive species or reduced grazing.",
  },
  {
    id: 6,
    label:
      "At the time of the project start date, was/is the project site free of any land use that could be displaced because either: \ni) The site had been abandoned for the two or more years prior to project start, or \nii) Commercial purposes on the site were not profitable and there was no timber harvesting occurring.",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      Unknown: 0.0,
    },
  },
  {
    id: 7,
    label:
      "At the time of the project start date, was/is the project site under a land use (e.g. aquaculture or agriculture) that could be displaced, but degradation of other wetlands will not occur as this is impossible or is prohibited by an enforced law?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      Unknown: 0.0,
    },
  },
  {
    id: 8,
    label:
      "At the time of the project start date, was/is the project site under a land use that will continue at a similar level of service or production during the project crediting period?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      Unknown: 0.0,
    },
    info: "Some land use activities that may fit this option include reed or hay harvesting, collection of fuelwood, and/or subsistence harvesting.",
  },
  {
    id: 9,
    label:
      "Can you provide document evidence to support the project site's starting land use scenario (from the above questions)? ",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      Unknown: 0.0,
    },
    info: "Possible document evidence includes laws and bylaws, management plans, annual reports, annual accounts, market studies, government studies or land use planning reports and documents.",
  },
  {
    id: 10,
    label:
      "Does the project developer intend to claim emissions reductions from reduced frequency of peat fires?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
  {
    id: 11,
    label:
      "If considering peat fire reduction credits, will the project activities include a combination of rewetting and fire management?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      NA: 0.0,
    },
  },
  {
    id: 12,
    label:
      "If considering peat fire reduction credits, has there been a demonstratable human-caused threat of frequent fires around the project site? For example, through peat drainage or arson. ",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      NA: 0.0,
    },
  },
  {
    id: 13,
    label:
      "Will the project combine ARR* activities with rewetting for any areas with organic soil.",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "ARR stands for afforestation, reforestation and revegetation. Wetlands typically comprise of mostly organic soils (histosols), which may be peat and/or muck.",
  },
  {
    id: 14,
    label:
      "Is the project developer able to define the geographic boundaries of the project area and geo-reference this area? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "Georeferencing is the conversion of the area mapping to a common spatial reference system (e.g. the creation of a shapefile).",
  },
  {
    id: 15,
    label:
      "Will each discrete area of land within the project site have a unique geographical identification?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "The project may contain more than one discrete area of land, but these must be geographically identifiable.",
  },
  {
    id: 16,
    label:
      "Does the project developer have all of the details regarding who holds land ownership and usage rights for the project site?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 17,
    label:
      "Are there ground-truth observations available to complement the land use/land cover mapping of the project area? Or does the project partner have the capacity to carry out ground-truthing?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 18,
    label:
      "Does the project developer have the capacity to ensure project strata are discernible and defined based on good practices and accuracy requirements?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "This data will need to be indicated and justified in the project description.",
  },
  {
    id: 19,
    label: "Does the project involve planting or restoring seagrass meadows?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 20,
    label:
      "Will the project developer have the capacity to monitor the restored seagrass meadows and demonstrate where natural expansion has resulted from the project?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      NA: 0.0,
    },
    info: "Aerial observations and direct field observations are both acceptable.",
  },
  {
    id: 21,
    label:
      "Will the project developer have the capacity to delineate the project meadow(s) from existing / non-project meadow(s)?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      NA: 0.0,
    },
    info: "Aerial observations and direct field observations are both acceptable.",
  },
  {
    id: 22,
    label:
      "Does the project developer have capacity to conduct a time series of vegetation composition for baseline analysis?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "These can be based off of scientific literature or expert judgement considering the vegetation succession schemes of the baseline conditions. ",
  },
  {
    id: 23,
    label:
      "Does the project developer have the capacity to map crown or vegetation cover using established methods in scientific literature, for the project scenario/monitoring?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 24,
    label: "Please check all of the following situations that apply to the project / project site:",
    options: [
      {
        id: 0,
        label:
          "Project activities qualify as IFM (Improved Forest Management) or REDD (Reducing Emissions from Deforestation and forest Degradation)",
      },
      { id: 1, label: "Baseline activities include commercial forestry" },
      {
        id: 2,
        label:
          "Project activities lower the water table (*unless the project converts open water to tidal wetlands, or improves the hydrological connection to impounded waters)",
      },
      {
        id: 3,
        label:
          "Hydrological connectivity of the project area with adjacent areas leads to a significant increase in GHG emissions outside the project area",
      },
      { id: 4, label: "Project activities include the burning of organic soil" },
      {
        id: 5,
        label:
          "Nitrogen fertilizer(s), such as chemical fertilizer or manure, will be applied in the project area during the project crediting period",
      },
    ],
    logic: {
      0: 0.01,
      1: 0.01,
      2: 0.01,
      3: 0.01,
      4: 0.01,
      5: 0.01,
    },
    multiple: true,
  },
  {
    id: 25,
    label:
      "Does the project proponent (and/or the project developer, if different) have all legal rights required to use the project area to operate a carbon offset project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "In terms of legal rights, please consider: government authorizations, traditional authority authorizations, land title or transfer of access and usage rights. Furthermore, if the project developer is a company or NGO, it must have the legal ability to operate in the region.",
  },
  {
    id: 26,
    label:
      "Can the project developer provide evidence either of land title/ ownership or transfer of land access and use rights? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 27,
    label:
      "Can the project developer provide evidence of legal authorization to operate the proposed project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 28,
    label:
      "Can the project proponent provide evidence of ownership over carbon rights (i.e. ownership of carbon credits generated by the project)? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 29,
    label:
      "Are the project activities in this area already mandated by any law or regulation which is systematically enforced?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.03,
    },
    info: "This is true in the case that any state law or regulation already demands the project activity occurs (e.g. a law was passed to enforce the reforestation of a specific area). For developing countries, this only applies if such laws are generally enforced.",
  },
  {
    id: 30,
    label: "Is the project area outside any already validated Verra AFOLU* project site?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "AFOLU refers to agriculture, forestry and other land uses. It incorporates ARR (afforestation, reforestation and revegetation) projects, as well as agriculture projects.",
  },
  {
    id: 31,
    label:
      "Is it possible to prove that there are general barriers preventing this type of project activity in the project area from occurring without the support of carbon credit finance?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 32,
    label:
      "Can transparent document evidence for the existence and significance of the identified barriers be provided?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 33,
    label:
      "Have you checked local, regional and national laws in order to ensure that the project will not violate any applicable regulations?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 34,
    label: "Are the implementation partners already identified?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "Project partners will need to be listed in the project's description at the time of submission.",
  },
  {
    id: 35,
    label:
      "Has / will the project be able to conduct a process of Free, Prior and Informed Consent with all landowners and/or community members that will be affected by it? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "This is particularly necessary in the case of community land - all community members who access and/or use the project land will need to consent to the project.",
  },
  {
    id: 36,
    label: "Which Sustainable Development Goals (SDGs) will the project contribute to?",
    sdgs: true,
    multiple: true,
  },
  {
    id: 37,
    label:
      "Does the project proponent have capacity and budget to conduct the local stakeholder consultation before project validation?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "The local stakeholder consultation has a few specific requirements for how it must be conducted, one of which is an in-person meeting with community members.",
  },
  {
    id: 38,
    label:
      "Does the project management team have expertise and prior experience with land management, carbon projects, and community engagement, either through the project proponent or implementation partners?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 39,
    label:
      "Are you planning to count the project's emissions reductions towards any other programs apart from the voluntary carbon market, such as an Emissions Trading Program? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
  },
  {
    id: 40,
    label:
      "Is the country of implementation potentially planning on accounting for voluntary market carbon credits in its Nationally Determined Contribution?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      Unknown: 0.0,
    },
  },
];

// Updated Mar 2, 2023
export const vcsvmr0006 = [
  {
    id: 0,
    label:
      "Have any activities started? This includes activities such as land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, expenditures for the project, or dissemination of technology.",
    options: ["Yes", "No"],
    qualifier: true,
    helper:
      "For Afforestation & Reforestation (A/R) projects, Gold Standard considers the start of planting as the project start date.",
  },
  {
    id: 1,
    label:
      "If the project has already started, was validation completed (or can/will it be completed) within 2 years of the project start date?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    indent: true,
  },
  {
    id: 2,
    label:
      "Does the project disseminate new, efficient thermal energy generation units (for example, biomass stoves, ovens, or dryers) to reduce the baseline use of non-renewable biomass or fossil fuels (such as coal or kerosene)?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "Biomass refers to organic material (i.e. plant or animal material), and typically wood, charcoal, or a processed bio-fuel would be used.",
  },
  {
    id: 3,
    label:
      "Does the project country or region have an emissions trading program or any other mechanism that includes GHG allowance trading?",
    options: ["Yes", "No", "Unsure"],
    logic: {
      Yes: 0.0,
      No: 0.05,
      Unsure: 0.0,
    },
  },
  {
    id: 4,
    label:
      "Are you planning to count the project's emissions reductions towards any other programs apart from the voluntary carbon market, such as an Emissions Trading Program? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
  },
  {
    id: 5,
    label:
      "Is the country of implementation potentially planning on accounting for voluntary market carbon credits in its Nationally Determined Contribution?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.03,
      No: 0.0,
      Unknown: 0.0,
    },
  },
  {
    id: 6,
    label:
      "Have you checked local, regional and national laws in order to ensure that the project will not violate any applicable regulations?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 7,
    label: "Are the implementation partners already identified?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "Project partners will need to be listed in the project's description at the time of submission.",
  },
  {
    id: 8,
    label:
      "Has / will the project be able to conduct a process of Free, Prior and Informed Consent with all landowners and/or community members that will be affected by it? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "This is particularly necessary in the case of community land - all community members who access and/or use the project land will need to consent to the project.",
  },
  {
    id: 9,
    label:
      "Has the project developer demonstrated legal right to control and operate the project (or program activities)?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 10,
    label: "Which Sustainable Development Goals (SDGs) will the project contribute to?",
    sdgs: true,
    multiple: true,
  },
  {
    id: 11,
    label:
      "Does the project proponent have capacity and budget to conduct the local stakeholder consultation before project validation?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "The local stakeholder consultation has a few specific requirements for how it must be conducted, one of which is an in-person meeting with community members.",
  },
  {
    id: 12,
    label: "Does the project technology have a rated thermal efficiency of at least 25%?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "The thermal efficiency rating should be provided by the technology's manufacturer. In the case of a stove, typically the results of a Water Boil Test (WBT) should be provided.",
  },
  {
    id: 13,
    label: "Is there evidence to prove this efficiency?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.04,
    },
    info: "The thermal efficiency rating should be provided by the technology's manufacturer. In the case of a stove, typically the results of a Water Boil Test (WBT) should be provided.",
  },
  {
    id: 14,
    label: "Is the project unit a stove or another type of technology?",
    options: ["Cookstove", "Other"],
    logic: {
      Cookstove: 0.04,
      Other: 0.0,
    },
  },
  {
    id: 15,
    label: "If the project unit is a stove, does it use only woody biomass?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.04,
      No: 0.0,
      NA: 0.0,
    },
  },
  {
    id: 16,
    label: "If the project unit is a stove, does it replace fossil fuel stoves?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: -0.02,
      No: 0.0,
      NA: 0.0,
    },
  },
  {
    id: 17,
    label:
      "If the project unit is a biomass stove that replaces fossil fuel stoves, can the (new) biomass source used for project stoves be demonstrated as renewable?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.01,
      No: 0.0,
      Unknown: 0.0,
    },
    info: "In the case that the biomass source is an ecosystem (forest, cropland, etc.), renewable means that the total carbon stocks do not systematically decrease over time. Otherwise, a renewable fuel can also come from the non-fossil fraction of industrial or municipal waste.",
  },
  {
    id: 18,
    label:
      "Will the project unit use biomass residues as its fuel (e.g. briquettes, wood chips, agricultural by-products)? ",
    options: ["Yes", "No"],
    logic: {
      Yes: -0.02,
      No: 0.0,
    },
  },
  {
    id: 19,
    label:
      "If the project does use biomass residue as fuel, can it be demonstrated that the biomass residue comes from exclusively renewable sources?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.01,
      No: 0.0,
      Unknown: 0.0,
    },
    info: "In order to be renewable, the collection/use of this biomass resiude for the project does not decrease carbon pools from the land it's take from, particularly dead wood, litter or soil organic carbon.",
  },
  {
    id: 20,
    label:
      "Does the project developer have the capacity to carry out Water Boiling Tests for both the baseline stove and a sample of each batch and type of project stove? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
  },
  {
    id: 21,
    label:
      "Can it be demonstrated that non-renewable biomass has been used in project region since 31 December 1989?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "This can be demonstrated using survey methods, or by referring to published literature, official reports or statistics. It is not required if the project stove is replacing a fossil fuel baseline stove.",
  },
  {
    id: 22,
    label:
      "Are the project activities in this area already mandated by any law or regulation which is systematically enforced?",
    options: ["Yes", "No"],
    logic: {
      Yes: -1,
      No: 0.0,
    },
    info: "This is true in the case that any state law or regulation already demands the project activity occurs (e.g. a law was passed to enforce the reforestation of a specific area). For developing countries, this only applies if such laws are generally enforced.",
  },
  {
    id: 23,
    label: "Is the project part of a government scheme or have the support of a multilateral fund?",
    options: ["Yes", "No"],
    logic: {
      Yes: -1,
      No: 0.0,
    },
  },
  {
    id: 24,
    label: "Does the project distribute stoves at zero cost to the end user?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
  },
  {
    id: 25,
    label:
      "Does the project have any other revenue sources aside from the selling of carbon credits?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
  {
    id: 26,
    label:
      "Can it be demonstrated that the project activity is either 1) not the most economically or financially attractive option or 2) not economically or financially feasible and thus would not occur due to a general barrier?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.0,
    },
    info: "Relevant barriers include issues around items such as investment, technology, prevailing practice, regulation or policy, institutions, available information, management, organizational capacity, and financial resourcing.",
  },
  {
    id: 27,
    label:
      "If the project technology's lifespan is shorter than the crediting period, can the project proponent provide replacement or retrofitting during the crediting period?",
    options: ["Yes", "No", "N/A - Project technology's lifespan is longer than crediting period"],
    logic: {
      Yes: 0.04,
      No: 0.0,
      NA: 0.0,
    },
    info: "The default crediting period of this project type is either 7 years (renewable twice) or ten years (fixed).",
  },
  {
    id: 28,
    label:
      "Can the project developer monitor project units, including monitoring fuel consumption if the project units use biomass residues as fuel?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: null,
  },
  {
    id: 29,
    label:
      "Do/will the stoves have unique identifiers and does the project developer have the capacity to collect and store end-user contacts and addresses?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "The end-user is the person, household, or institution using the project technology.",
  },
];

// Updated Mar 2, 2023
export const gsECS = [
  {
    id: 0,
    label:
      "Have any activities started? This includes activities such as land preparation, planting, rewetting, a change in agriculture or forestry practices, land protection practices, expenditures for the project, or dissemination of technology.",
    options: ["Yes", "No"],
    qualifier: true,
    helper:
      "For Afforestation & Reforestation (A/R) projects, Gold Standard considers the start of planting as the project start date.",
  },
  {
    id: 1,
    label:
      "If the previous question was answered as yes, were all required documents for preliminary review submitted within 1 year of the project start date, or is this still possible?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.0,
      No: -1.0,
      NA: 0.0,
    },
    info: "The project start date in this case is the earliest date on which the project developer has committed to expenditures related to the implementation of the project. Once the project has started, you have one year to submit for preliminary review.",
    indent: true,
  },
  {
    id: 2,
    label:
      "Does the project disseminate a technology that reduces GHG emissions and quantifies these reductions through direct measurement of energy or fuel consumed?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "Potential applicable technologies include electric and induction cookstoves, electric pressure cookers, LPG (liquefied petroleum gas) cookstoves, biogas stoves and bio-ethanol cookstoves. \nTechnologies can be distributed to households, communities, and/or institutions such as schools.",
  },
  {
    id: 3,
    label: "Please select the technology closest to your project's technology",
    options: [
      {
        id: 0,
        label: "Electric or induction cookstove",
        checked: false,
      },
      {
        id: 1,
        label: "Electric pressure cooker",
        checked: false,
      },
      {
        id: 2,
        label: "LPG cookstove",
        checked: false,
      },
      {
        id: 3,
        label: "Biogas stove",
        checked: false,
      },
      {
        id: 4,
        label: "Bio-ethanol cookstove",
        checked: false,
      },
    ],
    logic: {
      0: 0.06,
      1: 0.06,
      2: 0.06,
      3: 0.06,
      4: 0.06,
    },
    multiple: true,
  },
  {
    id: 4,
    label:
      "For fuel-based technologies, does the project developer have the capacity to ensure that cooking fuel amounts are recorded for each end-user?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.02,
      No: 0.0,
      NA: 0.0,
    },
    info: "End-user refers to the household or institution using the project's technology.",
  },
  {
    id: 5,
    label:
      "For fuel-based technologies, does the project developer have the capacity to put in place arrangements to ensure the recorded fuel is used only for cooking and not for other uses?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.02,
      No: 0.0,
      NA: 0.0,
    },
  },
  {
    id: 6,
    label:
      "Does the project area overlap with another listed, validated, or verified project area under any standard?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
  {
    id: 7,
    label:
      "Is it possible the project might displace cookstoves, or other relevant technology, from another Voluntary Carbon Market or CDM project?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.0,
      No: 0.05,
    },
  },
  {
    id: 8,
    label:
      "Does the project undermine or conflict with any regulation or guidance on thermal energy supply or fuel supply/use in the project area/country?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.0,
      No: 0.04,
      Unknown: 0.04,
    },
    info: "Regulation or guidance on thermal energy or fuel supply may revolve around a government's approach to electrification, access to clean cooking fuels, cooling and heating, etc.",
  },
  {
    id: 9,
    label:
      "Has / will the project be able to conduct a process of Free, Prior and Informed Consent with the potential technology end users?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 10,
    label:
      "Does the organization that will operate as the project developer have a legal registration and documentation of good standing?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "The project developer can be registered anywhere, but must be allowed to operate within the project site's jurisdiction.",
  },
  {
    id: 11,
    label: "Which Sustainable Development Goals (SDGs) will the project contribute to?",
    sdgs: true,
    multiple: true,
  },
  {
    id: 12,
    label: "Does the project technology have a rated thermal efficiency of at least 40%?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "Please contact the technology manufacturer to receive documentation of efficiency specifications.",
  },
  {
    id: 13,
    label: "Is there evidence to prove this efficiency?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.01,
      No: 0.0,
    },
  },
  {
    id: 14,
    label:
      "Does the project technology have predictable performance in that it is proven to be efficient and durable under field conditions?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
  },
  {
    id: 15,
    label:
      "Can the project developer put in place monitoring systems for the fuel or energy consumption of all project devices? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.02,
      No: 0.0,
    },
    info: "The monitoring systems should include a database, maintained by the project developer, of all fuel/energy consumption records.",
  },
  {
    id: 16,
    label: "Does the technology have continuous, useful energy output less than 150kW per unit?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "This should be the case for units that don't have industrial strength. The exact value should also be available in the manufacturer's specifications, certification, commercial guarantee, or installer technical report.",
  },
  {
    id: 17,
    label:
      "Do you have access to an up-to-date fraction of non-renewable biomass (fNRB) value for the project area?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.03,
      No: 0.0,
    },
    info: "We recommend checking if there is an up-to-date government-approved fNRB value for the project region.",
  },
  {
    id: 18,
    label:
      "For bio-ethanol cookstove projects, are the bio-ethanol cookstoves designed, constructed and operated to the requirements of a relevant national or local standard, or comparable literature?",
    options: ["Yes", "No", "N/A"],
    logic: {
      Yes: 0.02,
      No: 0.0,
      NA: 0.0,
    },
    info: "Relevant standards will include safety standards, among others.",
  },
  {
    id: 19,
    label:
      "Is/will there be an incentive mechanism in place to get rid of inefficient baseline technologies?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "Baseline technologies refer to those counterpart technologies that were being used by the project participants before the start of the project, e.g. their previous stoves.",
  },
  {
    id: 20,
    label:
      "Can you identify all fuels and cooking (or other relevant) technologies used by the target population?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
  },
  {
    id: 21,
    label:
      "Does the project proponent have capacity to regularly conduct a random baseline survey for each baseline scenario with 30 - 100 people per technology cohort, carried out through in-person interviews?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "Baseline scenarios must consider all technologies and fuels used.\nTechnology cohort refers to the distinct end user groups who received the project's technologies at different times, and thus have technologies of different ages.",
  },
  {
    id: 22,
    label:
      "Does the project proponent have capacity to regularly carry out a random sampling of Kitchen Performance Tests, which may comprise of between 30 and 300 samples?",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.04,
      No: 0.0,
    },
    info: "A Kitchen Performance Test is a specific type of test with a set of guidelines available. This is relevant only to projects based around cooking technologies.",
  },
  {
    id: 23,
    label: "Can the project proponent demonstrate a need for carbon finance? ",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "This may include demonstrating the unaffordability of the project technology for the target population.",
  },
  {
    id: 24,
    label:
      "Does the project technology have less than 20% adoption rate amongst the target population, according to the most recent country data (which must be no more than 3 years old)?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.04,
      No: 0.0,
      Unknown: 0.0,
    },
  },
  {
    id: 25,
    label:
      "Is the country of implementation potentially planning on accounting for voluntary market carbon credits in its Nationally Determined Contribution?",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.0,
      No: 0.04,
      Unknown: 0.0,
    },
  },
  {
    id: 26,
    label:
      "If the project technology's lifespan is shorter than the crediting period, can the project proponent provide replacement or retrofitting during the crediting period?",
    options: ["Yes", "No", "N/A - Project technology's lifespan is longer than crediting period"],
    logic: {
      Yes: 0.04,
      No: 0.0,
      NA: 0.0,
    },
    info: "The default crediting period of this project type is 5 years, so this must be considered if the project technology's useful lifespan is less than 5 years.",
  },
  {
    id: 27,
    label:
      "Can the project proponent provide adequate evidence that indoor air pollution (incl. PM 2.5 & carbon monoxide) is not worsened by the project compared to baseline, and that GHGs from the project's fuel and stove combination can be estimated with adequate precision? ",
    options: ["Yes", "No", "Unknown"],
    logic: {
      Yes: 0.05,
      No: 0.0,
      Unknown: 0.0,
    },
    info: "Adequate evidence of this can be demonstrated using certification from the manufacturer's test, field testing reports, lab testing reports, or results modelling the tech's operation under field conditions; or if none of those are available, independent agency reports or published literature from within the last 5 years. ",
  },
  {
    id: 28,
    label:
      "Can the project proponent collect contact information from both bulk purchasers and the project technology's end users which would allow a project auditor to easily contact and visit them.",
    options: ["Yes", "No"],
    logic: {
      Yes: 0.05,
      No: 0.0,
    },
    info: "Contact information includes phone numbers and GPS coordinates of where these project participants can be found.",
  },
];
