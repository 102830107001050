<script setup>
  import { ref } from "vue";

  const props = defineProps({
    imageUrl: String,
    title: String | Number,
    size: {
      type: String,
      default: "normal",
    },
  });

  const { imageUrl, title, size } = props;

  let initials = ref(null);

  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  if (title) {
    let inits = [...title.matchAll(rgx)] || [];
    initials.value = ((inits.shift()?.[1] || "") + (inits.pop()?.[1] || "")).toUpperCase();
  }
</script>

<template>
  <div
    :class="[
      'flex items-center justify-center overflow-hidden rounded-full bg-gray-50 align-middle text-xs font-medium text-gray-500 dark:bg-gray-800 dark:text-gray-500',
      size === 'large' ? 'h-12 w-12' : size === 'small' ? 'h-6 w-6' : 'h-8 w-8',
    ]"
  >
    <img
      v-if="imageUrl"
      class="h-full w-full object-cover"
      :src="imageUrl"
      :alt="title"
    />
    <span v-else>{{ initials ? initials : "N/A" }}</span>
  </div>
</template>
