<script setup>
  const props = defineProps({
    title: String | Number,
    body: String | Number,
  });
  let { title, body } = props;
</script>

<template>
  <div class="flex flex-col gap-0 py-3">
    <strong class="font-semibold text-gray-900 dark:text-gray-300"> {{ title }} </strong>
    <p>{{ body }}</p>
  </div>
</template>
