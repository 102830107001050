<script setup lang="ts">
  import { ref } from "vue";

  const props = defineProps({
    type: String,
  });

  let { type } = props;
  type.toLowerCase();

  let icon = ref(null);

  switch (type) {
    case "google":
      icon = "/icons/google.svg";
      break;
    default:
      icon = "/logo/logo-black.svg";
      break;
  }
</script>

<template>
  <img :src="icon" />
</template>
