<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <h1 class="mb-4 text-4xl font-semibold dark:text-gray-300">Properties</h1>
      <!-- Properties list -->
      <div class="my-6 w-full md:my-12">
        <div
          v-if="loading"
          class="flex h-64 animate-pulse flex-col items-center justify-center text-center text-5xl font-bold text-gray-300"
        >
          <small class="mx-auto w-full"> Loading properties </small>
        </div>
        <div
          v-if="!loading && !properties.length"
          class="mx-auto flex max-w-md flex-col items-center gap-4 border border-gray-100 p-12 text-center"
        >
          <strong class="text-xl font-medium text-gray-800 dark:text-gray-500">
            Ah nothing here yet...
          </strong>
          <p class="text-sm text-gray-500">
            Since we're all in this together, why not add a property you own or
            refer a friend to add theirs?
          </p>
          <Button
            classes="bg-transparent border-2 border-emerald-100 text-emerald-500 shadow-none text-sm w-full py-1"
            text="Add a property"
            to="/create/property"
          />
        </div>
        <PropertyList
          v-else
          :properties="properties"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute } from "vue-router";

  import { logPageView } from "@/services/analytics";

  import Button from "@/components/Button.vue";
  import PropertyList from "@/components/PropertyList.vue";

  export default {
    components: {
      Button,
      PropertyList,
    },
    setup() {
      const route = useRoute();
      const store = useStore();

      let properties = ref([]);
      let loading = ref(true);

      logPageView(route.name);

      store.watch(
        (state) => state.status,
        (state) => {
          if (state === "error" || state === "success") {
            return (loading.value = false);
          }
          loading.value = true;
        },
      );

      const getProperties = (methodology) => {
        const methodologies = [methodology];
        store
          .dispatch(
            "getProperties",
            !methodologies.includes(undefined) ? methodologies : null,
          )
          .then((response) => {
            console.info({ response });
            properties.value = response;
          });
      };

      getProperties();

      return {
        loading,
        properties,
      };
    },
  };
</script>
