<script setup>
  import { computed, reactive, ref, nextTick, onMounted, toRefs, watch } from "vue";

  import DoughnutChart from "@/components/Charts/DoughnutChart.vue";

  const props = defineProps({
    stats: {
      type: Object,
    },
    inventory: Array | Object,
    selectedYear: String | Number,
    totalInventory: Array | Object,
  });

  let { stats, inventory, selectedYear, totalInventory } = toRefs(props);
  console.info({
    stats: stats.value,
    inventory: inventory.value,
    selectedYear: selectedYear.value,
    totalInventory: totalInventory.value,
  });

  let investmentAmountByType = {
    credits: 0,
    investmentAmount: 0,
  };
  let currentYearTotalEmissions = ref(0);
  let doughnutChartRef = ref(null);

  let chartData = ref({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        cutout: "80%",
        spacing: 2,
      },
    ],
  });

  const calculateInventoryStats = (selectedYear) => {
    const inventoryStats = {};

    if (!inventory.value) return;

    inventory.value.forEach((project) => {
      const { type, annual_data } = project;

      if (!inventoryStats[type]) {
        inventoryStats[type] = {
          credits: 0,
          investmentAmount: 0,
        };
      }

      if (selectedYear) {
        const annualData = annual_data[selectedYear];
        inventoryStats[type].credits += annualData.credits;
        inventoryStats[type].investmentAmount += annualData.investment_amount;
      } else {
        Object.entries(annual_data).forEach(([year, data]) => {
          inventoryStats[type].credits += data.credits;
          inventoryStats[type].investmentAmount += data.investment_amount;
        });
      }
    });

    // Update currentYearTotalEmissions.value to sum up all the credits regardless of type
    currentYearTotalEmissions.value = Object.values(inventoryStats).reduce(
      (acc, { credits }) => acc + credits,
      0,
    );

    return inventoryStats;
  };

  // Update currentYearTotalEmissions when selectedYear changes
  watch(
    () => selectedYear.value,
    (newVal, oldVal) => {
      console.info("selectedYear changed", { newVal, oldVal });
      investmentAmountByType = calculateInventoryStats(newVal === "All Years" ? null : newVal);
      console.info({ investmentAmountByType: investmentAmountByType });

      if (!investmentAmountByType) return;

      // Return and update chartData to match investmentAmountByType using vue-chartjs
      chartData.value = {
        labels: Object.keys(investmentAmountByType),
        datasets: [
          {
            data: Object.values(investmentAmountByType).map((item) => item.credits),
            backgroundColor: [
              "#64748b",
              "#71717a",
              "#fbbf24",
              "#f59e0b",
              "#84cc16",
              "#14b8a6",
              "#3b82f6",
              "#8b5cf6",
            ],
            cutout: "80%",
            gap: 2,
          },
        ],
      };
    },
    { immediate: true },
  );
</script>

<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <!-- Doughnut Chart -->
      <div class="flex flex-col gap-4 md:gap-6">
        <DoughnutChart
          :chartData="chartData"
          :floatingLabel="currentYearTotalEmissions"
          title="Total Inventory"
          format="tonnage"
          propRef="doughnutChartRef"
          ref="doughnutChartRef"
        />
      </div>
      <!-- Table Data -->
      <table class="w-full table-auto text-left">
        <thead>
          <tr class="h-8 text-sm text-gray-500">
            <th class="font-medium">Project Type</th>
            <th class="font-medium">Inventory</th>
            <th class="text-right font-medium">Dollar Amount</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(row, key) in investmentAmountByType"
            :key="key"
          >
            <tr
              class="h-12 border-t border-gray-100 py-3 text-sm text-gray-900 dark:border-gray-700 dark:text-gray-400"
            >
              <!-- Show investmentAmountByType key -->
              <td class="font-semibold">{{ key }}</td>
              <td>
                {{
                  row.credits.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td class="text-right">
                {{
                  row.investmentAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
