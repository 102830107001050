<template>
  <TransitionRoot
    appear
    :show="permissionedModalOpen"
    as="template"
  >
    <Dialog as="div">
      <div class="fixed inset-0 z-50 overflow-y-auto bg-black/20 backdrop-blur-sm">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
            >
              <DialogTitle
                as="h3"
                class="mb-4 text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
              >
                {{ title }}
              </DialogTitle>
              <div class="mb-6">
                <p class="mb-4 text-sm text-gray-500">
                  {{ body }}
                </p>
              </div>

              <div
                v-if="authenticated"
                class="flex flex-col gap-4 md:flex-row"
              >
                <Button
                  square
                  text="View approval status"
                  type="button"
                  to="/onboarding"
                />
              </div>
              <div
                class="flex flex-col gap-4 md:flex-row"
                v-else
              >
                <Button
                  square
                  text="Create an account"
                  type="button"
                  @click="onToggleSignup"
                />
                <Button
                  classes="
                      bg-transparent text-gray-800 dark:text-gray-500
                      dark:border-gray-800
                      hover:bg-gray-50 hover:text-gray-800
                      shadow-none
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-gray-800
                    "
                  text="I have an account"
                  type="button"
                  @click="onToggleLogin"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import { ref, watch } from "vue";
  import { useRouter } from "vue-router";

  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";

  import Button from "@/components/Button.vue";

  import { logEvent } from "@/services/analytics";

  export default {
    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionRoot,
      TransitionChild,
      Button,
    },
    props: {
      authenticated: {
        default: false,
        type: Boolean,
      },
      permissionedModalOpen: {
        default: false,
        type: Boolean,
      },
      title: {
        default: "Create an account or log in to view projects",
        type: String || Number,
      },
      body: {
        default:
          "Sequestr is a managed marketplace for carbon offsets, and to ensure high-quality supply matches with high-quality buyers, you must sign in to view projects.",
        type: String || Number,
      },
    },
    setup(props) {
      const router = useRouter();

      let permissionedModalOpen = ref(props.permissionedModalOpen);

      watch(
        () => props.permissionedModalOpen,
        () => {
          console.info(`props: ${props.permissionedModalOpen}`);
          permissionedModalOpen.value = props.permissionedModalOpen;
        },
      );

      const onTogglePermissionedModal = (value) => {
        permissionedModalOpen.value = value;
      };

      const onToggleSignup = () => {
        logEvent("Permissioned modal: Signup continue");
        router.push("/signup");
      };
      const onToggleLogin = () => {
        logEvent("Permissioned modal: Login continue");
        router.push("/login");
      };

      return {
        permissionedModalOpen,
        onTogglePermissionedModal,
        onToggleSignup,
        onToggleLogin,
      };
    },
  };
</script>
