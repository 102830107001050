<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-3xl px-4 py-12">
      <h1 class="mb-8 text-4xl font-semibold dark:text-gray-300">
        {{
          editType === "project"
            ? "Edit project"
            : editType === "property"
            ? "Edit property"
            : "Edit profile"
        }}
      </h1>
      <!-- Edit form -->
      <div>
        <EditProject v-if="editType === 'project'" />
        <EditProperty v-if="editType === 'property'" />
        <EditProfile v-else />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { useRouter, useRoute } from "vue-router";

  import { logEvent, logPageView } from "@/services/analytics";

  import EditProject from "@/components/Edit/Project.vue";
  import EditProperty from "@/components/Edit/Property.vue";
  import EditProfile from "@/components/Edit/Profile.vue";

  export default {
    components: {
      EditProject,
      EditProperty,
      EditProfile,
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      logPageView(route.name);

      let editType = ref("property");
      let editId = ref(null);

      watch(
        () => route.name,
        () => {
          editType.value = route?.meta.type;
          editId.value = route?.params.id;
        },
        { immediate: true },
      );

      console.info({ editType, editId });

      logEvent("Edit", {
        type: editType.value,
      });

      // Send to home page if type is not defined
      if (!editType) {
        return router.push("/");
      }

      if ((editType === "property" || editType === "project") && !editId) {
        return router.push("/");
      }

      const isLoggedIn = computed(() => {
        return store.getters.isLoggedIn;
      });

      const forceLogout = () => {
        console.info("Force logout");
        store.dispatch("clearAll").then(() => {
          router.push("/login");
        });
        logEvent("Force logout");
      };

      // Force logout if `isLoggedIn` is not set
      if (!isLoggedIn.value) {
        return forceLogout();
      }

      store.dispatch("me").catch((err) => {
        console.info("profile error");
        if (err.response.status === 403) {
          forceLogout();
        }
      });

      return {
        editType,
      };
    },
  };
</script>
