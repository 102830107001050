<script setup>
  import { ChevronRightIcon } from "@heroicons/vue/24/outline";
  import ClientHeader from "./ClientHeader.vue";

  const props = defineProps({
    client: {
      name: {
        type: String,
        required: true,
      },
      id: String,
      brand_icon: {
        type: String,
        default: "/favicons/apple-touch-icon.png",
      },
      carbon_credits: {
        type: Object,
        default: {},
      },
      carbon_projects_purchased: {
        type: Array,
        default: [],
      },
      organization_type: String,
      progress: Object,
      region: String,
      sector: String,
      target: {
        type: String,
        default: "No target set",
      },
      target_classification: {
        type: String,
        default: "No target set",
      },
    },
  });

  const { client } = props;
  const clientPurchases = client.carbon_projects_purchased || [];
  // const carbonProjectsPurchased = clientPurchases.reduce((acc, curr) => {
  //   const { type, carbonCreditsGenerated, investmentAmount } = curr;
  //   const carbonCreditsGeneratedValue = carbonCreditsGenerated ? carbonCreditsGenerated : 0;
  //   const investmentAmountValue = investmentAmount ? investmentAmount : 0;
  //   const total = carbonCreditsGeneratedValue + investmentAmountValue;
  //   if (acc[type]) {
  //     acc[type] += total;
  //   } else {
  //     acc[type] = total;
  //   }
  //   return acc;
  // }, {});

  const clientInvestments = clientPurchases.reduce((acc, curr) => {
    const { type, investment_amount } = curr;
    const investmentAmountValue = investment_amount ? investment_amount : 0;
    if (acc[type]) {
      acc[type] += investmentAmountValue;
    } else {
      acc[type] = investmentAmountValue;
    }
    return acc;
  }, {});

  // Add up the total clientInvestments regardless of type
  const totalInvestments = Object.values(clientInvestments).reduce((acc, curr) => {
    return acc + curr;
  }, 0);
</script>

<template>
  <router-link
    :to="`/asset-managers/client/${client.id}`"
    class="flex flex-col gap-0 rounded-xl border border-gray-100 bg-gray-50 text-gray-900 hover:bg-gray-50/50 hover:shadow-xl dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700 md:rounded-2xl"
  >
    <!-- Top meta info -->
    <div
      class="dark:border-b-700 flex flex-col items-start justify-start gap-4 border-b border-b-gray-100 p-4 md:flex-row md:items-center md:justify-between md:p-6"
    >
      <ClientHeader
        :logo="client.brand_icon"
        :name="client.name"
      />
      <div class="flex flex-row gap-2">
        <strong
          v-if="totalInvestments"
          class="font-medium text-indigo-500"
        >
          Current carbon loans:
          {{
            totalInvestments.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            })
          }}
        </strong>
        <strong
          v-else
          class="font-medium text-gray-400 dark:text-gray-500"
        >
          No loans
        </strong>
        <ChevronRightIcon class="aspect-1 w-4" />
      </div>
    </div>
    <!-- Bottom boxes -->
    <div class="grid grid-cols-1 gap-0 text-gray-900 dark:text-gray-300 md:grid-cols-4">
      <div class="flex flex-col gap-2 p-4 md:p-6">
        <small class="text-gray-500"> Emissions this Year </small>
        <strong
          v-if="client.emissions && client.emissions.current_year?.total"
          class="text-lg font-semibold"
        >
          {{ client.emissions.current_year.total.toLocaleString("en-US") }}
          t
        </strong>
        <strong
          v-else
          class="font-medium opacity-50"
        >
          &mdash;
        </strong>
      </div>
      <div
        class="dark:border-700 flex flex-col gap-2 border-t border-gray-100 p-4 md:border-t-0 md:border-l md:p-6"
      >
        <small class="text-gray-500"> Future Inventory Required </small>
        <strong
          v-if="client.carbon_credits && client.carbon_credits?.committed_future_offsets"
          class="text-lg font-semibold"
        >
          {{ client.carbon_credits.committed_future_offsets.toLocaleString("en-US") }}
          t
        </strong>
        <strong
          v-else
          class="font-medium opacity-50"
        >
          &mdash;
        </strong>
      </div>
      <div
        class="dark:border-700 flex flex-col gap-2 border-l-0 border-t border-gray-100 p-4 md:border-t-0 md:border-l md:p-6"
      >
        <small class="text-gray-500"> ESG Rating </small>
        <strong
          v-if="client.financial_data && client.financial_data?.esg_rating"
          class="text-lg font-semibold"
        >
          {{ client.financial_data.esg_rating }}
        </strong>
        <strong
          v-else
          class="font-medium opacity-50"
        >
          &mdash;
        </strong>
      </div>
      <div
        class="dark:border-700 flex flex-col gap-2 border-t border-gray-100 p-4 md:border-t-0 md:border-l md:p-6"
      >
        <small class="text-gray-500"> Inventory Retired </small>
        <strong
          v-if="client.carbon_credits && client.carbon_credits?.retired"
          class="text-lg font-semibold"
        >
          {{ client.carbon_credits.retired.toLocaleString("en-US") }}
          t
        </strong>
        <strong
          v-else
          class="font-medium opacity-50"
        >
          &mdash;
        </strong>
      </div>
    </div>
  </router-link>
</template>
