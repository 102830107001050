<template>
  <TransitionRoot
    appear
    :show="verifyEmailOpen"
    as="template"
  >
    <Dialog as="div">
      <div class="fixed inset-0 z-30 overflow-y-auto bg-black/20 backdrop-blur-sm">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
            >
              <DialogTitle
                as="h3"
                class="mb-4 text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
              >
                Verify your email
              </DialogTitle>
              <div class="mb-6">
                <p class="mb-4 text-sm text-gray-500">
                  A verification email has been sent to {{ props.email }}. Please click the link in
                  the email to verify your email.
                </p>
              </div>

              <div class="flex flex-row gap-4">
                <Button
                  text="OK, got it"
                  type="button"
                  @click="onCloseModalClick"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";
  import { useRouter } from "vue-router";

  import { useAuthStore } from "@/store/authStore";

  import Button from "@/components/Button.vue";

  const authStore = useAuthStore();
  const router = useRouter();

  const props = defineProps({
    verifyEmailOpen: Boolean,
    email: String,
  });

  const onCloseModalClick = () => {
    authStore.toggleVerifyEmailModal();
    router.push("/verify-email");
  };
</script>
