<script setup>
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
  import { Doughnut } from "vue-chartjs";
  import { onMounted, ref, toRefs, watch } from "vue";

  const props = defineProps({
    chartData: {
      type: Object,
      required: true,
    },
    title: String,
    floatingLabel: [String, Number],
    format: {
      type: String,
      default: "currency",
    },
    propRef: String | Object,
  });

  let { chartData, title, floatingLabel, format, propRef } = toRefs(props);
  console.info({
    chartData: chartData.value,
    title: title.value,
    floatingLabel: floatingLabel.value,
    format: format.value,
    propRef: propRef.value,
  });

  let chartLabel = ref(null);

  function formatChartLabels() {
    if (format.value === "currency") {
      chartLabel.value = floatingLabel.value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      chartLabel.value = `${floatingLabel.value.toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
      })} t`;
    }
  }

  // Watch for chartData and floatingLabel prop changes
  watch(
    [chartData, floatingLabel],
    ([chartData, floatingLabel]) => {
      console.info("chartData", chartData);
      console.info("floatingLabel", floatingLabel);
      formatChartLabels();
    },
    { immediate: true, deep: true },
  );

  ChartJS.register(ArcElement, Tooltip, Legend);

  onMounted(() => {
    formatChartLabels();
  });
</script>

<template>
  <div
    v-if="chartData && chartData.labels.length"
    class="relative h-96"
  >
    <div class="absolute z-[1] flex h-full w-full flex-col items-center justify-center gap-0 pb-9">
      <strong class="text-lg text-gray-900 dark:text-gray-300">
        {{ chartLabel }}
      </strong>
      <small class="text-xs text-gray-500"> {{ title }} </small>
    </div>
    <Doughnut
      :ref="propRef"
      :data="chartData"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return (
                data.labels[tooltipItem.index] + ': ' + data.datasets[0].data[tooltipItem.index]
              );
            },
          },
        },
      }"
    />
  </div>
  <div
    v-else
    class="flex items-center justify-center rounded-lg border-gray-100 bg-gray-50 px-4 py-2 text-center text-gray-500 dark:border-gray-700 dark:bg-gray-800"
  >
    No data provided
  </div>
</template>
