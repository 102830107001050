<template>
  <div
    class="flex flex-row justify-between gap-4 border-b border-gray-100 py-2 align-middle text-base font-normal last-of-type:border-b-0 dark:border-gray-800"
  >
    <div class="flex flex-col gap-0">
      <strong class="font-semibold text-gray-900 dark:text-gray-400">{{ title }}</strong>
      <small
        v-if="helper"
        class="text-gray-500"
      >
        {{ helper }}
      </small>
    </div>
    <div class="flex flex-row justify-end gap-4 text-right text-sm md:text-inherit">
      <p
        v-if="amount"
        class="text-gray-500"
      >
        Amount: {{ amount.toLocaleString() }}
      </p>
      <p
        v-if="price"
        class="text-gray-500"
      >
        {{
          price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}
      </p>
      <p
        v-if="createdAt"
        class="text-gray-500"
      >
        {{ moment(createdAt).format("LL") }}
      </p>
    </div>
  </div>
</template>

<script setup>
  import moment from "moment";

  const props = defineProps({
    title: {
      required: true,
      type: String,
    },
    helper: String,
    amount: Number,
    price: Number,
    createdAt: Date | Number,
  });

  const { title, helper, amount, price, createdAt } = props;
</script>
