<template>
  <div class="flex flex-col gap-4">
    <h1 class="text-xl font-semibold text-gray-900 dark:text-gray-300">Alerts</h1>
    <div
      class="flex w-full flex-col items-stretch justify-start gap-2 md:flex-row md:items-center md:justify-between"
    >
      <div class="flex w-full flex-grow flex-col">
        <h2 class="text-lg font-bold text-gray-900 dark:text-gray-300">Regulatory update</h2>
        <p class="text-sm text-gray-500">
          A new regulation has been proposed that may impact your carbon offset investments. We
          recommend reviewing the details and adjusting your investment strategy if necessary. Click
          here to learn more.
        </p>
      </div>
      <button
        class="box-shadow-lg rounded-lg border border-gray-100 bg-transparent px-4 py-2 font-semibold text-gray-900 hover:border-emerald-600 hover:bg-emerald-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50 dark:border-gray-700 dark:text-gray-300"
      >
        View
      </button>
    </div>
  </div>
</template>
