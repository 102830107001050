<template>
  <div
    class="flex flex-row items-center justify-between gap-4 border-b border-gray-100 py-2 align-middle text-base font-normal last-of-type:border-b-0 dark:border-gray-800"
  >
    <div class="flex flex-1 flex-row items-start justify-start gap-4">
      <!-- Icon -->
      <div
        v-if="iconLeft"
        class="flex h-10 w-10 items-center justify-center rounded-full border border-gray-100 p-2 font-bold dark:border-gray-800"
      >
        <component
          class="h-full w-full"
          :is="setIconLeft"
        />
      </div>
      <!-- Text -->
      <div class="flex flex-1 flex-col gap-0">
        <strong class="text-md font-semibold text-black dark:text-white">{{ title }}</strong>
        <small
          v-if="helper"
          class="text-gray-900 dark:text-gray-400"
        >
          {{ helper }}
        </small>
      </div>
    </div>
    <p
      class="max-w-xs text-right text-sm text-gray-900 dark:text-gray-400 md:max-w-none md:text-inherit"
      v-if="detail"
    >
      {{ detailFormatted }}
    </p>
  </div>
</template>

<script setup>
  import { computed, ref } from "vue";
  import * as solid from "@heroicons/vue/24/solid";
  import * as outline from "@heroicons/vue/24/outline";

  const props = defineProps({
    title: {
      required: true,
    },
    helper: String | Number,
    detail: String | Number,
    iconLeft: String,
    iconOutline: {
      default: true,
      type: Boolean,
    },
  });

  const { title, helper, detail, iconLeft, iconOutline } = props;

  let detailFormatted = ref(null);

  if (typeof detail === "number") {
    detailFormatted.value = detail.toLocaleString();
  } else {
    detailFormatted.value = detail;
  }

  const icons = { solid, outline };
  const setIconLeft = computed(() => {
    return icons[iconOutline ? "outline" : "solid"][`${iconLeft}`] || null;
  });
</script>
