import axios from "axios";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 5000,
  withCredentials: false,
});

api.interceptors.request.use(
  (config) => {
    if (config.url.includes(["auth/login"])) {
      return config;
    }

    const token = cookies.get("session");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 403) {
      refreshToken();
    }

    if (Array.isArray(error?.response?.data?.message)) {
      return Promise.reject(error.response.data.message[0]);
    }

    return Promise.reject(error?.response?.data?.message);
  },
);

const refreshToken = () => {
  // TODO: implement refresh token
};
