<template>
  <div class="container mx-auto">
    <div class="mx-auto max-w-6xl px-4 py-12">
      <Loader v-if="isLoading" />
      <span
        v-if="isError"
        class="mt-10 block text-sm text-red-400"
        >Error fetching your projects: {{ error }}</span
      >
      <div
        class="mt-5 mb-20 space-y-5"
        v-if="projectsStore.hasSelfProjects && !isLoading"
      >
        <div v-for="project in projectsStore.projectsSelf">
          <router-link :to="`/project/${project.slug}`">
            <span class="block text-lg font-semibold dark:text-gray-300"
              >{{ project.name }} ({{ project.slug }})</span
            >
          </router-link>

          <span class="block text-sm dark:text-gray-300">{{
            project.projectStatus
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useProjectStore } from "@/store/projectStore";
  import { useMutation } from "vue-query";
  import Loader from "@/components/Loader.vue";
  import { onMounted, ref } from "vue";

  const projectsStore = useProjectStore();

  const { isLoading, isError, error, mutate } = useMutation(() =>
    projectsStore.getAllProjectsSelf(),
  );

  onMounted(() => {
    mutate();
  });
</script>
