<script setup>
  import { computed, onMounted, ref } from "vue";
  import { useMutation } from "vue-query";
  import { useRoute, useRouter } from "vue-router";
  import { ArrowLeftIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";
  import { TabGroup, TabList, TabPanels, TabPanel, Tab } from "@headlessui/vue";
  import ShortUniqueId from "short-unique-id";
  import { Country, State } from "country-state-city";

  import {
    MapboxMap,
    MapboxGeocoderControl,
    MapboxDrawControl,
    MapboxGeogeometryPolygon,
    MapboxMarker,
  } from "vue-mapbox-ts";

  import {
    gsARR,
    gsICS,
    gsICSSimplified,
    gsECS,
    vcsARR,
    vcsvm0033,
    vcsvmr0006,
  } from "@/services/eligibility-questions";
  import { projectSize, sdgList } from "@/services/index";
  import { logEvent, logPageView } from "@/services/analytics";

  import { useEligibilityStore } from "@/store/eligibilityStore";

  import Button from "@/components/Button.vue";
  import Checkbox from "@/components/Form/Checkbox.vue";
  import Divider from "@/components/Divider.vue";
  import Input from "@/components/Input.vue";
  import EligibilityInfoModal from "@/components/Modals/EligibilityInfo.vue";
  import EligibilityStateModal from "@/components/Modals/EligibilityState.vue";

  const route = useRoute();
  const router = useRouter();
  const eligibilityStore = useEligibilityStore();
  const mapboxAccessKey = import.meta.env.VITE_MAPBOX_KEY;

  let launchingSoon = ref(false);

  let userInputs = ref({
    title: null,
    standard: null,
    projectType: null,
    answers: [{ country: null }, { state: null }, { site_accuracy: null }],
    createdAt: null,
    fileUpload: null,
  });
  let projectTypesByStandard = ref([]);
  let questions = ref([]);
  let eligibilityUID = ref(null);
  let allQuestionsAnswered = ref(false);

  // Check to see if router has query with an ID, if not, we need to generate an ID and replace the state of router
  let routerQuery = ref(router.currentRoute.value.query?.e || null);
  let savedReport = ref(false);
  let isSaveReportLoading = ref(false);

  let infoModalState = ref(false);
  let infoModalTitle = ref(null);
  let infoModalBody = ref(null);

  let geometry = ref([]);
  let geojsonPolygons = ref([]);
  let geojsonMarkers = ref([]);

  let selectedTab = ref(0);
  const tabs = ["Draw on map", "Country & region", "Shape file"];

  const standards = eligibilityStore.standards;
  const projectTypes = eligibilityStore.projectTypes;

  // Methods
  const onStandardChange = (value) => {
    console.info("Selected standard:", value);
    const { code } = value;
    if (!code) return;

    projectTypesByStandard.value = projectTypes.filter(
      (projectType) => projectType.standard === code,
    );
  };
  const fetchQuestions = {
    gsARR: () => (questions.value = gsARR),
    gsICS: () => (questions.value = gsICS),
    gsICSSimplified: () => (questions.value = gsICSSimplified),
    gsECS: () => (questions.value = gsECS),
    vcsARR: () => (questions.value = vcsARR),
    vcsvm0033: () => (questions.value = vcsvm0033),
    vcsvmr0006: () => (questions.value = vcsvmr0006),
    default: () => (questions.value = []),
  };
  const onProjectTypeChange = (value) => {
    console.info("Selected project type:", value);

    const { questionnaire } = value;
    console.info({ questionnaire });
    questions.value = [];
    userInputs.value.answers = [];

    if (!questionnaire) return false;
    if (questionnaire === "default") {
      console.info("default questionnaire");
      launchingSoon.value = true;
      return;
    }

    questions.value = [];
    userInputs.value.answers = questions.value.reduce((acc, question) => {
      acc[question.id] = null;
      return acc;
    }, {});

    // Get the corresponding questionnaire from the questions.value array
    // Instead of a switch statement, we can use object literal notation
    fetchQuestions[questionnaire]();

    // Check if questions.value array has an object with the key "project_size", if so set the key "options" inside the found object with the values from projectSize using the label key
    const projectSizeQuestion = questions.value.find(
      (question) => (question.project_size && question.project_size) === true,
    );
    console.info({ projectSizeQuestion });
    if (projectSizeQuestion) {
      // Reduce the array from projectSize and just return an array with the value from key "label"
      projectSizeQuestion.options = projectSize.reduce((acc, size) => {
        acc.push(size.label);
        return acc;
      }, []);
    }
    console.info({ questions: questions.value });
  };
  const goBack = () => {
    router.push("/eligibility");
    return;
  };

  const {
    isLoading: isCreateReportLoading,
    isError: isCreateReportError,
    error: createReportError,
    mutate: createReportMutate,
  } = useMutation(() => eligibilityStore.createEligibility(), {
    onSuccess: (data) => {
      console.info({ data });
      eligibilityStore.eligibilityModalOpen = true;
    },
    onError: (error) => {
      console.error({ error });
      eligibilityStore.eligibilityModalOpen = false;
    },
  });

  const onCreateReportSubmit = () => {
    console.info("onCreateReportSubmit", userInputs.value);

    // If userInputs.value.fileUpload is an array we need to convert it to a file
    if (Array.isArray(userInputs.value.fileUpload)) {
      console.info("Converting fileUpload to file");
      // Convert userInputs.value.fileUpload to an object
      userInputs.value.fileUpload = { ...userInputs.value.fileUpload };
    }
    eligibilityStore.eligibility = userInputs.value;

    console.info({ eligibilityStoreEligibility: eligibilityStore.eligibility });

    if (!eligibilityStore.eligibility) return;

    logEvent("Submit eligibility report");
    createReportMutate();
  };

  const onDeleteReport = () => {
    eligibilityStore.toggleDeleteModal();
  };

  const onDeleteReportConfirm = () => {
    console.info("onDeleteReport", userInputs.value);
    if (!userInputs.value.id) return;
    eligibilityStore.deleteDraftEligibilityById(userInputs.value.id);

    logEvent("Delete draft eligibility report");
    router.push("/eligibility");
  };

  const onSaveReport = () => {
    console.info("onSaveReport", userInputs.value, eligibilityUID.value);
    isSaveReportLoading.value = true;

    // If no eligibilityUID.value, generate an id and replace the state of router with the query.e = shortId
    if (!eligibilityUID.value) {
      console.info("No router query, generating shortId...");
      const uid = new ShortUniqueId({ length: 6 });
      const eid = uid();
      const shortId = eid;
      console.info({ shortId, currentRoute: router.currentRoute.value });

      // Replace the state of vue router with the query with key "e" being the shortId
      router.push({
        path: "/eligibility/create",
        query: {
          e: shortId,
        },
        replace: false,
      });
      eligibilityUID.value = shortId;
    }

    // If userInputs.value.fileUpload is a file we need to store it as JSON
    if (userInputs.value.fileUpload instanceof File) {
      const reader = new FileReader();
      reader.readAsText(userInputs.value.fileUpload);
      reader.onload = (e) => {
        const geojson = JSON.parse(e.target.result);
        userInputs.value.fileUpload = geojson;
        console.info({ geojson, userInputs: userInputs.value });
      };
      reader.onerror = (error) => {
        console.error({ error });
      };
    }

    // Push the storageObj to the localStorage key "eligibilityList" via eligibilityStore.addDraftEligibility
    const draftReport = {
      createdAt: userInputs.value.createdAt || new Date(),
      updatedAt: new Date(),
      title: userInputs.value.title || "Untitled",
      standard: userInputs.value.standard,
      projectType: userInputs.value.projectType,
      answers: userInputs.value.answers,
      fileUpload: userInputs.value.fileUpload || null,
      mapbox: (geometry.value && true) || false,
      draft: true,
      id: eligibilityUID.value,
    };
    console.info({ draftReport });
    eligibilityStore.addDraftEligibility(draftReport);

    const newList = eligibilityStore.getAllDraftEligibilities;
    console.info({ newList });

    setTimeout(() => {
      savedReport.value = true;
      isSaveReportLoading.value = false;
    }, 250);
    return;
  };

  onMounted(() => {
    logPageView(route.name);
    console.info("query from router:", routerQuery.value);

    if (routerQuery.value) {
      // Check eligibilityStore draft for the draft if idLength is 6 or draft is true
      eligibilityUID.value = routerQuery.value;
      const draftEligibility = eligibilityStore.getDraftEligibilityById(routerQuery.value);
      console.info({ draftEligibility });
      userInputs.value = { ...draftEligibility };
      savedReport.value = true;

      if (draftEligibility?.standard) {
        onStandardChange(draftEligibility?.standard);
      }
      if (draftEligibility?.projectType) {
        onProjectTypeChange(draftEligibility?.projectType);
      }
      if (draftEligibility?.fileUpload) {
        selectedTab.value = 2;
      }
      if (draftEligibility?.answers) {
        // Map draftEligibility.answers to userInputs.value.answers[question.id] = answer
        Object.entries(draftEligibility.answers).forEach(([key, value]) => {
          userInputs.value.answers[key] = value;
        });

        // Check off any questions with multiple:true and answers.length > 0 and update them from the userInputs.value.answers
        questions.value.forEach((question) => {
          if (question.multiple && userInputs.value.answers[question.id]) {
            if (question.sdgs) {
              // Map the sdgList and set checked to true if the userInputs.value.answers[question.id] matches the sdg.id
              console.info("SDGs");
              sdgList.forEach((sdg) => {
                const checked = userInputs.value.answers[question.id]?.options.find(
                  (answer) => answer.id === sdg.id,
                );
                if (checked) {
                  sdg.checked = true;
                }
              });
            } else {
              question.options.forEach((option) => {
                // Find the userInputs.value.answers[question.id]?.options that matches the option.id and set the option.checked to true
                const checked = userInputs.value.answers[question.id]?.options.find(
                  (answer) => answer.id === option.id,
                );
                if (checked) {
                  option.checked = true;
                }
              });
            }
          }
        });
      }
      checkAllAnswers();
      return draftEligibility;
    }
  });

  const checkIfDraft = computed(() => {
    return routerQuery.value ? false : true;
  });

  const updateArea = (event) => {
    console.info(event);
    console.info("geometry.value before:", geometry.value);

    // If event.type is draw.create, add the geometry to the geometry.value object
    if (event.type === "draw.create") {
      geometry.value.push(event.features[0]);
      if (event.features[0].geometry.type === "Polygon") {
        geojsonPolygons.value.push(event.features[0].geometry.coordinates[0]);
      }
      if (event.features[0].geometry.type === "Point") {
        geojsonMarkers.value.push(event.features[0].geometry.coordinates);
      }
    }

    if (event.type === "draw.delete") {
      // Find the matching feature.id in geometry.value and remove it
      const featureIndex = geometry.value.findIndex(
        (feature) => feature.id === event.features[0].id,
      );
      if (featureIndex > -1) {
        geometry.value.splice(featureIndex, 1);
        geojsonPolygons.value.splice(featureIndex, 1);
      }
    }

    console.info("geometry.value after:", geometry.value);
    userInputs.value.fileUpload = geometry.value;
  };

  const uploadGeoJSON = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const geojson = JSON.parse(e.target.result);
      console.info({ geojson });

      // Clear the geojsonPolygons.value array and geoJSONMarkers.value array
      geometry.value = [];
      geojsonPolygons.value = [];
      geojsonMarkers.value = [];

      userInputs.value.fileUpload = geojson;
      // Find all the geometry.coordinates from geojson and push them to the geojsonPolygons.value array
      let tempPolygons = [];
      let tempMarkers = [];
      geojson.features.forEach((feature) => {
        if (feature.geometry.type === "Polygon") {
          tempPolygons.push(feature.geometry.coordinates[0]);
        }
        if (feature.geometry.type === "MultiPolygon") {
          feature.geometry.coordinates.forEach((coordinates) => {
            tempPolygons.push(coordinates[0]);
          });
        }
        if (feature.geometry.type === "LineString") {
          tempPolygons.push(feature.geometry.coordinates);
        }
        if (feature.geometry.type === "Point") {
          tempMarkers.push(feature.geometry.coordinates);
        }
      });
      tempPolygons.flat(Infinity);
      geojsonPolygons.value = tempPolygons;
      geojsonMarkers.value = tempMarkers;
      console.info({
        geojsonPolygons: geojsonPolygons.value,
        geojsonMarkers: geojsonMarkers.value,
        geometry: geometry.value,
      });
    };
    reader.readAsText(file);
  };

  const onClearGeoJSON = () => {
    userInputs.value.fileUpload = null;
    geojsonPolygons.value = [];
    geojsonMarkers.value = [];

    // Remove the file from file reader
    const fileInput = document.getElementById("file-input");
    fileInput.value = "";
  };

  const onCheckboxesChange = (event, questionId, option) => {
    console.info("onCheckboxesChange", event, questionId, option);
    // If event is pointerEvent, return
    if (event instanceof PointerEvent) return;

    const { id } = option;
    const { value } = event.target;
    option.checked = value === "on" ? true : false;
    console.info({ value });
    const question = questions.value[questionId];
    const answer = userInputs.value.answers[questionId];
    console.info({ question, answer });

    // If no answer, we need to push the question object to the answers array while keeping the other options in the answer.options array
    if (!answer) {
      console.info("No answer, pushing question to answers array");
      userInputs.value.answers[questionId] = {
        question,
        options: [option],
        multiple: question.multiple || true,
      };
    }
    // Otherwise we want to check if the userInputs.value.answers[questionId].options contains this option object already (if so remove it), else add the option object
    else {
      console.info("Answer exists, checking if option exists in answer.options");
      const optionIndex = answer.options.findIndex((o) => o.id === id);
      console.info({ optionIndex });
      if (optionIndex > -1) {
        console.info("Option exists, removing option from answer.options");
        answer.options.splice(optionIndex, 1);
      } else {
        console.info("Option does not exist, adding option to answer.options");
        answer.options.push(option);
      }
    }
  };

  const openInfoModal = (question) => {
    infoModalState.value = true;
    infoModalTitle.value = question.label;
    infoModalBody.value = question.info;
  };

  const onClearAllDrawings = () => {
    userInputs.value.fileUpload = null;
    geojsonPolygons.value = [];
    geojsonMarkers.value = [];
    geometry.value = [];
  };

  const onMapLoaded = () => {
    const draftEligibility = eligibilityStore.getDraftEligibilityById(routerQuery.value);
    console.info({ draftEligibility });

    if (!draftEligibility?.fileUpload) return;

    let featuresArray = [];

    if (draftEligibility.fileUpload && draftEligibility.fileUpload.features?.length) {
      featuresArray = draftEligibility.fileUpload.features;
    } else {
      featuresArray = draftEligibility.fileUpload;
    }
    console.info({ featuresArray });

    userInputs.value.fileUpload = featuresArray;

    let tempPolygons = [];
    let tempMarkers = [];

    featuresArray.map((feature) => {
      if (feature.geometry.type === "Polygon") {
        tempPolygons.push(feature.geometry.coordinates[0]);
      }
      if (feature.geometry.type === "MultiPolygon") {
        feature.geometry.coordinates.forEach((coordinates) => {
          tempPolygons.push(coordinates[0]);
        });
      }
      if (feature.geometry.type === "LineString") {
        tempPolygons.push(feature.geometry.coordinates);
      }
      if (feature.geometry.type === "Point") {
        tempMarkers.push(feature.geometry.coordinates);
      }
    });

    geojsonPolygons.value = tempPolygons;
    geojsonMarkers.value = tempMarkers;
    geometry.value = featuresArray;
  };

  const onTabChange = (index) => {
    selectedTab.value = index;
  };

  const onCountryChange = (country) => {
    console.info("onCountryChange()", {
      country,
    });
    if (!country) return;

    userInputs.value.answers.country = country;
  };
  const onStateChange = (state) => {
    console.info("onStateChange()", {
      state,
    });
    if (!state) return;

    userInputs.value.answers.state = state;
  };

  const onQuestionAnswered = () => {
    checkAllAnswers();
  };

  function checkAllAnswers() {
    // Check all questions and see if userInputs.value.answers has a key for each question
    const answeredQuestions = Object.keys(userInputs.value.answers);
    const allQuestions = Object.keys(questions.value);
    const unansweredQuestions = allQuestions.filter((q) => !answeredQuestions.includes(q));

    console.info({ answeredQuestions, allQuestions, unansweredQuestions });

    if (unansweredQuestions.length === 0) {
      console.info("All questions have been answered");
      allQuestionsAnswered.value = true;
    } else {
      console.info("Not all questions have been answered");
      allQuestionsAnswered.value = false;
    }
  }

  // Watch for userInputs.answers[question.id] to change and run handleAutoSave
  // watch(
  //   () => userInputs.value.answers,
  //   () => {
  //     handleAutoSave();
  //   },
  //   { deep: true },
  // );
</script>

<template>
  <div class="onboarding-container grid w-full grid-cols-1 md:grid-cols-12">
    <EligibilityInfoModal
      :showModal="infoModalState"
      :title="infoModalTitle"
      :body="infoModalBody"
      @on-close="infoModalState = false"
    />
    <EligibilityStateModal
      :showModal="eligibilityStore.deleteModalOpen"
      :onDeleteReportConfirm="onDeleteReportConfirm"
      type="delete"
    />
    <EligibilityStateModal
      :showModal="eligibilityStore.eligibilityModalOpen"
      type="submit"
    />
    <div class="col-span-1 p-4 md:col-span-8 md:p-8">
      <div
        class="container relative mx-auto mb-8 flex flex-col justify-between space-y-5 md:mb-24 md:max-w-4xl md:flex-row md:items-center md:space-y-0"
      >
        <div
          class="sticky top-0 left-0 right-0 flex w-full flex-row items-center justify-between text-sm text-gray-400"
        >
          <small
            @click="goBack"
            class="flex w-full max-w-[10rem] cursor-pointer flex-row items-center gap-2 underline hover:text-emerald-500"
          >
            <ArrowLeftIcon class="h-4 w-4" />
            Back
          </small>
        </div>
      </div>
      <div class="container mx-auto md:max-w-xl">
        <div
          class="mb-12 flex w-full flex-col items-start justify-items-stretch gap-4 md:justify-items-start"
        >
          <h1 class="text-3xl font-semibold dark:text-gray-300 md:text-4xl">
            Create a new eligibility report
          </h1>
          <p class="inline-block w-full text-lg text-gray-600">
            Sequestr's framework is a tool that can be used to quickly assess the eligibility of an
            early-stage project to determine if it will meet certification requirements with the
            supported standards and project types. It can also be used to assess the economic value
            of early-stage projects and thus to determine their risk profile before key elements of
            design are decided/confirmed.
          </p>
        </div>
        <Input
          placeholder="Enter a title for this report"
          label="Enter a title for this report"
          v-model="userInputs.title"
          autocomplete="off"
          type="text"
          name="reportTitle"
          id="reportTitle"
          required
          chunky
          full
        />
        <div class="w-full">
          <label class="mb-2 block text-base font-medium text-gray-600 dark:text-gray-500">
            Select registry (standard)
          </label>
          <v-select
            placeholder="Select standard"
            class="sq-v-s mb-4"
            v-model="userInputs.standard"
            :options="standards"
            :clearable="false"
            @option:selected="onStandardChange"
            required
          />
        </div>
        <div class="w-full">
          <label class="mb-2 block text-base font-medium text-gray-600 dark:text-gray-500">
            Select project type
          </label>
          <v-select
            placeholder="Select project type"
            :disabled="!userInputs.standard"
            class="sq-v-s mb-4"
            v-model="userInputs.projectType"
            :options="projectTypesByStandard"
            :reset-on-options-change="checkIfDraft"
            :clearable="false"
            @option:selected="onProjectTypeChange"
            required
          />
        </div>
        <Divider />
      </div>

      <div class="b container mx-auto max-w-xl">
        <h4 class="text-lg font-medium text-gray-800 dark:text-gray-300">
          You have three options for submitting your project's location: drawing an area within our
          <span
            class="cursor-pointer underline"
            @click="() => (selectedTab = 0)"
            >integrated map tool</span
          >, detailing the project site's, or entering your project's
          <span
            class="cursor-pointer underline"
            @click="() => (selectedTab = 1)"
            >country/region</span
          >, or by adding an existing
          <span
            class="cursor-pointer underline"
            @click="() => (selectedTab = 2)"
            >shape file</span
          >. Please select the most appropriate option based on the information currently known
          regarding project location.
        </h4>
        <Divider />
      </div>

      <!-- Mapbox container -->
      <div class="flex w-full flex-col gap-4">
        <div class="flex w-full flex-col items-start justify-start gap-4">
          <TabGroup
            :key="`tab-${selectedTab}`"
            :defaultIndex="0"
            :selectedIndex="selectedTab"
            @change="onTabChange"
          >
            <TabList
              class="container mx-auto flex w-full flex-row items-start justify-center gap-2 overflow-x-auto whitespace-nowrap rounded-full bg-gray-50 dark:bg-gray-600 md:w-auto md:max-w-xl"
            >
              <Tab
                v-for="(tab, i) in tabs"
                as="template"
                :key="`tab-${i}`"
                v-slot="{ selected }"
              >
                <button
                  :class="[
                    'w-full text-base font-semibold leading-5 md:w-auto',
                    'text-center align-middle ring-white ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-0 md:gap-4 md:text-left',
                    'rounded-lg bg-gray-50 py-2 px-3 dark:bg-gray-600',
                    selected
                      ? ' text-gray-900 dark:border-b-gray-300 dark:text-gray-300'
                      : ' text-gray-400 hover:text-gray-900 dark:hover:text-gray-300',
                  ]"
                >
                  {{ tab }}
                </button>
              </Tab>
            </TabList>

            <!-- Tab panels -->
            <TabPanels class="w-full">
              <!-- Draw settings -->
              <TabPanel class="flex flex-col gap-4">
                <div
                  class="container mx-auto flex flex-col items-start justify-start gap-4 md:max-w-xl"
                >
                  <h5 class="font-medium text-gray-500">
                    Draw or upload your project's shapefile(s).
                  </h5>
                  <button
                    @click="onClearAllDrawings"
                    class="rounded border border-gray-500 bg-transparent px-2 font-semibold text-gray-800 hover:border-emerald-500 hover:bg-emerald-500 hover:text-white dark:border-gray-300 dark:text-gray-300"
                  >
                    Clear all drawings
                  </button>
                </div>
                <!-- Mapbox -->
                <div class="mb-4 flex w-full flex-col gap-4 overflow-hidden rounded-lg">
                  <mapbox-map
                    :accessToken="mapboxAccessKey"
                    mapStyle="light-v10"
                    height="32rem"
                  >
                    <mapbox-geocoder-control />
                    <mapbox-draw-control
                      @loaded="onMapLoaded"
                      @create="updateArea"
                      @update="updateArea"
                      @delete="updateArea"
                    />
                    <mapbox-geogeometry-polygon
                      v-for="path in geojsonPolygons"
                      :path="path"
                    />
                    <mapbox-marker
                      v-for="marker in geojsonMarkers"
                      :lngLat="marker"
                    />
                  </mapbox-map>
                </div>
              </TabPanel>

              <!-- Country or region -->
              <TabPanel class="container mx-auto flex max-w-xl flex-col gap-8">
                <div class="div flex w-full flex-col">
                  <label
                    class="mb-2 block whitespace-pre-line text-base font-medium text-gray-600 dark:text-gray-600"
                  >
                    Select your country
                  </label>
                  <v-select
                    v-model="userInputs.answers.country"
                    :clearable="false"
                    :options="Country.getAllCountries()"
                    label="name"
                    placeholder="Select a country"
                    class="sq-v-s"
                    @option:selected="onCountryChange"
                  />
                </div>
                <div class="div flex w-full flex-col">
                  <label
                    class="mb-2 block whitespace-pre-line text-base font-medium text-gray-600 dark:text-gray-600"
                  >
                    Select your state or province
                  </label>
                  <v-select
                    v-model="userInputs.answers.state"
                    :clearable="false"
                    :options="State?.getStatesOfCountry(userInputs.answers.country?.isoCode)"
                    label="name"
                    placeholder="Select a state/province"
                    class="sq-v-s"
                    :disabled="!userInputs.answers.country"
                    @option:selected="onStateChange"
                  />
                </div>
              </TabPanel>
            </TabPanels>

            <!-- Upload otherwise -->
            <TabPanel class="container mx-auto flex max-w-xl flex-col gap-4">
              <div
                class="mb-8 flex w-full flex-shrink-0 flex-grow flex-row items-center justify-end rounded-xl border-2 border-dashed border-indigo-300 p-8"
              >
                <label class="flex w-full cursor-pointer flex-row justify-end text-left">
                  <span class="sr-only">Choose GeoJSON file</span>
                  <input
                    id="file-input"
                    @change="uploadGeoJSON"
                    type="file"
                    accept=".geojson"
                    class="block w-full text-sm text-slate-500 file:mr-4 file:rounded-full file:border-0 file:bg-indigo-50 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-indigo-700 hover:file:bg-indigo-100"
                  />
                </label>
                <button
                  @click="onClearGeoJSON"
                  class="rounded bg-gray-100 px-2 py-1 text-center text-xs font-medium dark:bg-gray-800 dark:text-gray-100"
                >
                  Remove
                </button>
              </div>
            </TabPanel>
          </TabGroup>
        </div>

        <!-- Outer container -->
        <div class="container mx-auto flex flex-col gap-8 md:max-w-xl">
          <div class="flex w-full flex-col">
            <label
              class="mb-2 block whitespace-pre-line text-base font-medium text-gray-600 dark:text-gray-600"
            >
              Please describe the accuracy of your project site's mapping and provide any additional
              relevant information.
            </label>
            <textarea
              v-model="userInputs.answers.site_accuracy"
              class="w-full resize-y rounded border-gray-100 bg-gray-50 ring-emerald-500 ring-opacity-60 ring-offset-0 focus:outline-none focus:ring-0 dark:border-gray-900 dark:bg-gray-900"
              placeholder="Describe accuracy of project site mapping or location"
            />
          </div>
          <div
            v-show="questions && questions.length"
            class="questions flex w-full flex-col gap-8"
          >
            <!-- Map and show the questions from the questionnaire by project type -->
            <div
              v-for="question in questions"
              :key="question.id"
              :class="`relative w-full ${question.indent ? 'question-indent pl-6' : ''}`"
            >
              <div class="flex flex-row items-start justify-start gap-4 md:justify-between">
                <label
                  class="mb-2 block whitespace-pre-line text-base font-medium text-gray-600 dark:text-gray-600"
                >
                  {{ question.label }}
                </label>
                <button
                  v-show="question.info"
                  class="rounded-full bg-gray-50 p-1 text-emerald-500 hover:bg-emerald-500 hover:text-white dark:bg-gray-800"
                  @click="openInfoModal(question)"
                >
                  <InformationCircleIcon class="h-5 w-5" />
                </button>
              </div>
              <!-- Store in the userInputs.answers object by question id -->
              <v-select
                v-if="question.options && !question.multiple"
                placeholder="Select a response"
                class="sq-v-s"
                v-model="userInputs.answers[question.id]"
                :options="
                  question.options?.map((option) => ({
                    label: option,
                    question: question.label,
                  }))
                "
                :clearable="false"
                @option:selected="onQuestionAnswered"
                required
              />
              <small
                v-show="question.helper"
                class="text-xs text-gray-500"
                >{{ question.helper }}
              </small>
              <!-- If multiple, we need to show the <Checkbox /> component with a list of options from question.options -->
              <div
                v-if="question.options && question.multiple"
                class="flex flex-col gap-1"
              >
                <Checkbox
                  v-for="option in question.options"
                  class="w-full py-2"
                  text-class="text-base font-normal text-gray-700 dark:text-gray-500 mt-[-0.25rem]"
                  :key="option.id"
                  :id="`${question.id}-${option.label}`"
                  :name="`${question.id}-${option.label}`"
                  :text="option.label"
                  :checked="option.checked"
                  @change="onCheckboxesChange($event, question.id, option)"
                />
              </div>
              <!-- If question.sdgs === true, show the <Checkbox /> component with a list of SDGs -->
              <div
                v-if="question.sdgs"
                class="flex flex-col gap-1"
              >
                <Checkbox
                  v-for="sdg in sdgList"
                  class="w-full py-2"
                  text-class="text-base font-normal text-gray-700 dark:text-gray-500 mt-[-0.25rem]"
                  :key="sdg.id"
                  :id="`sdg-${sdg.id}`"
                  :name="`sdg-${sdg.id}`"
                  :text="`${sdg.label}: ${sdg.description}`"
                  :checked="sdg.checked"
                  @change="onCheckboxesChange($event, question.id, sdg)"
                />
              </div>
            </div>

            <div class="sticky bottom-0 z-10 flex flex-col gap-4 py-8">
              <div class="flex w-full flex-row justify-between gap-4 md:gap-8">
                <div class="flex flex-row gap-4">
                  <Button
                    v-if="savedReport"
                    text="Delete"
                    type="button"
                    @click="onDeleteReport"
                    classes="w-auto border-amber-100 bg-amber-50 text-amber-500 hover:border-amber-600 hover:bg-amber-500 hover:text-white dark:border-amber-800 dark:bg-amber-900 dark:text-amber-600 dark:hover:bg-amber-500"
                    chunky
                  />
                  <Button
                    text="Save"
                    type="button"
                    :loading="isSaveReportLoading"
                    @click="onSaveReport"
                    classes="w-auto border-emerald-100 bg-emerald-50 text-emerald-500 hover:border-emerald-600 hover:bg-emerald-500 hover:text-white"
                    :disabled="!userInputs.title"
                    chunky
                  />
                </div>
                <Button
                  text="Submit report"
                  type="button"
                  @click="onCreateReportSubmit"
                  :loading="isCreateReportLoading"
                  :disabled="launchingSoon || !allQuestionsAnswered || isCreateReportLoading"
                  chunky
                />
              </div>

              <span
                v-if="isCreateReportError"
                class="mt-10 block text-sm text-red-400"
                >{{ createReportError }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="relative top-0 col-span-1 h-auto bg-gray-50 py-8 px-8 dark:bg-gray-900 md:sticky md:col-span-4 md:h-screen md:py-32"
    >
      <div class="flex flex-col items-stretch gap-4 md:max-w-lg">
        <div class="flex flex-col items-stretch gap-4">
          <div class="flex flex-col gap-2">
            <span class="block text-2xl font-semibold dark:text-gray-300"> Your report </span>
            <p class="text-sm text-gray-500">
              Once you hit submit report for feedback, our team will review your responses and send
              suggestions for improvement based on your answers, and the general eligibility
              assessment.
            </p>
          </div>
          <Divider margin="my-4" />
          <img
            class="w-full object-contain"
            src="/img/eligibility.png"
            alt="Eligibility report"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .questions {
    .question-indent {
      &::before {
        @apply rounded border-l-2 border-gray-100 dark:border-gray-800;
        position: absolute;
        content: "";
        height: 100%;
        left: 0.5rem;
        top: 0;
      }
    }
  }
</style>
