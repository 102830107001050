<template>
  <div class="onboarding-container grid w-full grid-cols-1 md:grid-cols-12">
    <div class="col-span-1 p-4 md:col-span-8 md:p-8">
      <div
        class="container relative mx-auto mb-8 flex flex-col justify-between space-y-5 md:mb-24 md:max-w-4xl md:flex-row md:items-center md:space-y-0"
      >
        <div
          class="absolute top-0 left-0 right-0 flex w-full flex-row items-center justify-between text-sm text-gray-400"
        >
          <small
            @click="goBack"
            class="flex w-full max-w-[10rem] cursor-pointer flex-row items-center gap-2 underline hover:text-emerald-500"
          >
            <ArrowLeftIcon class="h-4 w-4" />
            Back
          </small>
          <div class="h-1.5 rounded-full bg-gray-100 dark:bg-gray-700 md:mx-5 md:w-[60%]">
            <div
              class="h-1.5 rounded-full bg-emerald-600"
              style="width: 45%"
            ></div>
          </div>
          <span class="w-full max-w-[10rem] text-right">
            <small v-if="projectsStore.getLastSavedTime">
              Last saved: {{ moment(projectsStore.getLastSavedTime).utc(true).from() }}
            </small>
            <small v-else> Last saved: 0 mins ago </small>
          </span>
        </div>
      </div>
      <div class="container mx-auto pb-24 md:max-w-xl">
        <Loader
          v-if="isLoading"
          size="12"
        />
        <span
          v-else-if="isError"
          class="mt-10 block text-sm text-red-400"
          >Something went wrong: {{ error }}</span
        >
        <div v-if="projectsStore.projectDetails">
          <div v-if="projectsStore.isProjectDetailsStep">
            <div
              class="mb-12 flex w-full flex-col items-start justify-items-stretch gap-4 md:justify-items-start"
            >
              <h1 class="text-3xl font-semibold dark:text-gray-300 md:text-4xl">Project details</h1>
              <p class="inline-block w-full text-lg text-gray-600">
                Enter details for your project
              </p>
            </div>
            <Input
              placeholder="Enter current project size (ha)"
              label="Enter current project size in hectares"
              v-model="projectsStore.projectDetails.projectSize"
              autocomplete="off"
              type="number"
              name="projectSize"
              id="projectSize"
              chunky
              full
            />
            <Input
              placeholder="Enter lead organization details"
              label="Enter lead organization details"
              v-model="projectsStore.projectDetails.leadOrganizationDetails"
              autocomplete="off"
              type="text"
              name="leadOrganizationDetails"
              id="leadOrganizationDetails"
              chunky
              full
              textarea
            />

            <Checkbox
              text="Project can be scaled up in the near future"
              :checked="projectsStore.projectDetails.canProjectBeScaledUp"
              @change="toggleProjectScale"
              :class="projectsStore.projectDetails.canProjectBeScaledUp ? 'mb-6' : 'mb-0'"
              id="canProjectBeScaledUpCheckBox"
            />

            <Input
              v-if="projectsStore.projectDetails.canProjectBeScaledUp"
              placeholder="Enter future scale details"
              label="Enter future scale details"
              autocomplete="off"
              v-model="projectsStore.projectDetails.futureScaleDetails"
              type="text"
              name="futureScaleDetails"
              id="futureScaleDetails"
              chunky
              full
              textarea
            />

            <Divider />

            <p class="text-gray-900 dark:text-gray-300">
              Create an add a geo json file for more accuracy on your project. You can create one
              here.
            </p>

            <SmallButton
              class="mt-4"
              text="Upload geojson file"
              @click="addGeocodeJson"
            />

            <Divider />

            <Input
              placeholder="Describe the project activities at a high level"
              label="Describe the project activities at a high level"
              v-model="projectsStore.projectDetails.projectActivitiesHighLevel"
              autocomplete="off"
              type="text"
              name="projectActivitiesHighLevel"
              id="projectActivitiesHighLevel"
              chunky
              full
              textarea
            />

            <div
              v-for="(specie, index) in projectsStore.projectDetails.species"
              class="mb-4 rounded-lg border border-gray-100 p-3 dark:border-gray-600"
            >
              <span class="space-y-3">
                <Input
                  margin="0"
                  v-for="(value, key) in specie"
                  :placeholder="getSpeciesPlaceholder(key)"
                  :label="getSpeciesPlaceholder(key)"
                  autocomplete="off"
                  v-model="specie[key]"
                  :type="key === 'name' ? 'text' : 'number'"
                  :name="`${key}Field${index}`"
                  full
                />
              </span>
            </div>

            <SmallButton
              text="Add more species"
              class="mt-0 mb-6"
              @click="addMoreSpecies"
            />

            <Input
              placeholder="Describe the current use of the land"
              label="Describe the current use of the land"
              autocomplete="off"
              v-model="projectsStore.projectDetails.currentLandUse"
              type="text"
              name="currentLandUse"
              id="currentLandUse"
              chunky
              full
              textarea
            />

            <Input
              placeholder="Describe the equipment used for planting species"
              label="Describe the equipment used for planting species"
              autocomplete="off"
              v-model="projectsStore.projectDetails.equipmentUsedForPlantingSpecies"
              type="text"
              name="equipmentUsedForPlantingSpecies"
              id="equipmentUsedForPlantingSpecies"
              chunky
              full
              textarea
            />

            <Divider />

            <Checkbox
              text="This land was deforested within the last 10 years"
              :checked="projectsStore.projectDetails.wasDeforestedWithinTheLast10Years"
              @change="toggleDeforestation"
              id="wasDeforestedWithinTheLast10YearsCheckBox"
            />

            <Input
              v-if="projectsStore.projectDetails.wasDeforestedWithinTheLast10Years"
              placeholder="Describe why the land was deforested"
              label="Describe why the land was deforested"
              autocomplete="off"
              v-model="projectsStore.projectDetails.deforestationReason"
              type="text"
              name="deforestationReason"
              id="deforestationReason"
              chunky
              full
              textarea
            />

            <Divider />

            <div class="mb-4 rounded-lg border border-gray-100 p-3 dark:border-gray-600">
              <span class="space-y-3">
                <Input
                  v-for="(timeline, index) in projectsStore.projectDetails
                    .projectTimelineRequirements"
                  margin="0"
                  placeholder="Enter project timeline requirements"
                  label="Enter project timeline requirements"
                  autocomplete="off"
                  :key="`timelineRequirementsField${index}`"
                  v-model="projectsStore.projectDetails.projectTimelineRequirements[index]"
                  type="text"
                  :name="`timelineRequirementsField${index}`"
                  full
                />
              </span>
              <span class="mt-3 block text-sm text-gray-700 dark:text-gray-400">
                Suggestions: site survey, staff recruitment, staff training, nursery setup, seedling
                procurement, and project start date
              </span>
            </div>

            <SmallButton
              class="mt-0 mb-6"
              text="Add more"
              @click="addMoreRequirements"
            />

            <Input
              placeholder="Project length"
              label="Project length"
              autocomplete="off"
              v-model="projectsStore.projectDetails.projectLength"
              type="number"
              min="20"
              name="projectLength"
              id="projectLength"
              chunky
              full
              helper="Minimum project length is 20 years"
            />

            <div class="mt-10">
              <Button
                text="Continue to next step"
                type="submit"
                @click="
                  onProjectSave({
                    projectDetails: projectsStore.projectDetails,
                  })
                "
                :loading="isProjectSaveLoading"
                :disabled="isProjectSaveLoading"
                chunky
                full
              />
            </div>

            <span
              v-if="isProjectError"
              class="mt-10 block text-sm text-red-400"
              >{{ projectError }}</span
            >
          </div>

          <div v-if="projectsStore.isProjectImageStep">
            <div
              class="mb-12 flex w-full flex-col items-start justify-items-stretch gap-4 md:justify-items-start"
            >
              <h1 class="text-3xl font-semibold dark:text-gray-300 md:text-4xl">Project images</h1>
              <p class="inline-block w-full text-lg text-gray-600">
                Add at least one photo for your project
              </p>
            </div>

            <div class="overflow-hidden">
              <div class="mx-auto max-w-screen-xl text-center">
                <div class="mx-auto max-w-3xl lg:max-w-none">
                  <div
                    class="mt-6 sm:mt-5 sm:grid sm:grid-cols-1 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <DropZone
                      @files-dropped="onFilesDropped"
                      #default="{ dropZoneActive }"
                      class="drop-area mt-2 sm:col-span-2 sm:mt-0"
                    >
                      <div
                        v-cloak
                        v-on:dragover.prevent="onUploadDragoverEvent($event)"
                        v-on:drop.prevent="onUploadDropEvent($event)"
                        class="h-128 flex w-full items-center justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
                      >
                        <div
                          class="absolute z-10 h-20 w-20 rounded-full bg-gray-100 transition-opacity duration-500 ease-in-out"
                          v-bind:class="{
                            'opacity-100': uploadDragoverTracking,
                            'opacity-0': !uploadDragoverTracking,
                          }"
                          v-bind:style="{
                            left: `calc(${pageX.value}px - 2.5rem)`,
                            top: `calc(${pageY.value}px - 2.5rem)`,
                          }"
                        ></div>
                        <div class="relative z-20 text-center">
                          <p class="mt-5 text-sm text-gray-600">
                            Drag and drop images, or
                            <input
                              class="hidden"
                              type="file"
                              id="file-input"
                              multiple
                              @change="onInputChange"
                            />
                            <label
                              for="file-input"
                              class="cursor-pointer font-medium text-emerald-600 transition duration-150 ease-in-out hover:text-emerald-500 focus:underline focus:outline-none"
                            >
                              browse for files
                            </label>
                          </p>
                          <p class="mt-1 text-xs text-gray-500">
                            Minimum 800px with recommended. Max 8MB per file.
                          </p>
                          <p class="mt-4 text-xs text-gray-300 dark:text-gray-700">
                            Supported formats: png, gif, jpg, and jpeg
                          </p>
                        </div>
                      </div>
                    </DropZone>
                  </div>
                </div>
                <div class="mt-5 overflow-hidden">
                  <ul
                    class="space-y-3"
                    v-show="files.length"
                  >
                    <FilePreview
                      v-for="file of files"
                      :key="file.id"
                      :file="file"
                      tag="li"
                      @remove="onFileRemoveClick(file)"
                      :loading="isFileUploadLoading || isFileDeleteLoading"
                    />
                  </ul>
                </div>

                <div class="mt-10">
                  <Button
                    :text="isFileDropDirty ? 'Upload Files' : 'Continue to next step'"
                    type="submit"
                    @click="onFileSubmitClick"
                    :loading="isFileUploadLoading || isFileDeleteLoading"
                    :disabled="isFileUploadLoading || isFileDeleteLoading"
                    chunky
                    full
                  />

                  <span
                    v-if="isFileUploadError"
                    class="mt-10 block text-sm text-red-400"
                    >{{ fileUploadError }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="projectsStore.isProjectImpactStep">
            <div
              class="mb-12 flex w-full flex-col items-start justify-items-stretch gap-4 md:justify-items-start"
            >
              <h1 class="text-3xl font-semibold dark:text-gray-300 md:text-4xl">
                Impact assessment
              </h1>
              <p class="inline-block w-full text-lg text-gray-600">Describe any adverse impacts</p>
            </div>

            <Input
              placeholder="Describe the adverse impacts your project may have on local communities"
              label="Describe the adverse impacts your project may have on local communities"
              autocomplete="off"
              v-model="projectsStore.projectImpact.localCommunityImpact"
              type="text"
              name="localCommunityImpact"
              id="localCommunityImpact"
              chunky
              full
              textarea
            />

            <Input
              placeholder="Describe the adverse impacts your project may have on local biodiversity"
              label="Describe the adverse impacts your project may have on local biodiversity"
              autocomplete="off"
              v-model="projectsStore.projectImpact.localBiodiversityImpact"
              type="text"
              name="localBiodiversityImpact"
              id="localBiodiversityImpact"
              chunky
              full
              textarea
            />

            <Input
              placeholder="Describe any other adverse impacts your project may have"
              label="Describe any other adverse impacts your project may have"
              autocomplete="off"
              v-model="projectsStore.projectImpact.otherImpact"
              type="text"
              name="otherImpact"
              id="otherImpact"
              chunky
              full
              textarea
            />

            <Input
              placeholder="Describe the mitigation measures you are putting in place to address the above mentioned adverse effects"
              label="Describe the mitigation measures you are putting in place to address the above mentioned adverse effects"
              autocomplete="off"
              v-model="projectsStore.projectImpact.mitigationMeasures"
              type="text"
              name="mitigationMeasures"
              id="mitigationMeasures"
              chunky
              full
              textarea
            />

            <div class="mt-10">
              <Button
                text="Submit"
                type="submit"
                @click="
                  onProjectSave({
                    impact: projectsStore.projectImpact,
                  })
                "
                :loading="isProjectSaveLoading"
                :disabled="isProjectSaveLoading"
                chunky
                full
              />
            </div>

            <span
              v-if="isProjectError"
              class="mt-10 block text-sm text-red-400"
              >{{ projectError }}</span
            >
          </div>
          <div v-if="projectsStore.isProjectReviewStep">
            <PageHeader
              title="The Sequestr team is reviewing your project"
              body="We will reach out to you for any further questions"
            />
            <div class="mt-10">
              <Button
                text="View my projects"
                type="submit"
                @click="router.push(`/company/${companyUrl}`)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-1 bg-[#F9FAFB] dark:bg-gray-900 md:col-span-4">
      <div
        v-if="!isError && !isLoading"
        class="space-y-5 md:max-w-lg"
      >
        <div class="fixed mx-16 my-16">
          <div v-if="projectsStore.isProjectDetailsStep">
            <span class="block text-2xl font-semibold dark:text-gray-300 md:text-xl">
              Location and activity example from a similar project near you in Tanzania
            </span>
            <span class="block text-sm text-gray-400">
              As you progress through the project creation flow we'll show you information on
              comparable projects so you can better understand the type and quality of information
              we're looking for when adding a project to Sequestr.
            </span>

            <img
              src="/img/project-creation-illustration.png"
              class="mt-10 w-full"
            />
          </div>
          <div v-if="projectsStore.isProjectImageStep">
            <span class="block text-2xl font-semibold dark:text-gray-300 md:text-xl"> test </span>
            <span class="block text-sm text-gray-400">
              As you progress through the project creation flow we'll show you information on
              comparable projects so you can better understand the type and quality of information
              we're looking for when adding a project to Sequestr.
            </span>

            <img
              src="/img/project-creation-illustration.png"
              class="mt-10 w-full"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import moment from "moment";
  import { computed, onMounted, ref } from "vue";
  import { useMutation } from "vue-query";
  import { useRoute, useRouter } from "vue-router";
  import { createToast } from "mosha-vue-toastify";

  import { ArrowLeftIcon } from "@heroicons/vue/24/outline";

  import { useAuthStore } from "@/store/authStore";
  import { ProjectCreationSteps, useProjectStore } from "@/store/projectStore";

  import { logEvent, logPageView } from "@/services/analytics";

  import Divider from "@/components/Divider.vue";
  import Input from "@/components/Input.vue";
  import Loader from "@/components/Loader.vue";
  import Button from "@/components/Button.vue";
  import FilePreview from "@/components/FilePreview.vue";
  import SmallButton from "@/components/Form/SmallButton.vue";
  import Checkbox from "@/components/Form/Checkbox.vue";
  import DropZone from "@/components/DropZone.vue";
  import PageHeader from "@/components/PageHeader.vue";

  import createUploader from "@/compositions/file-uploader";
  import useFileList from "@/compositions/file-list";

  const authStore = useAuthStore();
  const projectsStore = useProjectStore();

  const companyUrl = computed(() => authStore.user?.company?.slug);

  const projectName = ref("");
  const router = useRouter();
  const route = useRoute();

  const getLastSavedTime = computed(() => moment(projectsStore.getLastSavedTime).utc(true).from());

  const { isLoading, isError, error, mutate } = useMutation(
    (slug) => projectsStore.getProjectBySlug(slug),
    {
      onSuccess: (data) => {
        files.value = data.images;
      },
      onError: (error) => {
        console.error(error);
        // TODO: handle project not found errors
      },
    },
  );

  const {
    isLoading: isProjectSaveLoading,
    isError: isProjectError,
    error: projectError,
    mutate: projectMutate,
  } = useMutation(({ slug, dataToUpdate }) => projectsStore.updateProject(slug, dataToUpdate), {
    onSuccess: () => {
      projectsStore.nextStep();
    },
  });

  onMounted(() => {
    const slug = route.params.slug;
    projectName.value = slug;
    mutate(slug);
    logPageView("Edit project", {
      id: slug,
    });
  });

  const goBack = () => {
    if (projectsStore.activeStep === ProjectCreationSteps.PROJECT_DETAILS) {
      router.push("/projects");
      return;
    }

    return projectsStore.previousStep();
  };

  // have to detect if the user interacted with the file upload so we can save the files accordingly
  const isFileDropDirty = ref(false);

  const addGeocodeJson = () => {
    console.log("Add geocode json button");
  };

  const addMoreSpecies = () => {
    projectsStore.projectDetails.species.push({
      name: "",
      density: "",
      rate: "",
    });
  };

  const addMoreRequirements = () => {
    projectsStore.projectDetails.projectTimelineRequirements.push("");
  };

  const toggleProjectScale = () => {
    projectsStore.projectDetails.canProjectBeScaledUp =
      !projectsStore.projectDetails.canProjectBeScaledUp;
  };

  const toggleDeforestation = () => {
    projectsStore.projectDetails.wasDeforestedWithinTheLast10Years =
      !projectsStore.projectDetails.wasDeforestedWithinTheLast10Years;
  };

  const getSpeciesPlaceholder = (key) => {
    switch (true) {
      case key === "name":
        return "Enter species";
      case key === "density":
        return "Enter density (species/hectare)";
      case key === "rate":
        return "Rate (hectares/year)";
    }
  };

  const onProjectSave = (data) => {
    projectMutate({
      slug: projectName.value,
      dataToUpdate: data,
    });
  };

  // image upload
  const { files, addFiles, removeFile } = useFileList();

  function onInputChange(e) {
    onFilesDropped(e.target.files);
    e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
  }

  const { uploadFiles } = createUploader();

  const {
    isLoading: isFileUploadLoading,
    isError: isFileUploadError,
    error: fileUploadError,
    mutate: fileUploadMutate,
  } = useMutation(() => uploadFiles(projectName.value, files.value), {
    onSuccess: (data) => {
      files.value = data.images;
      isFileDropDirty.value = false;
    },
    onError: (error, file) => {
      removeFile(file);
      createToast("Something went wrong uploading your image, try again.", {
        type: "danger",
        position: "bottom-right",
      });
    },
  });

  const {
    isLoading: isFileDeleteLoading,
    isError: isFileDeleteError,
    error: fileDeleteError,
    mutate: fileDeleteMutate,
  } = useMutation((path) => projectsStore.removeImageUpload(projectName.value, path), {
    onSuccess: (data, path) => {
      files.value = data.images;
      removeFile(path);
    },
    onError: () => {
      createToast("Something went wrong deleting your image, try again.", {
        type: "danger",
        position: "bottom-right",
      });
    },
  });

  const pageX = ref(0);
  const pageY = ref(0);
  const uploadDragoverTracking = ref(false);
  const uploadDragoverEvent = ref(false);

  const onUploadDragoverEvent = (e) => {
    uploadDragoverEvent.value = true;
    uploadDragoverTracking.value = true;
    pageX.value = e.pageX;
    pageY.value = e.pageY;
  };

  const onUploadDropEvent = (e) => {
    uploadDragoverEvent.value = false;
    uploadDragoverTracking.value = false;
    pageX.value = 0;
    pageY.value = 0;
  };

  const onFileRemoveClick = (file) => {
    fileDeleteMutate(file);
  };

  const onFilesDropped = (filesDropped) => {
    addFiles(filesDropped);
    isFileDropDirty.value = true;
  };

  const onFileSubmitClick = () => {
    if (isFileDropDirty.value) {
      fileUploadMutate();
    } else {
      projectsStore.nextStep();
    }
  };
</script>
