<script setup>
  import { computed, ref, toRaw } from "vue";
  import { storeToRefs } from "pinia";

  import { useDemoStore } from "@/store/demoStore";
  import { getRandomTailwindColor } from "@/utils";

  import BarChart from "@/components/Charts/BarChart.vue";

  const demoStore = useDemoStore();
  const { getSummaryStats } = storeToRefs(demoStore);
  const yearlyStats = toRaw(getSummaryStats.value.yearlyStats);
  console.info({
    yearlyStats,
  });

  let chartOptions = ref({
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: false,
        text: "Emissions by Year",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Year",
          font: {
            size: 14,
          },
        },
        stacked: true,
      },
      y: {
        title: {
          display: false,
          text: "Emissions (tons)",
          font: {
            size: 14,
          },
        },
        stacked: true,
      },
    },
  });

  // yearlyStats looks like: { 2023: { revenueByGeography: { Africa: Number, Asia: Number... } } }
  // Map to chartData
  const chartData = computed(() => {
    const labels = Object.keys(yearlyStats);
    const datasets = Object.keys(yearlyStats[labels[0]].revenueByGeography).map((geography) => {
      const data = labels.map((year) => {
        return yearlyStats[year].revenueByGeography[geography];
      });

      return {
        label: geography,
        data,
        backgroundColor: getRandomTailwindColor(),
      };
    });

    return {
      labels,
      datasets,
    };
  });
</script>

<template>
  <div class="flex w-full flex-col gap-4">
    <div class="relative w-full">
      <BarChart
        :chartData="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>
