<script setup>
  import { computed, ref, toRaw } from "vue";
  import { storeToRefs } from "pinia";

  import { useDemoStore } from "@/store/demoStore";

  import BarChart from "@/components/Charts/BarChart.vue";

  const demoStore = useDemoStore();
  const { getSummaryStats } = storeToRefs(demoStore);
  const yearlyStats = toRaw(getSummaryStats.value.yearlyStats);
  console.info({
    yearlyStats,
  });

  let chartOptions = ref({
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: false,
        text: "Emissions by Year",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Year",
          font: {
            size: 14,
          },
        },
        stacked: true,
      },
      y: {
        title: {
          display: false,
          text: "Emissions (tons)",
          font: {
            size: 14,
          },
        },
        stacked: true,
      },
    },
  });

  const getColor = (mechanism) => {
    switch (mechanism) {
      case "Permanent CDR":
        return "#FBBF24";
      case "Nature-based Avoidance":
        return "#3B82F6";
      case "Nature-based Removals":
        return "#10B981";
      default:
        return "#10B981";
    }
  };

  // yearlyStats looks like: { 2023: { totalRevenue, totalcarbonCredits, revenueByMechanism } }
  const chartData = computed(() => {
    let labels = [];
    let datasets = [];

    // Loop through the yearlyStats object and create a dataset for each mechanism
    for (const [year, stats] of Object.entries(yearlyStats)) {
      labels.push(year);
      for (const [mechanism, value] of Object.entries(stats.revenueByMechanism)) {
        let dataset = datasets.find((dataset) => dataset.label === mechanism);
        if (!dataset) {
          dataset = {
            label: mechanism,
            data: [],
            backgroundColor: [],
          };
          datasets.push(dataset);
        }
        dataset.data.push(value);
        dataset.backgroundColor.push(getColor(mechanism));
      }
    }

    return {
      labels,
      datasets,
    };
  });
</script>

<template>
  <div class="flex w-full flex-col gap-4">
    <div class="relative w-full">
      <BarChart
        :chartData="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>
