import { useAuthStore } from "@/store/authStore";

import About from "./views/About.vue";
import AssetManagers from "./views/AssetManagers/AssetManagers.vue";
import ActionItems from "./views/AssetManagers/ActionItems.vue";
import Auth from "./views/Auth.vue";
import Create from "./views/Create.vue";
import Company from "./views/Company.vue";
import Edit from "./views/Edit.vue";
import EditProject from "./views/EditProject.vue";
import EligibilityCreate from "./views/Eligibility/EligibilityCreate.vue";
import EligibilityList from "./views/Eligibility/EligibilityList.vue";
import EligibilityView from "./views/Eligibility/EligibilityView.vue";
import NotFound from "./views/NotFound.vue";
import Magic from "./views/Magic.vue";
import MyProjects from "./views/MyProjects.vue";
import NewPassword from "./views/NewPassword.vue";
import NoPersonalEmails from "./views/NoPersonalEmails.vue";
import Onboarding from "./views/Onboarding.vue";
import Profile from "./views/Profile.vue";
import Projects from "./views/Projects.vue";
import ProjectTypes from "./views/ProjectTypes.vue";
import Properties from "./views/Properties.vue";
import ResetPassword from "./views/ResetPassword.vue";
import Settings from "./views/Settings.vue";
import VerifyEmail from "./views/VerifyEmail.vue";
import Verify from "./views/Verify.vue";
import View from "./views/ViewSingleProject.vue";
import ViewSingleClient from "./views/AssetManagers/ViewSingleClient.vue";
import Home from "./views/Home.vue";

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  // Redirect home for now
  {
    path: "/",
    name: "Home",
    component: Home,
    // redirect: { name: "Projects" },
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    meta: { title: "Projects", requiresAuth: false },
  },
  {
    path: "/properties",
    name: "Properties",
    component: Properties,
    meta: { title: "Properties", requiresAuth: true },
  },
  { path: "/login", name: "Login", component: Auth, meta: { title: "Login" } },
  {
    path: "/signup",
    name: "Signup",
    component: Auth,
    meta: { title: "Signup" },
  },
  {
    path: "/verify-email",
    name: "verify email",
    component: VerifyEmail,
    meta: { title: "Verify your email address" },
  },
  {
    path: "/work-emails-only",
    name: "Work emails only",
    component: NoPersonalEmails,
    meta: { title: "Please use a work email to continue" },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: Onboarding,
    meta: { title: "Onboarding" },
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore();
      // check if user is coming to onboard for the first time
      if (
        typeof from.name === "undefined" &&
        typeof to.redirectedFrom === "undefined" &&
        to.name === "Onboarding" &&
        to.hash !== ""
      ) {
        next();
        // check if user is clicking into onboarding from another route
      } else if (authStore.user) {
        next();
      } else {
        next({ name: "Login" });
      }
    },
  },
  // Asset managers
  {
    path: "/asset-managers",
    name: "Sequestr for Asset Managers",
    meta: { title: "Sequestr for Asset Managers", requiresAuth: true, hideNav: true },
    component: AssetManagers,
  },
  {
    path: "/asset-managers/client/:id",
    name: "Client",
    meta: { title: "Client", requiresAuth: true, hideNav: true },
    component: ViewSingleClient,
  },
  {
    path: "/asset-managers/action-items",
    name: "Action Items",
    meta: { title: "Action Items", requiresAuth: true, hideNav: true },
    component: ActionItems,
  },
  // Profile
  {
    path: "/profile/me",
    name: "My profile",
    meta: { title: "My profile", requiresAuth: true, isMyProfile: true },
    component: Profile,
  },
  {
    path: "/profile/:id",
    name: "Profile",
    meta: { title: "Profile", requiresAuth: false },
    component: Profile,
  },
  {
    path: "/settings",
    name: "Settings",
    meta: { title: "Settings", requiresAuth: true },
    component: Settings,
  },
  // Company
  {
    path: "/company/:slug",
    name: "Company",
    meta: { title: "Company", requiresAuth: false },
    component: Company,
  },
  // Create routes
  {
    path: "/create",
    name: "Create project",
    component: Create,
    meta: {
      title: "Create a new project",
      requiresAuth: true,
      type: "project",
    },
  },
  {
    path: "/edit/profile",
    name: "Edit profile",
    component: Edit,
    meta: {
      title: "Edit profile",
      requiresAuth: true,
      type: "profile",
    },
  },
  {
    path: "/project/:slug",
    name: "View project",
    meta: { title: "Project", requiresAuth: false, property: false },
    component: View,
  },
  {
    path: "/project/me",
    name: "My projects",
    meta: { title: "My projects", requiresAuth: false, property: false },
    component: MyProjects,
  },
  {
    path: "/project/:slug/edit",
    name: "Edit project",
    meta: { title: "Edit Project", requiresAuth: false },
    component: EditProject,
  },
  // Eligibility
  {
    path: "/eligibility",
    name: "Eligibility",
    meta: { title: "Eligibility", requiresAuth: true },
    component: EligibilityList,
  },
  {
    path: "/eligibility/create",
    name: "Project eligibility",
    meta: { title: "Project eligibility", requiresAuth: true },
    component: EligibilityCreate,
  },
  {
    path: "/eligibility/:id",
    name: "Edit eligibility",
    meta: { title: "Edit eligibility", requiresAuth: true },
    component: EligibilityView,
  },
  // Rest
  {
    path: "/project-types",
    name: "Project types",
    meta: { title: "Project types", requiresAuth: false },
    component: ProjectTypes,
  },
  {
    path: "/verify",
    name: "Verify",
    meta: { title: "Verify your email", requiresAuth: false },
    component: Verify,
  },
  {
    path: "/magic",
    name: "Magic login",
    meta: { title: "Magic login", requiresAuth: false },
    component: Magic,
  },
  {
    path: "/reset-password",
    name: "Reset your password",
    meta: { title: "Reset your password", requiresAuth: false },
    component: ResetPassword,
  },
  {
    path: "/new-password",
    name: "Set a new password",
    meta: { title: "Set a new password", requiresAuth: false },
    component: NewPassword,
  },
  {
    path: "/about",
    name: "About",
    meta: { title: "About", requiresAuth: false },
    component: About,
    // example of route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('./views/About.vue')
  },
  { path: "/:path(.*)", component: NotFound },
];
