<script setup>
  import { onMounted, ref } from "vue";
  import { useMutation } from "vue-query";
  import { useRoute, useRouter } from "vue-router";
  import { ArrowLeftIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";
  import moment from "moment";
  import { MapboxMap, MapboxGeogeometryPolygon, MapboxMarker } from "vue-mapbox-ts";

  import {
    gsARR,
    gsICS,
    gsICSSimplified,
    gsECS,
    vcsARR,
    vcsvm0033,
    vcsvmr0006,
  } from "@/services/eligibility-questions";
  import { projectSize, sdgList } from "@/services/index";
  import { logEvent, logPageView } from "@/services/analytics";

  import { useEligibilityStore } from "@/store/eligibilityStore";

  import Button from "@/components/Button.vue";
  import Checkbox from "@/components/Form/Checkbox.vue";
  import Divider from "@/components/Divider.vue";
  import Input from "@/components/Input.vue";
  import Loader from "@/components/Loader.vue";
  import EligibilityInfoModal from "@/components/Modals/EligibilityInfo.vue";
  import EligibilityStateModal from "@/components/Modals/EligibilityState.vue";

  const route = useRoute();
  const router = useRouter();
  const eligibilityStore = useEligibilityStore();
  const mapboxAccessKey = import.meta.env.VITE_MAPBOX_KEY;

  let eligibilityID = ref(null);

  let userInputs = ref({
    title: null,
    standard: null,
    projectType: null,
    answers: [{ country: null }, { state: null }, { site_accuracy: null }],
    createdAt: new Date(),
    updatedAt: new Date(),
    reportSentDate: null,
    reportSent: false,
    reportUrl: null,
    fileUpload: null,
  });
  let questions = ref([]);
  let totalScore = ref(0);

  let infoModalState = ref(false);
  let infoModalTitle = ref(null);
  let infoModalBody = ref(null);

  let geojsonPolygons = ref([]);
  let geojsonMarkers = ref([]);

  // Methods
  const fetchQuestions = {
    gsARR: () => (questions.value = gsARR),
    gsICS: () => (questions.value = gsICS),
    gsICSSimplified: () => (questions.value = gsICSSimplified),
    gsECS: () => (questions.value = gsECS),
    vcsARR: () => (questions.value = vcsARR),
    vcsvm0033: () => (questions.value = vcsvm0033),
    vcsvmr0006: () => (questions.value = vcsvmr0006),
    default: () => (questions.value = []),
  };

  const goBack = () => {
    router.push("/eligibility");
    return;
  };

  const {
    isLoading: isEligibilityLoading,
    isError: isEligibilityError,
    error: eligibilityError,
    mutate: eligibilityMutate,
  } = useMutation((id) => eligibilityStore.getEligibility(id), {
    onSuccess: (data) => {
      let responses = data.responses || null;
      console.info({ data });
      const { id, title, createdAt, updatedAt, reportSentDate, reportSent, reportUrl, fileUpload } =
        data;
      const { answers, standard, projectType } = responses;
      let questionnaire = null;
      if (projectType) questionnaire = projectType.questionnaire;
      if (!responses) return;
      userInputs.value = {
        projectType,
        createdAt,
        updatedAt,
        standard,
        answers,
        title,
        reportSentDate,
        reportSent,
        reportUrl,
        fileUpload,
        id,
      };
      if (questionnaire) fetchQuestions[questionnaire]();

      // Map questions and answers
      // Check off any questions with multiple:true and answers.length > 0 and update them from the userInputs.value.answers
      questions.value.forEach((question) => {
        if (question.multiple && userInputs.value.answers[question.id]) {
          if (question.sdgs) {
            // Map the sdgList and set checked to true if the userInputs.value.answers[question.id] matches the sdg.id
            console.info("SDGs");
            sdgList.forEach((sdg) => {
              const checked = userInputs.value.answers[question.id]?.options.find(
                (answer) => answer.id === sdg.id,
              );
              if (checked) {
                sdg.checked = true;
              }
            });
          } else {
            question.options.forEach((option) => {
              // Find the userInputs.value.answers[question.id]?.options that matches the option.id and set the option.checked to true
              const checked = userInputs.value.answers[question.id]?.options.find(
                (answer) => answer.id === option.id,
              );
              if (checked) {
                option.checked = true;
              }
            });
          }
        }
      });

      // If fileUploaded, parse it to display the map
      if (userInputs.value.fileUpload) {
        const file = userInputs.value.fileUpload;
        let featuresArray = [];

        if (!file) return;

        if (typeof file === "object" && !file.features) {
          featuresArray = Object.entries(file).map(([key, value]) => value);
        } else {
          featuresArray = file.features;
        }
        console.info({ featuresArray });

        let tempPolygons = [];
        let tempMarkers = [];

        featuresArray.map((feature) => {
          if (feature.geometry.type === "Polygon") {
            tempPolygons.push(feature.geometry.coordinates[0]);
          }
          if (feature.geometry.type === "MultiPolygon") {
            feature.geometry.coordinates.forEach((coordinates) => {
              tempPolygons.push(coordinates[0]);
            });
          }
          if (feature.geometry.type === "LineString") {
            tempPolygons.push(feature.geometry.coordinates);
          }
          if (feature.geometry.type === "Point") {
            tempMarkers.push(feature.geometry.coordinates);
          }
        });

        geojsonPolygons.value = tempPolygons;
        geojsonMarkers.value = tempMarkers;
      }

      // If report sent, calculate the score
      if (reportSent) {
        if (!answers) return;

        const keys = Object.keys(answers).map(Number);

        // Get all the questions that match the keys
        const questionsFromAnswers = questions.value.filter((question) =>
          keys.includes(question.id),
        );

        const scoresFromAnswers = questionsFromAnswers.map((question) => {
          const answer = answers[question.id].label;
          const { logic } = question;
          const logicScore = logic[answer];
          return logicScore;
        });

        // Add up all the scores and add to totalScore.value
        totalScore.value = (
          scoresFromAnswers.reduce((acc, score) => acc + score, 0) * 100
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  });

  const {
    isLoading: isUpdateReportLoading,
    isError: isUpdateReportError,
    error: updateReportError,
    mutate: updateReportMutate,
  } = useMutation((id) => eligibilityStore.updateEligibility(id), {
    onSuccess: (data) => {
      console.info({ data });
      eligibilityStore.eligibilityModalOpen = true;
    },
    onError: (error) => {
      console.error({ error });
      eligibilityStore.eligibilityModalOpen = false;
    },
  });

  const {
    isLoading: isDeleteReportLoading,
    isError: isDeleteReportError,
    error: deleteReportError,
    mutate: deleteReportMutate,
  } = useMutation((id) => eligibilityStore.deleteEligibility(id), {
    onSuccess: (data) => {
      console.info({ data });
      eligibilityStore.eligibilityModalOpen = false;
    },
    onError: (error) => {
      console.error({ error });
      eligibilityStore.eligibilityModalOpen = false;
    },
  });

  const onUpdateReportSubmit = () => {
    console.info("onUpdateReportSubmit", userInputs.value);
    eligibilityStore.eligibility = userInputs.value;
    if (!eligibilityStore.eligibility) return;

    logEvent("Update eligibility report");
    updateReportMutate(eligibilityID.value);
  };

  const onDeleteReport = () => {
    eligibilityStore.toggleDeleteModal();
  };

  const onDeleteReportConfirm = () => {
    console.info("onDeleteReport", userInputs.value);
    if (!userInputs.value.id) return;

    if (userInputs.value.draft) {
      eligibilityStore.deleteDraftEligibilityById(userInputs.value.id);
    } else {
      deleteReportMutate(userInputs.value.id);
    }

    logEvent("Delete eligibility report");

    // Push to /eligibility and reload the page using router
    router.push("/eligibility", {
      query: {
        reload: true,
      },
    });
  };

  const onCheckboxesChange = (event, questionId, option) => {
    console.info("onCheckboxesChange", event, questionId, option);
    // If event is pointerEvent, return
    if (event instanceof PointerEvent) return;

    const { id } = option;
    const { value } = event.target;
    option.checked = value === "on" ? true : false;
    console.info({ value });
    const question = questions.value[questionId];
    const answer = userInputs.value.answers[questionId];
    console.info({ question, answer });

    // If no answer, we need to push the question object to the answers array while keeping the other options in the answer.options array
    if (!answer) {
      console.info("No answer, pushing question to answers array");
      userInputs.value.answers[questionId] = {
        question,
        options: [option],
        multiple: question.multiple || true,
      };
    }
    // Otherwise we want to check if the userInputs.value.answers[questionId].options contains this option object already (if so remove it), else add the option object
    else {
      console.info("Answer exists, checking if option exists in answer.options");
      const optionIndex = answer.options.findIndex((o) => o.id === id);
      console.info({ optionIndex });
      if (optionIndex > -1) {
        console.info("Option exists, removing option from answer.options");
        answer.options.splice(optionIndex, 1);
      } else {
        console.info("Option does not exist, adding option to answer.options");
        answer.options.push(option);
      }
    }
  };

  const openInfoModal = (question) => {
    infoModalState.value = true;
    infoModalTitle.value = question.label;
    infoModalBody.value = question.info;
  };

  onMounted(() => {
    logPageView(route.name);
    const { id } = route.params;
    eligibilityID.value = id;
    eligibilityMutate(eligibilityID.value);
  });
</script>

<template>
  <div class="onboarding-container grid w-full grid-cols-1 md:grid-cols-12">
    <EligibilityInfoModal
      :showModal="infoModalState"
      :title="infoModalTitle"
      :body="infoModalBody"
      @on-close="infoModalState = false"
    />
    <EligibilityStateModal
      :showModal="eligibilityStore.deleteModalOpen"
      :onDeleteReportConfirm="onDeleteReportConfirm"
      type="delete"
    />
    <EligibilityStateModal
      :showModal="eligibilityStore.eligibilityModalOpen"
      type="edit"
    />
    <div class="col-span-1 p-4 md:col-span-8 md:p-8">
      <div
        class="container relative mx-auto mb-8 flex flex-col justify-between space-y-5 md:mb-24 md:max-w-4xl md:flex-row md:items-center md:space-y-0"
      >
        <div
          class="absolute top-0 left-0 right-0 flex w-full flex-row items-center justify-between text-sm text-gray-400"
        >
          <small
            @click="goBack"
            class="flex w-full max-w-[10rem] cursor-pointer flex-row items-center gap-2 underline hover:text-emerald-500"
          >
            <ArrowLeftIcon class="h-4 w-4" />
            Back
          </small>
          <span class="w-full max-w-[10rem] text-right">
            <small v-if="userInputs.updatedAt">
              Last updated: {{ moment(userInputs.updatedAt).from() }}
            </small>
            <small v-else> Last saved: 0 mins ago </small>
          </span>
        </div>
      </div>
      <!-- Empty state -->
      <Loader v-if="isEligibilityLoading" />
      <!-- Loaded -->
      <div
        v-else
        class="container mx-auto md:max-w-xl"
      >
        <div
          class="mb-12 flex w-full flex-col items-start justify-items-stretch gap-4 md:justify-items-start"
        >
          <h1 class="text-3xl font-semibold dark:text-gray-300 md:text-4xl">
            View or edit your eligibility report
          </h1>
          <p class="inline-block w-full text-lg text-gray-600">
            Created on: {{ moment(userInputs.createdAt).format("DD MMMM Do YYYY") }}
          </p>
          <a
            v-if="userInputs.reportUrl"
            class="w-full rounded-lg bg-emerald-500 p-4 text-center font-bold text-white hover:bg-emerald-600 md:w-auto"
            :href="userInputs.reportUrl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download report
          </a>
        </div>
        <Input
          placeholder="Edit title for this report"
          label="Edit title for this report"
          v-model="userInputs.title"
          autocomplete="off"
          type="text"
          name="reportTitle"
          id="reportTitle"
          required
          chunky
          full
        />
        <div
          v-if="userInputs.standard && userInputs.projectType"
          class="flex flex-col items-stretch gap-4 align-middle text-gray-600 dark:text-gray-500 md:flex-row md:items-center"
        >
          <div class="w-full">
            <label class="mb-2 block text-base font-medium"> Registry (standard) </label>
            {{ userInputs.standard.label || `No registry` }}
          </div>
          <div class="hidden h-full w-[1px] border border-gray-100 dark:border-gray-800 md:block">
            &nbsp;
          </div>
          <div class="w-full">
            <label class="mb-2 block text-base font-medium"> Project type </label>
            {{ userInputs.projectType.label || `No project type` }}
          </div>
        </div>
        <div
          v-if="userInputs.fileUpload"
          class="mt-8 flex flex-col gap-4 text-indigo-500"
        >
          GeoJSON file has already been uploaded
          <!-- Mapbox -->
          <div class="flex w-full flex-col gap-4 overflow-hidden rounded-lg">
            <mapbox-map
              :accessToken="mapboxAccessKey"
              mapStyle="light-v10"
              height="32rem"
              :zoom="1"
            >
              <mapbox-geogeometry-polygon
                v-for="path in geojsonPolygons"
                :path="path"
              />
              <mapbox-marker
                v-for="marker in geojsonMarkers"
                :lngLat="marker"
              />
            </mapbox-map>
          </div>
        </div>
        <div
          v-if="userInputs.answers.country"
          class="mt-4 flex flex-col items-stretch gap-4 align-middle text-gray-600 dark:text-gray-500 md:flex-row md:items-center"
        >
          <div class="w-full">
            <label class="mb-2 block text-base font-medium"> Country </label>
            {{ userInputs.answers.country.name || `No country specified` }}
          </div>
          <div class="hidden h-full w-[1px] border border-gray-100 dark:border-gray-800 md:block">
            &nbsp;
          </div>
          <div class="w-full">
            <label class="mb-2 block text-base font-medium"> State </label>
            {{ userInputs.answers.state.name || `No state specified` }}
          </div>
        </div>
        <div
          v-show="questions && questions.length"
          class="flex w-full flex-col gap-4"
        >
          <Divider margin="mt-8 md:mt-12 mb-4 md:mb-8" />
          <p
            v-if="userInputs.reportSent"
            class="mb-8 text-gray-700 dark:text-gray-400"
          >
            Since a report has been sent, you cannot modify your answers, you can only update the
            title for your report.
            <br />
            <br />
            Below you'll find recommendations, if they have been provided, for your answers in order
            to improve the likelihood your project can generate carbon credits.
          </p>
          <!-- Map and show the questions from the questionnaire by project type -->
          <div
            v-for="(question, index) in questions"
            :key="question.id"
            class="w-full"
          >
            <div class="flex flex-row items-start justify-start gap-4 md:justify-between">
              <label
                class="mb-2 block whitespace-pre-line text-base font-medium text-gray-600 dark:text-gray-600"
              >
                {{ question.label }}
              </label>
              <button
                v-show="question.info"
                class="rounded-full bg-gray-50 p-1 text-emerald-500 hover:bg-emerald-500 hover:text-white dark:bg-gray-800"
                @click="openInfoModal(question)"
              >
                <InformationCircleIcon class="h-5 w-5" />
              </button>
            </div>
            <!-- Store in the userInputs.answers object by question id -->
            <v-select
              v-if="question.options && !question.multiple"
              placeholder="Select a response"
              class="sq-v-s mb-4"
              v-model="userInputs.answers[question.id]"
              :disabled="userInputs.reportSent"
              :options="
                question.options?.map((option) => ({
                  label: option,
                  question: question.label,
                }))
              "
              :clearable="false"
              required
            />
            <!-- If multiple, we need to show the <Checkbox /> component with a list of options from question.options -->
            <div
              v-if="question.options && question.multiple"
              class="flex flex-col gap-1"
            >
              <Checkbox
                v-for="option in question.options"
                class="w-full py-2"
                text-class="text-base font-normal text-gray-700 dark:text-gray-500"
                :key="option.id"
                :id="`${question.id}-${option.label}`"
                :name="`${question.id}-${option.label}`"
                :text="option.label"
                :checked="option.checked"
                @change="onCheckboxesChange($event, question.id, option)"
              />
            </div>
            <!-- If question.sdgs === true, show the <Checkbox /> component with a list of SDGs -->
            <div
              v-if="question.sdgs"
              class="flex flex-col gap-1"
            >
              <Checkbox
                v-for="sdg in sdgList"
                class="w-full py-2"
                text-class="text-base font-normal text-gray-700 dark:text-gray-500"
                :key="sdg.id"
                :id="`sdg-${sdg.id}`"
                :name="`sdg-${sdg.id}`"
                :text="`${sdg.label}: ${sdg.description}`"
                :checked="sdg.checked"
                @change="onCheckboxesChange($event, question.id, sdg)"
              />
            </div>
            <div
              v-if="
                question.logic &&
                question.logic[userInputs.answers[question.id]?.label] &&
                userInputs.reportSent
              "
              class="mb-4 flex w-full flex-row items-start justify-between gap-2"
            >
              <strong class="font-semibold text-indigo-500">
                Recommendation:
                {{ userInputs.answers[question.id]?.recommendation || `No recommendation` }}
              </strong>
              <small class="flex-shrink-0 text-right text-gray-700 dark:text-gray-400">
                Score:
                {{
                  question.logic[userInputs.answers[question.id]?.label].toLocaleString("en-US", {
                    maximumFractionDigits: 4,
                  }) * 100
                }}%
              </small>
            </div>
          </div>
        </div>

        <div class="sticky bottom-0 z-10 flex flex-col gap-4 py-8">
          <div class="flex w-full flex-row justify-between gap-4 md:gap-8">
            <Button
              text="Delete report"
              type="button"
              @click="onDeleteReport"
              :loading="isDeleteReportLoading"
              classes="w-auto border-amber-100 bg-amber-50 text-amber-500 hover:border-amber-600 hover:bg-amber-500 hover:text-white dark:border-amber-800 dark:bg-amber-900 dark:text-amber-600 dark:hover:bg-amber-500"
              chunky
            />
            <Button
              text="Update report"
              type="button"
              @click="onUpdateReportSubmit"
              :loading="isUpdateReportLoading"
              :disabled="userInputs.reportSent || isEligibilityLoading"
              chunky
            />
          </div>

          <span
            v-if="isUpdateReportError"
            class="mt-10 block text-sm text-red-400"
            >{{ updateReportError }}</span
          >
        </div>
      </div>
    </div>

    <div
      class="relative top-0 col-span-1 h-auto bg-gray-50 py-8 px-8 dark:bg-gray-900 md:sticky md:col-span-4 md:h-screen md:py-32"
    >
      <div
        v-show="!isEligibilityLoading"
        class="flex flex-col items-stretch gap-4 md:max-w-lg"
      >
        <div class="flex flex-col items-stretch gap-2">
          <span class="block text-2xl font-semibold dark:text-gray-300">
            Your eligibility report
          </span>
          <p
            v-if="!userInputs.reportUrl"
            class="text-gray-500"
          >
            Your downloadable report will be shown here after our team has carefully reviewed your
            responses.
            <br />
            <br />
            To get your report, please make sure you have paid for the invoice we sent to your
            email. If you have paid and not received your report, please email us at
            <a
              class="underline hover:text-emerald-500"
              href="mailto:hello@sequestr.io"
              >hello@sequestr.io</a
            >.
          </p>
          <p
            class="text-gray-500"
            v-else
          >
            You'll find your eligibility question responses and recommendations, in addition you can
            download your report as a
            <a
              v-if="userInputs.reportUrl"
              class="text-emerald-500 underline hover:text-emerald-800"
              :href="userInputs.reportUrl"
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF here </a
            >.
          </p>
        </div>
        <!-- Score if paid -->
        <div v-if="userInputs.reportSent">
          <Divider />
          <div class="flex flex-col gap-8">
            <div class="flex flex-row items-center justify-between">
              <strong class="text-xl font-semibold text-gray-700 dark:text-gray-300"
                >Eligibility score</strong
              >
              <span
                :class="`${
                  totalScore < 0
                    ? 'text-red-500'
                    : totalScore > 0 && totalScore <= 50
                    ? 'text-amber-500'
                    : 'text-emerald-500'
                }`"
              >
                {{ totalScore }}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
