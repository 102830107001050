<template>
  <TransitionRoot
    appear
    :show="open"
    as="template"
  >
    <Dialog as="div">
      <div class="fixed inset-0 z-30 overflow-y-auto bg-black/20 backdrop-blur-sm">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
            >
              <DialogTitle
                as="h3"
                class="mb-4 text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
              >
                Company Invite
              </DialogTitle>
              <div
                v-if="isGetInviteDetailsLoading"
                class="align-center mx-auto flex justify-center py-6 text-black"
              >
                <Loader />
              </div>
              <small
                class="mb-5 inline-block w-full text-sm text-red-400"
                v-if="isGetInviteDetailsError"
              >
                Error: {{ getInviteDetailError }}
              </small>
              <div v-if="teamInviteCompanyName">
                <div class="mb-6">
                  <p class="mb-4 text-sm text-gray-500">
                    You've been invited to join {{ teamInviteCompanyName }}!
                  </p>
                </div>

                <small
                  class="mb-5 inline-block w-full text-sm text-red-400"
                  v-if="isAcceptInviteError || isGetInviteDetailsError"
                >
                  Error: {{ acceptInviteError || getInviteDetailError }}
                </small>

                <div class="flex flex-row gap-4">
                  <Button
                    :loading="isAcceptInviteLoading || isGetInviteDetailsLoading"
                    :disabled="isAcceptInviteLoading"
                    text="Accept"
                    type="button"
                    @click="acceptInvite"
                  />
                  <Button
                    text="Close"
                    classes="rounded-md border-0 border-gray-200 bg-gray-100 px-2 py-1 text-sm text-gray-900 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700 shadow-none"
                    type="button"
                    @click="props.onCloseModalClick"
                  />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";
  import Button from "@/components/Button.vue";
  import { useMutation, useQuery } from "vue-query";
  import { api } from "@/utils/axios";
  import { computed, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import Loader from "@/components/Loader.vue";
  import { useAuthStore } from "@/store/authStore";

  const teamInviteCompanyName = ref(null);
  const inviteCode = ref(null);

  const props = defineProps({
    onCloseModalClick: Function,
    open: Boolean,
  });

  const authStore = useAuthStore();
  const router = useRouter();
  const route = useRoute();
  inviteCode.value = route.query?.inviteCode || null;

  const {
    isLoading: isGetInviteDetailsLoading,
    isError: isGetInviteDetailsError,
    error: getInviteDetailError,
  } = useQuery(
    ["companyInvite", inviteCode],
    () =>
      api.get("company-invites", {
        params: {
          inviteCode: inviteCode.value,
        },
      }),
    {
      enabled: computed(() => !!inviteCode.value),
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        teamInviteCompanyName.value = data.title;
      },
    },
  );
  const {
    isLoading: isAcceptInviteLoading,
    isError: isAcceptInviteError,
    error: acceptInviteError,
    mutate: acceptInvite,
  } = useMutation(
    () =>
      api.post("company-invites/accept", {
        inviteCode: inviteCode.value,
      }),
    {
      onSuccess: ({ data }) => {
        authStore.user = data;
        inviteCode.value = null;
        props.onCloseModalClick();
        router.replace({
          ...router.currentRoute.value,
          query: {
            ...(route?.query?.tab && { tab: route.query.tab }),
          },
        });
      },
    },
  );
</script>
