<template>
  <router-link
    v-if="to"
    :class="[
      `
        drop-shadow-
        flex
        flex-row
        items-center
        justify-between
        rounded-md
        border
        text-center
        text-base
        font-bold
        leading-6
        backdrop-blur-xl
        transition
        disabled:cursor-not-allowed
        disabled:bg-gray-200
        disabled:drop-shadow-none
        dark:disabled:bg-gray-500
      `,
      setChunky,
      setClasses,
      setFull,
      setSquare,
    ]"
    :disabled="disabled"
    :loading="loading"
    :to="to"
  >
    <Loader v-show="loading" />
    <SocialIcon
      v-if="socialIcon"
      :class="['w-5', square ? 'mr-0' : 'mr-1']"
      :type="socialIcon"
    />
    <component
      v-if="iconLeft"
      :class="['w-5', square ? 'mr-0' : 'mr-1']"
      :is="setIconLeft"
    />
    <div
      v-if="text"
      class="flex-grow"
    >
      {{ text }}
    </div>
    <component
      v-if="iconRight"
      :class="['w-5', square ? 'ml-0' : 'ml-1']"
      :is="setIconRight"
    />
  </router-link>
  <button
    v-else
    :aria-disabled="disabled"
    :class="[
      `
        flex
        flex-row
        items-center
        justify-between
        rounded-md
        border
        text-center
        text-base
        font-bold
        leading-6
        shadow-sm
        backdrop-blur-xl
        transition
        disabled:cursor-not-allowed
        disabled:bg-gray-200
        disabled:shadow-none
        dark:disabled:bg-gray-500
      `,
      setChunky,
      setClasses,
      setFull,
      setSquare,
    ]"
    :disabled="disabled"
    :loading="loading"
    :type="type"
  >
    <Loader v-show="loading" />
    <SocialIcon
      v-if="socialIcon"
      :class="['w-5', square ? 'mr-0' : 'mr-1']"
      :type="socialIcon"
    />
    <component
      v-if="iconLeft"
      :class="['w-5', square ? 'mr-0' : 'mr-1']"
      :is="setIconLeft"
    />
    <div
      v-if="text"
      class="flex-grow"
    >
      {{ text }}
    </div>
    <component
      v-if="iconRight"
      :class="['w-5', square ? 'ml-0' : 'ml-1']"
      :is="setIconRight"
    />
  </button>
</template>

<script>
  import { computed } from "vue";
  import * as solid from "@heroicons/vue/24/solid";
  import * as outline from "@heroicons/vue/24/outline";

  import Loader from "./Loader.vue";
  import SocialIcon from "./SocialIcon.vue";

  export default {
    components: {
      Loader,
      SocialIcon,
    },
    props: {
      disabled: Boolean,
      loading: Boolean,
      loaderColor: String,
      chunky: Boolean,
      classes: String,
      color: String,
      iconLeft: String,
      iconRight: String,
      iconOutline: {
        default: true,
        type: Boolean,
      },
      full: {
        default: false,
        type: Boolean,
      },
      socialIcon: String,
      square: {
        default: false,
        type: Boolean,
      },
      type: {
        default: "button",
        type: String,
      },
      text: String,
      to: String,
      width: {
        default: null,
        type: String,
      },
    },
    setup(props) {
      const {
        color,
        classes,
        chunky,
        disabled,
        full,
        iconOutline,
        iconLeft,
        iconRight,
        loaderColor,
        loading,
        socialIcon,
        square,
        type,
        to,
        width,
      } = props;

      const icons = { solid, outline };
      const setIconLeft = computed(() => {
        return icons[iconOutline ? "outline" : "solid"][`${iconLeft}`] || null;
      });
      const setIconRight = computed(() => {
        return icons[iconOutline ? "outline" : "solid"][`${iconRight}`] || null;
      });

      let setClasses = classes
        ? classes
        : "text-white bg-emerald-500 border-emerald-500 hover:bg-emerald-600 hover:border-emerald-600 disabled:border-gray-200 dark:disabled:border-600";

      let setChunky = chunky ? "px-5 py-4" : "px-4 py-2";
      // If width is defined, use width, otherwise set full to true or use w-auto
      let setFull = width ? width : full ? "w-full" : "w-auto";
      let setSquare = square && chunky ? "p-4" : square && !chunky ? "p-2" : "";

      return {
        icons,
        loaderColor,
        setIconLeft,
        setIconRight,
        setFull,
        setChunky,
        setClasses,
        setSquare,
        socialIcon,
        square,
        type,
        to,
      };
    },
  };
</script>
