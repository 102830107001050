import { defineStore } from "pinia";
import { api } from "@/utils/axios";

export const useCompanyStore = defineStore("companyStore", {
  state: () => ({
    company: null,
  }),
  actions: {
    async getCompany(slug) {
      const { data } = await api.get(`company/${slug}`);
      this.company = data;
      return data;
    },
    async getCompanyMembers() {
      const { data } = await api.get("company/members");
      return data;
    },
    async deleteMember(id) {
      const { data } = await api.delete(`company/members/${id}`);
      return data;
    },
    clear() {
      this.company = null;
    },
  },
});
