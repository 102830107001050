<template>
  <div
    class="grid w-full grid-flow-row auto-rows-max grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3"
  >
    <div
      v-for="project in projects"
      :key="project.id"
      class="mb-4 flex flex-col gap-2"
    >
      <router-link
        :to="!project.slug ? `` : `/project/${project.slug}`"
        :class="[
          'relative flex w-full flex-col gap-4 overflow-hidden ',
          project.soon || !project.slug
            ? 'cursor-not-allowed text-gray-400'
            : 'text-gray-900 hover:text-emerald-500  dark:text-gray-300',
        ]"
      >
        <!-- Image, name & methodology  -->
        <div class="flex flex-col gap-1">
          <strong
            class="overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold lg:text-2xl"
          >
            {{ project.name }}
          </strong>
          <div class="flex w-full flex-row items-center justify-between gap-2 align-middle">
            <div
              class="flex w-full flex-1 flex-row items-center justify-start gap-1 overflow-hidden text-ellipsis whitespace-nowrap align-middle text-gray-500 dark:text-gray-600"
            >
              <div
                v-if="project.mechanism"
                class="flex-0 flex w-auto flex-row items-center justify-start gap-1 align-middle"
              >
                <div
                  v-if="project.soon"
                  class="text-sm font-medium capitalize text-blue-500"
                >
                  Launching soon
                  <span>•</span>
                </div>
                <div class="text-sm font-medium capitalize">
                  {{ project.mechanism }}
                  <span>•</span>
                </div>
              </div>
              <div
                v-if="project.location && project.location.address_components"
                class="text-sm font-medium"
              >
                {{ project.location?.formatted_address || "No location specified" }}
              </div>
              <div
                v-else
                class="text-sm font-medium"
              >
                No location
              </div>
            </div>
          </div>
        </div>
        <div
          :class="`justify-middle relative flex h-64 flex-col items-center rounded-md align-middle ${
            project.images?.length ? null : 'bg-gray-50 dark:bg-gray-900'
          }`"
        >
          <!-- Status -->
          <span
            v-if="project.projectStatus"
            class="absolute bottom-[-0.8125rem] right-4 z-[1] rounded-lg border-blue-100 bg-blue-50/50 px-3 py-1 text-sm font-semibold text-blue-600 shadow-sm backdrop-blur-lg"
          >
            {{ project.type }}
          </span>
          <img
            v-if="project.images?.length"
            :src="project.images[0]"
            :alt="project.name"
            class="absolute z-0 order-first h-full w-full rounded-lg object-cover"
          />
          <div
            class="font-bolder flex h-full w-full flex-1 items-center justify-center text-center align-middle text-lg text-gray-200 dark:text-gray-600"
            v-else
          >
            No image
          </div>
        </div>
        <div class="details flex w-full flex-col gap-4">
          <div class="flex w-full flex-col">
            <p
              class="mb-4 h-auto w-full overflow-hidden text-ellipsis text-sm font-medium leading-7 text-gray-600 dark:text-gray-500 md:mb-0"
              v-snip="2"
            >
              {{ project.projectData?.story?.context || `No description provided` }}
            </p>
          </div>
        </div>
      </router-link>
      <ProjectCreator
        v-if="project.company"
        :company="project.company"
        size="small"
      />
    </div>
  </div>
</template>

<script setup>
  import ProjectCreator from "./ProjectCreator.vue";
  import Tag from "./Tag.vue";

  import { useAuthStore } from "@/store/authStore";
  const authStore = useAuthStore();

  const props = defineProps({
    projects: {
      type: Array,
      default: [],
    },
  });

  let { projects } = props;
  console.info({ projects });

  const getProjectStatus = (status) => {
    switch (status) {
      default:
        return null;
      case "pending_creation":
        return "Draft";
      case "requires_additional_work":
      case "pending_verification":
        return "Pending Approval";
      case "verified":
        return "Verified";
      case "unlisted":
        return "Unlisted";
    }
  };

  // const extractFromAddress = (components, type) => {
  //   if (!components || !type) return "No location";
  //   return (
  //     components
  //       .filter((component) => component.types.indexOf(type) === 0)
  //       .map((item) => item.long_name)
  //       .pop() || null
  //   );
  // };
</script>
