<script setup>
  import { ref } from "vue";

  import DoughnutChart from "@/components/Charts/DoughnutChart.vue";
  import BarChart from "../Charts/BarChart.vue";

  const props = defineProps({
    credits: {
      type: Array,
      default: [],
      required: false,
    },
    emissions: {
      required: false,
      current_year: {
        scope_1: Number,
        scope_2: Number,
        scope_3: Number,
        total: Number,
      },
      projections: [
        {
          year: Number,
          scope_1: Number,
          scope_2: Number,
          scope_3: Number,
        },
      ],
    },
  });

  let { credits, emissions } = props;

  let currentYearTotalEmissions = ref(emissions.current_year?.total || 0);
  let emissionsByYear = ref([]);
  let offsetsAndEmissionsData = ref([]);
  let offsetsAndEmissionsDataOptions = ref({
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: false,
        text: "Emissions by Year",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Year",
          font: {
            size: 14,
          },
        },
        stacked: true,
      },
      y: {
        title: {
          display: false,
          text: "Emissions (tons)",
          font: {
            size: 14,
          },
        },
        stacked: true,
      },
    },
  });

  // // Add current_year scope_1, scope_2, scope_3, and total to currentYearTotalEmissions.value
  // Object.keys(emissions.current_year).forEach((key) => {
  //   currentYearTotalEmissions.value += emissions.current_year[key];
  // });

  // Map the emissions.current_year scope_1, scope_2, and scope_3 to the doughnut chart, remove the "total" key
  let colors = ["#94a3b8", "#fbbf24", "#3b82f6"];
  // Remove the last key
  let filteredKeys = Object.keys(emissions.current_year).filter((key) => key !== "total");
  console.info({ filteredKeys });

  let chartData =
    ref({
      labels: filteredKeys,
      datasets: [
        {
          // Generate background colors for each location depending on the number of locations from the colors array
          backgroundColor: filteredKeys.map((_, index) => colors[index]),
          data: filteredKeys.map((key) => emissions.current_year[key]),
          cutout: "80%",
          spacing: 2,
        },
      ],
    }) || [];

  console.info({ chartData: chartData.value });

  // Map the emissions.projections to the table data into an array of objects that look like [{ year: 2023, emissions: 1000 }]
  emissionsByYear.value = emissions.projections.map((year) => {
    return {
      year: year.year,
      emissions: year.scope_1 + year.scope_2 + year.scope_3,
    };
  });

  // Map creditsPurchasedByYear by the "annual_data" key inside credits (props) array
  // the credits array looks like this: [annual_data: [2023: { credits: Number, investment_amount: Number } ]]
  let projectsAnnualData = credits.map((credit) => {
    return credit.annual_data;
  });

  // If projectsAnnualData is not empty, map the annual_data from this object and return the credits and investment_amount by year (add all the years and their credits together)
  let creditsPurchasedByYear = {};

  if (projectsAnnualData.length > 0) {
    projectsAnnualData.forEach((project) => {
      Object.keys(project).forEach((year) => {
        if (creditsPurchasedByYear[year]) {
          creditsPurchasedByYear[year].credits += project[year].credits;
          creditsPurchasedByYear[year].investment_amount += project[year].investment_amount;
        } else {
          creditsPurchasedByYear[year] = {
            credits: project[year].credits,
            investment_amount: project[year].investment_amount,
          };
        }
      });
    });
  }

  console.info({
    emissionsByYear: emissionsByYear.value,
    creditsPurchasedByYear: creditsPurchasedByYear,
  });

  // Map the emissionsByYear and creditsPurchasedByYear to the bar chart. emissionsByYear should subtract the creditsPurchasedByYear from the total emissions by each year. If the emissions in a year are not defined or the year is not given, move on
  offsetsAndEmissionsData.value = {
    labels: Object.keys(emissionsByYear.value).map((year) => {
      return emissionsByYear.value[year].year;
    }),
    datasets: [
      {
        label: "Emissions",
        data: Object.keys(emissionsByYear.value).map((year) => {
          if (creditsPurchasedByYear[emissionsByYear.value[year].year]) {
            return (
              emissionsByYear.value[year].emissions -
              creditsPurchasedByYear[emissionsByYear.value[year].year].credits
            );
          } else {
            return emissionsByYear.value[year].emissions;
          }
        }),
        backgroundColor: "#6b7280",
        barThickness: 20,
      },
      {
        label: "Offsets",
        data: Object.keys(emissionsByYear.value).map((year) => {
          if (creditsPurchasedByYear[emissionsByYear.value[year].year]) {
            return creditsPurchasedByYear[emissionsByYear.value[year].year].credits;
          } else {
            return 0;
          }
        }),
        backgroundColor: "#10b981",
        barThickness: 20,
      },
    ],
  };
</script>

<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <!-- Doughnut Chart -->
      <div class="flex flex-col gap-4 md:gap-6">
        <strong class="text-lg font-semibold text-gray-500">Current Year Emissions</strong>
        <DoughnutChart
          :chartData="chartData"
          :floatingLabel="currentYearTotalEmissions"
          title="Total Emissions"
          format="tonnage"
        />
      </div>
      <!-- Table Data -->
      <div class="flex flex-col gap-4 md:gap-6">
        <strong class="text-lg font-semibold text-gray-500">Projected Emissions by Year</strong>
        <table class="w-full table-auto text-left">
          <thead>
            <tr class="h-8 text-sm text-gray-500">
              <th class="font-medium">Year</th>
              <th class="text-right font-medium">Total Scope 1, 2, 3 Emissions</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="emissionsByYear"
              v-for="row in emissionsByYear"
            >
              <tr
                class="h-12 border-t border-gray-100 py-3 text-sm text-gray-900 dark:border-gray-700 dark:text-gray-400"
              >
                <td class="font-semibold">{{ row.year }}</td>
                <td class="text-right">
                  {{
                    row.emissions.toLocaleString("en-US", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  t
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex flex-col gap-0">
      <h2 class="text-2xl font-semibold dark:text-gray-300">Emissions &amp; Offsets Projections</h2>
      <p class="text-gray-500">Offsets include both removals and avoidance credits.</p>
    </div>
    <BarChart
      :chartData="offsetsAndEmissionsData"
      :options="offsetsAndEmissionsDataOptions"
    />
  </div>
</template>
