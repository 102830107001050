<template>
  <TransitionRoot
    appear
    :show="modalOpen"
    as="template"
  >
    <Dialog as="div">
      <div class="fixed inset-0 z-30 overflow-y-auto bg-black/20 backdrop-blur-sm">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
            >
              <DialogTitle
                as="h3"
                class="mb-4 text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
              >
                {{ title }}
              </DialogTitle>
              <div class="mb-6">
                <p class="mb-4 text-sm text-gray-500">
                  {{ body }}
                </p>
              </div>

              <div class="flex flex-row gap-4">
                <Button
                  text="OK, got it"
                  type="button"
                  @click="onCloseModalClick"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import { ref, watch } from "vue";

  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";

  import Button from "@/components/Button.vue";

  export default {
    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionRoot,
      TransitionChild,
      Button,
    },
    props: {
      showModal: {
        default: false,
        type: Boolean,
      },
      title: String,
      body: String,
    },
    setup(props) {
      let modalOpen = ref(props.showModal);

      watch(
        () => props.showModal,
        () => {
          modalOpen.value = props.showModal;
        },
      );
      return {
        modalOpen,
      };
    },
    methods: {
      onCloseModalClick() {
        this.$emit("on-close");
        this.modalOpen = false;
      },
    },
  };
</script>
