import { defineStore } from "pinia";
import { api } from "@/utils/axios";

export const ProjectCreationSteps = {
  PROJECT_DETAILS: "ProjectDetails",
  PROJECT_IMAGES: "ProjectImages",
  PROJECT_IMPACT: "ProjectImpact",
  PROJECT_ADDITIONALITY: "ProjectAdditional",
  PROJECT_REVIEW: "ProjectReview",
};

const projectDefault = {
  name: "",
  type: null,
  location: null,
  company: {
    id: null,
    title: null,
    slug: null,
    imageUrl: null,
    websiteUrl: null,
  },
  updatedAt: null,
  projectData: {
    story: {
      context: null,
      highlights: [],
    },
  },
};

export const useProjectStore = defineStore("projectStore", {
  state: () => ({
    activeStep: ProjectCreationSteps.PROJECT_DETAILS,
    project: projectDefault,
    projectsSelf: [],
  }),
  getters: {
    getLastSavedTime(state) {
      if (!state.project.updatedAt) {
        return null;
      }
      return new Date(state.project.updatedAt);
    },
    shouldHideBackButton(state) {
      return this.isProjectDetailsStep;
    },
    isProjectDetailsStep(state) {
      return state.activeStep === ProjectCreationSteps.PROJECT_DETAILS;
    },
    isProjectImageStep(state) {
      return state.activeStep === ProjectCreationSteps.PROJECT_IMAGES;
    },
    isProjectImpactStep(state) {
      return state.activeStep === ProjectCreationSteps.PROJECT_IMPACT;
    },
    isProjectAdditionalityStep(state) {
      return state.activeStep === ProjectCreationSteps.PROJECT_ADDITIONALITY;
    },
    isProjectReviewStep(state) {
      return state.activeStep === ProjectCreationSteps.PROJECT_REVIEW;
    },
    hasSelfProjects(state) {
      return state.projectsSelf.length > 1;
    },
    getProjectStatus(state) {
      switch (state.project.projectStatus) {
        default:
          return null;
        case "pending_creation":
          return "Draft";
        case "requires_additional_work":
        case "pending_verification":
          return "Pending Approval";
        case "verified":
          return "Verified";
        case "unlisted":
          return "Unlisted";
      }
    },
    projectDetails(state) {
      return state.project.projectDetails;
    },
    projectImpact(state) {
      return state.project.impact;
    },
  },
  actions: {
    nextStep() {
      switch (true) {
        case this.activeStep === ProjectCreationSteps.PROJECT_DETAILS:
          this.activeStep = ProjectCreationSteps.PROJECT_IMAGES;
          break;
        case this.activeStep === ProjectCreationSteps.PROJECT_IMAGES:
          this.activeStep = ProjectCreationSteps.PROJECT_IMPACT;
          break;
        case this.activeStep === ProjectCreationSteps.PROJECT_IMPACT:
          this.activeStep = ProjectCreationSteps.PROJECT_REVIEW;
          break;
        default:
          break;
      }
    },
    previousStep() {
      switch (true) {
        case this.activeStep === ProjectCreationSteps.PROJECT_IMAGES:
          this.activeStep = ProjectCreationSteps.PROJECT_DETAILS;
          break;
        case this.activeStep === ProjectCreationSteps.PROJECT_IMPACT:
          this.activeStep = ProjectCreationSteps.PROJECT_IMAGES;
          break;
        case this.activeStep === ProjectCreationSteps.PROJECT_REVIEW:
          this.activeStep = ProjectCreationSteps.PROJECT_IMPACT;
          break;
        default:
          break;
      }
    },
    async createProject() {
      const { data } = await api.post("projects", {
        name: this.project.name,
        type: this.project.type.code,
        location: this.project.location,
      });
      this.project = data;
      this.lastSaved = data.updatedAt;
      return data;
    },
    async getAllProjectsSelf() {
      const { data } = await api.get("projects/me");
      this.projectsSelf = data;
      return data;
    },
    async getAllProjects(type) {
      const { data } = await api.get(`projects`, {
        params: {
          type,
        },
      });
      return data;
    },
    async getProjectBySlug(slug) {
      const { data } = await api.get(`projects/${slug}`);
      this.project = data;
      return data;
    },
    async getProjectTypes() {
      const { data } = await api.get("projects/types");
      return data;
    },
    async updateProject(slug, dataToUpdate) {
      const { data } = await api.patch(`projects/${slug}`, dataToUpdate);
      this.project = data;
      return data;
    },
    async removeImageUpload(slug, path) {
      const { data } = await api.delete(`projects/${slug}/upload`, {
        data: {
          path,
        },
      });
      this.project = data;
      return data;
    },
    async purchaseAuction(slug, volume) {
      const { data } = await api.post(`auction/${slug}/purchase`, { volume });
      return data;
    },
    async getLatestAuctionBySlug(slug) {
      const { data } = await api.get(`auction/${slug}`);
      this.project.auction = data;
      return data;
    },
    async getAuctionPurchasesBySlug(slug) {
      const { data } = await api.get(`auction/${slug}/purchases`);
      return data;
    },
    clearProject() {
      this.project = projectDefault;
    },
  },
});
