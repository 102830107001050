<template>
  <div
    class="h-32 w-32 overflow-hidden rounded-full border border-gray-200 dark:border-gray-800 md:h-32 md:w-32"
  >
    <img
      v-if="src"
      class="h-full w-full object-cover"
      :src="`${src}${file ? '' : '?tpl=xlarge'}`"
      :alt="getInitials()"
    />
    <div
      v-else
      class="flex h-full w-full items-center justify-center bg-white text-center text-gray-300"
    >
      {{ getInitials() || `No image` }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      user: {
        default: null,
        type: Object,
      },
      file: {
        default: false,
        type: Boolean,
      },
      src: String,
    },
    setup(props) {
      const getInitials = () => {
        let initials = "";
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

        if (props.user) {
          initials = [...props.user.name.matchAll(rgx)] || [];
          initials = ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();
        }
        return initials;
      };
      return {
        getInitials,
      };
    },
  };
</script>
