<template>
  <div
    v-if="loading"
    class="flex h-[50vh] w-full animate-pulse flex-col items-center justify-center text-center text-5xl font-bold text-gray-300"
  >
    <small class="mx-auto w-full"> Loading profile </small>
  </div>
  <div
    v-if="!loading && noProfile"
    class="flex h-[50vh] w-full flex-col items-center justify-center gap-8 text-center text-5xl font-bold text-gray-300"
  >
    <small class="mx-auto w-full"> This user does not exist </small>
    <div class="mx-auto w-auto">
      <Button
        @click="claimProfile"
        classes="bg-transparent border-2 border-emerald-100 text-emerald-500 shadow-none text-sm w-full py-1 px-2"
        text="Claim this profile"
        type="button"
      />
    </div>
  </div>
  <div
    v-else
    class="w-full"
  >
    <!-- Modal -->
    <TransitionRoot
      appear
      :show="modalOpen"
      as="template"
    >
      <Dialog
        as="div"
        @close="onModalToggle"
      >
        <div class="fixed inset-0 z-10 overflow-y-auto bg-black/20 backdrop-blur-sm">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span
              class="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900"
              >
                <DialogTitle
                  as="h3"
                  class="mb-4 text-xl font-semibold leading-6 text-gray-900 dark:text-gray-500"
                >
                  Saved profile
                </DialogTitle>
                <div class="mb-6">
                  <p class="text-sm text-gray-500">
                    Awesome, your profile is looking good! Share it with the world via Twitter.
                  </p>
                </div>

                <div class="flex flex-row gap-4">
                  <Button
                    classes="
                      bg-blue-50 text-blue-500
                      dark:bg-blue-500 dark:text-blue-50
                      dark:border-blue-800
                      hover:bg-blue-200 hover:text-blue-800
                      shadow-none
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-blue-500
                    "
                    text="Share on Twitter"
                    type="button"
                    @click="shareProfile"
                  />
                  <Button
                    classes="
                      bg-transparent text-gray-800 dark:text-gray-500
                      dark:border-gray-800
                      hover:bg-gray-50 hover:text-gray-800
                      shadow-none
                      focus:outline-none
                      focus-visible:ring-2
                      focus-visible:ring-offset-2
                      focus-visible:ring-gray-800
                    "
                    text="Back home"
                    type="button"
                    @click="backHome"
                  />
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- Inputs -->
    <div class="flex w-full flex-col gap-4">
      <div
        class="relative mb-4 flex w-full flex-col items-center justify-start gap-4 md:flex-row md:justify-between"
      >
        <ProfilePicture
          :user="profile"
          :src="profile.profile_picture && profile.profile_picture.url"
          :file="isFile"
        />
        <div class="relative flex w-full flex-col items-center justify-end gap-4 md:flex-row">
          <input
            @change="onFileChange"
            placeholder="Choose a property photo (optional)"
            type="file"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            class="block w-full truncate text-xs text-gray-500 file:mr-4 file:rounded-md file:border-0 file:bg-emerald-50 file:py-2 file:px-4 file:text-xs file:font-semibold file:text-emerald-700 hover:file:bg-emerald-100 md:w-auto"
          />
          <button
            class="z-1 w-full rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-emerald-500 hover:bg-emerald-500 hover:text-white md:w-auto"
            type="button"
            @click="removeFile"
          >
            Remove image
          </button>
        </div>
      </div>
      <form
        @submit.prevent="onSaveProfile"
        class="flex w-full flex-col gap-4"
      >
        <div class="flex flex-col gap-4 md:flex-row md:gap-6">
          <Input
            placeholder="Your name"
            v-model="profile.name"
            label="Your name*"
            name="name"
            type="text"
            id="name"
            required
            full
          />
          <Input
            placeholder="Your Sequestr username"
            v-model="profile.username"
            label="Your username*"
            name="username"
            type="text"
            :min="3"
            id="username"
            required
            full
          />
        </div>
        <Input
          placeholder="Your email"
          v-model="profile.email"
          label="Your email*"
          helper="Your email can't be changed at this time, this feature is coming soon! For now you can reach out to `hello@sequestr.io` to change your email."
          name="email"
          type="email"
          id="email"
          readonly
          full
        />
        <div class="flex flex-col gap-4 md:flex-row md:gap-6">
          <Input
            placeholder="Set a new password"
            v-model="password"
            autocomplete="new-password"
            helper="Leave blank if you do not wish to change your password."
            label="New password"
            name="new-password"
            type="new-password"
            id="password"
            full
          />
          <Input
            placeholder="Confirm new password"
            v-model="confirmPassword"
            autocomplete="new-password"
            label="Confirm new password"
            name="confirm-password"
            type="new-password"
            id="confirm-password"
            full
          />
        </div>
        <Input
          placeholder="Your bio"
          v-model="profile.bio"
          label="Your bio"
          name="bio"
          type="text"
          id="bio"
          textarea
          full
        />
        <div v-if="editLocation">
          <label
            class="mb-2 inline-block w-full text-sm text-gray-500"
            for="location"
          >
            Your location*
          </label>
          <GMapAutocomplete
            placeholder="Enter a location"
            @place_changed="onPlace"
            v-model="location"
            name="location"
            id="location"
            class="mb-4 w-full rounded-md border border-gray-100 bg-gray-50 px-4 py-2 placeholder-gray-400 invalid:border-pink-500 invalid:text-pink-600 focus:border-emerald-500 focus:outline-none focus:ring-1 focus:ring-emerald-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 disabled:shadow-none dark:border-gray-900 dark:bg-gray-800 dark:text-gray-500"
            required
          >
          </GMapAutocomplete>
        </div>
        <div
          class="relative w-full"
          v-else
        >
          <button
            class="absolute right-0 text-sm text-indigo-400 hover:text-indigo-800 dark:text-gray-400"
            @click="onEditLocation"
          >
            Edit
          </button>
          <Input
            placeholder="Enter your location"
            v-model="location"
            label="Your location"
            name="location"
            type="text"
            id="location"
            readonly
            full
          />
        </div>
        <Input
          placeholder="Your website"
          v-model="profile.external_link"
          label="Your website"
          name="external_link"
          type="url"
          id="external_link"
          full
        />
        <Button
          :loading="profileSaving"
          :disabled="disabled"
          text="Save profile"
          type="button"
          @click="onSaveProfile"
        />
      </form>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from "vue";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { createToast } from "mosha-vue-toastify";

  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
  } from "@headlessui/vue";

  import Button from "@/components/Button.vue";
  import Input from "@/components/Input.vue";
  import ProfilePicture from "@/components/ProfilePicture.vue";

  export default {
    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionRoot,
      TransitionChild,
      Button,
      Input,
      ProfilePicture,
    },
    setup() {
      const router = useRouter();
      const store = useStore();

      let address = ref({});
      let disabled = ref(false);
      let editLocation = ref(false);
      let loading = ref(true);
      let location = ref("No location");
      let modalOpen = ref(false);
      let noProfile = ref(false);
      let profile = ref(null);
      let profileSaving = ref(false);
      let password = ref("");
      let confirmPassword = ref("");
      let isFile = ref(false);
      let file = ref(null);

      profile.value = store.getters.user;

      if (!profile.value) {
        return router.push("/login");
      }

      watch(
        () => profile.value,
        (newValue) => {
          profile.value = newValue;
        },
      );
      console.info("profile: ", profile.value);
      loading = false;

      if (profile.value.location && profile.value.location.data) {
        const lat = profile.value.location.data.lat;
        const lng = profile.value.location.data.lng;

        store.dispatch("getLocationFromLatLng", { lat, lng }).then((resp) => {
          const addr = resp.formatted_address;
          location.value = addr;
        });
      }

      console.info("location value: ", location.value);

      const backHome = () => {
        router.push("/");
        onModalToggle(false);
      };

      const onEditLocation = () => {
        editLocation.value = !editLocation.value;
      };

      const onFileChange = (e) => {
        console.info("onFileChange");
        const readFile = e.target.files[0];
        if (!readFile) {
          return;
        }

        // Set ref value
        isFile.value = true;
        file.value = readFile;
        profile.value.profile_picture = {
          path: null,
          url: URL.createObjectURL(readFile),
        };
      };

      const onModalToggle = (value) => {
        modalOpen.value = value;
      };

      const onPlace = (place) => {
        console.info({ place });
        if (!place) {
          return;
        }

        const locality = place.address_components.find((r) => {
          return r.types.includes("locality");
        });
        let addr = "No location found";
        if (locality.length > 1) {
          addr = locality[0];
        } else {
          addr = locality;
        }

        location.value = addr;
        const lat = place.geometry?.location?.lat();
        const lng = place.geometry?.location?.lng();
        // Location set
        if (profile.value.location) {
          profile.value.location.data.lat = lat;
          profile.value.location.data.lng = lng;
        }
        // No location set
        else {
          profile.value.location = {
            type: "point",
            data: {
              lat: lat,
              lng: lng,
            },
          };
        }
      };

      const onSaveProfile = () => {
        console.info("save profile: ", profile.value);
        disabled.value = true;
        profileSaving.value = true;
        const {
          id,
          name,
          username,
          email,
          bio,
          location,
          profile_picture,
          external_link,
          email_verified,
        } = profile.value;

        // Changing password
        if (password.value && confirmPassword.value) {
          if (password.value !== confirmPassword.value) {
            disabled.value = false;
            profileSaving.value = false;
            return window.alert("Your new password must match the confirmation input");
          }

          store
            .dispatch("setNewPassword", {
              email: email,
              auth_token: localStorage.getItem("token"),
              password: password.value,
              confirm_password: confirmPassword.value,
            })
            .then((resp) => {
              console.info({ resp });
              disabled.value = false;
              profileSaving.value = false;
              createToast("Your password has been updated", {
                position: "bottom-right",
              });
              password.value = "";
              confirmPassword.value = "";
            })
            .catch((err) => {
              console.info({ err });
              if (err.response.status === 403) {
                return router.push("/login");
              }
              window.alert("Something went wrong, reload the page and try again or contact us");
              return router.push("/login");
            });
        }

        const updatedProfile = {
          id,
          name,
          username,
          email,
          bio,
          location,
          external_link,
          email_verified,
          ...(profile_picture && profile_picture.path && { profile_picture: profile_picture }),
          ...(file.value && { image_file: file.value }),
        };
        store
          .dispatch("updateProfile", updatedProfile)
          .then((resp) => {
            console.info("updated profile: ", resp);
            onModalToggle(true);
            disabled.value = false;
            profileSaving.value = false;
          })
          .catch((err) => {
            console.info({ err });
            if (err.response.data.message.includes("Duplicate record")) {
              window.alert(`That username is already taken, please try another!`);
            } else {
              window.alert(`Something went wrong saving your profile, please try again!`);
            }
            console.warn(err);
          });
      };

      const removeFile = () => {
        file.value = null;
        profile.value.profile_picture = null;
      };

      const shareProfile = () => {
        console.info("Share profile");
        window
          .open(
            `https://twitter.com/intent/tweet?url=https://app.sequestr.io/profile/${profile.value.id}&via=SequestrIO&hashtags=climatecrisis,climatechange&text=I am helping solve the climate crisis, see my profile to learn more.`,
            "_blank",
          )
          .focus();
      };

      return {
        // Variables
        address,
        disabled,
        editLocation,
        isFile,
        loading,
        location,
        modalOpen,
        noProfile,
        profile,
        profileSaving,
        password,
        confirmPassword,
        // Methods
        backHome,
        onEditLocation,
        onFileChange,
        onModalToggle,
        onPlace,
        onSaveProfile,
        removeFile,
        shareProfile,
      };
    },
  };
</script>
